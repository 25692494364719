import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { LightsSelectorStyled, OptionButtonGroup, RadioOption } from './LightsSelector.styled';
import { Text } from 'elements';

const options = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
  {
    label: "I'm using a third party light",
    value: 'OTHER',
  },
];

const LightsSelector = ({ title, field, form }) => {
  const [selectedOption, setSelectedOption] = useState(field.value);

  const onSelectOption = (option) => {
    setSelectedOption(option);
    const setField = form?.setFieldValue;
    if (field?.name && setField) setField(field.name, option);
  };

  const renderEnvOption = ({ label, value }, index) => {
    const isSelected = value === selectedOption;

    return (
      <RadioOption
        type='button'
        key={index}
        label={<Text modifiers={['brandFont', 'xLarge', 'left']} content={label} as='span' />}
        onChange={() => onSelectOption(value, index)}
        field={{ name: 'glowRings', value }}
        isSelected={isSelected}
      />
    );
  };

  return (
    <LightsSelectorStyled>
      {!!title && <Text modifiers={['brandFont', 'xLarge', 'left']} content={title} as='span' />}
      <OptionButtonGroup>{options.map(renderEnvOption)}</OptionButtonGroup>
    </LightsSelectorStyled>
  );
};

LightsSelector.propTypes = {
  title: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
};

export default LightsSelector;
