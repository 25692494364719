import styled from 'styled-components';

export const LivePlantsWrapper = styled.div.attrs({
  className: 'LivePlantsWrapper',
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;

  .Icon {
    margin-right: 0.25rem;
    width: 1rem;
    height: 1rem;
  }

  .Text {
    color: ${({ theme }) => theme.farmProject.green};
  }
`;
