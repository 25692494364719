import React from 'react';

import { Modal } from 'components';
import { Text, Title } from 'elements';

const SizeModal = ({ isOpen, onClose, title, body }) => {
  return (
    <Modal isOpen={isOpen} onCloseAction={onClose}>
      <Title content={title}></Title>
      <Text isHTML modifiers='xLarge' content={body}></Text>
    </Modal>
  );
};

export default SizeModal;
