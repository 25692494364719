import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme/Media';

export const modifiers = {
  accentColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.magenta}
    `,
  }),
};

export const BundleShopCardStyled = styled.div.attrs({
  className: 'BundleShopCardStyled ',
})`
  width: 100%;
  max-width: 26.5rem;
  border-radius: 0.25rem;
  border: solid 1px ${({ theme }) => theme.farmProject.gray_2};
  position: relative;
  margin: 0.5rem 1rem 0.5rem 0;

  .PillLabelStyled {
    padding: 0.125rem 0.75rem;
    position: absolute;
    z-index: 5;
    top: 0.75rem;
    left: 0.75rem;

    .Text {
      font-size: 0.65rem;
    }
  }

  .TextTooltipWrapperStyled {
    width: 100%;
    bottom: 0.5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    z-index: 1;
  }

  @media ${breakPoints.medium_break} {
    margin: 1rem 2rem 1rem 0;
    .TextTooltipWrapperStyled {
      padding: 0 1rem 0 10.6rem;
      margin: 0;
      top: auto;
      bottom: 1.9rem;
      transform: translateY(100%);
    }
  }

  ${applyStyleModifiers(modifiers)};
`;

export const BundleShopInner = styled.div.attrs({
  className: 'BundleShopInner',
})`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.25rem;

  @media ${breakPoints.medium_break} {
    flex-direction: row;
  }
`;

export const ImageWrapper = styled.div.attrs({
  className: 'ImageWrapper',
})`
  height: 8.75rem;
  width: 100%;
  flex-shrink: 0;
  cursor: pointer;

  @media ${breakPoints.medium_break} {
    width: 9.5rem;
    height: 17rem;

    .Image {
      picture {
        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }
`;

export const BundleInfo = styled.div.attrs({
  className: 'BundleInfo',
})`
  padding: 0.75rem;

  @media ${breakPoints.medium_break} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const SeedlingsDetails = styled.div.attrs({
  className: 'SeedlingsDetails',
})`
  padding: 0;
  margin: 0;
  list-style: none;

  .Title {
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 0.5rem;
    text-align: left;
  }

  .Text {
    letter-spacing: normal;
    color: ${({ theme }) => theme.farmProject.gray};
    text-align: left;
  }

  .ButtonText {
    margin: 0;
    display: block;
    width: fit-content;

    font-size: 0.875rem;
    margin-top: 0.25rem;

    @media ${breakPoints.medium_break} {
      font-size: 0.8rem;
      margin-top: 0.125rem;
    }

    @media ${breakPoints.large_break} {
      font-size: 0.65rem;
    }

    span {
      font-size: inherit;
    }
  }
`;

export const CardFooter = styled.div.attrs({
  className: 'CardFooter',
})`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .Button {
    width: 55%;
    margin: auto;
    margin-left: 0;

    @media ${breakPoints.medium_break} {
      width: 100%;
    }
  }

  .QtyControl {
    width: 55%;
    margin-top: 0;
    margin-left: 0;
  }
`;

export const FeaturesList = styled.div.attrs({
  className: 'FeaturesList',
})`
  display: flex;
  width: 100%;
  margin: 1rem 0;
  justify-content: left;

  @media ${breakPoints.medium_break} {
    justify-content: flex-start;
  }
`;

export const Feature = styled.div.attrs({
  className: 'Feature',
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1rem;

  .Icon {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.45rem;
    flex-shrink: 0;

    svg {
      path {
        fill: ${({ theme }) => theme.farmProject.light_gray};
      }
    }
  }

  .Text {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.farmProject.light_gray};
    text-align: left;
  }
`;

export const LabelBundle = styled.div.attrs({
  className: 'LabelBundle',
})`
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .Text {
    font-size: 0.65rem;
    color: ${({ theme }) => theme.farmProject.light_gray};
  }

  .Text + .Text {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 0.25rem;
      height: 0.25rem;
      border-radius: 1rem;
      background-color: ${({ theme }) => theme.farmProject.light_gray};
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }
  }
`;
