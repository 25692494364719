import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  collapse: () => ({
    styles: `
      min-height: 0;
    `,
  }),
};

export default styled.div`
  min-height: 100vh;
  ${applyStyleModifiers(modifiers)};
`;
