import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  disabled: () => ({
    styles: `  
      opacity: 0.25;
    `,
  }),
};

export const ProductGiftListStyled = styled.div.attrs({
  className: 'ProductGiftListStyled',
})``;

export const ListInner = styled.div.attrs({
  className: 'ListInner',
})`
  > .Title {
    letter-spacing: normal;
    margin-bottom: 0.5rem;
  }
  .ProductGiftStyled {
    margin-top: 1rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
  ${applyStyleModifiers(modifiers)};
`;
