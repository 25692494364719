import React from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { SwiperStyled } from './Swiper.styled';

require('swiper/swiper.scss');
require('swiper/components/pagination/pagination.scss');
require('swiper/components/navigation/navigation.scss');

SwiperCore.use([Pagination, Navigation, Autoplay]);

const CustomSwiper = ({ items = [], onSwiperClick, renderSlideCard, swiperProps }) => {
  const renderSwiperSlide = (item, index) => {
    const onSlideClick = () => onSwiperClick && onSwiperClick(item, index);

    return (
      <SwiperSlide key={index} onClick={onSlideClick}>
        {renderSlideCard(item, index)}
      </SwiperSlide>
    );
  };

  return (
    <SwiperStyled>
      <Swiper {...swiperProps}>{items.map(renderSwiperSlide)}</Swiper>
    </SwiperStyled>
  );
};

CustomSwiper.displayName = 'CustomSwiper';

CustomSwiper.propTypes = {
  items: PropTypes.array,
  swiperProps: PropTypes.shape({
    loop: PropTypes.bool,
    autoplay: PropTypes.object,
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    navigation: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    centeredSlides: PropTypes.bool,
    centeredSlidesBounds: PropTypes.bool,
    centerInsufficientSlides: PropTypes.bool,
    preventInteractionOnTransition: PropTypes.bool,
    spaceBetween: PropTypes.number,
    slidesPerView: PropTypes.number,
    slidesPerColumn: PropTypes.number,
    slidesPerColumnFill: PropTypes.oneOf(['row', 'column']),
    breakpoints: PropTypes.object,
    watchSlidesProgress: PropTypes.bool,
    onSlideChange: PropTypes.func,
    onSwiper: PropTypes.func,
  }),
  onSwiperClick: PropTypes.func,
  renderSlideCard: PropTypes.func,
};

export default CustomSwiper;
