import { createSlice, createSelector } from '@reduxjs/toolkit';

import { AXIOS } from 'constants/redux';
import { contentCacheUrl } from 'utils/envConfig';
import { FARMSTAND_SKUS, HALO_LIGHTS_SKUS } from 'constants/sku';
import { BUYABLES } from 'constants/contentful';

/**
 * * Contentful - Redux Reducer
 *
 * Contenful App Data
 *
 * @param state
 *
 */

// reducer, action types, action creators all in 1 createSlice
const contentfulSlice = createSlice({
  name: 'contentful',
  initialState: { loading: '' },
  reducers: {
    setContentCacheMap(state, { payload }) {
      state.cacheMap = payload;
    },
    setIsFetchingCacheMap(state, { payload }) {
      state.isFetchingCacheMap = payload;
    },
    fetchContentfulSuceeded(state, { payload }) {
      const { label, data, customSetter } = payload;
      state.loading = '';
      if (customSetter) {
        customSetter(state, data);
      } else if (label) {
        state[payload.label] = payload.data.items;
      }
    },
    // set loading error
    fetchContentfulError(state, { payload }) {
      state.error = payload.error;
      state.loading = '';
    },
    // clear contentful by specific label
    clearContentful(state, { payload }) {
      state[payload.label] = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContentful.toString(), (state, { payload }) => {
      state.loading = payload;
    });
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = contentfulSlice;

// Extract and export action creators from slice by name
export const {
  setContentCacheMap,
  setIsFetchingCacheMap,
  fetchContentfulStart,
  fetchContentfulEnd,
  fetchContentfulError,
  clearContentful,
  setContentfulMessages,
  fetchContentfulSuceeded,
} = actions;

// Export the reducer as the default
export default reducer;

export const getGlowRingContent = createSelector([(state) => state.contentful.buyables], (buyables) => {
  if (!buyables?.length) return [];
  return buyables.filter((buyable) => HALO_LIGHTS_SKUS.includes(buyable.sys.id));
});

export const getContentful = (state) => state.contentful;
export const getBuyablesByCategory = createSelector(
  getContentful,
  (_, cat) => cat,
  (contentful, cat) => contentful[BUYABLES.label]?.filter((entry) => entry.fields.category === cat)
);
export const getContentDeviceTypes = createSelector(getContentful, (contentful) => contentful.deviceTypes);
export const getContentMessages = createSelector(getContentful, (contentful) => contentful.messages || {});
export const getContentCacheMap = createSelector([(state) => state.contentful.cacheMap], (cacheMap) => cacheMap);
export const getIsFetchingCacheMap = createSelector([(state) => state.contentful.isFetchingCacheMap], (isFetching) => isFetching);

export const getFarmstandsContent = createSelector(getContentDeviceTypes, (devices) => {
  if (!devices?.length) return [];
  return devices.filter(({ sys }) => FARMSTAND_SKUS.includes(sys.id));
});

export const getFarmstandsSizeNormalized = createSelector(getFarmstandsContent, (farmstands) => {
  if (!farmstands?.length) return [];
  return farmstands.reduce((acc, device) => {
    const { sys, fields } = device;
    return { ...acc, [fields.size]: { id: sys.id, ...fields } };
  }, {});
});

// Async Action Types
export const FETCH_CONTENTFUL = 'contentful/FETCH_ENTRIES';

// Async action creator for fetching contentful data
export const fetchContentful = (payload) => ({
  type: FETCH_CONTENTFUL,
  payload,
});

// Async action creator for fetching contentful data
export const fetchContentCacheMap = () => ({
  type: AXIOS,
  payload: {
    url: `${contentCacheUrl}/cache.json`,
    method: 'GET',
    onSuccess: setContentCacheMap,
    setLoading: setIsFetchingCacheMap,
    // TODO: onFailure: retryFetchContentCacheMap,
  },
});

// Async action creator for fetching contentful messages data
export const fetchContentfulMessages = () => ({
  type: FETCH_CONTENTFUL,
  payload: {
    label: 'messages',
    content_type: 'errorMessages',
    customSetter: (state, data) => {
      state.messages = data.items.reduce((acc, curr) => {
        acc[curr.fields.errorCode] = curr.fields.message;
        return acc;
      }, {});
    },
  },
});
