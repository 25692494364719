import React from 'react';
import PropTypes from 'prop-types';

import CardStyled from './Card.styled';

const Card = ({ children, modifiers }) => {
  return <CardStyled modifiers={modifiers}>{children}</CardStyled>;
};

Card.defaultProps = {
  children: null,
};
Card.propTypes = {
  children: PropTypes.any,
};

export default Card;
