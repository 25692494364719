import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Modal from '../Modal';
import { Text, SquaredButton, Title } from 'elements';
import { ModalButtonGroup, ModalPlantsRow } from './SelectBundleModal.styled';

import { getNumSeedlingsInCart } from 'reduxState/cart';

const SelectBundleModal = ({ show, title, body, onReplace, onAdd, onCloseAction }) => {
  const seedlingsInCartQty = useSelector(getNumSeedlingsInCart) || 0;
  const [body1 = '', bodies2 = ''] = body?.split?.('_plant_number_');
  const [body2 = '', body3 = ''] = bodies2?.split?.('<br>');

  return (
    <Modal isOpen={show} onCloseAction={onCloseAction} modalType='bundleModal'>
      <Title as='h2' modifiers='small' content={title} />
      <ModalPlantsRow>
        <Text modifiers={['xLarge', 'brandFont']}>
          {body1}
          <Text as='span' modifiers='brandSmall'>
            {seedlingsInCartQty}
          </Text>
          {body2}
        </Text>
      </ModalPlantsRow>
      <Text modifiers={['xLarge', 'brandFont', 'light']}>
        <br />
        {body3}
      </Text>
      <ModalButtonGroup>
        <SquaredButton label='ADD' modifiers='whiteActive' onClick={onAdd} />
        <SquaredButton label='REPLACE' modifiers='tertiary' onClick={onReplace} />
      </ModalButtonGroup>
    </Modal>
  );
};

SelectBundleModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  onReplace: PropTypes.func,
  onAdd: PropTypes.func,
  onCloseAction: PropTypes.func,
};

export default SelectBundleModal;
