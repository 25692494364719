import React from 'react';
import { useDispatch } from 'react-redux';
import { graphql, navigate, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { FarmProductDetail } from 'components';

import { paths } from 'constants';
import { addBulkItems, addBulkItemsAndDiscount, setLastAddedFarmstandId } from 'reduxState/cart';
import { useCatalog } from 'utils/hooks';

const HERO_SECTIONS = {
  FARM_SIZE: '33UOk53H6Dpt6KK84ATtdQ',
  FARMSTANDS: '1368lss8SXKqO26v9USf0B',
  GLOW_RINGS: '5Xhp8IPTGCRqqsoOkwA8mJ',
  INFO: '56AAjSX24Q93rD4AWOwSry',
  ACCORDION: 'hbaq2ebZDR5uqxQoJ6hN9',
  GIFT: '70NeXaQmuLKX5lWC28mlfB',
  PROMO: '6VUrIn7ScLkCHmoiBHqLNM',
  VISUALIZER_BANNER: 'xVLZyh7vzRqUNtl0OIP86',
};

const FarmstandHeroSection = ({
  title,
  lowerTitle,
  body,
  percentages = [],
  farmSize,
  hasScrollBehavior = false,
  customSectionIDs,
  customImageData,
}) => {
  const imageData = graphql`
    query {
      contentfulSectionBlocks(contentful_id: { eq: "1ImV6ZP0zJL4RtFbCWgQ9O" }) {
        mediaBackground {
          title
          contentful_id
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
          title
          file {
            url
            contentType
            fileName
          }
        }
        leftBackgroundAccent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
        body2 {
          body2
          id
        }
      }
    }
  `;

  useCatalog();
  const dispatch = useDispatch();
  const { FARM_SIZE, FARMSTANDS, GLOW_RINGS, INFO, ACCORDION, PROMO, GIFT, VISUALIZER_BANNER } = customSectionIDs || HERO_SECTIONS;
  const farmstandSection = percentages?.find?.(({ sys }) => sys.id === FARMSTANDS)?.fields || {};
  const farmstandsData = farmstandSection?.farmstandModels?.map?.((e) => ({ ...(e?.fields || {}), id: e?.sys?.id || '' })) || [];
  const glowRingsSection = percentages?.find?.(({ sys }) => sys.id === GLOW_RINGS)?.fields || {};
  const infoSection = percentages?.find?.((e) => e.sys.id === INFO)?.fields || {};
  const accordionSection = percentages?.find?.((e) => e.sys.id === ACCORDION)?.fields?.percentages || [];
  const promoSection = percentages?.find?.(({ sys }) => sys.id === PROMO)?.fields || {};
  const giftsSection = percentages?.find?.(({ sys }) => sys.id === GIFT)?.fields || {};
  const buttonSizesTitle = percentages?.find?.(({ sys }) => sys.id === FARM_SIZE)?.fields?.title;
  const growEnvTitle = percentages?.find?.(({ sys }) => sys.id === FARM_SIZE)?.fields?.body2;
  const sizeModalContent = percentages?.find?.(({ sys }) => sys.id === FARM_SIZE)?.fields?.primaryButton;
  const visualizerContent = percentages?.find?.(({ sys }) => sys.id === VISUALIZER_BANNER)?.fields;

  const onAddFarmstand = ({ farmstandCatalog, glowRingCatalog, relatedGifts, isPromoSelected }) => {
    if (farmstandCatalog) {
      const items = [{ ...farmstandCatalog, qty: 1 }];
      if (glowRingCatalog) items.push({ ...glowRingCatalog, qty: 1 });
      if ((relatedGifts || []).length) relatedGifts.forEach(({ sys }) => items.push({ sku: sys.id, qty: 1 }));

      if (isPromoSelected && !!(promoSection || {}).title) dispatch(addBulkItemsAndDiscount({ items, discount: promoSection.title }));
      else dispatch(addBulkItems({ items }));

      dispatch(setLastAddedFarmstandId(farmstandCatalog.sku));
      navigate(paths.FILL_YOUR_FARMSTAND_BUNDLES);
    }
  };

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        const finalData = customImageData ? customImageData : data;
        return (
          <FarmProductDetail
            title={title}
            lowerTitle={lowerTitle}
            body={body}
            sizeModalContent={sizeModalContent}
            mediaBackgroundGatsby={finalData?.contentfulSectionBlocks?.mediaBackground}
            leftBackgroundAccentGatsby={finalData?.contentfulSectionBlocks?.leftBackgroundAccent?.gatsbyImageData}
            video={finalData?.contentfulSectionBlocks?.body2?.body2}
            buttonSizesTitle={buttonSizesTitle}
            growEnvTitle={growEnvTitle}
            glowRingsSection={glowRingsSection}
            infoSection={infoSection}
            accordionSection={accordionSection}
            promoSection={promoSection}
            giftsSection={giftsSection}
            farmstandsData={farmstandsData}
            visualizerContent={visualizerContent}
            onAddToCart={onAddFarmstand}
            hasScrollBehavior={hasScrollBehavior}
            farmSize={farmSize}
          />
        );
      }}
    />
  );
};

FarmstandHeroSection.propTypes = {
  title: PropTypes.string,
  lowerTitle: PropTypes.string,
  body: PropTypes.string,
  percentages: PropTypes.array,
  farmSize: PropTypes.string,
  hasScrollBehavior: PropTypes.bool,
  customSectionIDs: PropTypes.object,
  customImageData: PropTypes.any,
};

export default FarmstandHeroSection;
