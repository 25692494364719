import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { minBy as _minBy, maxBy as _maxBy, isEmpty as _isEmpty, find as _find } from 'lodash';

import { ButtonText, Title, Text, SquaredButton, ResponsiveEmbed, Icon } from 'elements';
import { GrowEnvironmentToggle, StarRating, AffirmMessage, Modal } from 'components';

import { FarmGlowRings, FarmAccordion, FarmIconFeatures, RentalProgramBanner } from 'components/farm-product-detail/components';
import { FarmSelectSize, FarmCarouselMobile, FarmCarouselDesktop } from './components';

import {
  HeroWrapper,
  HeroDetailStyled,
  HeroDetailInner,
  ProductInformation,
  ProductDescription,
  PlayButtonWrapper,
} from 'components/farm-product-detail/FarmProductDetail.styled';

import { formatDynamicDecimalPrice as formatPrice } from 'utils/cart-utils';
import growingEnvironments from 'constants/growingEnvironments';
import { farmMapSizeSku, glowRingsMapSizeSku } from 'constants/sku';

import IconPlay from 'elements/icon/assets/play-filled-icon.svg';
import { productDetail } from 'utils/googleTagManager';
import { getIsUserInRentalState } from 'app/reduxState/user';

const FarmProductDetailBlock = ({
  title,
  body,
  farmstandsData = [],
  farmstandsCatalog,
  glowRingsCatalog,
  reviewsData,
  sizesSection,
  environmentsSection,
  visualizerContent,
  rentalContent,
  glowRingsSection,
  ctaSection,
  iconsSection,
  accordionSection,
  mediaBackground,
  video = '',
  onAddToCart,
  displayPriceRange,
}) => {
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedEnv, setSelectedEnv] = useState(null);
  const [isGRAdded, setIsGRAdded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isUserInContiguous = useSelector(getIsUserInRentalState);

  const farmstandsContent = farmstandsData.reduce((farms, farm) => ({ ...farms, [farm.size]: farm }), {});
  const selectedFarmstand = farmstandsContent[selectedSize];
  const farmstandPrice = farmstandsCatalog.find((farmCat) => +farmCat.size === +selectedFarmstand?.size)?.priceCents;
  const carouselImages = mediaBackground?.map?.((e) => ({ id: e.sys.id, ...e.fields }));

  const minPrice = formatPrice(_minBy(farmstandsCatalog, (e) => e.priceCents)?.priceCents || 0, false);
  const maxPrice = formatPrice(_maxBy(farmstandsCatalog, (e) => e.priceCents)?.priceCents || 0, false);
  const rangePricesFormatted = `$${minPrice} - $${maxPrice}`;
  const showingGlowRing =
    (!!selectedFarmstand && glowRingsCatalog?.find((e) => e?.sku === glowRingsMapSizeSku[selectedFarmstand?.size])) || null;
  const farmPriceFormatted = formatPrice(farmstandPrice);
  const glowRingAddPrice = (isGRAdded && showingGlowRing?.priceCents) || 0;
  const buttonPrice = formatPrice(farmstandPrice + glowRingAddPrice);
  // HEADS UP -> if we are hiding the GR section, and GR are added, show the user the total(button) price
  const productPriceOrRange = !selectedFarmstand
    ? rangePricesFormatted
    : isGRAdded && !glowRingsSection?.display
    ? buttonPrice
    : farmPriceFormatted;
  const hasFarmstand = !!selectedFarmstand && !_isEmpty(selectedFarmstand);
  const isCtaEnabled = !!selectedSize && !!selectedEnv;
  const buttonText = isCtaEnabled ? `${ctaSection?.text} ${buttonPrice}` : 'SELECT A SIZE';

  useEffect(() => {
    if (sizesSection?.default) setSelectedSize(sizesSection.default);
  }, [sizesSection?.default]);

  useEffect(() => {
    if (environmentsSection?.default) {
      const shouldAddGlowRing = environmentsSection.default === growingEnvironments.INDOOR;
      setSelectedEnv(environmentsSection.default);
      setIsGRAdded(shouldAddGlowRing);
    }
  }, [environmentsSection?.default]);

  const onSelectFarmstand = (newSize) => {
    const farmstandPrice = farmstandsCatalog.find((farmCat) => +farmCat.size === +newSize)?.priceCents;
    setSelectedSize(newSize);
    productDetail({
      pageName: 'The Farmstand',
      name: `${newSize}-Plant Farmstand`,
      id: farmMapSizeSku[newSize],
      price: farmstandPrice,
      category: 'Farmstand',
    });
  };

  const onSelectGlowRing = (isGlowRingAdded) => setIsGRAdded(isGlowRingAdded);

  const onEnvChange = (newEnv) => {
    const shouldAddGlowRing = newEnv === growingEnvironments.INDOOR;
    setSelectedEnv(newEnv);
    setIsGRAdded(shouldAddGlowRing);
  };

  const onAddFarmstand = () => {
    const farmstandCatalog = _find(farmstandsCatalog, { sku: selectedFarmstand.id }) || null;
    const glowRingCatalog = isGRAdded ? showingGlowRing : null;

    if (onAddToCart) onAddToCart({ farmstandCatalog, glowRingCatalog });
  };

  const toggleVideoModal = () => setShowModal(!showModal);

  const playVideoComponent = () => (
    <PlayButtonWrapper>
      <ButtonText label={`Meet the Farmstand`} onClick={toggleVideoModal} eventData={{ action: `Meet the Farmstand` }}>
        <Icon>
          <IconPlay />
        </Icon>
      </ButtonText>
    </PlayButtonWrapper>
  );

  return (
    <HeroWrapper id='shop-farmstands'>
      <HeroDetailStyled>
        {showModal && video && (
          <Modal isOpen={showModal} onCloseAction={toggleVideoModal}>
            <ResponsiveEmbed embedCode={video} />
          </Modal>
        )}
        <HeroDetailInner>
          <FarmCarouselDesktop
            data={carouselImages}
            size={selectedSize}
            environment={selectedEnv}
            visualizerData={visualizerContent}
            playVideoComponent={video && playVideoComponent}
          />
          <FarmCarouselMobile
            data={carouselImages}
            size={selectedSize}
            environment={selectedEnv}
            visualizerData={visualizerContent}
            playVideoComponent={video && playVideoComponent}
          />
          <ProductInformation>
            <Title as='h2' modifiers='secondarySmall' content={title} isHTML />
            {!!reviewsData && (
              <div className='RatingWrapper'>
                <StarRating readOnly rating={Math.round(reviewsData.average)} />
                <ButtonText
                  label={`(${reviewsData.total} ${reviewsData?.total === 1 ? 'REVIEW' : 'REVIEWS'})`}
                  href={reviewsData.url}
                  modifiers={['underlineContent', 'compact', 'secondary']}
                  eventData={{ action: 'Read the Reviews' }}
                />
              </div>
            )}
            {displayPriceRange && (
              <Text as='span' modifiers={['brandFont']} className='Price'>
                {productPriceOrRange}
              </Text>
            )}
            <AffirmMessage priceCents={(hasFarmstand ? +buttonPrice?.replace(/\D+/g, '') : minPrice) * 100} />
            <ProductDescription tabIndex={0}>
              <Text content={body} isHTML />
            </ProductDescription>
            {sizesSection?.display && (
              <FarmSelectSize
                title={sizesSection.title}
                data={sizesSection.options}
                selectedSize={selectedSize}
                onSelectFarmstand={onSelectFarmstand}
              />
            )}
            {environmentsSection?.display && (
              <GrowEnvironmentToggle
                title={<Text content={environmentsSection.title} as='span' modifiers={['brandFont', 'noLineHeight', 'bold', 'small']} />}
                activeLabel={environmentsSection.options[0] || ''}
                inactiveLabel={environmentsSection.options[1] || ''}
                defaultValue={environmentsSection.default}
                modifiers={['darkGray']}
                shouldWaitOnDefault={false}
                onValueChange={onEnvChange}
                showDetailModal
              />
            )}
            {glowRingsSection?.display && selectedEnv === growingEnvironments.INDOOR && (
              <FarmGlowRings
                title={glowRingsSection.headline}
                body={glowRingsSection.body}
                image={glowRingsSection.image?.fields?.file?.url}
                glowRing={showingGlowRing}
                hasFarmstand={hasFarmstand}
                onSelectGlowRing={onSelectGlowRing}
                isGRAdded={isGRAdded}
              />
            )}
            <SquaredButton
              modifiers={['tertiary', !isCtaEnabled && 'newDisabled']}
              label={buttonText}
              onClick={onAddFarmstand}
              dataNw='add-farmstand-to-cart'
              eventData={{ action: buttonText }}
            />
            <RentalProgramBanner
              title={rentalContent?.title}
              body={rentalContent?.body}
              cta={rentalContent?.primaryButton?.fields}
              shouldShow={isUserInContiguous && !!selectedEnv && selectedEnv !== growingEnvironments.INDOOR}
            />
            {ctaSection?.headline && <Text modifiers={['brandFont', 'center']} content={ctaSection.headline} />}
            {!!iconsSection?.length && <FarmIconFeatures data={iconsSection} shouldUseDescription />}
            {!!accordionSection && <FarmAccordion data={accordionSection} />}
          </ProductInformation>
        </HeroDetailInner>
      </HeroDetailStyled>
    </HeroWrapper>
  );
};

FarmProductDetailBlock.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  farmstandsData: PropTypes.array,
  farmstandsCatalog: PropTypes.array,
  glowRingsCatalog: PropTypes.array,
  reviewsData: PropTypes.object,
  sizesSection: PropTypes.object,
  glowRingsSection: PropTypes.object,
  environmentsSection: PropTypes.object,
  ctaSection: PropTypes.object,
  iconsSection: PropTypes.array,
  accordionSection: PropTypes.array,
  mediaBackground: PropTypes.array,
  visualizerContent: PropTypes.object,
  rentalContent: PropTypes.object,
  video: PropTypes.string,
  onAddToCart: PropTypes.func,
  displayPriceRange: PropTypes.bool,
};

export default FarmProductDetailBlock;
