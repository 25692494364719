import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const modifiers = {
  disabled: () => ({
    styles: `
      pointer-events: none;
      .Title, .Text {
        opacity: 0.3;
      }
      .Image {
        opacity: 0.2;
      }
    `,
  }),
};

export const GlowingRingCallStyled = styled.div.attrs({
  className: 'GlowingRingCallStyled',
})`
  margin: 1.25rem 0;
`;

export const GlowingCallInner = styled.div.attrs({
  className: 'GlowingCallInner',
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;

  .Image {
    width: 6.5rem;
    height: 6.5rem;
    flex-shrink: 0;

    picture {
      img {
        width: 105%;
      }
    }
  }

  ${applyStyleModifiers(modifiers)};
`;

export const GlowingCallInformation = styled.div.attrs({
  className: 'GlowingCallInformation',
})`
  margin-left: 0.5rem;
  max-width: 15rem;

  .Title {
    font-size: 1rem;
    letter-spacing: normal;
  }

  .Text {
    margin: 0.25rem 0 0.65rem;
    * {
      font-size: 0.75rem;
    }

    a {
      color: ${({ theme }) => theme.farmProject.green};
    }
  }

  .Button {
    height: 1.75rem;
    padding: 0.125rem;
    width: 100%;
  }
`;
