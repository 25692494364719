import styled from 'styled-components';

import breakPoints from 'theme/Media';

import { Image } from '../../elements';

export const HeroProductDetailStyled = styled.div.attrs({
  className: 'HeroProductDetailStyled',
})`
  max-width: 100%;
  margin: 0;
  position: relative;
`;

export const BackgroundMobile = styled(Image).attrs({
  className: 'BackgroundMobile',
})`
  picture {
    img {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }
  }
`;

export const BackgroundDesktop = styled(Image).attrs({
  className: 'BackgroundDesktop',
})`
  picture {
    img {
      width: 105%;
    }
  }

  @media ${breakPoints.xLarge_break} {
    picture {
      img {
        transform: translate(-47%, -50%);
      }
    }
  }
  display: block;
  padding-top: 40%;
`;

export const ImageWrapper = styled.div.attrs({
  className: 'ImageWrapper',
})``;

export const HeroInfoWrapperInner = styled.div.attrs({
  className: 'HeroInfoWrapperInner',
})`
  padding: 2rem 1.5rem;
  max-width: 30rem;
  margin: auto;

  @media ${breakPoints.xLarge_break} {
    margin-right: 2rem;
    max-width: 33rem;
  }
`;

export const HeroInfoWrapper = styled.div.attrs({
  className: 'HeroInfoWrapper',
})`
  margin: auto;
  text-align: center;
  width: 100%;
  background-color: ${({ theme }) => theme.farmProject.light_green_7};
  height: 100%;

  .Button,
  .QtyControl {
    margin: auto;
    margin-left: 0;
    width: 100%;
    display: block;
    height: 2.5rem;
    max-width: 20rem;
  }
  @media ${breakPoints.xLarge_break} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-100%, -50%);
    width: 50%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .TextTooltipWrapperStyled {
    justify-content: flex-start;
    .Text {
      font-size: 0.825rem;
    }
    .TipIconWrapper {
      .TooltipStyled {
        width: 25vw;
        min-width: 12rem;

        .Text {
          font-size: 0.725rem;
        }

        @media ${breakPoints.medium_break} {
          width: 15vw;
        }
      }
    }
  }
`;

export const ProductInfo = styled.div.attrs({
  className: 'ProductInfo',
})`
  margin-bottom: 1.5rem;

  .Title {
    text-align: center;
    font-size: 2.5rem;
    line-height: normal;
    text-align: left;
    font-weight: 500;
  }

  .Text {
    text-align: left;
  }

  span {
    display: block;
    margin: 0.125rem 0 1rem;
    font-style: italic;
    text-align: left;
  }

  @media ${breakPoints.xLarge_break} {
    padding-bottom: 0;
    border-bottom: none;

    .Title {
      font-size: 3rem;
    }
  }
`;
