import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Picture = styled.picture`
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
  display: block;
  img {
    max-width: 100%;
  }
`;

const Image = ({ src, urlParams, alt, onClick, forwardRef, className, height, width, media, loading, isStaticAsset }) => {
  // use webp format for supporting browsers
  const imgParams = urlParams ? urlParams + '&fm=webp' : '?fm=webp';
  return (
    <Picture onClick={onClick} ref={forwardRef} className={className} tabIndex={onClick ? 0 : -1}>
      <source srcSet={isStaticAsset ? src : src + imgParams} type='image/webp' alt={alt} media={media} />
      <img height={height} width={width} src={src} alt={alt} loading={loading} />
    </Picture>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  urlParams: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  forwardRef: PropTypes.any,
};

Image.defaultProps = {
  urlParams: '',
  alt: '',
  onClick: null,
};

export default Image;
