import { useEffect, useState } from 'react';
import { environment } from 'utils/envConfig';
/**
 * * useAffirm - Custom hook to async load stripe script, create a stripe instance and return it
 *  Affirm will load (if it hasn't already) and refresh (if it has already loaded)
 *  refreshTrigger is an optional argument to force affirm script refresh under a certain condition
 *
 */

export default function useAffirm() {
  const [tooltips, setTooltipAdditions] = useState([]); // affirm tooltips added to DOM
  let config_key = '';
  let config_script = '';

  if (environment === 'production') {
    config_key = 'OJMITFM5G4ASXH46';
    config_script = 'https://cdn1.affirm.com/js/v2/affirm.js';
  } else {
    config_key = 'G1BDVAGDRF8EQAX5';
    config_script = 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js';
  }

  // set observer
  useEffect(() => {
    // early return for older browsers - add array length to force load script
    if (!window.MutationObserver) setTooltipAdditions(['forceLoad']);

    const observer = new MutationObserver(records => {
      const tooltipAdded = records.find(e =>
        [...e.addedNodes].find(n => {
          return n.querySelector && n.querySelector('.affirm-product-modal');
        })
      );

      if (tooltipAdded && !tooltips.includes(tooltipAdded)) {
        setTooltipAdditions([...tooltips, tooltipAdded]);
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      if (observer) observer.disconnect();
    };
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    let timeout;

    // Make sure affirm is loaded
    if (window && window.affirm && tooltips.length) {
      // Refresh affirm - handles async loaded affirm modal links
      if (window.affirm.ui && window.affirm.ui.refresh) window.affirm.ui.refresh();

      // Create the callback for affirm actions.
      window.affirm.ui.ready(function() {
        // For affirm checkout errors, we want to just refresh the checkout page
        window.affirm.ui.error.on('close', function() {
          // Refresh the checkout page.
          document.location.reload();
        });

        // listen for the closing of the Affirm modal on mobile
        // and scroll back to the Affirm button once you've done so
        if (typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1) {
          window.affirm.events.on('prequal:close', function() {
            document.querySelector('.affirm-product-modal').scrollIntoView({ behavior: 'smooth', block: 'center' });
          });
        }
      });
    }

    // If affirm not yet loaded then set up and load the script
    if (window && !window.affirm && tooltips.length) {
      const affirmJs = document.createElement('script');
      affirmJs.id = 'affirm-js';
      affirmJs.async = true;
      affirmJs.defer = true;
      affirmJs.text = `
      const _affirm_config = {};
      _affirm_config.public_api_key = "${config_key}";
      _affirm_config.script = "${config_script}";
    (function(l, g, m, e, a, f, b) {
      var d,
        c = l[m] || {},
        h = document.createElement(f),
        n = document.getElementsByTagName(f)[0],
        k = function(a, b, c) {
          return function() {
            a[b]._.push([c, arguments]);
          };
        };
      c[e] = k(c, e, 'set');
      d = c[e];
      c[a] = {};
      c[a]._ = [];
      d._ = [];
      c[a][b] = k(c, a, b);
      a = 0;
      for (b = 'set add save post open empty reset on off trigger ready setProduct'.split(' '); a < b.length; a++) d[b[a]] = k(c, e, b[a]);
      a = 0;
      for (b = ['get', 'token', 'url', 'items']; a < b.length; a++) d[b[a]] = function() {};
      h.async = !0;
      h.defer = true;
      h.src = g[f];
      n.parentNode.insertBefore(h, n);
      delete g[f];
      d(g);
      l[m] = c;
    })(window, _affirm_config, 'affirm', 'checkout', 'ui', 'script', 'ready');
    `;
      // set 5 sec timeout here to wait for initial load to pass
      timeout = setTimeout(() => {
        if (document.body && !window.affirm && tooltips.length) {
          document.body.appendChild(affirmJs);
        }
      }, 5000);
    }
    return () => clearTimeout(timeout, 5000);
  }, [config_key, config_script, tooltips]);
}
