import styled from 'styled-components';

export const UserReviewCardStyled = styled.div.attrs({
  className: 'UserReviewCardStyled',
  tabIndex: '0',
})`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  height: 12.813rem;
  border-radius: 0.5rem;
  background-color: white;

  .RateStarsWrapper {
    margin-bottom: 0.5rem;

    .Icon {
      svg {
        * {
          stroke: transparent;
        }
      }
    }
  }

  .Title {
    text-transform: normal;
    text-align: left;
  }

  .Text {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .Text + .Text {
    margin-top: 0.5rem;
  }
`;

export const NameCheck = styled.div.attrs({
  className: 'NameCheck',
})`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.25rem;

  .Icon {
    border-radius: 100%;
    background-color: ${({ theme }) => theme.farmProject.green};
    margin-left: 0.45rem;
    position: relative;
    top: 1px;

    svg {
      width: 65%;
      height: 65%;
      fill: white;
    }
  }
`;
