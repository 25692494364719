import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual as _isEqual } from 'lodash';
import SwiperCore, { Navigation, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import _isEmpty from 'lodash/isEmpty';

require('swiper/swiper.scss');
require('swiper/components/navigation/navigation.scss');
require('swiper/components/keyboard');

import { Media, PillLabel } from 'components';
import { FarmVisualizerBanner } from 'components/farm-product-detail/components';

import {
  ProductHeroSliderStyled,
  ProductSlideStyled,
  HeroSliderInner,
  HeroSliderSecondary,
  ProductSlideThumb,
} from 'components/farm-product-detail/components/farm-carousel-desktop/FarmCarouselDesktop.styled';

import { FARMSTAND_SKUS } from 'constants/sku';
import { trackClick } from 'utils/googleTagManager';
import growingEnvironments from 'constants/growingEnvironments';

const { OUTDOOR, INDOOR } = growingEnvironments;

SwiperCore.use([Navigation, Keyboard]);

const FarmCarouselDesktop = ({ size, data = [], dataGatsby = [], promo, playVideoComponent, visualizerData, environment }) => {
  const [imgSelectIndex, setImgSelectIndex] = useState(0);
  const finalData = useMemo(() => {
    const imgs = dataGatsby?.length > 0 ? dataGatsby : data;
    return imgs.filter(({ title }) => !environment || title.includes(environment) || (!title.includes(INDOOR) && !title.includes(OUTDOOR)));
  }, [data, dataGatsby, environment]);
  const shouldLoop = (finalData?.length || 0) > 6;
  const slidesPerView = shouldLoop ? 6 : finalData?.length;

  useEffect(() => {
    if (size && finalData?.length) {
      const farmImgIndex = finalData.findIndex((e) => e.description.includes(size));
      if (farmImgIndex > -1) setImgSelectIndex(farmImgIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  const renderProductSlide = useCallback(
    (slideItem, index) => {
      const onSelectImage = (index) => () => {
        setImgSelectIndex(index);
        trackClick({ action: 'FS Image' });
      };

      const handleKeyPress = (index) => (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.stopPropagation();
          setImgSelectIndex(index);
        }
      };

      const isSelected = (index) => imgSelectIndex === index;

      if (!slideItem) return null;

      return (
        <SwiperSlide
          key={index}
          onClick={onSelectImage(index)}
          role='button'
          tabIndex={0}
          onKeyPress={handleKeyPress(index)}
          aria-label={(FARMSTAND_SKUS.includes(slideItem?.description) && slideItem.title) || slideItem.description}
        >
          <ProductSlideThumb modifiers={isSelected(index) && 'selectedBorder'}>
            <Media
              altDescription={FARMSTAND_SKUS.includes(slideItem?.description) && slideItem.title}
              asset={slideItem}
              nativeWidth={200}
            />
          </ProductSlideThumb>
        </SwiperSlide>
      );
    },
    [imgSelectIndex]
  );

  const renderMainImage = useCallback(() => {
    return (
      <Media
        altDescription={FARMSTAND_SKUS.includes(finalData?.[imgSelectIndex]?.description) && finalData[imgSelectIndex].title}
        asset={finalData?.[imgSelectIndex]}
        nativeWidth={1000}
      />
    );
  }, [imgSelectIndex, finalData]);

  return (
    <ProductHeroSliderStyled>
      {!!promo && !!finalData?.[imgSelectIndex]?.description && <PillLabel content={promo} />}
      <HeroSliderInner>
        <ProductSlideStyled>{renderMainImage()}</ProductSlideStyled>
      </HeroSliderInner>
      <HeroSliderSecondary>
        <Swiper slidesPerView={slidesPerView} watchSlidesVisibility watchSlidesProgress loop={shouldLoop} navigation={shouldLoop}>
          {finalData?.map(renderProductSlide)}
        </Swiper>
      </HeroSliderSecondary>
      {!!visualizerData && !_isEmpty(visualizerData) && (
        <FarmVisualizerBanner
          title={visualizerData.title}
          body={visualizerData.body}
          cta={visualizerData.primaryButton?.fields}
          mobileCtas={visualizerData.additionalButtons}
        />
      )}
      {playVideoComponent && playVideoComponent()}
    </ProductHeroSliderStyled>
  );
};

FarmCarouselDesktop.propTypes = {
  size: PropTypes.any,
  data: PropTypes.array,
  dataGatsby: PropTypes.array,
  promo: PropTypes.string,
  playVideoComponent: PropTypes.any,
  visualizerData: PropTypes.object,
  environment: PropTypes.string,
};

const shouldPreventUpdate = (prevProps, nextProps) => {
  return (
    _isEqual(prevProps.data, nextProps.data) &&
    _isEqual(prevProps.dataGatsby, nextProps.dataGatsby) &&
    _isEqual(prevProps.visualizerData, nextProps.visualizerData) &&
    prevProps.size === nextProps.size &&
    prevProps.promo === nextProps.promo &&
    prevProps.environment === nextProps.environment &&
    !!prevProps.playVideoComponent === !!nextProps.playVideoComponent
  );
};

export default memo(FarmCarouselDesktop, shouldPreventUpdate);
