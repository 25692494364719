import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';

import useContent from 'utils/hooks/useContent';
import { getOpenModal, setCloseModal } from 'reduxState/modal';
import { GROW_ENVIRONMENT_MODAL } from 'constants/contentful';

import { Title, Text } from 'elements';
import { GrowEnvironmentModalStyled } from './GrowEnvironmentModal.styled';
import { Modal } from 'components/modal';

const GrowEnvironmentModal = () => {
  const openModal = useSelector(getOpenModal);
  const content = useContent(GROW_ENVIRONMENT_MODAL, openModal.id === 'growEnvironment');
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(setCloseModal());
  };

  if (!content) return null;

  return (
    <GrowEnvironmentModalStyled>
      <Modal isOpen={openModal.id === 'growEnvironment'} onCloseAction={handleCloseModal}>
        <Title content={content[0].fields.title} />
        <Text modifiers='xLarge' isHTML content={content[0].fields.subHeaderText} />
      </Modal>
    </GrowEnvironmentModalStyled>
  );
};

GrowEnvironmentModal.defaultProps = {};

GrowEnvironmentModal.propTypes = {};

export default GrowEnvironmentModal;
