import React from 'react';
import { useSelector } from 'react-redux';

import { Text } from 'elements';
import { ZipMessageErrorStyled } from './ZipMessageError.styled';

const ZipMessageError = () => {
  const isZipSupported = useSelector((state) => state.user.isZipSupported);
  const messages = useSelector((state) => state.contentful.messages);

  if (isZipSupported || !messages) return null;
  const { LG_invalid_zipcode } = messages;

  return (
    <ZipMessageErrorStyled>
      <Text modifiers={['small', 'error']} isHTML content={LG_invalid_zipcode} />
    </ZipMessageErrorStyled>
  );
};

export default ZipMessageError;
