import styled from 'styled-components/macro';
import breakpoints from 'theme/Media';

const PromoContainerStyled = styled.div.attrs({ className: 'PromoContainer' })`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0;
  > div {
    flex: 1;
    margin-right: 1rem;
    display: flex;
    justify-content: space-between;
  }
  > button {
    align-self: unset;
  }
  @media ${breakpoints.medium_break} {
    > div {
      margin-right: 0;
    }
  }
`;

export default PromoContainerStyled;
