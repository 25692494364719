export const NON_RENTAL_STATES = ['AK', 'HI'];

export const isZipFromContiguous = (zipCode) => {
  const intZip = +zipCode;
  const isHawaii = intZip >= 96701 && intZip <= 96898;
  const isAlaska = intZip >= 99501 && intZip <= 99950;

  return intZip && !isHawaii && !isAlaska;
};

export const isStateOrZipFromRentalState = ({ zip, state }) => {
  return state ? !NON_RENTAL_STATES.includes(state) : !!zip && isZipFromContiguous(zip);
};
