import React from 'react';
import PropTypes from 'prop-types';

import ShapeIcon from 'elements/icon/assets/farmstand.svg';
import SeedlingsIcon from 'elements/icon/assets/seedling.svg';
import GrowIcon from 'elements/icon/assets/seedling-port.svg';
import DinnerIcon from 'elements/icon/assets/dinner-plate.svg';
import SmallArrow from 'elements/icon/assets/arrow-small.svg';
import Arrow from 'elements/icon/assets/arrow.svg';
import { ContentSection } from 'components';
import { Text, Title, Icon } from 'elements';

import {
  HowItWorksSectionStyled,
  ListIcon,
  ListItem,
  IconWrapper,
  RefilProcess,
  RefilLabel,
  RefilLabelIcon,
} from './HowItWorksSection.styled';

const howItWorksIcons = {
  '7Hjp83UOKUGOp3pT9ck5vV': ShapeIcon,
  Uah5WhpMXMPKELCTofkl3: SeedlingsIcon,
  '1ER3g8JXK8oVKSLrkwkv0L': GrowIcon,
  '3XUNp6Wp0nFX0mn2UEWX3E': DinnerIcon,
};

const HowItWorksSection = ({ title, steps }) => {
  return (
    <HowItWorksSectionStyled tabIndex={0}>
      <ContentSection animation='fadeInUp'>
        <div>
          <Title as='h3' modifiers='secondarySmall' content={title} />
        </div>
        <ListIcon>
          {steps.map((stepItem) => {
            const id = stepItem?.sys?.id;
            const title = stepItem?.fields?.title;
            const body = stepItem?.fields?.body;
            const StepIcon = howItWorksIcons[id];

            return (
              <ListItem key={id} tabIndex={0}>
                <IconWrapper>
                  <Icon>
                    <StepIcon />
                  </Icon>
                </IconWrapper>
                <Title as='h5' modifiers='lineHeightNormal' content={title} />
                <Text content={body} />
              </ListItem>
            );
          })}
        </ListIcon>
        <RefilProcess tabIndex={0} aria-label='repeat last three steps'>
          <Icon>
            <SmallArrow />
          </Icon>
          <RefilLabelIcon aria-label='return to initial steps'>
            <Icon>
              <Arrow />
            </Icon>
            <Text modifiers='brand' as='span' content='Repeat' />
          </RefilLabelIcon>
          <RefilLabel>
            <Text modifiers='brand' as='span' content='Repeat' />
          </RefilLabel>
        </RefilProcess>
      </ContentSection>
    </HowItWorksSectionStyled>
  );
};

HowItWorksSection.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array,
};

export default HowItWorksSection;
