import styled from 'styled-components/macro';
import breakPoints from 'theme/Media';

export const GrowInOutSection = styled.div.attrs({
  className: 'GrowInOut',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.gray_1};

    .SectionInner {
      .ContentWrapper {
        .Title {
          margin-bottom: 3rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 3rem;
            max-width: 30rem;
            margin: auto;
            margin-bottom: 3rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding: 8rem 2rem;

      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 4rem;
            max-width: 48rem;
            margin: auto;
            margin-bottom: 4rem;
          }
        }
      }
    }
  }
`;

export const OptionsGrow = styled.div.attrs({
  className: 'OptionsGrow',
})`
  margin: 5rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
`;

export const Option = styled.div.attrs({
  className: 'Option',
})`
  width: calc(50% - 0.7rem);
  height: 22rem;
  position: relative;

  @media ${breakPoints.medium_break} {
    height: 20rem;
  }

  @media ${breakPoints.large_break} {
    height: 12rem;
    display: flex;
    justify-content: center;

    .Shape {
      width: 45%;
      max-width: 7rem;
      margin-right: 1rem;
    }

    .OptionInformation {
      width: 55%;
      max-width: 13rem;

      .Title {
        font-size: 1.875rem;
        text-align: left;
      }

      .Text {
        font-size: 0.875rem;
        text-align: left;
      }

      .Button {
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        margin-top: 3rem;

        span {
          font-size: 0.75rem;
        }
      }
    }
  }
`;

export const Shape = styled.div.attrs({
  className: 'Shape',
})`
  position: relative;

  .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    color: #ff00ff;
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translate(70%, -50%);
  }

  .Image {
    margin-bottom: 2rem;

    picture {
      img {
        max-width: 3.75rem;
        margin: auto;
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }
    }
  }

  .Icon {
    height: 8rem;
    margin: auto;
    margin-bottom: 1.5rem;

    svg {
    }
  }
`;

export const OptionInformation = styled.div.attrs({
  className: 'OptionInformation',
})`
  .Title {
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: normal;
  }

  .Text {
    font-size: 0.75rem;
    line-height: 1.75;
  }

  .Button {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0.25rem;

    span {
      font-size: 0.6rem;
    }
  }

  @media ${breakPoints.medium_break} {
    .Button {
      max-width: 11rem;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
