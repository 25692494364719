import styled from 'styled-components/macro';

import { Modal } from 'components';

export default styled(Modal)`
  .InputStyled {
    margin-bottom: 1rem;
    div {
      max-width: unset;
    }
  }
  .SelectStyled {
    margin-top: 1rem;
  }
  .Button {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  form {
    margin-top: 2rem;
  }
`;
