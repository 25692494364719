import React, { createContext, useState, useContext, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FeatureFlagStatus } from 'constants/featureFlagStatus';
import PropTypes from 'prop-types';
import { getStorage } from 'utils/storageManager';

export const FeatureFlagContext = createContext(null);
export const useFeatureFlags = () => useContext(FeatureFlagContext);

const FeatureFlagProvider = ({ children }) => {
  const featureFlagData = useStaticQuery(graphql`
    query {
      flagMap: allContentfulFeatureFlag(filter: { status: { ne: "Rollout" } }) {
        nodes {
          name
          status
          dependentFeatureFlags {
            status
          }
        }
      }

      rolloutFlagMap: allContentfulFeatureFlag(filter: { status: { eq: "Rollout" } }) {
        nodes {
          name
          status
          dependentFeatureFlags {
            status
          }
        }
      }
    }
  `);

  //TODO implement infinte recursive dependent Feature flag: featureA -> featureB -> featureC ...
  const flagMap = featureFlagData.flagMap.nodes.reduce((map, flag) => {
    const canFeatureBeEnabled = flag.dependentFeatureFlags
      ? flag.dependentFeatureFlags?.every((dep) => dep.status === FeatureFlagStatus.ON.label)
      : true;

    map[flag.name] = canFeatureBeEnabled && flag.status === FeatureFlagStatus.ON.label;
    return map;
  }, {});

  const rolloutFlagMap = featureFlagData.rolloutFlagMap.nodes.reduce((map, flag) => {
    map[flag.name] = FeatureFlagStatus.ROLLOUT.value;
    return map;
  }, {});

  const [flags, setFlags] = useState({ ...flagMap, ...rolloutFlagMap });

  useEffect(() => {
    const flagOverrides = Object.keys(rolloutFlagMap).reduce((map, key) => {
      map[key] = getStorage(key) || rolloutFlagMap[key];
      return map;
    }, {});

    setFlags({ ...flags, ...flagOverrides });
  }, []);

  return <FeatureFlagContext.Provider value={flags}>{children}</FeatureFlagContext.Provider>;
};

FeatureFlagProvider.propTypes = {
  children: PropTypes.any,
};

export default FeatureFlagProvider;
