const messages = {
  CARD: 'LGE_card_invalid',
  CARD_FAILED: 'LGE_stripeCardFailed',
  ADDRESS: 'LGE_fedExAddressFailed',
  EMAIL: 'LGE_email_invalid',
  GENERIC: 'LGE_0',
  PASSWORD: 'LGE_password_invalid',
  PHONE: 'LGE_phone_invalid',
  REQUIRED: 'LGE_required',
  SIGN_UP: 'LG_newsletter_submit',
  ZIP: 'LGE_zip_pattern',
  PO_BOX: 'LGE_fedExPoBox',
  PROMO_SUCCESS: 'LG_coupon_applied',
  PROMO_FAILURE: 'LGE_coupon_invalid',
  WRONG_PASSWORD: 'auth/wrong-password', //from firebase
  USER_NOT_FOUND: 'auth/user-not-found', //from firebase
  TOO_MANY: 'auth/too-many-requests', //from firebase
  INVALID_EMAIL: 'auth/invalid-email', //from firebase - unlikely to see since have front-end validation
  USER_DISABLED: 'auth/user-disabled', //from firebase - unlikely to see as we don't disable users in firebase
  RESET_SUCCESS: 'LG_reset_password_success', //made up - not from backend
  SET_SUCCESS: 'LG_set_password_success', //made up - not from backend
  SUSPENDED: 'LGE_suspended', //made up - not from backend
  CANCELED: 'LGE_canceled', //made up - not from backend
  PAUSED: 'LGE_paused', //made up - not from backend
  ZIP_UNSUPPORTED: 'LGE_zip_unsupported',
};

messages.ALL = Object.values(messages);

export default messages;
