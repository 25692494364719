const layout = {
  maxContainerWidth: '1440px',
  desktopNavHeight: '0',
  mobileNavHeight: '0',
  desktopWidth: '1140px',
  desktopMinWidth: '1023px',
  mobileWidth: '740px',
  tabletWidth: '960px',
};

export default layout;
