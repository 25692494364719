const DIGIT = '9',
  ALPHA = 'A',
  ALPHANUM = 'S';

export const phoneNumberRegex = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/;

export const punctuationNumberRegex = /\B(?=(\d{3})+(?!\d))/g;

export const prodLinkPathRegex = /lettucegrow.com(\/\S*)/;

export const prodExternalLinkRegex = /^(?!.*(www\.lettucegrow)).*\.lettucegrow\.com.*/gi;

export const formatNumberPunctuation = (numberValue, separator = ',') => {
  if (!numberValue || isNaN(parseInt(numberValue, 10))) return '';

  return numberValue.toString().replace(punctuationNumberRegex, separator);
};

const addPlaceholdersToOutput = (output, index, placeholder) => {
  for (; index < output.length; index++) {
    if (output[index] === DIGIT || output[index] === ALPHA || output[index] === ALPHANUM) {
      output[index] = placeholder;
    }
  }
  return output;
};

export const toPhoneNumber = (value) => toPattern(value, '(999) 999-9999');

// Code from:
// https://github.com/vanilla-masker/vanilla-masker
export const toPattern = (value, opts) => {
  if (!value) return '';
  const pattern = typeof opts === 'object' ? opts.pattern : opts,
    patternChars = pattern.replace(/\W/g, ''),
    output = pattern.split(''),
    values = value.toString().replace(/\W/g, ''),
    charsValues = values.replace(/\W/g, ''),
    outputLength = output.length,
    placeholder = typeof opts === 'object' ? opts.placeholder : undefined;

  let i,
    index = 0;
  for (i = 0; i < outputLength; i++) {
    // Reached the end of input
    if (index >= values.length) {
      if (patternChars.length === charsValues.length) {
        return output.join('');
      } else if (placeholder !== undefined && patternChars.length > charsValues.length) {
        return addPlaceholdersToOutput(output, i, placeholder).join('');
      } else {
        break;
      }
    }
    // Remaining chars in input
    else {
      if (
        (output[i] === DIGIT && values[index].match(/[0-9]/)) ||
        (output[i] === ALPHA && values[index].match(/[a-zA-Z]/)) ||
        (output[i] === ALPHANUM && values[index].match(/[0-9a-zA-Z]/))
      ) {
        output[i] = values[index++];
      } else if (output[i] === DIGIT || output[i] === ALPHA || output[i] === ALPHANUM) {
        if (placeholder !== undefined) {
          return addPlaceholdersToOutput(output, i, placeholder).join('');
        } else {
          return output.slice(0, i).join('');
        }
        // exact match for a non-magic character
      } else if (output[i] === values[index]) {
        index++;
      }
    }
  }
  return output.join('').substr(0, i);
};

export const videoIdVimeo = (url) => {
  const videoUrlRegExp = /(.*)\/(\d+)/u;

  return url.replace(videoUrlRegExp, '$2');
};
