import styled from 'styled-components';

import { IncrementInput } from 'components';
import { SquaredButton } from 'elements';

import breakpoints from 'theme/Media';

export const QtyControl = styled(IncrementInput).attrs({
  className: 'QtyControl',
})`
  margin-top: 0.8rem;

  .Input {
    width: 100%;
  }

  @media ${breakpoints.medium_break} {
    width: 80%;
  }
`;

export const AddToAutofillStyled = styled(SquaredButton).attrs({
  className: 'AddToAutofillStyled',
})`
  margin-top: 0.8rem;
  @media ${breakpoints.medium_break} {
    width: 80%;
  }
`;
