import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const IconFeatureStyled = styled.div.attrs({
  className: 'IconFeatureStyled',
})`
  padding: 1rem 0;
  border-top: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  border-bottom: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  margin: 1rem 0;
`;

export const FeatureList = styled.ul.attrs({
  className: 'SizeButtonGroup',
})`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li.attrs({
  className: 'ListItem',
})`
  width: 33%;
  max-width: 12rem;
  margin: 0 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .Icon {
    width: 2.5rem;
    height: 2.5rem;

    svg {
      width: 95%;
    }
  }

  .Image {
    width: 2.375rem;
    height: 2.375rem;
  }

  .Text {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    text-align: center;
    line-height: 1.45;
  }

  .TipIconWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .Icon {
      display: none;
    }

    .TooltipStyled {
      background-color: white;
      left: 50%;
      top: calc(15% + 1.25rem);
      transform: translate(-50%, 50%);

      .Text {
        margin-top: 0;
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .TipIconWrapper {
      .TooltipStyled {
        max-width: 18rem;
        top: calc(50% + 1.5rem);
      }
    }
  }

  @media ${breakPoints.middle_break} {
    .TipIconWrapper {
      .TooltipStyled {
        top: calc(45% + 1.5rem);
      }
    }
  }
`;
