import styled from 'styled-components';

export const RemoveItemButtonStyled = styled.button.attrs({
  className: 'RemoveItemButton',
})`
  font-size: 12px;
  text-align: center;
  color: ${({ theme }) => theme.farmProject.light_gray};
  background: transparent;
  border: 1px solid ${({ theme }) => theme.farmProject.light_gray};
  border-radius: 100%;
  padding: 0;
  height: 18px;
  width: 18px;
  cursor: pointer;
`;
