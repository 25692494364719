import styled from 'styled-components/macro';
import Flex from 'components/flex/Flex';
import breakPoints from 'theme/Media';

export const ModalQuickViewWrapper = styled.div.attrs({ className: 'ModalQuickViewWrapper' })`
  .modal__backdrop {
    background: rgba(0, 0, 0, 0.5);
  }

  .ModalInner {
    max-width: 100%;
  }

  .modal__Content {
    padding: 0;
    width: 23.4375rem;
    max-width: 58.75rem;
    background: none;

    @media ${breakPoints.large_break} {
      width: 58.75rem;
    }

    .ModalCloseBtn {
      background-color: ${({ theme }) => theme.farmProject.white};
      z-index: 10;
      border-radius: 100%;

      svg {
        width: 0.875rem;
        height: 0.875rem;

        @media ${breakPoints.large_break} {
          width: 1.125rem;
          height: 1.125rem;
        }
      }
    }
  }
`;

export const SwiperWrapper = styled.div`
  display: block;
  width: 100%;

  .swiper-button-prev {
    top: 57%;
    left: 0;

    @media ${breakPoints.large_break} {
      top: 43%;
      left: 0.5rem;
    }

    &::after {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .swiper-button-next {
    top: 57%;
    right: 0;

    @media ${breakPoints.large_break} {
      top: 43%;
      right: 0.5rem;
    }

    &::after {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .swiper-container {
    padding-bottom: 1.5rem;
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-pagination.swiper-pagination-bullets {
    bottom: 0;

    .swiper-pagination-bullet {
      background-color: ${({ theme }) => theme.farmProject.white};
      opacity: 0.3;

      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
`;

export const SwipeItem = styled.article`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.farmProject.white};
  margin-bottom: 1.5rem;

  @media ${breakPoints.large_break} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 470px;
    margin-bottom: 0;
  }
`;

export const ImageWrapper = styled.div`
  height: 10.375rem;
  width: 100%;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.farmProject.gray_2};
  margin-bottom: 1.375rem;

  @media ${breakPoints.large_break} {
    height: 100%;
    margin-bottom: 0;
  }

  .Image {
    picture {
      img {
        width: auto;
        height: 100%;
        mix-blend-mode: multiply;
      }
    }
  }
`;

export const SwiperItemWrapContent = styled.div`
  display: block;
  padding-bottom: 2.875rem;

  @media ${breakPoints.large_break} {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const SwiperItemContent = styled.div`
  padding: 0 2.375rem;
  text-align: left;
  margin-bottom: 1.5rem;

  @media ${breakPoints.large_break} {
    padding: 0;
  }

  .BundleName {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }

  .Title {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: 2rem;
    text-transform: uppercase;

    @media ${breakPoints.large_break} {
      font-size: 2rem;
      margin-right: 1.5rem;
    }
  }
  .Text {
    text-align: left;
  }
  .IntroSentence {
    margin-bottom: 1.25rem;

    @media ${breakPoints.large_break} {
      margin-bottom: 1rem;
    }
  }
  .LabelGrowing {
    font-weight: 400;
    margin-bottom: 0.25rem;
    color: ${({ theme }) => theme.farmProject.dark_green};
  }
`;

export const SeedlingInfoDetails = styled.div`
  width: 100%;
  margin-bottom: 0.75rem;
`;

export const SeedlingFeatures = styled(Flex)`
  justify-content: flex-start;
  position: relative;

  .Text {
    letter-spacing: normal;
    color: ${({ theme }) => theme.farmProject.gray};
  }
`;

export const WrapCTA = styled.div`
  padding: 0 1.25rem;

  position: absolute;
  bottom: 1.5rem;
  width: calc(100% - 2.5rem);

  @media ${breakPoints.large_break} {
    padding: 0;
    text-align: left;

    position: static;
    bottom: initial;
    width: auto;
  }

  .Button {
    margin: 0;
    width: 100%;

    @media ${breakPoints.large_break} {
      width: auto;
      min-width: 14.375rem;
    }
  }

  .IncreaseInput {
    margin-top: 0;
    width: auto;
    min-width: 14.375rem;
  }
`;
