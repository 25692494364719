import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

import { Text, SquaredButton, Image, Link, ButtonText } from 'elements';
import { AddToCart } from 'components';
import { TextTooltipWrapper } from 'components';
import ShopCategories from 'constants/shopCategories';
import colors from 'constants/styledColors';
import itemAvailabilities from 'constants/itemAvailabilities';
import { getItemById } from 'reduxState/catalog';
import paths from 'constants/paths';
import growingEnvironments from 'constants/growingEnvironments';
import shopCategories from 'constants/shopCategories';
import { getDayMonthFormat } from 'utils/date-utils';
import { formatPrice } from 'utils/cart-utils';

const { SUPPLIES, GIFT, EXTENSION, SEEDLINGS, BUNDLES } = shopCategories;

const ListItem = styled.div.attrs({
  className: 'ListItem',
})`
  height: 238px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px 5px;
  justify-content: space-between;
  background-color: ${colors.WHITE};
  opacity: ${(props) => (props.state === 'entered' ? 1 : 0)};
  transition: opacity 400ms ease-in;
  position: relative;
  .Button,
  .IncreaseInput {
    width: 100%;
  }

  .ButtonText span {
    text-align: left;
  }

  .Text {
    &:last-of-type {
      letter-spacing: 0.1px;
    }
  }
  .AddToCartStyled,
  .QtyControl {
    margin-top: 0;
  }
`;

const ImgWrapper = styled.div`
  align-self: center;
  .Image {
    width: 125px;
    height: 125px;
    object-fit: contain;
  }
`;

const PLACEHOLDER_IMAGE =
  'https://images.ctfassets.net/tjhwhh07rwer/4vtw9IJNQadFm0nJHWXPE0/5ccf4d2d25d85e3cbc548b3b20f71a57/SeedlingSide.jpeg';

const FlagLabel = styled.div(
  (props) => `
  width: fit-content;
  height: 17px;
  background: ${props.theme.farmProject.brand_primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 0px;
  z-index: 1;
  padding: 0.125rem 0.25rem;

  p {
    font-size: 10px;
    font-family: Rubik, sans-serif;
    color: ${props.theme.farmProject.gray_3};
  }
`
);

const ShopListItem = ({ itemId, state, path, className, content, flagLabel, onClick }) => {
  const messages = useSelector((state) => state.contentful.messages) || {};
  let item = useSelector((state) => getItemById(state, itemId));
  const reservation = useSelector((state) => state.reservation);
  const userEnvironment = useSelector((state) => state.user.userSetEnvironment);

  const image = item.imageUrl ? item.imageUrl : PLACEHOLDER_IMAGE;
  let cta = '';
  let _path = path;

  switch (item.availability) {
    case itemAvailabilities.OUT_OF_SEASON:
      if ([SUPPLIES, EXTENSION, GIFT].includes(item.category)) {
        cta = <AddToCart isOutOfSeason outOfSeasonLabel='UNAVAILABLE' showPrice={false} />;
      }
      break;
    case itemAvailabilities.AVAILABLE:
      if (item.category === GIFT) {
        _path = paths.GIFT_CARD;
        cta = <SquaredButton label='CUSTOMIZE' href={paths.GIFT_CARD} as='a' eventData={{ action: 'CUSTOMIZE' }} modifiers='size16' />;
      } else {
        const ctaProps = {};

        if (flagLabel && onClick) ctaProps.onFirstAdd = onClick;

        cta = <AddToCart item={item} {...ctaProps} showPrice={false} />;
      }
      break;
    default:
      return;
  }

  return (
    <ListItem state={state} className={className} data-nw={`shop-item-${itemId}`}>
      {!!flagLabel && (
        <FlagLabel>
          <p>{flagLabel}</p>
        </FlagLabel>
      )}
      <ImgWrapper>
        <Link href={_path}>
          <Image alt={item.name} picture={image} nativeWidth={175} eventData={{ action: `Image: ${item.name.toUpperCase()}` }} />
        </Link>
      </ImgWrapper>
      {item.name && (
        <ButtonText
          modifiers={['secondary', 'small']}
          label={item.name.toUpperCase()}
          href={_path}
          eventData={{ action: `Text: ${item.name.toUpperCase()}` }}
        />
      )}
      <Text modifiers={['brandFont']}>{formatPrice(item.priceCents / 100)}</Text>
      {cta}
    </ListItem>
  );
};

ShopListItem.propTypes = {
  itemId: PropTypes.string,
  state: PropTypes.string,
  className: PropTypes.string,
  path: PropTypes.string,
  seedShipDate: PropTypes.number,
  content: PropTypes.object,
  flagLabel: PropTypes.string,
};

export default ShopListItem;
