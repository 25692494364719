import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AXIOS } from 'constants/redux';

export const initialState = {
  attemptedCode: '',
  code: '',
  email: '',
  expiry: '',
  items: [],
  hasExpired: false, // UI flag just to show Oops message to user in PromoBanner
};

const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    setReservation(state, { payload }) {
      // null payload for expired or invalid reservation
      if (state.attemptedCode && !payload) {
        state.attemptedCode = initialState.attemptedCode;
        state.hasExpired = true;
        return;
      }
      state.attemptedCode = initialState.attemptedCode;
      state.code = payload.code;
      state.email = payload.email;
      state.expiry = payload.expiry;
      state.items = payload.items;
      state.hasExpired = payload.expiry <= Date.now();
    },
    setReservationCode(state, { payload }) {
      state.attemptedCode = payload;
    },
    resetReservation(state) {
      state.code = initialState.code;
      state.email = initialState.email;
      state.expiry = initialState.expiry;
      state.items = initialState.items;
      state.hasExpired = initialState.hasExpired;
    },
  },
});

const { actions, reducer } = reservationSlice;
export const { setReservation, resetReservation, setReservationCode } = actions;

export const getReservation = (state) => state.reservation;

export const getReservationItems = createSelector(getReservation, (reservation) => reservation.items || []);

export const getItemInResevation = createSelector([getReservationItems, (_, sku) => sku], (items, sku) => {
  if (!items) return null;

  return items.find((resItem) => resItem.sku === sku);
});

/**
 * * fetchReservation - Async Action Creator to hit reservation BE point
 *
 * @param {object} code - reservation code
 *
 */
export const fetchReservation = (code) => {
  return {
    type: AXIOS,
    payload: {
      url: `/app/public/reservation/${code}`,
      method: 'GET',
      onSuccess: setReservation,
    },
  };
};

export default reducer;
