import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';

import {
  FooterStyled,
  FooterContent,
  FooterList,
  FooterListBottom,
  ListsLinksWrappers,
  ContactInfo,
  Contact,
  GroupIcon,
  MailingForm,
  ConfirmationForm,
  FooterBottom,
  BottomInfo,
  Signature,
  Lists,
  LinkWrapper,
  WrapVersion,
} from './Footer.styled';

import OsanoRequestFormModal from 'components/modal/osano-request-form-modal/OsanoRequestFormModal';

import { ListLinks } from '../index';
import { Title, Link, Text, Icon, Input, ButtonText, Select } from '../../elements/index';
import { FormikEmailInput } from 'components';

import LetterIcon from 'elements/icon/assets/mail.svg';
import PhoneIcon from 'elements/icon/assets/phone.svg';
import FacebookIcon from 'elements/icon/assets/facebook.svg';
import YoutubeIcon from 'elements/icon/assets/youtube.svg';
import InstagramIcon from 'elements/icon/assets/instagram.svg';
import Logo from 'elements/icon/assets/LG_logo.svg';
import TFP from 'elements/icon/assets/TFP_logo.svg';
import { paths } from 'constants/index';
import emailSignup from 'utils/newsletter-api';

import { getUserCountryId, getCAPIUserInfo } from 'reduxState/user';
import { appVersion } from 'utils/envConfig';

import useCountries from 'utils/hooks/useCountries';

const US = 'Z3Jvdy5kYi5Db3VudHJ5LTIyMA';

const Footer = () => {
  const { LGE_0 } = useSelector((state) => state.contentful.messages) || {};
  const [status, setStatus] = useState({});
  const [isOsanoOpen, setIsOsanoOpen] = useState(false);
  const userCountry = useSelector(getUserCountryId);
  const capiData = useSelector(getCAPIUserInfo);
  const { countries } = useCountries();

  const doNotSellOnClick = () => setIsOsanoOpen(true);
  const modalOnClose = () => setIsOsanoOpen(false);
  const cookieManagementOnClick = () => window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');

  return (
    <FooterStyled>
      <FooterContent>
        <LinkWrapper to='/'>
          <Icon>
            <Logo />
          </Icon>
        </LinkWrapper>
        <Lists>
          <ListsLinksWrappers>
            <FooterList>
              <Title as='h5' modifiers='inverted' content='SERVICE' className='FooterLists-Title' />
              <ListLinks>
                <Link content='FAQ' href={paths.FAQ} />
                <Link content='Accessibility Statement' href={paths.ACCESSIBILITY_STATEMENT} />
                <Link content='Careers' href={paths.CAREERS_PAGE} />
                <Link content='Gift Cards' href={paths.GIFT_CARD} />
                <Link content='Refer A Friend' href={paths.faqPosts.REFERRAL} />
                <Link content='Affirm' href={paths.AFFIRM_LANDING} />
                <Link content='Warranty' href={paths.WARRANTY} />
                <Link content='Return policy' href={paths.RETURN_LANDING} />
              </ListLinks>
            </FooterList>
            <FooterList>
              <Title as='h5' modifiers='inverted' content='LEARN' className='FooterLists-Title' />
              <ListLinks>
                <Link content='The Farmstand' href={paths.FARMSTAND} />
                <Link content='Resources' href={paths.BLOG} />
                <Link content='Recipes' href={paths.RECIPES} />
                <Link content='Seedlings' href={paths.SEEDLINGS} />
                <Link content='Customer Reviews' href={paths.CUSTOMER_REVIEWS} />
              </ListLinks>
            </FooterList>
          </ListsLinksWrappers>
          <ContactInfo>
            <Title as='h5' modifiers='inverted' content='CONNECT' />
            <Contact>
              <Link href={paths.VIRTUAL_SHOPPING}>
                <Text modifiers={['inverted', 'brandFont']} content='Virtual Consultation' />
              </Link>
            </Contact>
            <Contact>
              <Link href={paths.CONTACT_US}>
                <Text modifiers={['inverted', 'brandFont']} content='Contact Us' />
              </Link>
            </Contact>
            <Contact>
              <Link href='mailto:help@lettucegrow.com'>
                <Text modifiers={['inverted', 'brandFont']} content='help@lettucegrow.com' />
                <Icon modifiers='inverted'>
                  <LetterIcon />
                </Icon>
              </Link>
            </Contact>
            <Contact>
              <Link href='tel:+15122344001'>
                <Text modifiers={['inverted', 'brandFont']} content='Call us at (512) 234-4001' />
                <Icon modifiers='inverted'>
                  <PhoneIcon />
                </Icon>
              </Link>
            </Contact>
            <Contact>
              <Link href={paths.EDUCATION}>
                <Text modifiers={['inverted', 'brandFont']} content='Education Contact' />
              </Link>
            </Contact>
            <GroupIcon>
              <Link href={paths.EXTERNAL_FACEBOOK} target='_blank' ariaAttributes={{ 'aria-label': 'Facebook Link' }}>
                <Icon modifiers='inverted'>
                  <FacebookIcon />
                </Icon>
              </Link>
              <Link href={paths.EXTERNAL_YOUTUBE} target='_blank' ariaAttributes={{ 'aria-label': 'Youtube Link' }}>
                <Icon modifiers='inverted'>
                  <YoutubeIcon />
                </Icon>
              </Link>
              <Link href={paths.EXTERNAL_INSTAGRAM} target='_blank' ariaAttributes={{ 'aria-label': 'Instagram Link' }}>
                <Icon modifiers='inverted'>
                  <InstagramIcon />
                </Icon>
              </Link>
            </GroupIcon>
          </ContactInfo>
        </Lists>
        <MailingForm>
          <Title as='h5' modifiers='inverted' content={(status || {}).success || 'JOIN OUR MAILING LIST!'} />
          <Formik
            onSubmit={async (values, { setSubmitting }) => {
              const params = {
                email: values.email,
                formType: 'footerNewsletter',
                zip: values.zipCode,
                capiData,
              };
              if (values.countryId) params.countryId = values.countryId;

              const response = await emailSignup(params);
              if (((response || {}).data || {}).success === true) {
                setStatus({ success: 'Welcome to Our Family!' });
              } else {
                setStatus({ error: LGE_0 });
              }
              setSubmitting(false);
            }}
            initialValues={{ email: '', zipCode: '', terms: false }}
          >
            {({ isSubmitting, values }) => (
              <Form noValidate>
                {!(status || {}).success && (
                  <>
                    <FormikEmailInput modifiers={['standard', 'brandForStandard', 'brandForStandardBlackBackground']} required />
                    {userCountry !== US && !!userCountry ? (
                      <Field
                        required
                        name='countryId'
                        label='Select a Country'
                        helperText='*Required'
                        component={Select}
                        value={values.countryId || userCountry}
                        modifiers={['inverted', 'noSidePadding', 'medium']}
                        options={countries.map((country) => ({
                          value: country.id,
                          label: country.name,
                        }))}
                      />
                    ) : (
                      <Field
                        name='zipCode'
                        variant='standard'
                        modifiers={['standard', 'brandForStandard', 'brandForStandardBlackBackground']}
                        label='Zip Code:'
                        component={Input}
                      />
                    )}
                    <ConfirmationForm>
                      <ButtonText type='submit' label='SUBMIT' modifiers='underline' disabled={isSubmitting}></ButtonText>
                      {/* <Field name='terms' component={Checkbox} label='I agree with privacy policy.' /> */}
                    </ConfirmationForm>
                    {!!(status || {}).error && <Text content={(status || {}).error} as='span' modifiers={['inverted', 'error']} isHTML />}
                  </>
                )}
              </Form>
            )}
          </Formik>
        </MailingForm>
        <GroupIcon>
          <LinkWrapper as='a' href={paths.EXTERNAL_FACEBOOK} target='_blank' ariaAttributes={{ 'aria-label': 'Facebook Link' }}>
            <Icon modifiers='inverted'>
              <FacebookIcon />
            </Icon>
          </LinkWrapper>
          <LinkWrapper as='a' href={paths.EXTERNAL_YOUTUBE} target='_blank' ariaAttributes={{ 'aria-label': 'Youtube Link' }}>
            <Icon modifiers='inverted'>
              <YoutubeIcon />
            </Icon>
          </LinkWrapper>
          <LinkWrapper as='a' href={paths.EXTERNAL_INSTAGRAM} target='_blank' ariaAttributes={{ 'aria-label': 'Instagram Link' }}>
            <Icon modifiers='inverted'>
              <InstagramIcon />
            </Icon>
          </LinkWrapper>
        </GroupIcon>
        <FooterListBottom>
          <Title as='h5' modifiers='inverted' content='LEGAL' className='FooterLists-Title' />
          <ListLinks>
            <Link content='Terms & Conditions' href={paths.TERMS} />
            <Link content='Privacy Policy' href={paths.PRIVACY} />
            <Link content='California Privacy Policy' href={paths.CCPA} />
            <ButtonText
              type='button'
              label='Do Not Sell My Information'
              modifiers={['inverted', 'brandHover']}
              onClick={doNotSellOnClick}
            />
            <ButtonText type='button' label='Manage My Cookies' modifiers={['inverted', 'brandHover']} onClick={cookieManagementOnClick} />
            <OsanoRequestFormModal isOpen={isOsanoOpen} onCloseAction={modalOnClose} />
          </ListLinks>
        </FooterListBottom>
        <FooterBottom>
          <BottomInfo>
            <Text modifiers='inverted' as='span' content={`Copyright Ⓒ ${new Date().getFullYear().toString()}, Lettuce Grow®`} />
            <Signature>
              <Text modifiers='inverted' as='span' content='An Initiative of:' />
              <LinkWrapper as='a' href={paths.EXTERNAL_TFP} target='_blank'>
                <Icon>
                  <TFP />
                </Icon>
              </LinkWrapper>
            </Signature>
          </BottomInfo>
          <LinkWrapper to='/'>
            <Icon>
              <Logo />
            </Icon>
          </LinkWrapper>
        </FooterBottom>

        <WrapVersion modifiers={['justifyCenter', 'flex1']}>
          <Text modifiers={['inverted', 'tiny']} content={`Version ${appVersion}`} />
        </WrapVersion>
      </FooterContent>
    </FooterStyled>
  );
};

export default Footer;
