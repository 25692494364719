import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { getOpenModal, setCloseModal, MODALS } from 'reduxState/modal';
import { userAddPaymentMethod, getLoginState } from 'reduxState/user';
import { useAuth } from 'utils/hooks';
import logError from 'utils/errorHandler';

import { Modal } from '../index';
import { Title, SquaredButton, Text, Input } from 'elements';
import { StyledCardElement, CompleteAccountModalStyled } from './CompleteAccountModal.styled';

/* 
  HEADS UP - this modal can show on the order confirmation page directly after a successful checkout, and assumes two things
  - if a user has an account in GCS (not a guest) they are logged in (checkout requires this)
  - all users have an authToken in state (checkout requires this)
*/

const CompleteAccountModal = ({ content }) => {
  const dispatch = useDispatch();
  const openModal = useSelector(getOpenModal);
  const stripe = useStripe();
  const elements = useElements();
  const auth = useAuth();
  const isLoggedIn = useSelector(getLoginState);
  const [isInitiallyLoggedIn] = useState(isLoggedIn); // show/hide the password field based on logged in state upon initial render
  const messages = useSelector((state) => state.contentful.messages);
  if (_isEmpty(content)) return null;

  const closeModal = () => dispatch(setCloseModal());

  const handleSubmit = async (values, { setStatus }) => {
    setStatus('submitting');
    try {
      const cardElement = elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(cardElement);
      if (error) throw new Error(error);
      if (token) {
        dispatch(
          userAddPaymentMethod({
            token,
            auth,
            password: values?.password,
            onSuccess: () => {
              setStatus('success');
              setTimeout(() => dispatch(setCloseModal()), 4000);
            },
            onFailure: () => {
              setStatus('failure');
            },
          })
        );
      }
    } catch (err) {
      logError(err);
    }
  };

  const isOpen = openModal.id === MODALS.COMPLETE_ACCOUNT;
  return (
    <CompleteAccountModalStyled>
      <Modal isOpen={isOpen} onCloseAction={closeModal}>
        <Title>{content.fields.title}</Title>
        <Text className='Description' isHTML modifiers={['small', 'noLineHeight']} content={content.fields.body}></Text>
        <Formik
          initialValues={{ password: '', card: '' }}
          onSubmit={handleSubmit}
          validationSchema={isInitiallyLoggedIn ? null : Yup.object().shape({ password: Yup.string().required(messages?.LGE_required) })}
        >
          {({ errors, status, setFieldError }) => {
            const handleStripeChange = (card) => {
              if (card.error) {
                setFieldError('card', card.error.message);
              } else {
                setFieldError('card', '');
              }
            };

            return (
              <>
                <StyledCardElement onChange={handleStripeChange} />
                <Form noValidate>
                  {!isInitiallyLoggedIn && (
                    <Field
                      required
                      variant='filled'
                      name='password'
                      type='password'
                      label='Password'
                      helperText='*Required'
                      component={Input}
                    />
                  )}
                  <SquaredButton
                    type='submit'
                    label={
                      !isInitiallyLoggedIn
                        ? content.fields.additionalButtons?.[0]?.fields?.linkText
                        : content.fields.primaryButton?.fields?.linkText
                    }
                    modifiers={[(!stripe || !!errors.card || !!errors?.password) && 'disabled', status === 'submitting' && 'loading']}
                  />
                </Form>
                <Text className='StatusMessage' modifiers={['noLineHeight', 'tiny', 'brandFont', 'errorColor']}>
                  {errors.card || (status === 'failure' ? messages?.LGE_0 : '')}
                </Text>
                {status === 'success' && (
                  <Text className='StatusMessage' modifiers={['noLineHeight', 'tiny', 'brandFont', 'brandColor']}>
                    {content.fields.headline}
                  </Text>
                )}
              </>
            );
          }}
        </Formik>
      </Modal>
    </CompleteAccountModalStyled>
  );
};

CompleteAccountModal.propTypes = {
  content: PropTypes.object,
};
export default CompleteAccountModal;
