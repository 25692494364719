import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  disabled: () => ({
    styles: `  
      opacity: 0.4;
    `,
  }),
};

export const ProductGiftStyled = styled.div.attrs({
  className: 'ProductGiftStyled',
})`
  > .Title {
    letter-spacing: normal;
    margin-bottom: 0.75rem;
  }
`;

export const ProductGiftWrapper = styled.div.attrs({
  className: 'ProductGiftWrapper',
})`
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 24rem;

  .Title {
    letter-spacing: normal;
    display: block;
    font-size: 20px;
  }

  .PromoPriceStyled {
    margin-bottom: 0.5rem;
  }

  > .Text,
  * {
    margin-right: 0.25rem;
    padding: 0;
    margin: 0;
    font-size: 0.75rem;
  }

  .Text + .Text {
    margin-right: 0;
  }

  ${applyStyleModifiers(modifiers)};
`;

export const ProductDescription = styled.div.attrs({
  className: 'ProductDescription',
})`
  margin-left: 0.5rem;
`;

export const ImageProductWrapper = styled.div.attrs({
  className: 'ImageProductWrapper',
})`
  width: 6.5rem;
  height: 6.5rem;
  display: block;
  flex-shrink: 0;

  .Image {
    picture {
      img {
        width: 105%;
      }
    }
  }
`;
