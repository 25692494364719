const font = {
  mainRegular: 'Source Serif Pro, serif',
  mainMedium: 'Source Serif Pro, serif',
  mainLight: 'Source Serif Pro, serif',
  mainBold: 'Rubik, sans-serif',
  mainBlack: 'Rubik, sans-serif',
};

font.all = Object.values(font);

export default font;
