import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import { initialState as globalUIInitialState } from 'app/reduxState/globalUI';

const SET_SELECTED_BUNDLE_SIZE = 'SET_SELECTED_BUNDLE_SIZE';

export const SEEDLINGS_BUNDLE_SIZE = 6;

// UPDATING TO MATCH THE DEFAULTS WE HAVE SET IN GLOBALUI REDUX SLICE
const initialState = {
  selectedBundleSize: 24,
  numberOfSeedBundles: globalUIInitialState?.farmProductPage?.selectedSize / SEEDLINGS_BUNDLE_SIZE,
};

export const setSelectedBundleSize = (payload) => ({
  type: SET_SELECTED_BUNDLE_SIZE,
  payload,
});

const bundleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_BUNDLE_SIZE:
      return { ...state, selectedBundleSize: action.payload, numberOfSeedBundles: action.payload / SEEDLINGS_BUNDLE_SIZE };
    default:
      return state;
  }
};

export const BundleContext = React.createContext();

const BundleContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(bundleReducer, initialState);

  return <BundleContext.Provider value={{ state, dispatch }}>{children}</BundleContext.Provider>;
};

BundleContextProvider.propTypes = {
  children: PropTypes.node,
};

export default BundleContextProvider;
