import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

import { Text, SquaredButton, Icon, Image, GatsbyImage } from 'elements';
import { StarRating } from 'components';
import { CustomerReviewStyled, QuoteIcon } from './SliderCustomerContent.styled';
import Quote from 'elements/icon/assets/quote.svg';

import paths from 'constants/paths';
import { useBreakpoint } from 'utils/hooks';
import { getAreProductReviewsMounted } from 'reduxState/globalUI';

const CustomerReview = ({ modifiers, review, customerName, reviewLocation, backgroundImage, backgroundImageGatsby }) => {
  const size = useBreakpoint();
  const location = useLocation();
  const areProductReviewsMounted = useSelector(getAreProductReviewsMounted);
  const ctaUrl = areProductReviewsMounted ? `${location.pathname}#reviews-and-questions` : paths.CUSTOMER_REVIEWS;
  return (
    <CustomerReviewStyled modifiers={modifiers}>
      {!!backgroundImageGatsby && <GatsbyImage image={backgroundImageGatsby} alt={customerName} />}
      {!!backgroundImage && !backgroundImageGatsby && (
        <Image picture={backgroundImage} nativeWidth={size === 'XL' ? 700 : 900} alt={customerName} />
      )}
      <QuoteIcon>
        <Icon>
          <Quote />
        </Icon>
      </QuoteIcon>
      <StarRating readOnly rating={5} modifiers='yellowColor' />
      <Text modifiers={['noLineHeight']} content={review} />
      <Text modifiers={['xLarge', 'brandFont']} content={customerName} />
      <Text modifiers={['small', 'brandFont']} content={reviewLocation} />
      <SquaredButton label='READ THE REVIEWS' href={ctaUrl} />
    </CustomerReviewStyled>
  );
};

CustomerReview.propTypes = {
  review: PropTypes.string.isRequired,
  customerName: PropTypes.string,
  reviewLocation: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundImageGatsby: PropTypes.object,
  totalReviews: PropTypes.number,
  modifiers: PropTypes.any,
};

CustomerReview.defaultProps = {
  customerName: '',
  reviewLocation: '',
  backgroundImage: '',
  totalReviews: 0,
};
export default CustomerReview;
