import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { AddToCart, PillLabel, TextTooltipWrapper } from 'components';
import { Title, Text, Image, Icon, ButtonText } from 'elements';

import {
  BundleShopCardStyled,
  BundleShopInner,
  BundleInfo,
  ImageWrapper,
  SeedlingsDetails,
  FeaturesList,
  Feature,
  CardFooter,
  LabelBundle,
} from './BundleShopCard.styled';

import Sun from 'elements/icon/assets/sun-icon.svg';
import Home from 'elements/icon/assets/home.svg';

import {
  getSeedShipLine,
  getSeedWarningLine,
  isSeedAvailable,
  isSeedOutOfStock,
  isSeedShoppableAndOutOfSeason,
} from 'utils/seedlings-utils';
import growingEnvironments from 'constants/growingEnvironments';
import { useBreakpoint } from 'utils/hooks';
import { getContentMessages } from 'app/reduxState/contentful';
import { getReservationItems } from 'app/reduxState/reservation/reservation';
import { getUserSetEnv } from 'app/reduxState/user';
import { getCatalogEnvironment } from 'app/reduxState/catalog';
import { formatPrice } from 'utils/cart-utils';

const { OUTDOOR, INDOOR } = growingEnvironments;

const PLACEHOLDER_IMAGE =
  'https://images.ctfassets.net/tjhwhh07rwer/4vtw9IJNQadFm0nJHWXPE0/5ccf4d2d25d85e3cbc548b3b20f71a57/SeedlingSide.jpeg';

const BundleShopCard = ({ catalog = {}, content = {}, modifiers, toggleQuickViewModal, setQuickView }) => {
  const isCustomBundle = !!catalog.contentSourceSku;
  const reservationItems = useSelector(getReservationItems);
  const messages = useSelector(getContentMessages);
  const userEnv = useSelector(getUserSetEnv);
  const catalogEnv = useSelector(getCatalogEnvironment);
  const userEnvironment = userEnv === 'DEFAULT' ? (catalogEnv === INDOOR ? INDOOR : OUTDOOR) : userEnv;
  const isMobile = ['SM', 'MD'].includes(useBreakpoint());

  const {
    isBundleFeatured,
    isGoodToGrowOutdoor,
    isGoodToGrowIndoor,
    plantCount = 6,
    plantsLinks = [],
    indoorApproved,
    listingImage,
    listingImageMobile,
  } = content?.fields || {};
  const { name } = catalog;
  const image = (isMobile && listingImageMobile?.fields?.file?.url) || listingImage?.fields?.file?.url || PLACEHOLDER_IMAGE;
  const seedlingsList = (catalog.plants || plantsLinks || []).map((plant) => plant.name || plant.fields?.friendlyName).join(', ');
  const isNotGoodForIndoor = userEnvironment === INDOOR && !indoorApproved;

  const { label: warnLabel = '', tooltip: warnTooltip = '' } = getSeedWarningLine(catalog, messages, indoorApproved, userEnvironment) || {};
  const { label: shipLabel = '' } = getSeedShipLine(catalog);
  const isReservedItem = reservationItems.find(({ sku }) => sku === catalog?.sku);
  const isBuyableAndOutOfSeason = isSeedShoppableAndOutOfSeason(catalog);
  const isOutOfStock = isSeedOutOfStock(catalog);
  const shouldShowCTA = isCustomBundle || isSeedAvailable(catalog) || isBuyableAndOutOfSeason || isOutOfStock;
  const shouldShowWarn = !!warnLabel && !isReservedItem;
  const shouldShowShipText = !!shipLabel && !isReservedItem;

  const handleModal = () => {
    toggleQuickViewModal();
    setQuickView({ data: catalog, showCTA: shouldShowCTA });
  };

  return (
    <BundleShopCardStyled modifiers={modifiers}>
      <BundleShopInner>
        {isBundleFeatured && <PillLabel modifiers='yellow' content='FEATURED' />}
        <ImageWrapper>
          {!!image && (
            <Image
              alt={`Click here to get more details about: ${name}`}
              picture={image}
              nativeWidth={500}
              eventData={{ action: `Image: ${name?.toUpperCase?.()}` }}
              onClick={handleModal}
            />
          )}
        </ImageWrapper>
        <BundleInfo>
          <SeedlingsDetails>
            <LabelBundle>
              <Text modifiers={['brandFont', 'noLineHeight']} content='REFILL BUNDLE' />
              <Text modifiers={['brandFont', 'noLineHeight']} content={`${plantCount} PLANTS`} />
            </LabelBundle>
            <Title as='h3' modifiers={['tertiaryXXSmall', 'brandColor']} content={name?.toUpperCase?.()} />
            <Text modifiers={['brandFont', 'noLineHeight', 'light', 'tiny']} content={seedlingsList} />
            <ButtonText modifiers={['brand', 'underline']} label='Learn more' onClick={handleModal} />
          </SeedlingsDetails>
          <CardFooter>
            <FeaturesList>
              {isGoodToGrowOutdoor && (
                <Feature>
                  <Icon>
                    <Sun />
                  </Icon>
                  <Text modifiers={['brandFont', 'small', 'noLineHeight']} content='GROWS WELL OUTDOORS' />
                </Feature>
              )}
              {isGoodToGrowIndoor && (
                <Feature>
                  <Icon>
                    <Home />
                  </Icon>
                  <Text modifiers={['brandFont', 'small', 'noLineHeight']} content='GROWS WELL INDOORS' />
                </Feature>
              )}
            </FeaturesList>
            <Text modifiers={['brandFont', 'left']}>{formatPrice(catalog.priceCents / 100)}</Text>
            {shouldShowCTA && <AddToCart item={catalog} isSoldOut={isOutOfStock} showPrice={false} />}
          </CardFooter>
        </BundleInfo>
        {(shouldShowWarn || shouldShowShipText) && (
          <TextTooltipWrapper
            label={`${shouldShowShipText ? `${shipLabel}. ` : ''}${warnLabel ? `${warnLabel.replace('<br>', '')}.` : ''}`}
            isLabelHTML={isNotGoodForIndoor}
            tooltipText={warnTooltip}
            labelModifiers={['small', 'brandColor']}
          />
        )}
      </BundleShopInner>
    </BundleShopCardStyled>
  );
};

BundleShopCard.propTypes = {
  catalog: PropTypes.object,
  content: PropTypes.object,
  modifiers: PropTypes.any,
  toggleQuickViewModal: PropTypes.func,
  setQuickView: PropTypes.func,
};

export default BundleShopCard;
