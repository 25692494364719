import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { AddToCartBulkStyled } from './AddToCartBulk.styled';

import { addBulkItems } from 'reduxState/cart';
import { useBreakpoint } from 'utils/hooks';

const AddToCartBulk = ({ items, isDisabled }) => {
  const dispatch = useDispatch();
  const size = useBreakpoint();

  const addToCart = () => {
    dispatch(addBulkItems({ items }));
  };

  return <AddToCartBulkStyled label='ADD TO CART' onClick={addToCart} modifiers={[size === 'SM' && 'fluid', isDisabled && 'disabled']} />;
};

AddToCartBulk.defaultProps = {
  isDisabled: false,
  items: [],
};

AddToCartBulk.propTypes = {
  isDisabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      qty: PropTypes.number,
    })
  ),
};

export default AddToCartBulk;
