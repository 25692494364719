import React from 'react';
import PropTypes from 'prop-types';

import { Image, Title, Text, Link } from 'elements';

import { GlowingRingCallStyled, GlowingCallInner, GlowingCallInformation } from './BundleItems.styled';

import paths from 'constants/paths';

const defaultGR = paths.shopPages.GLOW_RINGS.SIZE_4;
const glowRingsPaths = Object.values(paths.shopPages.GLOW_RINGS);

const glowRingItemID = 'vaVjkZimR5t8AHuoXluHG';

const BundleItems = ({ title, glowRing, bundleItems = [] }) => {
  const renderItem = (item, index) => {
    const { title: itemTitle, body, mediaContent } = item?.fields || {};
    const image = mediaContent?.fields?.file?.url;
    const imageAlt = mediaContent?.fields?.description;
    const glowRingLink = item?.sys?.id === glowRingItemID ? glowRingsPaths.find((e) => e.includes(glowRing?.slug)) || defaultGR : null;

    return (
      <GlowingCallInner key={index}>
        {!!image && <Image picture={image} alt={imageAlt} />}
        <GlowingCallInformation>
          {glowRingLink ? (
            <Link as='h1' href={glowRingLink} className='Title' content={itemTitle} modifiers={['brandColor']} />
          ) : (
            <Title modifiers={['lineHeightNormal']} content={itemTitle} />
          )}
          <Text modifiers={['brandFont', 'noLineHeight']} content={body} isHTML />
        </GlowingCallInformation>
      </GlowingCallInner>
    );
  };

  return (
    <GlowingRingCallStyled>
      <Text modifiers={['brandFont', 'noLineHeight']} content={title} isHTML />
      {bundleItems.map(renderItem)}
    </GlowingRingCallStyled>
  );
};

BundleItems.propTypes = {
  title: PropTypes.string,
  glowRing: PropTypes.object,
  bundleItems: PropTypes.array,
};

export default BundleItems;
