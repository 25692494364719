import { race, take, put, select, takeLatest, call, delay } from 'redux-saga/effects';
import { navigate } from 'gatsby';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import _isEqual from 'lodash/isEqual';

import { axiosPost, axiosGet } from 'utils/api-utils';
import { environment, authDomain } from 'utils/envConfig';
import { getStorage } from 'utils/storageManager';
import logError from 'utils/errorHandler';
import { trackEnhancedCheckout } from 'utils/googleTagManager';
import { postOrderCleanup, createStripeOrder } from 'app/pages/checkout/CheckoutHelpers';

import { resetReservation } from 'reduxState/reservation/reservation';
import {
  clearCart,
  setCartValue,
  getCart,
  checkDuplicatedOrderAction,
  setIsAltDuplicatedOrder,
  setVerifiedAddress,
  setVerifiedAddressError,
  cancelPayIntentPoll,
  submitAltPaymentFailed,
  submitAltPaymentSucceeded,
  submitAltPayment,
  retrySubmitAltPayment,
  loadBuyNowPreview,
  setBuyNowItems,
  addItemBuyNow,
  updateItemBuyNow,
  removeItemBuyNow,
  loadRecommendeOrderPreview,
  getTotalBuyNowItems,
  payIntentFailed,
  payIntentSucceeded,
  submitRecommendedOrder,
  getTotalBuyNowSeedlings,
  getBuyNowSeedlings,
  clearBuyNow,
  updateAutofillPendingOrdersSuccess,
  getAutofillPendingOrderItems,
  submitAutofillOrder,
  setIsBuyNowLoaded,
  getTotalAutofillPendingOrderItems,
  checkoutSucceeded,
  fetchShippingOptions,
  fetchShippingOptionsSuccess,
  fetchShippingOptionsError,
  fetchCartPreview,
  fetchCartPreviewSuccess,
  fetchCartPreviewError,
  removeDiscount,
  setDiscount,
  openCartModal,
  addItem,
  removeItem,
  removeItemOfType,
  removeItemsOfCategories,
  updateItem,
  addBulkItems,
  addBulkItemsAndDiscount,
  addBulkItemsAndDiscountArray,
  openOrderSummaryModal,
  closeModal,
} from 'reduxState/cart';
import {
  setMyFarm,
  userLogout,
  setUserValue,
  getLoginState,
  getCAPIUserInfo,
  fetchMyFarm,
  fetchMyOrders,
  setMyOrders,
  fetchUserDevices,
  getUser,
  getCustomer,
  getUserAuthToken,
  fetchRecommendedOrderSuccess,
  getTotalSuggestedSeedlings,
  getMySuggestedSeedlings,
  getAutofillPreferences,
  userUpdateAutofillSettings,
  getUserAddress,
  userPaymentMethodAdded,
} from 'reduxState/user';
import { setCatalog, getCatalogEnvironment } from 'reduxState/catalog';
import { getContentMessages } from 'reduxState/contentful';
import { setOpenModal, MODALS } from 'reduxState/modal';
import { shouldOptInAutofillWithPurchase } from 'reduxState/globalUI';

import paths from 'constants/paths';
import shopCategories from 'constants/shopCategories';
import PaymentMethod from 'constants/PaymentMethod';
import { NOOK } from 'constants/sku';

import { checkIsDuplicatedOrder } from 'utils/duplicate-order-util';
import { trackClick } from 'utils/googleTagManager';
import { nameSort } from 'utils/seedlings-utils';

const DEBOUNCE_TIME = 500;

function* guestAuthToken({ payload }) {
  const auth = getAuth();
  const { values } = payload;
  try {
    const { SimpleCrypto } = yield import('simple-crypto-js');
    const key = SimpleCrypto?.generateRandom?.();
    const password = values.email + key;
    yield put(setUserValue({ label: 'password', value: password }));
    const guestSignupResponse = yield createUserWithEmailAndPassword(auth, values.email, password);
    const authToken = yield guestSignupResponse.user.getIdToken();
    return authToken;
  } catch (error) {
    yield put(submitAltPaymentFailed(error.message));
  }
}

function* checkAccount({ payload }) {
  const { email } = payload;
  try {
    const hasAccount = yield axiosGet(`/app/public/checkAccount/${email}`, {
      headers: { authDomain },
    });

    if (hasAccount && hasAccount.data) {
      yield put(setUserValue({ label: 'hasAccount', value: true }));
    }
  } catch (error) {
    logError(error);
  }
}

function* checkDuplicatedOrder() {
  const cart = yield select(getCart);
  const authToken = yield select(getUserAuthToken);

  if (!authToken) return;

  const isDuplicate = yield checkIsDuplicatedOrder({ authToken, cart });
  yield put(setIsAltDuplicatedOrder(isDuplicate));
}

function* checkVerifiedAddress({ payload: { address: verifiedAddress, success } }) {
  /* HEADS UP - "false" success here means that FedEx could not even guess at the correct address
    "true" success means that fedex was able to verify the address OR attempted to alter the address to be valid
   */
  const userAddress = yield select(getUserAddress);
  const isUserAddressValid =
    success &&
    _isEqual(
      { city: verifiedAddress.city, state: verifiedAddress.state, zip: verifiedAddress.zip },
      { city: userAddress.city, state: userAddress.state, zip: userAddress.zip }
    );
  yield put(setVerifiedAddressError(!isUserAddressValid));
}

function* doLogout() {
  yield put(setCartValue({ label: 'canUpdateAddress', value: true }));
  yield put(setCartValue({ label: 'paymentType', value: 'credit' }));
}

function updateDataLayer({ payload }) {
  const { values, cart } = payload;

  if (environment === 'production') {
    const lgUserId = getStorage('lgClientId');
    window.LogRocket && window.LogRocket.identify(lgUserId, { name: values.firstName + ' ' + values.lastName, email: values.email });
  }

  trackEnhancedCheckout({ actionField: { step: 2, option: cart.paymentType }, products: cart.items });
}

function* createPayIntent({ payload }) {
  const { amount, authToken, e } = payload;
  try {
    const payIntentForm = new FormData();
    payIntentForm.append('amount', amount);
    const configReq = { withCredentials: true, supressErrorCodeExpectation: true };
    const formUrlConfig = { ...configReq, headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    const { data } = yield axiosPost('/app/lgcom/stripe/paymentIntent', payIntentForm, formUrlConfig, authToken);
    return { paymentIntentId: data.id, clientSecret: data.client_secret };
  } catch (error) {
    e.complete('fail');
    yield put(submitAltPaymentFailed(e.message));
    throw new Error(error.message);
  }
}

function* confirmPayment({ payload }) {
  const { clientSecret, e, stripe } = payload;
  try {
    const { /*paymentIntent,*/ error } = yield stripe.confirmCardPayment(
      clientSecret,
      { payment_method: e.paymentMethod.id },
      { handleActions: false }
    );

    if (error) {
      throw new Error(error.message);
    }
  } catch (error) {
    e.complete('fail');
    yield put(submitAltPaymentFailed(e.message));
    throw new Error(e.message);
  }
}

function* checkPayIntentStatus({ payload }) {
  const { paymentIntentId, e, authToken } = payload;
  try {
    const { data } = yield axiosGet(`/app/lgcom/stripe/paymentIntent/${paymentIntentId}`, {}, authToken);
    const isCanceled = ['canceled'].includes(data.status);
    const isSuccessful = ['requires_capture', 'succeeded'].includes(data.status);
    if (isSuccessful) {
      e.complete('success');
      yield put(payIntentSucceeded());
    } else if (isCanceled) {
      e.complete('fail');
      yield put(cancelPayIntentPoll());
    } else {
      // keep going recursively
      yield call(checkPayIntentStatus, { payload });
    }
  } catch (error) {
    e.complete('fail');
    yield put(payIntentFailed(e.message));
    throw new Error(error.message);
  }
}

function* createOrder({ payload }) {
  const { values, cart, user, authToken, e, paymentIntentId } = payload;
  const catalogEnvironment = yield select(getCatalogEnvironment);
  const reservation = yield select((state) => state.reservation);
  const order = createStripeOrder(values, cart.shouldOverrideAddress, null, cart, user, reservation, catalogEnvironment);
  const type = e.methodName;
  const { exp_month, exp_year, last4, brand } = e.paymentMethod.card;
  const response = yield axiosPost(
    '/app/lgcom/cartCheckoutStripe',
    { ...order, exp_month, exp_year, last4, brand, type, paymentIntentId },
    { withCredentials: true, supressErrorCodeExpectation: true },
    authToken
  );
  return response.data;
}

function* submitAltPaymentFlow({ payload }) {
  const { e, stripe, values, altPayType, onFailure = () => {}, shouldAllowRepeat = true } = payload;
  const isLoggedIn = yield select(getLoginState);
  const capiData = yield select(getCAPIUserInfo);
  const customer = yield select(getCustomer);
  const cart = yield select(getCart);
  const user = yield select(getUser);
  let authToken;

  try {
    if (user.authToken) {
      authToken = user.authToken;
    } else {
      authToken = yield call(guestAuthToken, { payload: { values } });
    }
    yield call(updateDataLayer, { payload: { values, cart } });

    const amount = cart.totalCents / 100;
    const { paymentIntentId, clientSecret } = yield call(createPayIntent, { payload: { amount, authToken, e } });
    yield call(confirmPayment, { payload: { clientSecret, e, stripe } });
    const { resolved } = yield race({
      paymentIntent: call(checkPayIntentStatus, { payload: { paymentIntentId, authToken, e } }),
      resolved: take([cancelPayIntentPoll.type, payIntentSucceeded.type]),
    });
    if (resolved?.type === payIntentSucceeded.type) {
      const order = yield call(createOrder, { payload: { e, cart, user, values, authToken, paymentIntentId } });
      yield put(submitAltPaymentSucceeded());
      postOrderCleanup({ order: { ...order, eventPayMethod: altPayType }, cart, isLoggedIn, capiData, customer });
      yield put(checkoutSucceeded({ orderId: order.orderNumber, paymentMethod: altPayType }));
      if (isLoggedIn) {
        yield put(fetchMyFarm());
        const hasFarmstandItems = !!order.items?.filter?.((item) => item.category === shopCategories.FARMSTAND)?.length;
        if (hasFarmstandItems) yield put(fetchUserDevices());
      }
    }
  } catch (error) {
    if (error?.response?.status === 417 && shouldAllowRepeat) {
      yield put(retrySubmitAltPayment({ e, stripe, values, altPayType, onFailure, error }));
    } else {
      yield call(submitAltPaymentFlowFailed, { error, onFailure });
    }
  }
}

function* retrySubmitAltPaymentFlow({ payload }) {
  const { e, stripe, values = {}, altPayType, onFailure, error } = payload;
  yield put(fetchCartPreview({ zip: values.zip }));
  // https://redux-saga.js.org/docs/api/index.html#selectselector-args
  // "It's important to note that when an action is dispatched to the store, the middleware first forwards the action to the reducers and then notifies the Sagas."
  // This means that state is already updated by the time the action hits the sagas
  const { cartPreviewSuccess, cartPreviewError } = yield race({
    cartPreviewSuccess: take(fetchCartPreviewSuccess.type),
    cartPreviewError: take(fetchCartPreviewError.type),
  });

  if (cartPreviewSuccess) yield put(submitAltPayment({ e, stripe, values, altPayType, onFailure, shouldAllowRepeat: false }));
  else if (cartPreviewError) yield call(submitAltPaymentFlowFailed, { error, onFailure });
}

function* submitAltPaymentFlowFailed({ error, onFailure }) {
  window.dataLayer?.push({
    event: 'formSubmitFailure',
    formId: 'checkout',
  });
  yield put(submitAltPaymentFailed(error.message));

  const messages = yield select(getContentMessages);
  yield call(onFailure, messages[error?.code] || 'Please try again or contact us for help - (512) 234-4001');
}

function* doLoadRecommendeOrderPreview() {
  const autofillPreferences = yield select(getAutofillPreferences);
  const totalAutofillPendingOrderItems = yield select(getTotalAutofillPendingOrderItems);
  const buyNowItems =
    autofillPreferences?.enabled && totalAutofillPendingOrderItems > 0
      ? yield select(getAutofillPendingOrderItems)
      : yield select((state) => state.cart.buyNow.items);

  const user = yield select((state) => state.user);
  let zipCode = '73301';
  if (user.zip) zipCode = user.zip;

  yield put(loadRecommendeOrderPreview({ items: buyNowItems, zip: zipCode, isRecommendedOrder: true }));
}

function* checkoutSucceededFlow({ payload }) {
  const { orderId, paymentMethod } = payload;
  const isLoggedIn = yield select(getLoginState);

  yield navigate(paths.ORDER_CONFIRMATION + `/${orderId}`);
  yield put(resetReservation());
  yield put(clearCart());

  /* HEADS UP - ensure all crucial yields happen before the take effect below on line 346
     in order to gracefully handle post checkout steps even if 'setMyOrders' is not dispatched 
  */
  let CCOnFile = paymentMethod === PaymentMethod.STRIPE;
  if (isLoggedIn) {
    yield put(fetchMyOrders());
    const { payload } = yield take(setMyOrders.type);
    // HEADS UP - If a user is logged in then we check their entire order history (including current order) for a CC to charge
    CCOnFile = !!payload.cards?.length;
  }
  const didOptInAutofillWithPurchase = yield select(shouldOptInAutofillWithPurchase);
  const shouldAutoEnableAutoFill = didOptInAutofillWithPurchase && CCOnFile;
  const shouldPromptForCC = didOptInAutofillWithPurchase && !CCOnFile;
  const config = { enabled: true };
  if (shouldAutoEnableAutoFill) {
    yield put(userUpdateAutofillSettings(config));
  } else if (shouldPromptForCC) {
    yield put(setOpenModal(MODALS.COMPLETE_ACCOUNT));
    yield take(userPaymentMethodAdded.type);
    yield put(userUpdateAutofillSettings(config));
  }
}

function* fetchShippingOptionsFlow({ payload: { zip, items, promoCode, sendOn } }) {
  yield delay(DEBOUNCE_TIME);
  const url = `app/public/getShippingOptions`;
  const params = {
    address: { zipcode: zip },
    items,
    promoCode,
    sendOn,
  };

  try {
    const responseData = yield axiosPost(url, params, { withCredentials: true });
    yield put(fetchShippingOptionsSuccess(responseData?.data));
  } catch (error) {
    yield put(fetchShippingOptionsError(error));
  }
}

function* fetchCartPreviewFlow({ payload, type }) {
  yield delay(DEBOUNCE_TIME);
  const cart = yield select(getCart);
  const nookInCart = cart.items?.find((item) => item?.sku === NOOK);
  const hasNookWithoutCategory = !!nookInCart && !nookInCart?.category;

  // Only run cart preview if 1. Cart modal is open or 2. we're on checkout or 3. we're setting a promo code
  if (!!cart.sideModalToShow || window.location.pathname === paths.CHECKOUT || type === setDiscount.toString() || hasNookWithoutCategory) {
    const reservation = yield select((state) => state.reservation);
    const user = yield select(getUser);

    const cartToSend = {
      items: cart.items
        .filter((item) => item?.sku && item?.qty)
        .map((item) => {
          // Return full item if it is a Gift Card for meta data
          if (item.category === shopCategories.GIFT)
            return {
              sku: item.sku,
              qty: item.qty,
              meta: {
                imageUrl: item.imageUrl,
                amountCents: item.priceCents,
              },
            };

          // Backend only needs sku and qty for each item.
          return {
            sku: item.sku,
            qty: item.qty,
          };
        }),
    };

    cartToSend.zip = payload?.zip || user.zip || '73301';

    cartToSend.discounts = cart.discounts;

    // if there is an URL promocode and no manual promocode was added, then send URL promocode to BE
    const manualDiscounts = cart.manualDiscounts.filter((e) => !(type === removeDiscount.toString() && e === payload));
    if (!manualDiscounts.length && !!cart.urlDiscount) {
      cartToSend.discounts = { [cart.urlDiscount]: 0, ...cartToSend.discounts };
    }

    if (reservation.code) cartToSend.reservation = reservation.code;

    const isLoggedIn = yield select(getLoginState);

    const url = isLoggedIn ? '/app/lgcom/v2/cartPreview' : '/app/public/v2/cartPreview';
    const authToken = yield select(getUserAuthToken);
    try {
      const responseData = yield axiosPost(url, cartToSend, { withCredentials: true }, authToken);

      yield put(fetchCartPreviewSuccess({ ...responseData?.data, zip: cartToSend.zip }));
    } catch (error) {
      fetchCartPreviewError(error);
    }
  }
}

function* submitAltPaymentSaga() {
  yield takeLatest(submitAltPayment.toString(), submitAltPaymentFlow);
}

function* guestAuthTokenSaga() {
  yield takeLatest('CHECKOUT_AUTH_TOKEN', guestAuthToken);
}

function* logoutSaga() {
  yield takeLatest(userLogout.toString(), doLogout);
}

function* checkAccountSaga() {
  yield takeLatest('CHECK_ACCOUNT', checkAccount);
}

function* checkDuplicatedOrderSaga() {
  yield takeLatest(checkDuplicatedOrderAction.toString(), checkDuplicatedOrder);
}

function* checkVerifiedAddressSaga() {
  yield takeLatest(setVerifiedAddress.toString(), checkVerifiedAddress);
}

function* doSetBuyNowItems({ payload }) {
  const totalSuggestedSeedlings = yield select(getTotalSuggestedSeedlings);
  const totalBuyNowItems = yield select(getTotalBuyNowItems);

  if (totalSuggestedSeedlings > 0 && totalBuyNowItems === 0) {
    yield put(setBuyNowItems(payload.lineItems));
  }
  yield put(setIsBuyNowLoaded(true));
}

function* doSubmitRecommendedOrder({ payload }) {
  const { items, setIsLoading } = payload;
  const authToken = yield select(getUserAuthToken);

  if (!authToken) return;

  const totalBuyNowSeedlings = yield select(getTotalBuyNowSeedlings);
  const totalSuggestedSeedlings = yield select(getTotalSuggestedSeedlings);
  const mySuggestedSeedlings = yield select(getMySuggestedSeedlings);
  const buyNowSeedlings = yield select(getBuyNowSeedlings);

  const seedlingsSuggestedCompare = mySuggestedSeedlings.map((item) => ({ name: item.name, qty: item.qty })).sort(nameSort);
  const seedlingsBuyNowCompare = buyNowSeedlings.map((item) => ({ name: item.name, qty: item.qty })).sort(nameSort);

  try {
    setIsLoading(true);

    const response = yield axiosPost('app/lgmobile/cartCheckout', { items: items }, {}, authToken);

    if (response?.data?.orderNumber) {
      //additional tracking events
      trackClick({ action: 'recorder', label: totalBuyNowSeedlings > totalSuggestedSeedlings ? 'greater' : 'less' });
      trackClick({
        action: 'recorder',
        label: JSON.stringify(seedlingsSuggestedCompare) === JSON.stringify(seedlingsBuyNowCompare) ? 'no_tweak' : 'yes_tweak',
      });
      trackClick({ action: 'recorder', label: `num_seedlings: #${totalBuyNowSeedlings}` });

      //clean up the buyNow state
      yield put(clearBuyNow());

      navigate(paths.ORDER_CONFIRMATION + `/${response.data.orderNumber}`);
    }
  } catch (error) {
    yield put(setOpenModal('error'));
  } finally {
    setIsLoading(false);
  }
}

function* doSubmitAutofillOrder({ payload }) {
  const { pendingOrderId, setIsLoading } = payload;
  const authToken = yield select(getUserAuthToken);

  if (!authToken) return;

  try {
    setIsLoading(true);

    const response = yield axiosPost(`/app/lgcom/pendingOrder/${pendingOrderId}/process`, {}, {}, authToken);

    if (response?.data?.isSuccess) {
      //clean up the buyNow state
      yield put(clearBuyNow());

      navigate(paths.ORDER_CONFIRMATION + `/${response.data.payload.customerOrderId}`);
    }
  } catch (error) {
    yield put(setOpenModal('error'));
  } finally {
    setIsLoading(false);
  }
}

function* copySuggestedItemsToBuyNowItems() {
  yield takeLatest(fetchRecommendedOrderSuccess.toString(), doSetBuyNowItems);
}

function* getShippingOptions() {
  yield takeLatest(fetchShippingOptions.toString(), fetchShippingOptionsFlow);
}

// list of actions for this middleware
const CART_PREVIEW_ACTIONS = [
  addItem.toString(),
  removeItem.toString(),
  removeItemOfType.toString(),
  removeItemsOfCategories.toString(),
  updateItem.toString(),
  setDiscount.toString(),
  removeDiscount.toString(),
  addBulkItems.toString(),
  addBulkItemsAndDiscount.toString(),
  addBulkItemsAndDiscountArray.toString(),
  setMyFarm.toString(),
  setCatalog.toString(),
  openCartModal.toString(),
  openOrderSummaryModal.toString(),
  closeModal.toString(),
  userLogout.toString(),
  fetchCartPreview.toString(),
];

function* getCartPreview() {
  yield takeLatest(CART_PREVIEW_ACTIONS, fetchCartPreviewFlow);
}

function* loadRecommendeOrderPreviewSaga() {
  yield takeLatest(setBuyNowItems.toString(), doLoadRecommendeOrderPreview);
  yield takeLatest(loadBuyNowPreview.toString(), doLoadRecommendeOrderPreview);
  yield takeLatest(addItemBuyNow.toString(), doLoadRecommendeOrderPreview);
  yield takeLatest(updateItemBuyNow.toString(), doLoadRecommendeOrderPreview);
  yield takeLatest(removeItemBuyNow.toString(), doLoadRecommendeOrderPreview);
  yield takeLatest(retrySubmitAltPayment.toString(), retrySubmitAltPaymentFlow);
  yield takeLatest(updateAutofillPendingOrdersSuccess.toString(), doLoadRecommendeOrderPreview);
}

function* submitRecommendedOrderSaga() {
  yield takeLatest(submitRecommendedOrder.toString(), doSubmitRecommendedOrder);
}

function* submitAutofillOrderSaga() {
  yield takeLatest(submitAutofillOrder.toString(), doSubmitAutofillOrder);
}

function* checkoutSucceededSaga() {
  yield takeLatest(checkoutSucceeded.toString(), checkoutSucceededFlow);
}

export default [
  checkoutSucceededSaga,
  submitAltPaymentSaga,
  guestAuthTokenSaga,
  logoutSaga,
  checkAccountSaga,
  checkDuplicatedOrderSaga,
  checkVerifiedAddressSaga,
  loadRecommendeOrderPreviewSaga,
  copySuggestedItemsToBuyNowItems,
  submitRecommendedOrderSaga,
  submitAutofillOrderSaga,
  getShippingOptions,
  getCartPreview,
];
