import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const SwiperImageWrapper = styled.div.attrs({
  className: 'SwiperImageWrapper',
})`
  height: 100%;
  .SwiperStyled {
    height: 100%;
  }
  .swiper-container {
    .swiper-button-prev {
      top: 47%;
      left: -3px;
      width: 1.8rem;
      height: 1.6rem;

      &:after {
        width: 2rem;
        height: 2rem;
        content: url('/icons/left.svg');
      }
    }

    .swiper-button-next {
      top: 47%;
      right: 6px;
      width: 1.8rem;
      height: 1.6rem;

      &:after {
        width: 2rem;
        height: 2rem;
        content: url('/icons/right.svg');
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        background: ${({ theme }) => theme.farmProject.green};
      }

      .swiper-pagination-bullet-active {
        background: ${({ theme }) => theme.farmProject.green};
      }
    }
  }

  @media ${breakPoints.large_break} {
    .swiper-container {
      height: 100%;
    }
  }
`;

export const ImageWrapper = styled.div.attrs({
  className: 'ImageWrapper',
})`
  height: 18rem;
  background-color: #e8e8e8;

  .Image {
    picture {
      img {
        height: 100%;
        width: auto;
      }
    }
  }

  @media ${breakPoints.large_break} {
    height: 100%;

    .Image {
      picture {
        img {
          height: 80%;
        }
      }
    }
  }
`;
