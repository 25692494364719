import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import breakPoints from 'theme/Media';

const modifiers = {
  white: ({ theme }) => ({
    styles: `
      .ContentSection {
        background-color: ${theme.farmProject.white};
      }
    `,
  }),
  gray2: ({ theme }) => ({
    styles: `
      .ContentSection {
        background-color: ${theme.farmProject.gray_2};
      }
    `,
  }),
};

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;

export const FarmstandReviewedStyled = styled.div.attrs({
  className: 'FarmstandReviewedStyled',
})`
  .ContentSection {
    .SectionInner {
      max-width: 33.5rem;
      margin: auto;

      > a {
        display: block;
        width: 100%;
        height: 20rem;
        overflow: hidden;
        position: absolute;
        top: 10rem;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .Image {
        width: 100%;
        height: 100%;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        picture {
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 125%;
            width: auto;
          }
        }
      }

      .ContentWrapper {
        margin-bottom: 2rem;

        .Title {
          font-size: 2rem;
          margin-bottom: 1rem;
        }

        .Button {
          width: 12rem;
          margin: auto;
          margin-top: 1.5rem;
        }
      }

      .lg-responsive-iframe-wrapper {
        padding: 50%;
        padding-bottom: 0;
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      padding: 5rem 3rem;

      .SectionInner {
        > a {
          height: 28rem;
          top: 14rem;
        }

        .Image {
          picture {
            img {
              position: relative;
              top: auto;
              left: auto;
              transform: none;
            }
          }
        }

        .ContentWrapper {
          .Title {
            font-size: 3rem;
          }

          .Text {
            font-size: 1rem;
            max-width: 25rem;
            margin: auto;
          }

          .Button {
            margin-top: 3rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 62rem;

        > a {
          width: calc(50% - 2rem);
          height: 50rem;
          position: relative;
          top: auto;
          left: auto;
          transform: none;
        }

        .Image {
          margin: auto;
          margin-bottom: 0;
          width: 100%;
          max-width: 23rem;

          picture {
            img {
              height: 100%;
              position: relative;
              top: auto;
              left: auto;
              transform: none;
            }
          }
        }

        .ContentWrapper {
          width: calc(50% - 2rem);
          margin-bottom: 0;

          .Title {
            text-align: left;
          }

          .Text {
            text-align: left;
            margin-left: 0;
          }

          .Button {
            margin-left: 0;
          }
        }

        .PlayerWrapperStyled {
          width: 50%;
        }

        .lg-responsive-iframe-wrapper {
          padding: 30%;
          padding-bottom: 0;
        }

        .ResponsiveEmbed {
          margin-left: 1.5rem;
        }
      }
    }
  }

  ${applyStyleModifiers(modifiers)};
`;
