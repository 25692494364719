import styled from 'styled-components/macro';

const SectionHeadline = styled.h2`
  font-size: 1.7rem;
  margin: 0.2rem 0;
  padding: 0 4px;
  display: ${props => (props.isInlineBlock ? 'inline-block' : 'inline')};
  text-transform: initial;
  width: fit-content;
  line-height: 1.5;
`;

export default SectionHeadline;
