import styled from 'styled-components';

export const SuggestionStyled = styled.div.attrs({ className: 'Suggestion' })`
  text-align: left;
  background-color: ${({ theme }) => theme.farmProject.light_green_2};
  margin: 8px 0px;
  padding: 3px 0px;
  width: 100%;
`;

export const SuggestionInner = styled.div`
  position: relative;

  p {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 0.75rem;
    padding: 0.5rem 0.85rem;
    text-decoration: none;

    strong {
      font-weight: 600;
    }

    a {
      cursor: pointer;
      padding-top: 0.25rem;

      span {
        color: ${({ theme }) => theme.farmProject.green};
        text-decoration: underline;
      }
    }
  }
`;
