import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import breakPoints from 'theme/Media';

const modifiers = {
  white: ({ theme }) => ({
    styles: `
      .ContentSection {
        background-color: ${theme.farmProject.white};
      }
    `,
  }),
  gray3: ({ theme }) => ({
    styles: `
      .ContentSection {
        background-color: ${theme.farmProject.gray_3};
      }
    `,
  }),
  paddingBottom: () => ({
    styles: `
      .ContentSection {
        padding-bottom: 5rem;
      }
    `,
  }),
};

export const SavingSectionStyled = styled.div.attrs({
  className: 'SavingSectionStyled',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.gray_2};
    padding: 5rem 2rem 0;

    .SectionInner {
      max-width: 33.5rem;
      margin: auto;

      .ContentWrapper {
        .Image {
          margin-bottom: 3rem;

          picture {
            img {
              position: relative;
              top: auto;
              left: auto;
              transform: none;
            }
          }
        }

        .Title {
          text-align: left;
          max-width: 20rem;
          margin-bottom: 2rem;
        }

        .Text {
          text-align: left;
          margin-bottom: 1.5rem;
        }

        .Button {
          width: 100%;
          max-width: 24rem;
          margin-left: 0;
          margin-top: 2rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 3rem;
            max-width: 26rem;
          }

          .Text {
            max-width: 32rem;
          }

          .Button {
            max-width: 17rem;
            margin-top: 3rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 62rem;

        .ContentWrapper {
          width: calc(50% - 2rem);

          .Title {
            font-size: 3.5rem;
            max-width: 30rem;
          }

          .Text {
            max-width: 25rem;
          }

          .Image {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  ${applyStyleModifiers(modifiers)};
`;

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;
