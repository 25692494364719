import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

import breakPoints from 'theme/Media';

export const modifiers = {
  collapse: () => ({
    styles: `
      min-height: 0;
      @media ${breakPoints.medium_break} {
          min-height: 0;
      }
    `,
  }),
};

export default styled.div`
  min-height: ${({ mobileLoadingHeight }) => mobileLoadingHeight || '100vh'};
  @media ${breakPoints.medium_break} {
    min-height: 100vh;
  }
  ${applyStyleModifiers(modifiers)};
`;
