import client from 'utils/contentful-client';
import { fetchAppSettings, fetchAppSettingsError, setAppSettings } from 'reduxState/appSettings';
import { updateFarmProductPage } from 'reduxState/globalUI';
import farmstandSizes from 'constants/farmstandSizes';
import logError from 'utils/errorHandler';
/**
 * * Redux Middleware for App Settings fetch action - FETCH_APP_SETTINGS
 *
 * @param {Parameters in action.payload}
 * @param content_type - contentful content type
 * @param include - default contentful - 3
 * @param label - contentful name ie. home, phoneNumber
 *
 */

const appSettingsMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const store = getState();
  // Middleware only for getting app setting content types
  if (action.type !== fetchAppSettings.toString()) return;

  // set loading state

  let cachedContentMap = store.contentful.cacheMap;
  client({ content_type: 'appSettings' }, cachedContentMap)
    .getEntries({ content_type: 'appSettings' })
    .then((data) => {
      const farmstandSizeDefault = Number(data.items.find((item) => item.fields.key === 'defaultRecommendedFarmstand')?.fields?.value);
      const pathname = window.location.pathname;
      const hasSizeInUrl = Object.values(farmstandSizes).some((size) => pathname.includes(size));

      dispatch(setAppSettings({ data }));
      if (!hasSizeInUrl) {
        dispatch(updateFarmProductPage({ key: 'selectedSize', value: farmstandSizeDefault }));
      }
    })
    .catch((error) => {
      // set error in appSettings store
      dispatch(fetchAppSettingsError({ error: error.message }));
      // dispatch error for logging
      logError(error);
    });
};

export default appSettingsMiddleware;
