import React from 'react';
import { PropTypes } from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';

import { ListLinksStyled, modifiers } from './ListLinks.styled';

const ListLinks = ({ modifiers, children }) => {
  const isArray = typeof children === 'object' && children.length > 0;
  return (
    <ListLinksStyled modifiers={modifiers}>
      {isArray &&
        children.map((link, key) => {
          return <li key={key}>{link}</li>;
        })}
      {!isArray && <li>{children}</li>}
    </ListLinksStyled>
  );
};

ListLinks.propTypes = {
  modifiers: styleModifierPropTypes(modifiers),
  children: PropTypes.node,
};

export default ListLinks;
