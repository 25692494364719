import styled from 'styled-components/macro';
import breakPoints from 'theme/Media';

export const FormWrapper = styled.div.attrs({
  className: 'FormWrapper',
})`
  margin-top: 3rem;

  .Title {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }

  .Button {
    width: 100%;
    max-width: 18rem;
    height: 2.25rem;
    margin: auto;
    margin-top: 2.5rem;
  }

  @media ${breakPoints.large_break} {
    margin-top: 6.25rem;
    margin-bottom: 3rem;
    .Button {
      max-width: unset;
    }
  }
`;

export const FormGroup = styled.div.attrs({
  className: 'FormGroup',
})`
  .InputStyled {
    margin: 1.5rem auto;

    div {
      max-width: 25rem;
    }

    &:nth-child(2) {
      margin-bottom: 2.75rem;

      @media ${breakPoints.medium_break} {
        margin-bottom: 2.5rem;
      }
    }

    @media ${breakPoints.medium_break} {
      margin-bottom: 1.5rem;
    }
  }

  .SelectStyled,
  .TextArea {
    max-width: 25rem;
    margin-left: auto;
    margin-right: auto;
  }

  .SelectStyled {
    margin-bottom: 3.25rem;
  }

  .TextArea + .Text {
    text-align: left;
    max-width: 25rem;
    margin: 0 auto 3.25rem;
    padding-left: 14px; // match input
  }

  .ContactListItem {
    align-items: flex-start;
    padding: 0 0 0 0.5rem;
    max-width: 25rem;

    .ButtonText {
      margin-bottom: 0;
      padding: 0;
    }
  }
`;

export const AddPhotoWrapper = styled.div.attrs({
  className: 'AddPhotoWrapper',
})`
  text-align: center;
  .Text {
    margin-bottom: 0.5rem;
  }

  .Text + .Text {
    font-size: 0.75rem;
  }

  .AddPhotoBtnGroup {
    flex-wrap: wrap;
    flex-shrink: 1;
    justify-content: center;

    > * {
      margin: 0 0.25rem;
    }
    > :first-child {
      margin-bottom: 0.5rem;
    }
    > :nth-child(3n + 1) {
      margin-left: 0rem;
    }
    > :nth-child(3n + 3) {
      margin-right: 0rem;
    }
  }

  @media ${breakPoints.medium_break} {
    .AddPhotoBtnGroup {
      > * {
        margin: 0 0.5rem;
      }
      > :first-child {
        margin-bottom: 1rem;
      }
    }
  }

  @media ${breakPoints.large_break} {
    text-align: left;

    .AddPhotoBtnGroup {
      justify-content: space-between;
      > * {
        margin: 0 0;
        width: 4.6rem;
        height: 4.6rem;

        .Image {
          width: 4.6rem;
          height: 4.6rem;
        }
      }
      > :first-child {
        margin-bottom: 0rem;
      }
    }
  }
`;
