export const AutofillWeeks = {
  OFF: {
    label: 'Off',
    value: 'off',
  },
  ONE_WEEK: {
    label: '1 week',
    value: 7,
  },
  TWO_WEEKS: {
    label: '2 weeks',
    value: 14,
  },
  THREE_WEEKS: {
    label: '3 weeks',
    value: 21,
  },
};
