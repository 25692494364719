import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ResponsiveEmbedStyled from './ResponsiveEmbed.styled';

const ResponsiveEmbed = ({ embedCode }) => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
    }
  }, [hasMounted]);

  if (!hasMounted) {
    return (
      <ResponsiveEmbedStyled className='ResponsiveEmbed'>
        <iframe></iframe>
      </ResponsiveEmbedStyled>
    );
  } else {
    return <ResponsiveEmbedStyled className='ResponsiveEmbed' dangerouslySetInnerHTML={{ __html: embedCode }}></ResponsiveEmbedStyled>;
  }
};

ResponsiveEmbed.propTypes = {
  embedCode: PropTypes.string.isRequired,
};

export default ResponsiveEmbed;
