import React from 'react';
import PropTypes from 'prop-types';

import SelectBase from '@lettucegrow/atoms.select';

const Select = ({
  id,
  name,
  value,
  required,
  onChange,
  label,
  error,
  options,
  form,
  field,
  modifiers,
  helperText,
  autoComplete,
  onBlur,
  dataAttributes,
  disabled,
}) => {
  const inputName = name || field.name;
  const inputValue = value || field.value;
  const inputChange = onChange || field.onChange;
  const inputBlur = onBlur || field.onBlur;
  const inputTouched = form.touched[inputName];
  const errorMessage = (inputTouched && (error || form.errors[inputName])) || null;
  return (
    <SelectBase
      label={label}
      options={options}
      dataAttributes={dataAttributes}
      disabled={disabled}
      autoComplete={autoComplete}
      required={required}
      onBlur={inputBlur}
      onChange={inputChange}
      name={inputName}
      id={id}
      value={inputValue}
      error={errorMessage}
      modifiers={modifiers}
      helperText={helperText}
    />
  );
};

Select.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.array,
  autoComplete: PropTypes.string,
  helperText: PropTypes.string,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
  }),
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  dataAttributes: PropTypes.object,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
};

export default Select;
