const buttonTypes = {
    SOLID: 'solid',
    OUTLINE: 'outline',
    SQUARE_OUTLINE: 'square-outline',
    TEXT: 'text',
    UNDERLINE: 'underline',
};

buttonTypes.ALL = Object.values(buttonTypes);

export default buttonTypes;
