import styled from 'styled-components/macro';

export const PaginationWrapper = styled.div.attrs({
  className: 'PaginationWrapper',
})`
  margin-top: 1.5rem;

  .MuiPagination-root {
    max-width: 20rem;
    margin: auto;

    .MuiPagination-ul {
      justify-content: space-between;
    }

    .MuiPaginationItem-root {
      margin: 0 0.125rem;
      border-radius: 0;
      font-size: 1rem;
      color: ${({ theme }) => theme.farmProject.dark_gray};
    }

    .MuiPaginationItem-icon {
      font-size: 1.5rem;
    }

    .Mui-selected {
      border-bottom: solid 1px ${({ theme }) => theme.farmProject.green};
      color: ${({ theme }) => theme.farmProject.green};
      background-color: transparent;
      position: relative;
    }
  }
`;
