import { clearPendingUpdateVote, getUserPlantsPendingVotes } from 'app/reduxState/userPreferences';
import { put, takeLatest, select } from 'redux-saga/effects';

import { getUserAuthToken } from 'reduxState/user';
import {
  getSeedlingPreference,
  isFetchingSeedlingPreferences,
  fetchedPreferencesSuccess,
  fetchPreferences,
  seedlingUpVote,
  seedlingDownVote,
  resetVote,
  saveUpdates,
} from 'reduxState/userPreferences';

import { axiosGet, axiosPost, axiosDelete } from 'utils/api-utils';

function* removePreference({ payload, shouldUpdateStore = true }) {
  const authToken = yield select(getUserAuthToken);
  const endpoint = `/app/lgcom/recommendedOrder/preference/buyable/${payload}`;
  try {
    const response = yield axiosDelete(endpoint, {}, authToken);
    if (shouldUpdateStore) yield put(fetchedPreferencesSuccess({ preferences: response.data.payload.buyablePreferences }));
  } catch (err) {
    // TODO:
  }
}

function* updatePreference({ payload, shouldUpdateStore = true }) {
  const authToken = yield select(getUserAuthToken);
  const body = {
    type: 'PLANT',
    isLiked: payload.vote,
    isSpecific: true,
    plantTypeId: payload.id,
  };
  const endpoint = `/app/lgcom/recommendedOrder/preference/buyable/${payload.preferenceId}?isLiked=${payload.vote}`;
  try {
    const response = yield axiosPost(endpoint, body, { method: 'put' }, authToken);
    if (shouldUpdateStore) yield put(fetchedPreferencesSuccess({ preferences: response.data.payload.buyablePreferences }));
  } catch (error) {
    // TODO:
  }
}

function* createPreference({ payload }) {
  const authToken = yield select(getUserAuthToken);
  const body = {
    type: 'PLANT',
    isLiked: payload.vote,
    isSpecific: true,
    plantTypeId: payload.id,
  };
  const endpoint = `/app/lgcom/recommendedOrder/preference/buyable`;
  try {
    const response = yield axiosPost(endpoint, body, {}, authToken);
    yield put(fetchedPreferencesSuccess({ preferences: response.data.payload.buyablePreferences }));
  } catch (error) {
    // TODO:
  }
}

function* createOrUpdatePreference({ type, payload }) {
  const isUpVote = type === seedlingUpVote.toString();
  const existingPreference = yield select((state) => getSeedlingPreference(state, payload));
  if (existingPreference) {
    yield updatePreference({ payload: { preferenceId: existingPreference.id, vote: isUpVote } });
  } else {
    yield createPreference({ payload: { id: payload, vote: isUpVote } });
  }
}

function* executePendingUpdates({ payload }) {
  const isLikesSection = !!payload?.isLikesSection;
  const pendingVotes = yield select((state) => getUserPlantsPendingVotes(state, isLikesSection));
  for (let i = 0; i < pendingVotes.length; i++) {
    const { plantTypeId, id, liked } = pendingVotes[i];
    if (liked === null) yield removePreference({ payload: id, shouldUpdateStore: false });
    else yield updatePreference({ payload: { id: plantTypeId, preferenceId: id, vote: liked }, shouldUpdateStore: false });
  }

  yield put(clearPendingUpdateVote({ clearLikedSectionOnly: isLikesSection, clearDislikedSectionOnly: !isLikesSection }));
  yield doFetchPreferences({ shouldOverwrite: true });
  payload?.onFinish?.();
}

function* doFetchPreferences({ shouldOverwrite = false }) {
  const authToken = yield select(getUserAuthToken);
  const endpoint = '/app/lgcom/recommendedOrder/preference';
  try {
    yield put(isFetchingSeedlingPreferences());
    const response = yield axiosGet(endpoint, {}, authToken);
    yield put(fetchedPreferencesSuccess({ preferences: response.data.buyablePreferences, shouldOverwrite }));
  } catch (err) {
    // TODO:
  }
}

function* seedlingVoteSaga() {
  yield takeLatest([seedlingUpVote.toString(), seedlingDownVote.toString()], createOrUpdatePreference);
}

function* resetVoteSaga() {
  yield takeLatest(resetVote.toString(), removePreference);
}

function* executePendingUpdatesSaga() {
  yield takeLatest(saveUpdates.toString(), executePendingUpdates);
}

function* fetchPreferencesSaga() {
  yield takeLatest(fetchPreferences.toString(), doFetchPreferences);
}
export default [seedlingVoteSaga, fetchPreferencesSaga, resetVoteSaga, executePendingUpdatesSaga];
