import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getShippingOptions } from 'reduxState/cart';
import { SuggestionStyled, SuggestionInner } from '../suggestion/Suggestion.styled';

import { Text } from 'elements';

import shippingOptionCategories from 'constants/shippingOptionCategories';

const ShippingUpsell = ({ shippingCategory }) => {
  const shippingOptions = useSelector(getShippingOptions);

  const seedlingShippingMessages = shippingOptions
    ?.find((opt) => opt.category === shippingCategory)
    ?.options?.find((opt) => opt.id === 'STANDARD')?.messages;

  if (!seedlingShippingMessages?.length) return null;

  return (
    <SuggestionStyled>
      <SuggestionInner>{<Text isHTML content={seedlingShippingMessages?.join(' ')} />}</SuggestionInner>
    </SuggestionStyled>
  );
};

ShippingUpsell.propTypes = {
  shippingCategory: PropTypes.oneOf(Object.values(shippingOptionCategories)),
};

ShippingUpsell.defaultProps = {
  shippingCategory: shippingOptionCategories.PLANT,
};

export default ShippingUpsell;
