const farmstandSizes = {
  12: '12',
  18: '18',
  24: '24',
  30: '30',
  36: '36',
};

farmstandSizes.ALL = Object.values(farmstandSizes);

module.exports = farmstandSizes;
