import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setUserSetEnvironment } from 'reduxState/user';
import { Icon, Text, ButtonText } from 'elements';
import Toggle from 'components/toggle/Toggle';
import House from 'src/elements/icon/assets/house.svg';
import Tree from 'src/elements/icon/assets/tree.svg';

import { GrowEnvironmentToggleStyled } from './GrowEnvironmentToggle.styled';
import GrowEnvironmentModal from 'components/modal/grow-environment/GrowEnvironmentModal';

import growingEnvironments from 'constants/growingEnvironments';
import { setOpenModal, MODALS } from 'reduxState/modal';
import { getCatalogEnvironment } from 'reduxState/catalog';
import { useQueryParams } from 'utils/hooks';

const { OUTDOOR, INDOOR } = growingEnvironments;

const GrowEnvironmentToggle = ({
  title,
  activeLabel,
  inactiveLabel,
  defaultValue,
  modifiers,
  showDetailModal,
  shouldWaitOnDefault,
  onValueChange,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const recommendation = useSelector(getCatalogEnvironment);
  const isTitleString = typeof title === 'string';

  const onChangeHandle = (isOn) => {
    const newValue = (isOn ? activeLabel : inactiveLabel)?.toUpperCase()?.includes?.(OUTDOOR) ? OUTDOOR : INDOOR;
    onValueChange?.(newValue);
    dispatch(setUserSetEnvironment(newValue));
  };

  const handleGrowEnvironmentModal = () => {
    dispatch(setOpenModal(MODALS.GROW_ENVIRONMENT));
  };

  const { environment: paramEnvironment } = useQueryParams();

  useEffect(() => {
    if (paramEnvironment) {
      dispatch(setUserSetEnvironment(paramEnvironment));
      history.replace({
        search: '',
      });
    }
    /* eslint-disable-next-line */
  }, []);

  const startingEnvironment = paramEnvironment || recommendation;
  const initValue = defaultValue ? activeLabel.toUpperCase().includes(defaultValue.toUpperCase()) : startingEnvironment !== INDOOR;

  let renderDetailModal = title;
  if (showDetailModal) {
    renderDetailModal = (
      <ButtonText modifiers='textLeft' onClick={handleGrowEnvironmentModal} eventData={{ action: 'Which is best for me?' }}>
        {isTitleString ? <Text modifiers={['brandFont', 'xLarge']} content={title} /> : title}
      </ButtonText>
    );
  } else if (isTitleString) {
    renderDetailModal = <Text modifiers={['brandFont', 'semibold', 'xLarge']} content={title} />;
  }
  return (
    <>
      <GrowEnvironmentToggleStyled modifiers={['directionColumn', ...modifiers]}>
        {renderDetailModal}
        {(!shouldWaitOnDefault || startingEnvironment) && (
          <Toggle
            startOn={initValue}
            labelOn={
              <>
                <Icon modifiers='medium'>
                  <Tree />
                </Icon>
                <Text content={activeLabel} modifiers={['brandFont', 'xLarge']} />
              </>
            }
            labelOff={
              <>
                <Icon modifiers='medium'>
                  <House />
                </Icon>
                <Text content={inactiveLabel} modifiers={['brandFont', 'xLarge']} />
              </>
            }
            onChange={onChangeHandle}
            eventDataActive={{ action: isTitleString ? `${title} ${activeLabel}` : activeLabel }}
            eventDataInactive={{ action: isTitleString ? `${title} ${inactiveLabel}` : inactiveLabel }}
          />
        )}
      </GrowEnvironmentToggleStyled>
      {showDetailModal && <GrowEnvironmentModal />}
    </>
  );
};

GrowEnvironmentToggle.propTypes = {
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  activeLabel: PropTypes.string,
  inactiveLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  showDetailModal: PropTypes.bool,
  shouldWaitOnDefault: PropTypes.bool,
  onValueChange: PropTypes.func,
};

GrowEnvironmentToggle.defaultProps = {
  title: 'I’m primarily growing:',
  activeLabel: 'Outdoors',
  inactiveLabel: 'Indoors',
  showDetailModal: false,
  shouldWaitOnDefault: true,
};

export default GrowEnvironmentToggle;
