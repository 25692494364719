const colors = {
  mainGreen: '#3D714E',
  black: '#1C1C1C',
  white: '#FFF',
  errorRed: '#d0021b',
  mainYellow: '#ffca3c',
  blue: '#56cbdc',
  grey: '#b4b4b4',
  lightGrey: '#e0e0e0',
  backgroundGrey: '#f7f7f7',
  // mediumGrey: '#979797',
  inputBlack: '#0d0b02',
};

export default colors;
