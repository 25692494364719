import { useEffect } from 'react';
import { useLocation } from '@reach/router';

import { klaviyoKey } from 'utils/envConfig';

/**
 * useKlaviyo - Custom hook to async load stripe script, create a stripe instance and return it
 * Klaviyo will load (if it hasn't already) and refresh (if it has already loaded)
 *
 */
export default function useKlaviyo() {
  const location = useLocation();

  useEffect(() => {
    if (location.hostname !== 'localhost') {
      const klaviyoScript = document.createElement('script');
      klaviyoScript.id = 'klaviyo';
      klaviyoScript.async = true;
      klaviyoScript.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=' + klaviyoKey;
      document.body.appendChild(klaviyoScript);
    }
  }, [location.hostname]);
}
