import React from 'react';
import { navigate } from 'gatsby';

import { useDispatch, useSelector } from 'react-redux';
import { closeModal, getNumSeedlingsInCart, getFarmstandsInCart, getCartItems } from 'reduxState/cart';
import { FARMSTANDS_2020, NOOK_DEVICE } from 'constants/contentful';
import { useContent } from 'utils/hooks';
import _get from 'lodash/get';
import paths from 'constants/paths';
import { shopSubCategories } from 'constants/index';
import { ButtonText } from 'elements';

import { SuggestionStyled, SuggestionInner } from './Suggestion.styled';

const Suggestion = () => {
  const items = useSelector(getCartItems);
  const seedlingsInCartQty = useSelector((state) => getNumSeedlingsInCart(state)) || 0;
  const farmstandsInCart = useSelector((state) => getFarmstandsInCart(state)) || [];
  const dispatch = useDispatch();
  const content = useContent(FARMSTANDS_2020);
  const nookContent = useContent(NOOK_DEVICE)?.[0];
  let emptyElement = <></>;

  if (!content || !nookContent || !items) return null;

  if (farmstandsInCart.length === 0) return emptyElement;

  const { farmstandModels } = content[0].fields;

  const totalFarmstandsCapacity = farmstandsInCart.reduce((total, { sku, qty, subcategory }) => {
    const isNook = subcategory === shopSubCategories.NOOK;
    const model = isNook ? nookContent : farmstandModels.find((f) => _get(f, 'sys.id') === sku);
    const sizes = _get(model, 'fields.size', 0) * qty;
    return total + sizes;
  }, 0);

  const seedlingText = seedlingsInCartQty === 1 ? `seedling` : `seedlings`;
  const farmstandText = farmstandsInCart.length > 1 || farmstandsInCart[0].qty > 1 ? `Farmstands hold` : `Farmstand holds`;
  if (seedlingsInCartQty === totalFarmstandsCapacity) return emptyElement;

  return (
    <SuggestionStyled>
      <SuggestionInner>
        <p>
          You have <strong>{seedlingsInCartQty}</strong> {seedlingText} and your {farmstandText} <strong>{totalFarmstandsCapacity}</strong>.
          <br />
          <ButtonText
            as='a'
            label={seedlingsInCartQty < totalFarmstandsCapacity ? 'ADD MORE SEEDLINGS' : 'EDIT SEEDLINGS'}
            onClick={() => {
              dispatch(closeModal());
              navigate(paths.FILL_YOUR_FARMSTAND_BUNDLES);
            }}
          />
        </p>
      </SuggestionInner>
    </SuggestionStyled>
  );
};

export default Suggestion;
