import styled from 'styled-components/macro';
import { breakPoints, fontSize } from '@lettucegrow/atoms.utils.theme';

import { Flex } from 'components';

export default styled.nav.attrs({ className: 'Nav' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.farmProject.gray_2};
  height: ${({ theme }) => theme.layout.navHeight};
  padding: 0 24px;
  transition: top 0.3s;
  position: ${({ isPageScrolled }) => (isPageScrolled ? 'fixed' : 'initial')};
  top: ${({ isPageScrolled }) => (isPageScrolled ? 0 : '30px')};
  left: 0;
  right: 0;
  z-index: 30;

  .Logo {
    width: 185px;
  }

  .NavPageLinks,
  .NavAccountLinks,
  .SearchLink {
    display: none;
  }

  .NavActionLinks {
    align-items: center;
  }

  .NavAccountLinks {
    .Icon {
      width: 0.8rem;
      height: 1rem;
      svg {
        height: 90%;
        stroke: ${(props) => props.theme.farmProject.dark_gray};
      }
    }
    .Icon + .Icon {
      margin-left: 0.5rem;
      width: 0.5rem;
    }
  }

  .SearchLink {
    margin-left: 1rem;
    width: 1rem;
    height: 1rem;
    overflow: visible;
    svg {
      stroke-width: 0.5;
      stroke: ${({ theme }) => theme.farmProject.dark_gray};
    }
  }

  .CartWrapper {
    cursor: pointer;
    width: 2.3rem;
    margin-left: 1rem;
    justify-content: space-evenly;
    align-items: center;
    .Icon {
      cursor: pointer;
      svg {
        stroke: ${({ theme }) => theme.farmProject.magenta};
        width: 99%;
        path {
          fill: ${({ theme }) => theme.farmProject.magenta};
        }
      }
    }
  }

  .NavPageLinks {
    flex: 1;
    justify-content: center;
    gap: 2rem;
    margin: 0 1rem;
  }

  .Sandwich {
    margin-right: 2.2rem;
  }

  .MobileNav {
    width: 100vw;
    max-height: ${({ theme }) => `calc(100vh - ${theme.layout.navAccessibilityHeight})`};
    background-color: ${({ theme }) => theme.farmProject.white};
    position: fixed;
    top: ${({ theme, isPageScrolled }) => (isPageScrolled ? theme.layout.navHeight : theme.layout.navAccessibilityHeight)};
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 21;
    overflow: auto;

    .SubLinkWrapper {
      gap: 5%;
      border-bottom: 1px solid ${({ theme }) => theme.farmProject.light_green_5};
      padding-bottom: 2.5rem;
    }

    .SubLinkImage {
      flex: 1;
      border-radius: 0.25rem;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3), 0px 3px 3px 2px rgba(0, 0, 0, 0.15);
      .Image {
        height: 10rem;
      }
      .ButtonText {
        margin: 0 auto;
      }
    }

    .Search {
      width: 90%;
      .InputStyled {
        .MuiInput-root,
        .MuiTextField-root {
          max-width: 100%;
        }
      }
      .MuiInput-underline:before {
        border-bottom-color: ${({ theme }) => theme.farmProject.gray_2};
      }
    }

    .lg-accordion__inner {
      .ButtonText:not(:first-of-type) {
        margin: 1.5rem 0;
      }
    }
  }

  .MobileNavPageLinks {
    margin: 0;
    width: 90%;
    li {
      height: 75px;
    }
    li:not(:first-of-type):not(:nth-of-type(2)) {
      border-top: 1px solid ${({ theme }) => theme.farmProject.light_green_5};
    }
  }

  @media ${breakPoints.large_break} {
    .NavPageLinks,
    .NavAccountLinks,
    .SearchLink {
      display: flex;
    }
    .Sandwich {
      display: none;
    }
    .MobileNav {
      display: none;
    }
  }
`;

export const NavPageLink = styled.div.attrs({ className: 'NavPageLink' })`
  > .ButtonText {
    height: ${({ theme }) => theme.layout.navHeight};
    border-bottom: 3px solid ${({ theme, showUnderline }) => (showUnderline ? theme.farmProject.brand_primary : 'transparent')};
  }
  > .ButtonText span {
    ${fontSize(16)};
    letter-spacing: 1.5;
  }
`;

export const SubNavOverlay = styled(Flex)`
  padding-top: ${({ isOpen }) => (isOpen ? '1.5rem' : 0)};
  position: absolute;
  width: 100vw;
  height: ${({ isOpen }) => (isOpen ? '18.5rem' : 0)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0.6)};
  transition: opacity 0.3s;
  top: ${({ theme, isPageScrolled }) => (isPageScrolled ? theme.layout.navHeight : theme.layout.navAccessibilityHeight)};
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.farmProject.white};
  box-shadow: ${({ isOpen }) => (isOpen ? '0px 2px 2px rgba(0, 0, 0, 0.3), 0px 3px 3px 2px rgba(0, 0, 0, 0.15)' : 'none')};
  z-index: 12;

  > .Image {
    width: 18.5rem;
    height: 100%;
  }
  > .Text {
    align-self: start;
    margin: 0 5.5rem;
    width: 11rem;
  }
  .ButtonText span {
    ${fontSize(14)};
    letter-spacing: 1.5;
  }
  .SubLinks {
    max-width: 65%;
    margin: 0 3rem 0 0;
    max-height: 12rem;
    > .ButtonText {
      margin: 0.5rem;
    }
  }
  .SubLinkImage {
    .Link,
    .Image {
      width: 10rem;
      height: 10rem;
    }
    .Text {
      padding: 0.25rem;
    }
  }
`;
