import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import _partition from 'lodash/partition';

import { Text, SquaredButton, Input, Select, TextArea, ButtonText } from 'elements';
import { ImageUploads } from 'components';
import { FormWrapper, FormGroup, AddPhotoWrapper } from './ContactUsForm.styled';
import { ContactListItem } from 'components/contact-section/styled';

import { anonymousUserSubjects, existingUserSubjects } from 'constants/contactSubjects';
import { phoneNumberRegex, toPhoneNumber } from 'utils/pattern-utils';
import { getLoginState } from 'reduxState/user';
import logError from 'utils/errorHandler';
import { dbFormatPhone } from 'utils/form-utils';
import { axiosPost } from 'utils/api-utils';
import { authDomain } from 'utils/envConfig';
import { useContent } from 'utils/hooks';
import { CONTACT_US } from 'constants/contentful';
import { imageKeysFilter, MAX_BYTE_SIZE } from 'components/image-uploads/utils';

const ContactUsForm = ({ emailContent }) => {
  const messages = useSelector((state) => state.contentful.messages);
  const location = useLocation();
  const isLoggedIn = useSelector(getLoginState);
  const user = useSelector((state) => state.user);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const content = useContent(CONTACT_US);
  const subjectsList = isLoggedIn ? existingUserSubjects : anonymousUserSubjects;

  useEffect(() => {
    let timeout;
    if (isSubmitted) {
      timeout = setTimeout(() => setIsSubmitted(false), 4000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isSubmitted]);

  if (!content || !messages) return null;

  const fields = content[0]?.fields;

  // check url for default selected subject
  const queryParams = location.search ? queryString.parse(location.search) : null;
  const subjectValue = queryParams ? queryParams.subject : '';

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const [imagesKeys, otherKeys] = _partition(Object.keys(values), imageKeysFilter);
    const restValues = otherKeys.reduce((acc, curr) => ({ ...acc, [curr]: values[curr] }), {});

    const { phone, ...rest } = restValues;
    setSubmitting(true);
    const data = new FormData();
    let command = { ...rest };
    if (phone) {
      command = { phone: dbFormatPhone(phone), ...rest };
    }
    data.append('command', JSON.stringify(command));
    imagesKeys.forEach((key) => !!values[key]?.name && data.append('file', values[key]));

    try {
      if (isLoggedIn) {
        await axiosPost('/app/lgcom/contactFormMulti', data, {}, user.authToken);
      } else {
        await axiosPost('/app/public/contactFormPublicMulti', data, {
          headers: { authDomain },
        });
      }

      setIsSubmitted(true);
      resetForm();

      window.dataLayer.push({
        event: 'formSubmitted',
        formId: 'contact',
      });
    } catch (error) {
      window.dataLayer.push({
        event: 'formSubmitFailure',
        formId: 'contact',
      });

      logError(error);
    }
  };

  return (
    <FormWrapper>
      <FormGroup>
        <Formik
          enableReinitialize
          initialValues={{
            ...(!isLoggedIn && { name: '', phone: '', email: '' }),
            subject: subjectValue,
            body: '',
            image1: null,
            image2: null,
            image3: null,
            image4: null,
            image5: null,
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            ...(!isLoggedIn && {
              name: Yup.string().required(messages.LGE_required),
              email: Yup.string().email(messages.LGE_email_invalid).required(messages.LGE_required),
              phone: Yup.string().required(messages.LGE_required).matches(phoneNumberRegex, messages?.LG_invalid_phone_number),
            }),
            subject: Yup.string().required(messages.LGE_required),
            body: Yup.string().required(messages.LGE_required),
          })}
        >
          {({ setFieldValue, isSubmitting, values }) => {
            const imagesKeys = Object.keys(values).filter(imageKeysFilter);
            const totalFileSize = imagesKeys.reduce((sum, key) => sum + (values[key]?.size || 0), 0);
            const phoneChange = (event) => {
              setFieldValue('phone', toPhoneNumber(event.target.value));
            };
            return (
              <Form noValidate>
                {!!emailContent?.body && (
                  <ContactListItem>
                    <ButtonText
                      as='a'
                      modifiers={['underlined', 'brand']}
                      label={emailContent?.body}
                      href={`mailto:${emailContent?.lowerTitle}`}
                    />
                  </ContactListItem>
                )}
                {!isLoggedIn && (
                  <>
                    <Field
                      required
                      variant='filled'
                      name='name'
                      type='text'
                      label='Full Name'
                      autocomplete='name'
                      helperText='*Required'
                      component={Input}
                    />
                    <Field
                      variant='filled'
                      name='phone'
                      label='Phone (555-555-5555)'
                      component={Input}
                      autoComplete='tel'
                      inputMode='numeric'
                      helperText='*Required'
                      onChange={phoneChange}
                    />
                    <Field
                      required
                      variant='filled'
                      name='email'
                      type='text'
                      label='Email'
                      helperText='*Required'
                      component={Input}
                      autocomplete='email'
                    />
                  </>
                )}
                <Field
                  required
                  name='subject'
                  label='Select Subject'
                  helperText='*Required'
                  component={Select}
                  options={Object.keys(subjectsList).map((key) => ({
                    value: key,
                    label: subjectsList[key],
                  }))}
                />
                <Field required variant='filled' name='body' type='text' label='Message' helperText='*Required' component={TextArea} />
                <AddPhotoWrapper>
                  <Text modifiers={['brandFont']} content='Add Photos' as='label' for='image1' />
                  <Text modifiers='tiny' content={fields.sections?.[0]?.fields?.body2} />
                  <ImageUploads values={values} setFieldValue={setFieldValue}></ImageUploads>
                </AddPhotoWrapper>
                <SquaredButton
                  label={isSubmitting ? '...SENDING' : isSubmitted ? 'THANKS!' : 'SEND'}
                  type='submit'
                  disabled={totalFileSize > MAX_BYTE_SIZE}
                  modifiers={[totalFileSize > MAX_BYTE_SIZE && 'disabled']}
                />
              </Form>
            );
          }}
        </Formik>
      </FormGroup>
    </FormWrapper>
  );
};

ContactUsForm.propTypes = {
  emailContent: PropTypes.object,
};

export default ContactUsForm;
