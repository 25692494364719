const sectionTypes = {
  MARQUEE: 'sectionMarquee',
  STANDARD: 'section0',
  REVIEWS: 'sectionType1',
  PERCENTAGE: 'sectionPercentageBlock',
  TEXT: 'sectionSimpleText',
  TEASER: 'sectionTeaserLink',
};

sectionTypes.ALL = Object.values(sectionTypes);

export default sectionTypes;
