import React, { useEffect, useState /*useRef,*/ } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import { Title, Text, Input, Select, SquaredButton } from 'elements';
import InternationalAcquisitionStyled from './InternationalAcquisition.styled';

import { getOpenModal, setOpenModal, setCloseModal, MODALS } from 'reduxState/modal';
import { getUserCountryId, getCAPIUserInfo } from 'reduxState/user';

import logError from 'utils/errorHandler';
import { useContent } from 'utils/hooks';
import { INTERNATIONAL_MODAL } from 'constants/contentful';
import { emailSignup } from 'utils/newsletter-api';
import { getStorage } from 'utils/storageManager';
import useCountries from 'utils/hooks/useCountries';

const US = 'Z3Jvdy5kYi5Db3VudHJ5LTIyMA';

const InternationalAcquisition = () => {
  const dispatch = useDispatch();
  const userCountry = useSelector(getUserCountryId);
  const openModal = useSelector(getOpenModal);
  const capiData = useSelector(getCAPIUserInfo);
  const content = useContent(INTERNATIONAL_MODAL, openModal?.id === MODALS.INTERNATIONAL);
  const messages = useSelector((state) => state.contentful.messages);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { countries } = useCountries();
  const hasBeenShown = getStorage('lettucegrow-int-email-618');

  useEffect(() => {
    if (userCountry !== US && !!userCountry && !hasBeenShown) {
      dispatch(setOpenModal(MODALS.INTERNATIONAL));
    }
  }, [userCountry, hasBeenShown, dispatch]);

  if (!content || !countries) return null;
  const fields = content?.[0]?.fields;

  const handleCloseModal = () => {
    dispatch(setCloseModal());
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    if (isSubmitted) {
      handleCloseModal();
      return;
    }
    const data = { ...values, formType: 'internationalAcquisition', capiData };
    try {
      await emailSignup(data);
      setIsSubmitted(true);
      window.dataLayer.push({
        event: 'formSubmitted',
        formId: 'international-acquisition',
      });
    } catch (error) {
      setSubmitting(false);
      logError(error);
    }
  };

  return (
    <InternationalAcquisitionStyled
      className='InternationalAcquisitionModal'
      isOpen={openModal?.id === MODALS.INTERNATIONAL}
      onCloseAction={handleCloseModal}
    >
      <Title content={fields.title} />
      <Text content={fields.subHeaderText} />
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
          countryId: userCountry,
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string().email(messages?.LGE_email_invalid).required(messages?.LGE_required),
          countryId: Yup.string().required(messages?.LGE_required),
        })}
      >
        {({ isSubmitting, touched, errors, values }) => {
          const hasError = (touched.countryId && errors.countryId) || (touched.email && errors.email);
          return (
            <Form noValidate>
              <Field
                required
                variant='filled'
                name='email'
                type='text'
                label='Email'
                helperText='*Required'
                component={Input}
                autocomplete='email'
              />
              <Field
                required
                name='countryId'
                label='Select a Country'
                helperText='*Required'
                component={Select}
                value={values.countryId || userCountry}
                options={countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                }))}
              />
              <SquaredButton
                label={isSubmitting ? '...SUBMITTING' : isSubmitted ? 'THANKS!' : 'SUBMIT'}
                type='submit'
                disabled={hasError}
                modifiers={[hasError && 'disabled']}
              />
              <Text modifiers={['small', 'brandFont', 'disclaimer']} content={fields.buttonText}></Text>
            </Form>
          );
        }}
      </Formik>
    </InternationalAcquisitionStyled>
  );
};

export default InternationalAcquisition;
