import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Title, Text, Image, Link } from 'elements';
import { StarRating, Swiper } from 'components';
import { PressReviewSliderWrapper, PressSliderWrapper, PressSlider } from './PressReviewSlider.styled';

import { useBreakpoint } from 'utils/hooks';
import paths from 'constants/paths';

const calloutData = {
  isCallout: true,
};

const PressReviewSlider = ({ title, body, showCallout, brands, modifiers }) => {
  const viewportWidth = useBreakpoint();
  const [hasMounted, setHasMounted] = useState(false); // flag to disable render on server/build time
  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
    }
  }, [hasMounted]);

  const sliderConfig = {
    loop: true,
    navigation: true,
    speed: 500,
    slidesPerView: viewportWidth === 'XL' ? 5 : 1,
    className: viewportWidth === 'XL' ? 'center' : false,
    initialSlide: 0,
  };
  if (!brands?.length || !hasMounted) return null;
  const brandsItems = [...brands];
  showCallout && brandsItems.splice(viewportWidth !== 'XL' ? 0 : 2, 0, calloutData);
  const renderBlock = (block) => {
    if (block.isCallout) {
      return (
        <PressSlider key='callout'>
          <StarRating readOnly rating={5} modifiers='yellowColor' />
          <Text isHTML modifiers={['bold', 'light', viewportWidth !== 'XL' ? 'xxLarge' : 'xLarge']} content={body} />
          <Link
            modifiers={['underline', 'darkGrayColor']}
            href={paths.CUSTOMER_REVIEWS}
            content='READ THE REVIEWS'
            eventData={{ action: 'READ THE REVIEWS' }}
          />
        </PressSlider>
      );
    }
    return (
      <PressSlider key={block.sys.id}>
        <Image
          picture={block.fields?.mediaContent?.fields?.file?.url}
          alt={block.fields?.mediaContent?.fields?.description}
          nativeWidth={240}
        />
        <Text modifiers={['noLineHeight', (viewportWidth === 'LG' || viewportWidth === 'XL') && 'small']} content={block.fields?.body} />
        <Link
          modifiers={['underline', 'darkGrayColor']}
          href={block.fields?.callToAction?.fields?.linkUrl}
          target='_blank'
          content='READ FULL ARTICLE'
          eventData={{ action: 'READ FULL ARTICLE' }}
        />
      </PressSlider>
    );
  };
  return (
    <PressReviewSliderWrapper modifiers={modifiers}>
      {!!title && <Title content={title} />}
      <PressSliderWrapper>
        <Swiper swiperProps={sliderConfig} items={brandsItems} renderSlideCard={renderBlock} />
      </PressSliderWrapper>
    </PressReviewSliderWrapper>
  );
};

PressReviewSlider.defaultProps = {
  showCallout: false,
};

PressReviewSlider.propTypes = {
  showCallout: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  brands: PropTypes.array,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
export default PressReviewSlider;
