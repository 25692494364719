import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as FullStory from '@fullstory/browser';

import { MessageBanner, SiteNavigation, Navigation, HorizontalSwipeMenuShop, Footer } from 'components';
import ErrorBoundary from 'components/deprecated/ErrorBoundary';
import MainStyled, { UsableNetLink } from './LandingPageWrapper.styled';
import Cart from 'components/cart/Cart';
import LoginModal from 'components/modal/login-modal/LoginModal';
import { ErrorModal, InternationalModal } from 'components/modal/';

import { fetchContentCacheMap, getContentCacheMap, getIsFetchingCacheMap } from 'reduxState/contentful';
import { setUrlDiscount, addBulkItems, openCartModal, setLastAddedFarmstandId } from 'app/reduxState/cart';
import { setOpenModal, setCloseModal } from 'app/reduxState/modal';
import { getIsLoading } from 'app/reduxState/appSettings';
import paths from 'constants/paths';
import { useKlaviyo, useAffirm, useRouteChange, useQueryParams } from 'utils/hooks';
import { fullStoryId } from 'utils/envConfig';
import { prodLinkPathRegex } from 'utils/pattern-utils';
import FeatureFlagProvider from 'app/context/FeatureFlag';

import { LettuceGiveBanner, SlimBanner } from 'components';

import { ShopMenu } from 'constants/shopMenu';

const LandingPageWrapper = ({ children }) => {
  const location = useLocation();
  const isFromMobile = useSelector((state) => state.user.isFromMobile);
  const [isMounted, setIsMounted] = useState(false);
  const isLoading = useSelector(getIsLoading);
  const contentCacheMap = useSelector(getContentCacheMap);
  const isFetchingCacheMap = useSelector(getIsFetchingCacheMap);
  const dispatch = useDispatch();

  useKlaviyo();
  useRouteChange();
  useAffirm();
  useQueryParams(['pc', 'res', 'UsableNetAssistive']);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      window.makerAddToCart = (items = [], discount) => {
        discount && dispatch(setUrlDiscount(discount));
        items.length && dispatch(addBulkItems({ items }));
      };
      window.makerLastAddedFarmstandId = (sku) => {
        dispatch(setLastAddedFarmstandId(sku));
      };
      window.makerOpenModal = (modalName) => {
        dispatch(setOpenModal(modalName));
      };
      window.makerCloseModal = () => {
        dispatch(setCloseModal());
      };
      window.makerLinkTo = (url) => {
        const includesDomain = url?.includes('lettucegrow.com');
        if (includesDomain) {
          const pathPattern = prodLinkPathRegex;
          const path = url.match(pathPattern);
          path && path[1] && navigate(path[1]);
        } else if (url.startsWith('/')) {
          navigate(url);
        }
      };
      window.openCartModal = () => {
        dispatch(openCartModal());
      };
    }

    if (!contentCacheMap && !isFetchingCacheMap) dispatch(fetchContentCacheMap());
  }, [isMounted, contentCacheMap, dispatch, isFetchingCacheMap]);

  useEffect(() => {
    const timeout = setTimeout(() => FullStory.init({ orgId: fullStoryId }), 7000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const isShopPage = !!ShopMenu.find((item) => location.pathname.includes(item.path))?.path || false;

  return (
    <ErrorBoundary>
      <FeatureFlagProvider>
        <MainStyled>
          <SlimBanner modifiers={['gray2', 'rightAlignedDesktop']}>
            {isMounted && (
              <UsableNetLink
                href='#'
                className='UsableNetAssistive'
                onClick={window.enableUsableNetAssistive}
                modifiers={['darkGrayColor', 'bold', 'underline', 'small']}
              >
                ENABLE ACCESSIBILITY
              </UsableNetLink>
            )}
          </SlimBanner>
          <SiteNavigation />
          <Navigation />
          <Cart />
          <MessageBanner location={location} />
          {isShopPage && <HorizontalSwipeMenuShop />}

          {children}
          {isMounted &&
          !location.pathname.includes(paths.OPEN_SURVEY) &&
          !location.pathname.includes(paths.SURVEY_RESULTS) &&
          !location.pathname.includes(paths.ORDER_CONFIRMATION) &&
          !isFromMobile &&
          !location.pathname.includes(paths.CHECKOUT) &&
          !location.pathname.includes(paths.FILL_YOUR_FARMSTAND) &&
          !location.pathname.includes(paths.FILL_YOUR_FARMSTAND_BUNDLES) &&
          !location.pathname.includes('/ios') ? (
            <>
              <LettuceGiveBanner />
            </>
          ) : (
            <></>
          )}
          {isMounted &&
          !isFromMobile &&
          !isLoading &&
          !location.pathname.includes(paths.CHECKOUT) &&
          !location.pathname.includes(paths.RECOMMENDED_ORDER_DETAILS) &&
          !location.pathname.includes(paths.PERSONALIZE_RECOMMENDATION) ? (
            <Footer />
          ) : (
            <></>
          )}
        </MainStyled>
        <LoginModal />
        <ErrorModal />
        <InternationalModal />
      </FeatureFlagProvider>
    </ErrorBoundary>
  );
};

LandingPageWrapper.propTypes = {
  children: PropTypes.any,
};

export default LandingPageWrapper;
