import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { Text, Title, SquaredButton, GatsbyImage } from 'elements';

import { GrowSection, ContentWrapper } from './FarmGrow.styled';

const Grow = ({ title, body, callToAction, gatsbyImageData, gatsbyImageDataAlt }) => (
  <GrowSection>
    <ContentSection>
      <ContentWrapper>
        <Title modifiers={['inverted', 'lineHeightNormal']} as='h3' content={title} />
        {!!gatsbyImageData && <GatsbyImage image={gatsbyImageData} alt={gatsbyImageDataAlt} />}
      </ContentWrapper>
      <ContentWrapper>
        <Text modifiers='inverted' content={body} isHTML />
        <SquaredButton
          as='button'
          label={callToAction?.fields?.linkText || callToAction?.fields?.text}
          href={callToAction?.fields?.linkUrl || callToAction?.fields?.url}
          modifiers='secondary'
          eventData={{ action: callToAction?.fields?.linkText || callToAction?.fields?.text }}
        />
      </ContentWrapper>
    </ContentSection>
  </GrowSection>
);

Grow.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  callToAction: PropTypes.object,
  gatsbyImageData: PropTypes.object,
  gatsbyImageDataAlt: PropTypes.string,
};

export default Grow;
