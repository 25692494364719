import styled from 'styled-components/macro';
import breakPoints from 'theme/Media';

export const ToastStyled = styled.div.attrs({
  className: 'ToastStyled',
})`
  background-color: ${({ theme }) => theme.farmProject.green};
  border: 1px solid ${({ theme }) => theme.farmProject.green};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35rem 1rem;
  margin: 0;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 5rem;
  width: fit-content;

  .Icon {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.5rem;

    svg {
      g {
        g {
          path {
            fill: white;
          }
        }
      }
    }
  }

  span {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 0.625rem;
    letter-spacing: normal;
    color: white;
    text-decoration: none;
    line-height: normal;
    text-align: left;
  }

  .ButtonText span {
    font-size: 0.625rem;
    letter-spacing: normal;
    line-height: normal;
    text-decoration: underline;
    padding-left: 0.5rem;
    border-left: solid 1px white;
    margin-left: 0.5rem;
  }

  @media ${breakPoints.large_break} {
    span {
      font-size: 0.75rem;
    }

    .ButtonText span {
      font-size: 0.75rem;
    }
  }
`;
