import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

const SET_BUNDLE_SELECTED = 'SET_BUNDLE_SELECTED';

const initialState = { bundleSelected: null };

export const setSelectedBundle = (payload) => ({
  type: SET_BUNDLE_SELECTED,
  payload,
});

const fyfBundleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUNDLE_SELECTED:
      return { ...state, bundleSelected: action.payload };
    default:
      return state;
  }
};

export const FYFBundleContext = React.createContext();

const FYFBundleContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(fyfBundleReducer, initialState);

  return <FYFBundleContext.Provider value={{ state, dispatch }}>{children}</FYFBundleContext.Provider>;
};

FYFBundleContextProvider.propTypes = {
  children: PropTypes.node,
};

export default FYFBundleContextProvider;
