import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import styled from 'styled-components/macro';

import colors from 'constants/styledColors';
import imageUrls from 'constants/imageUrls';
import {
  removeItem,
  updateItem,
  addBulkItems,
  getVouchersUsedCountByItemSku,
  getVouchersUsedDiscountCentsByItemSku,
} from 'reduxState/cart';
import { formatPrice, getItemPath } from 'utils/cart-utils';
import { getDayMonthFormat } from 'utils/date-utils';
import { getSeedlingsListFromBundle, getSeedlingsListFromCustomBundle, formatSeedlingsList } from 'utils/bundles-utils';
import { useContent, useBundleSeedlings } from 'utils/hooks';

import Image from 'components/deprecated/elements/Image';
import { IncrementInput, TextTooltipWrapper, PromoPrice } from 'components';
import { ButtonText, Text } from 'elements';

import Tooltip from '../tooltip/Tooltip';
import RemoveItemButton from 'components/remove-item-button/RemoveItemButton';
import shopCategories from 'constants/shopCategories';
import { PLANT_BUNDLE, PLANT_TYPE } from 'constants/contentful';
import { KNOT_FOODIE_BUNDLE_SKUS, KNOT_NEWLYWED_BUNDLE_SKUS } from 'constants/sku';

const LineItemContainer = styled.li`
  display: block;
  width: 100%;
  border-bottom: 1px solid ${colors.GREY};
  position: relative;
  opacity: ${(props) => (props.state === 'exiting' ? '0' : '1')};
  transition: opacity 200ms ease-in;
  padding: 1.85rem 0 1.85rem 0;
  font-family: ${({ theme }) => theme.farmProject.font_family};

  .ButtonText {
    max-width: 12rem;
    max-height: 2.4rem;
    display: block;
    pointer-events: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
  }

  .image-container {
    max-width: 100px;
    pointer-events: ${({ disableLinks }) => (disableLinks ? 'none' : 'auto')};
    picture {
      img {
        display: block;
      }
    }
  }
  .IncreaseInput {
    max-width: 5.7rem;

    .Input {
      height: 1.65rem;
      font-size: 0.75rem;
    }

    .BtnInput {
      width: auto;
      height: auto;
      span {
        font-size: 1.5rem;
      }
      &:first-of-type {
        left: 20%;
      }
      &:last-of-type {
        left: auto;
        right: 0%;
      }
    }
  }

  &:last-child {
    transition: opacity 1ms linear;
    border-bottom: none;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .IncreaseInput {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
  }
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
`;

const ItemDetails = styled.div.attrs({
  className: 'LineItemDetails',
})`
  display: flex;

  .image-container {
    vertical-align: top;
    margin-right: 20px;
    max-width: 80px;
  }
  .text-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
    max-width: 12rem;
  }

  img {
    max-width: 100%;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  text-align: right;
  justify-content: center;

  .TextTooltipWrapperStyled {
    margin-top: 0.2rem;

    .Text {
      line-height: normal;
    }
  }
`;

const ItemTitleWrapper = styled.div.attrs({
  className: 'ItemTitleWrapper',
})`
  position: relative;

  .TipIconWrapper {
    width: 8rem;

    .Icon {
      top: -1rem !important;
      width: 4rem;
      height: 1rem;
      overflow: visible;

      pointer-events: none;

      svg {
        opacity: 0;
      }

      &:after {
        width: 15px;
        height: 15px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        background-color: white;
        margin: 0;
        left: 1.3rem;
        top: 1rem;
        z-index: 10;
      }
    }

    .TooltipStyled {
      padding: 0.75rem;
      max-width: 12rem;
      border-radius: 0;
      background-color: white;
      border: none;
      box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.3);
      top: -0.6rem;
      left: auto;
      -webkit-transform: translate(1.9rem, -50%);
      -ms-transform: translate(1.9rem, -50%);
      transform: translate(-3rem, 0);
    }
  }
`;

const Price = styled.p`
  font-size: 0.9375rem;
  font-family: ${({ theme }) => theme.farmProject.font_family};
  color: ${({ theme }) => theme.farmProject.brand_primary};
`;

/**
 * * A Line Item for the Cart on Lettuce Grow
 *
 * @prop {object} item - expects: name, imageUrl, category, subText, qty, sku, priceCents and optionally discountedPriceCents
 * @prop {function} closeModal - function to control the closing of the modal which contains the cart
 */

const LineItem = ({ item, closeModal, state, className, isFarmstand, freeGifts = [], isV2Item }) => {
  const isPlantBundle = item.category === shopCategories.BUNDLES;
  const isCustomPlantBundle = item.category === shopCategories.CUSTOM_BUNDLES;
  const dispatch = useDispatch();
  const reservation = useSelector((state) => state.reservation);
  const bundlesContent = useContent(PLANT_BUNDLE, isPlantBundle) || [];
  const plantContent = useContent(PLANT_TYPE, isCustomPlantBundle) || [];
  const { seedlings } = useBundleSeedlings(isCustomPlantBundle ? item.sku : null);
  const customBundleSeedlings = seedlings;
  const [seedlingsList, setSeedlingsList] = useState([]);
  const hasPrice = item?.priceCents > 0;
  const hasDiscount = !!item?.originalPriceCents;
  const isFreeGift = !!item?.originalPriceCents && item?.priceCents === 0;
  const freeGiftLabel = 'FREE GIFT';
  const itemVouchersUsedDiscountCents = useSelector((state) => getVouchersUsedDiscountCentsByItemSku(state, item.sku));
  const itemVouchersUsedCount = useSelector((state) => getVouchersUsedCountByItemSku(state, item.sku));

  const reservedItem = reservation.items.find((resItem) => resItem.sku === item.sku);

  useEffect(() => {
    if (isCustomPlantBundle && !!customBundleSeedlings.length && !!plantContent.length)
      setSeedlingsList(getSeedlingsListFromCustomBundle(customBundleSeedlings, plantContent));
    // eslint-disable-next-line
  }, [isCustomPlantBundle, customBundleSeedlings, plantContent.length]);

  useEffect(() => {
    if (isPlantBundle && !!bundlesContent.length && !seedlingsList.length)
      setSeedlingsList(getSeedlingsListFromBundle(item, bundlesContent));
    // eslint-disable-next-line
  }, [isPlantBundle, bundlesContent.length, seedlingsList.length]);

  const incrementQty = (event) => {
    if (freeGifts?.length) {
      const items = [{ sku: event.currentTarget.id, qty: 1 }];
      freeGifts.forEach((sku) => items.push({ sku, qty: 1 }));
      return dispatch(addBulkItems({ items }));
    }
    return dispatch(updateItem({ sku: event.currentTarget.id, qty: parseInt(item.qty) + 1 }));
  };

  const decrementQty = (event) => {
    return dispatch(updateItem({ sku: event.currentTarget.id, qty: parseInt(item.qty) - 1 }));
  };

  const getItemPrice = (price, qty = 1) => formatPrice((price * qty) / 100, 2);
  const getVoucherAdjustedPrice = (price, qty) => {
    return formatPrice((price * qty - itemVouchersUsedDiscountCents) / 100, 2);
  };

  const onNavigateToItemPath = () => {
    const isFoodieBundle = KNOT_FOODIE_BUNDLE_SKUS.includes(item.sku);
    const isNewlywedBundle = KNOT_NEWLYWED_BUNDLE_SKUS.includes(item.sku);
    const shouldAddNavigationProps = isFoodieBundle || isNewlywedBundle;
    const navigationProps = { state: { bundleSelectionIndex: isFoodieBundle ? 0 : isNewlywedBundle ? 1 : null } };

    closeModal();
    if (shouldAddNavigationProps) navigate(getItemPath(item), navigationProps);
    else navigate(getItemPath(item));
  };

  const handleRemoveItem = useCallback(
    (event) => {
      return dispatch(removeItem(event.currentTarget.value));
    },
    [dispatch]
  );

  const image = item.imageUrl ? item.imageUrl : imageUrls.PLACEHOLDER_IMAGE;

  return (
    <LineItemContainer state={state} className={className} disableLinks={isCustomPlantBundle}>
      <FlexRow>
        <div className='image-container'>
          <Image alt={item.name} src={image} urlParams='?w=175' onClick={onNavigateToItemPath} />
        </div>
        <FlexColumn>
          <FlexRow>
            <ItemDetails>
              <div className='text-container'>
                <ItemTitleWrapper>
                  <ButtonText
                    onClick={onNavigateToItemPath}
                    label={`${item.name} ${isPlantBundle ? ` (${item.plantCount} Seedlings)` : ''}`}
                    modifiers={['secondary', 'textLeft', 'compact']}
                  />
                  {(isPlantBundle || isCustomPlantBundle) && !!seedlingsList.length && (
                    <Tooltip callOut='See plants' iconModifiers='invertedStroke' text={formatSeedlingsList(seedlingsList)} />
                  )}
                </ItemTitleWrapper>
                {isFarmstand && !isV2Item && (
                  <Tooltip iconModifiers='invertedStroke' text='Everything you need to get growing except seedlings.' />
                )}
                {/* <ItemSubtext>{item.subText}</ItemSubtext> */}
              </div>
            </ItemDetails>
            {!isFreeGift && <RemoveItemButton value={item.sku} onClick={(event) => handleRemoveItem(event)} />}
          </FlexRow>
          <FlexRow>
            <FlexColumn>
              <IncrementInput
                sku={item.sku}
                quantity={item.qty}
                onDecrease={decrementQty}
                onIncrease={incrementQty}
                disabledDecrease={isFreeGift || item.qty === 1}
                disabledIncrease={isFreeGift || (reservedItem && reservedItem.max && item.qty >= reservedItem.max)}
                isActive={item.category !== 'Gift Card'}
                modifiers='fluid'
              />
              {!!itemVouchersUsedCount && (
                <Text modifiers={['brandFont', 'tiny', 'italic']}>{`${itemVouchersUsedCount} CREDIT${
                  itemVouchersUsedCount > 1 ? 'S' : ''
                } APPLIED`}</Text>
              )}
            </FlexColumn>

            {(hasPrice || hasDiscount) && (
              <PriceContainer>
                {hasDiscount ? (
                  <PromoPrice
                    modifiers={['invertedColumn', 'alignRight']}
                    oldPrice={getItemPrice(item.originalPriceCents, item.qty)}
                    newPrice={isFreeGift ? freeGiftLabel : getItemPrice(item.priceCents, item.qty)}
                  />
                ) : itemVouchersUsedDiscountCents ? (
                  <PromoPrice
                    modifiers={['invertedColumn', 'alignRight']}
                    oldPrice={getItemPrice(item.priceCents, item.qty)}
                    newPrice={getVoucherAdjustedPrice(item.priceCents, item.qty)}
                  />
                ) : (
                  <Price>{getItemPrice(item.priceCents, item.qty)}</Price>
                )}

                {!!item.shipsOnDate && <TextTooltipWrapper label={`Available ${getDayMonthFormat(item.shipsOnDate, true)}`} />}
              </PriceContainer>
            )}
          </FlexRow>
          {reservedItem && reservedItem.max && <Text modifiers={['small', 'errorColor']}>{`Maximum of ${reservedItem.max}`}</Text>}
        </FlexColumn>
      </FlexRow>
    </LineItemContainer>
  );
};

LineItem.propTypes = {
  item: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  state: PropTypes.any,
  className: PropTypes.string,
  isFarmstand: PropTypes.bool,
  freeGifts: PropTypes.array,
  isV2Item: PropTypes.bool,
};

export default LineItem;
