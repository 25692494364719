import { getStorage } from 'utils/storageManager';
import { formatPrice } from './cart-utils';
import eventTrackingTypes from 'constants/eventTrackingTypes';

class MyTagManager {
  constructor() {
    this.lastPath = undefined;
  }

  pushPath(currentPath, params, email, isLoggedIn, capiData = {}) {
    if (typeof window === 'undefined') return;
    if (currentPath !== this.lastPath) {
      const lgClientId = getStorage('lgClientId');
      //not related to tag manager - makes sure you start at top of page when go to a new page on site.
      window.scroll(0, 0);

      const dataLayerHistory = getStorage('_dataLayerHistory');
      let allPageParams = {};

      if (dataLayerHistory?.timeout > Date.now()) {
        allPageParams = dataLayerHistory?.history?.find?.((data) => data.event === 'Pageview');
      }

      const campaignParams = {
        campaignName: params.utm_campaign || allPageParams?.campaignName,
        campaignMedium: params.utm_medium || allPageParams?.campaignMedium,
        campaignSource: params.utm_source || allPageParams?.campaignSource,
        campaignKeyword: params.utm_term || allPageParams?.campaignKeyword,
        campaignContent: params.utm_content || allPageParams?.campaignContent,
      };

      window.dataLayer?.push({ ecommerce: null });

      //For google tag manager - analytics, etc
      window.dataLayer?.push({
        event: 'Pageview',
        url: currentPath,
        email,
        isLoggedIn,
        user_id: lgClientId,
        ...(capiData.firstName ? capiData : {}),
        ...(campaignParams.campaignName && { campaignName: campaignParams.campaignName }),
        ...(campaignParams.campaignMedium && { campaignMedium: campaignParams.campaignMedium }),
        ...(campaignParams.campaignSource && { campaignSource: campaignParams.campaignSource }),
        ...(campaignParams.campaignContent && { campaignContent: campaignParams.campaignContent }),
        ...(campaignParams.campaignKeyword && { campaignKeyword: campaignParams.campaignKeyword }),
      });

      this.lastPath = currentPath;
    }
  }
}

const LGTagManager = new MyTagManager();

export const productDetail = ({ pageName, name, id, price, category }) => {
  trackEnhancedEcommerce({
    payload: {
      event: 'eec.productImpression',
      url: window.location.pathname,
      ecommerce: {
        impressions: [
          {
            name,
            id,
            price,
            category,
          },
        ],
      },
    },
  });

  trackEnhancedEcommerce({
    payload: {
      ecommerce: {
        detail: {
          actionField: { list: pageName }, // 'detail' actions have an optional list property.
          products: [
            {
              name,
              id,
              price,
              category,
            },
          ],
        },
      },
    },
  });
};

// used for analytics formatting in checkout events
const formatProductsForDataLayer = (items) => {
  return items.map((item) => {
    return {
      name: item.name,
      id: item.sku,
      price: `${item.priceCents / 100}`,
      category: item.category,
      quantity: item.qty,
    };
  });
};

export const trackEnhancedPurchase = ({ orderId, actionField, products, customerIdLong }) => {
  const formattedProducts = formatProductsForDataLayer(products);

  trackEnhancedEcommerce({
    payload: {
      event: 'eec.purchase',
      orderId,
      ecommerce: {
        purchase: {
          actionField,
          products: formattedProducts,
        },
        customerIdLong,
      },
    },
  });
};

export const trackEnhancedRemoveFromCart = ({ products }) => {
  const formattedProducts = formatProductsForDataLayer(products);

  trackEnhancedEcommerce({
    payload: {
      event: 'eec.removeFromCart',
      ecommerce: {
        remove: {
          products: formattedProducts,
        },
      },
    },
  });
};

export const trackEnhancedCheckout = ({ actionField, products }) => {
  const formattedProducts = formatProductsForDataLayer(products);

  trackEnhancedEcommerce({
    payload: {
      event: 'eec.checkout',
      ecommerce: {
        actionField,
        products: formattedProducts,
      },
    },
  });
};

export const trackEnhancedAddToCart = ({ products, capiData = {} }) => {
  const formattedProducts = formatProductsForDataLayer(products);

  trackEnhancedEcommerce({
    payload: {
      event: 'eec.addToCart',
      ecommerce: {
        add: {
          products: formattedProducts,
        },
      },
      ...capiData,
    },
  });
};

export const trackEnhancedEcommerce = ({ payload }) => {
  if (window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(payload);
  }
};

export const trackClick = ({ action, label, callback }) => {
  trackCustomGAEvent({ event: 'click', action, label, callback });
};

export const trackViewed = ({ action, label, callback }) => {
  trackCustomGAEvent({ event: 'viewed', action, label, callback });
};

export const trackCustomGAEvent = ({ event, action, label, callback }) => {
  if (typeof window !== 'undefined') {
    const finalLabel = label || window.location.pathname;
    window.dataLayer?.push({
      event: event,
      eventCategory: event,
      eventAction: action,
      eventLabel: finalLabel,
      eventCallback: callback,
      experiments: window?.experiments || '',
    });
  }
};

export const trackFarmstandPurchase = ({ price, capiData = {} }) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event: 'farmstandPurchase',
      farmstandPurchaseValue: formatPrice(price / 100, 2, false),
      ...capiData,
    });
  }
};

/*
 * Track purchased buyable types: supplies, seedlings, gift cards
 */
export const trackBuyablePurchase = ({ eventNameType = 'supplies', items }) => {
  const totalItems = items.reduce((a, b) => a + b.qty, 0);
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event: eventTrackingTypes[eventNameType].category,
      eventCategory: eventTrackingTypes[eventNameType].category,
      eventAction: eventTrackingTypes[eventNameType].action,
      eventLabel: `${eventTrackingTypes[eventNameType].label}: #${totalItems}`,
    });
  }
};

export default LGTagManager;
