import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'elements';

import { ReviewFooterStyled } from './ReviewFooter.styled';
import VoteButtons from './VoteButtons';

const ReviewFooter = ({ id, votesUp, votesDown }) => {
  return (
    <ReviewFooterStyled>
      <Text modifiers={['brandFont', 'noLineHeight', 'small']} content='Was this review helpful?' />
      <VoteButtons initialUpVotes={votesUp} initialDownVotes={votesDown} entityId={id} entityType='reviews' />
    </ReviewFooterStyled>
  );
};

ReviewFooter.propTypes = {
  id: PropTypes.number,
  votesUp: PropTypes.number,
  votesDown: PropTypes.number,
};

export default ReviewFooter;
