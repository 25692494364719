import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  on: ({ theme }) => ({
    styles: `
  .ButtonText:first-of-type {
    border-bottom: 3px solid ${theme.farmProject.brand_primary};
    .Text {
      font-weight: 500;
    }
    .Icon svg,
    path {
      fill: ${theme.farmProject.brand_primary};
    }
  }
  .ButtonText:last-of-type {
    border-bottom: 3px solid transparent;
    .Text {
      font-weight: 400;
    }
    .Icon svg,
    path {
      fill: ${theme.farmProject.dark_gray};
    }
  }
    `,
  }),
};

export const ToggleStyled = styled.div.attrs({
  className: 'ToggleStyled',
})``;

export const ToggleInner = styled.div.attrs({
  className: 'ToggleInner',
})``;

export const ButtonGroup = styled.div.attrs({
  className: 'ButtonGroup',
})`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-content: center;

  .ButtonText:first-of-type {
    border-bottom: 3px solid transparent;
    .Text {
      font-weight: 400;
    }
    .Icon svg,
    path {
      fill: ${({ theme }) => `${theme.farmProject.dark_gray}`};
    }
  }
  .ButtonText:last-of-type {
    border-bottom: 3px solid ${({ theme }) => `${theme.farmProject.brand_primary}`};
    .Text {
      font-weight: 500;
    }
    .Icon svg,
    path {
      fill: ${({ theme }) => `${theme.farmProject.brand_primary}`};
    }
  }

  ${applyStyleModifiers(modifiers)}
`;
