import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme/Media';

export const modifiers = {
  backgroundGreen: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.green};

      .Text {
        color: ${theme.farmProject.gray_3};
      }

      .Link {
        color: ${theme.farmProject.gray_3};
      }

      .swiper-container {
        .swiper-slide {
          .PressSlider {
            .Image {
              picture {
                img {
                  filter: invert(100%) brightness(200%);
                }
              }
            }
          }
        }
        .swiper-button-prev {
          &::after {
            filter: invert(100%) brightness(200%);
          }
        }
        .swiper-button-next {
          &::after {
            filter: invert(100%) brightness(200%);
          }
        }
      }
    `,
  }),
};

export const PressReviewSliderWrapper = styled.div.attrs({
  className: 'PressReviewSlider',
})`
  background: ${({ theme }) => theme.farmProject.gray_2};
  .Title {
    font-size: 2rem;
    line-height: normal;
    letter-spacing: normal;
  }
  ${applyStyleModifiers(modifiers)};
`;

export const PressSliderWrapper = styled.div.attrs({
  className: 'PressSliderWrapper',
})`
  margin-top: 2rem;
  max-width: 100%;
  margin: auto;

  .swiper-container {
    .swiper-button-prev {
      left: -0.25rem;
    }
  }

  .swiper-container {
    .swiper-wrapper {
      align-items: center;
    }
  }

  @media ${breakPoints.large_break} {
  }

  @media ${breakPoints.xLarge_break} {
    max-width: 70rem;
  }
`;

export const PressSlider = styled.div.attrs({
  className: 'PressSlider',
  tabIndex: '0',
})`
  max-width: 14rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin: 1.5rem auto 1rem;
  box-sizing: border-box;

  .Image {
    width: fit-content;
    height: 4rem;
    margin: auto;

    picture {
      img {
        height: 100%;
        object-fit: contain;
        margin: auto;
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }
    }
  }

  .RateStarsWrapper {
    .Icon {
      svg {
        path {
          stroke: transparent;
        }
      }
    }
  }

  .Text {
    margin: 1.5rem 0 0.75rem;
    text-align: center;
  }

  .Link {
    display: block;
    font-size: 0.75rem;
    text-align: center;
    width: 8rem;
    margin: auto;
    margin-top: 1rem;
  }

  @media ${breakPoints.xLarge_break} {
    padding: 1rem;
  }
`;
