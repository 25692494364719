import { useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import breakpoints from 'theme/Media';

export const useBreakpointAlt = () => {
  const [size, setSize] = useState('SM');

  useEffect(() => {
    let mediumMediaQuery, largeMediaQuery, xLargeMediaQuery;

    const onChangeMediaQuery = ({ target }) => {
      if (target?.matches) {
        if (target.media === breakpoints.xLarge_break) return setSize('XL');
        else if (target.media === breakpoints.large_break) return setSize('LG');
        else if (target.media === breakpoints.medium_break) return setSize('MD');
      }
      return setSize('SM');
    };

    if (typeof window !== 'undefined' && !!window.matchMedia) {
      mediumMediaQuery = window.matchMedia(`${breakpoints.medium_break}`);
      largeMediaQuery = window.matchMedia(`${breakpoints.large_break}`);
      xLargeMediaQuery = window.matchMedia(`${breakpoints.xLarge_break}`);

      if (mediumMediaQuery.matches) setSize('MD');
      else if (largeMediaQuery.matches) setSize('LG');
      else if (xLargeMediaQuery.matches) setSize('XL');

      mediumMediaQuery.addEventListener('change', onChangeMediaQuery);
      largeMediaQuery.addEventListener('change', onChangeMediaQuery);
      xLargeMediaQuery.addEventListener('change', onChangeMediaQuery);
    }

    return () => {
      mediumMediaQuery?.removeEventListener?.('change', onChangeMediaQuery);
      largeMediaQuery?.removeEventListener?.('change', onChangeMediaQuery);
      xLargeMediaQuery?.removeEventListener?.('change', onChangeMediaQuery);
    };
  }, []);

  return size;
};

export default () => {
  const isMD = useMediaQuery(`${breakpoints.medium_break}`, { noSsr: true });
  const isLG = useMediaQuery(`${breakpoints.large_break}`, { noSsr: true });
  const isXL = useMediaQuery(`${breakpoints.xLarge_break}`, { noSsr: true });

  if (isXL) return 'XL';
  if (isLG) return 'LG';
  if (isMD) return 'MD';
  else return 'SM';
};
