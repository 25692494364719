import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const FarmModalStyled = styled.div.attrs({
  className: 'FarmModal',
})`
  .ComponentModal {
    .ModalWrapper {
      .modal__Content {
        padding-top: 0;
        padding-bottom: 0;
        overflow-y: unset;

        .ModalCloseBtn {
          top: 1.5rem;
        }

        .ModalInner {
          max-width: none;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ComponentModal {
      .ModalWrapper {
        .modal__Content {
          .ModalCloseBtn {
            top: 2.5rem;
          }
          .ModalInner {
            padding-left: 1.75rem;
            padding-right: 1.75rem;
          }
        }
      }
    }
  }
`;
