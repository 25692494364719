import React from 'react';
import PropTypes from 'prop-types';

import { Text, Icon, Image } from 'elements';
import { Modal, ReviewContent, ReviewFooter, Swiper } from 'components';

import { ModalReviewWrapper, SliderWrapper } from './ModalReview.styled';
import { Review, CustomerInfo, CustomerAvatar, CustomerInfoContent, ProductInfo } from '../../ReviewsAndQuestions.styled';

import Check from 'elements/icon/assets/circle-check.svg';

import { formatShortDate } from 'utils/date-utils';

const ModalReview = ({ content, onClose }) => {
  return (
    <Modal isOpen={!!content} size='fitContent' onCloseAction={onClose}>
      {!!content && (
        <ModalReviewWrapper>
          {!!content?.images_data?.length && (
            <SliderWrapper>
              <Swiper
                swiperProps={{
                  navigation: true,
                  pagination: { clickable: true },
                }}
                items={content?.images_data}
                renderSlideCard={(image) => <Image picture={image.original_url} alt='user_review_image' nativeWidth={1000} />}
              />
            </SliderWrapper>
          )}
          <Review>
            <CustomerInfo>
              <CustomerAvatar>
                <Text modifiers={['noLineHeight', 'noLetterSpace', 'brandFont']} content={content.user?.display_name?.substring?.(0, 1)} />
                {content.reviewer_type === 'verified_reviewer' && (
                  <Icon>
                    <Check />
                  </Icon>
                )}
              </CustomerAvatar>
              <CustomerInfoContent>
                <Text modifiers={['noLineHeight', 'brandColor']} content={content.user?.display_name} />
                <Text modifiers={['noLineHeight', 'brandFont']} content={formatShortDate(content.created_at)} />
                <ProductInfo>
                  <Text content='Product:' modifiers='brandFont' />
                  <Text modifiers={['noLineHeight', 'brandFont', 'light']} content={content.productTitle} />
                </ProductInfo>
              </CustomerInfoContent>
            </CustomerInfo>
            <ReviewContent review={content} isOnModal />
            <ReviewFooter id={content.id} votesUp={content.votes_up} votesDown={content.votes_down} />
          </Review>
        </ModalReviewWrapper>
      )}
    </Modal>
  );
};

ModalReview.propTypes = {
  content: PropTypes.object,
  onClose: PropTypes.func,
};

export default ModalReview;
