import _isEmpty from 'lodash/isEmpty';

import { getSeedlingAvailableDateObject, getDaysBetween, getDayMonthFormat } from 'utils/date-utils';
import itemAvailabilities from 'constants/itemAvailabilities';
import shopCategories from 'constants/shopCategories';
import growingEnvironments from 'constants/growingEnvironments';

const { AVAILABLE, OUT_OF_SEASON } = itemAvailabilities;
const { SEEDLINGS, BUNDLES, CUSTOM_BUNDLES } = shopCategories;

// Sort seedlings by name callback
export const nameSort = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

// sort seedings by AVAIlABLE first, followed by next season
export const availabilitySort = (a, b) => {
  if (a.availability === OUT_OF_SEASON && b.availability !== OUT_OF_SEASON) {
    return 1;
  }
  if (a.availability !== OUT_OF_SEASON && b.availability === OUT_OF_SEASON) {
    return -1;
  }
  if (a.availability === AVAILABLE && b.availability === AVAILABLE) {
    if (a.shipsOnDate && b.shipsOnDate) {
      return a.shipsOnDate < b.shipsOnDate ? -1 : a.shipsOnDate > b.shipsOnDate ? 1 : 0;
    } else {
      return b.shipsOnDate ? -1 : a.shipsOnDate ? 1 : 0;
    }
  }
  return 0;
};

export const bundlesSort = (a, b) => {
  if (a.category === BUNDLES) {
    if (a.category === b.category) {
      return 0;
    } else {
      return -1;
    }
  } else if (b.category === BUNDLES) {
    return 1;
  } else {
    return 0;
  }
};

// sort out of seedlings based on the FEATURED tag placed by content team
export const featuredSort = (seedlingContent = []) => (a, b) => {
  const aContent = seedlingContent.find((plant) => plant.sys.id === a.sku);
  const bContent = seedlingContent.find((plant) => plant.sys.id === b.sku);
  if (!aContent || !bContent) {
    return 0;
  }
  if (aContent.fields.isFeatured && !bContent.fields.isFeatured) {
    return -1;
  }
  if (!aContent.fields.isFeatured && bContent.fields.isFeatured) {
    return 1;
  }
  return 0;
};

// sort out of seedlings based on the LIMITED RELEASE tag placed by content team
export const limitedReleaseSort = (seedlingContent = []) => (a, b) => {
  const aContent = seedlingContent.find((plant) => plant.sys.id === a.sku);
  const bContent = seedlingContent.find((plant) => plant.sys.id === b.sku);
  if (!aContent || !bContent) {
    return 0;
  }
  if (aContent.fields.isLimitedRelease && !bContent.fields.isLimitedRelease) {
    return -1;
  }
  if (!aContent.fields.isLimitedRelease && bContent.fields.isLimitedRelease) {
    return 1;
  }
  return 0;
};

// sort out of season seedlings with availability date first, followed by next season
export const availableDateSort = (a, b) => {
  const areSeedsOutOfSeason = a.availability === OUT_OF_SEASON && b.availability === OUT_OF_SEASON;
  /**
   * 3 cases below - if just Seed A, just Seed B or both have no dates
   * 1. if just Seed A has no dates
   * 2. if just Seed B has no dates
   * 3. if both seeds have no dates
   */
  if (areSeedsOutOfSeason && !a.inSeasonDate && !a.inStockDate && (b.inSeasonDate || b.inStockDate)) {
    return 1;
  }
  if (areSeedsOutOfSeason && (a.inSeasonDate || a.inStockDate) && !b.inSeasonDate && !b.inStockDate) {
    return -1;
  }
  if (areSeedsOutOfSeason && !a.inSeasonDate && !a.inStockDate && !b.inSeasonDate && !b.inStockDate) {
    return 0;
  }
  //if Seed A, Seed B or both are inStock (are buyable) but are not in season
  if (areSeedsOutOfSeason && (!a.inStockDate || !b.inStockDate)) {
    if (!a.inStockDate && !b.inStockDate) {
      return a.inSeasonDate < b.inSeasonDate ? -1 : a.inSeasonDate > b.inSeasonDate ? 1 : 0;
    } else {
      return b.inStockDate ? -1 : a.inStockDate ? 1 : 0;
    }
  }
  //if both seedlings are outOfStock
  if (areSeedsOutOfSeason && a.inStockDate && b.inStockDate && a.inStockDate !== b.inStockDate) {
    return a.inStockDate < b.inStockDate ? -1 : a.inStockDate > b.inStockDate ? 1 : 0;
  }
  return 0;
};

export const getUpdatedSeedlingWithinBuyableDate = (seed, buyableDays, hasFarmstandInCart = false) => {
  if (!seed || _isEmpty(seed)) return {};

  const { availability, inStockDate, inSeasonDate, shipsOnDate } = seed;

  const isOutSeason = availability === OUT_OF_SEASON;
  const availabilityDate = getSeedlingAvailableDateObject(seed);
  const isStockWithinBuyable =
    isOutSeason && inStockDate && buyableDays ? getDaysBetween(new Date(), new Date(inStockDate)) <= buyableDays : false;
  const isSeasonWithinBuyable =
    isOutSeason && inSeasonDate && buyableDays ? getDaysBetween(new Date(), new Date(inSeasonDate)) <= buyableDays : false;
  const isWithinBuyableDate =
    isOutSeason && availabilityDate && buyableDays ? getDaysBetween(new Date(), availabilityDate) <= buyableDays : false;
  const isSeedInFutureShippingBuyable = isOutSeason && !!seed.shipsOnDate;

  return {
    ...seed,
    availability: (hasFarmstandInCart && isWithinBuyableDate) || isSeedInFutureShippingBuyable ? AVAILABLE : availability,
    inStockDate: hasFarmstandInCart && isStockWithinBuyable ? null : inStockDate,
    inSeasonDate: hasFarmstandInCart && isSeasonWithinBuyable ? null : inSeasonDate,
    shipsOnDate: hasFarmstandInCart && isWithinBuyableDate ? null : shipsOnDate,
  };
};

export const environmentSort = (seedlingContent = []) => (a, b) => {
  const aContent = seedlingContent.find((plant) => plant.sys.id === a.sku);
  const bContent = seedlingContent.find((plant) => plant.sys.id === b.sku);
  if (!aContent || !bContent) {
    return 0;
  }
  if (aContent.fields.indoorApproved && !bContent.fields.indoorApproved) {
    return -1;
  }
  if (!aContent.fields.indoorApproved && bContent.fields.indoorApproved) {
    return 1;
  }
  return 0;
};

/**
 * @param {Object} item Seedling object from catalog
 * @return {Boolean} boolean indicating if the Seedling is Out Of Season or not
 */
export const isSeedAvailable = (item = {}) => {
  if (!item || !item?.category) return false;

  const isSeedling = [SEEDLINGS, BUNDLES].includes(item.category);
  const isAvailable = item.availability === AVAILABLE;
  return isSeedling && isAvailable;
};

/**
 * @param {Object} item Seedling object from catalog
 * @return {Boolean} boolean indicating if the Seedling is Out Of Season or not
 */
export const isSeedOutOfSeason = (item = {}) => {
  if (!item || !item?.category) return false;

  const isSeedling = [SEEDLINGS, BUNDLES].includes(item.category);
  const isOutOfSeason = item.availability === OUT_OF_SEASON;
  return isSeedling && isOutOfSeason;
};

/**
 * @param {Object} item Seedling object from catalog
 * @return {Boolean} boolean indicating if a Seedling is: 1. Shoppable (available in stock) AND 2. Out of Season.
 */
export const isSeedShoppableAndOutOfSeason = (item = {}) => {
  if (!item || !item?.category) return false;

  const isOutOfSeason = isSeedOutOfSeason(item);
  const isAvailableInStock = !item.inStockDate;
  const isNotInGoodSeason = !!item.inSeasonDate;
  return isOutOfSeason && isAvailableInStock && isNotInGoodSeason;
};

/**
 * @param {Object} item Seedling object from catalog
 * @return {Boolean} boolean indicating if a Seedling is Out of Stock.
 */
export const isSeedOutOfStock = (item = {}) => {
  if (!item || !item?.category) return false;

  const isOutOfSeason = isSeedOutOfSeason(item);
  const isUnavailableInStock = !!item.inStockDate;
  return isOutOfSeason && isUnavailableInStock;
};

/**
 * @param {Object} item Seedling object from catalog
 * @param {Object} messages Contentful messages object
 * @param {Boolean} isIndoorApproved flag that indicates if the seedling is good to grow indoor
 * @param {String} env the growing Environment string value
 * @return {Object} Seedling warning line object with Label and Tooltip message
 */
export const getSeedWarningLine = (item = {}, messages = {}, isIndoorApproved = false, env = '') => {
  const seedsCategories = [SEEDLINGS, BUNDLES];
  if (!item || !seedsCategories.includes(item?.category)) return {};

  const isNotGoodForIndoor = env === growingEnvironments.INDOOR && !isIndoorApproved;
  const { inStockDate, inSeasonDate } = item;
  const { LG_seasonalSeedling = '', LG_buyableSeedlingHelpText = '', LG_growOutdoors = '' } = messages;
  const isBuyableAndOutOfSeason = isSeedShoppableAndOutOfSeason(item);
  const isOutOfStock = isSeedOutOfStock(item);
  const date = isBuyableAndOutOfSeason ? inSeasonDate : isOutOfStock ? inStockDate : null;
  const dateFormat = date ? getDayMonthFormat(date) : '';
  const label = isNotGoodForIndoor
    ? 'Not recommended for <br>indoor growing'
    : isBuyableAndOutOfSeason
    ? isIndoorApproved
      ? LG_seasonalSeedling
      : LG_growOutdoors
    : isOutOfStock
    ? 'Available'
    : null;

  const labelWithDate = label ? `${label} ${dateFormat}` : null;
  const tooltip = isBuyableAndOutOfSeason && !isNotGoodForIndoor ? LG_buyableSeedlingHelpText.replace('DATE_TAG', dateFormat) : null;

  return !label ? {} : { label: labelWithDate, tooltip };
};

/**
 * @param {Object} item Seedling object from catalog
 * @return {Object} Seedling warning line object with Label and Tooltip message
 */
export const getSeedShipLine = (item = {}) => {
  const seedsCategories = [SEEDLINGS, BUNDLES, CUSTOM_BUNDLES];
  if (!item || !seedsCategories.includes(item?.category) || !item?.shipsOnDate || item?.availability !== AVAILABLE) return {};

  return { label: `Ships ${getDayMonthFormat(item?.shipsOnDate, true)}` };
};
