import axios from 'axios';

import { gcsUrlPrefix, authDomain } from 'utils/envConfig';
import logError from 'utils/errorHandler';
import { getAuth } from 'firebase/auth';
import { AXIOS } from 'constants/redux';

import { setOpenModal, setLabel } from 'reduxState/modal';

import { getStorage, getCookie } from 'utils/storageManager';

export const HTTP_CODE_EXPECTATION_FAILED = 417;

const getCredentialsOption = (url = '') => ({
  withCredentials: !!url && !url?.includes('content') && !url?.includes('yotpo'),
});

const getUtmParams = () => {
  // from GTM
  const params = window?.dataLayer?.find?.((data) => data?.event === 'Pageview');

  if (params) {
    return {
      utm_source: (params.campaignSource || document.referrer || '').toString() || undefined,
      utm_medium: (params.campaignMedium || 'referrer').toString() || undefined,
      utm_campaign: (params.campaignName || '').toString() || undefined,
      utm_term: (params.campaignKeyword || '').toString() || undefined,
      utm_content: (params.campaignContent || '').toString() || undefined,
    };
  }
  return {};
};

const getUserIds = (url) => {
  // get analytics ids from cookie if possible
  const gaClientId = getCookie('_ga');
  const ids = {};

  if (gaClientId && ((!!gcsUrlPrefix && url.includes(gcsUrlPrefix)) || url.startsWith('/'))) {
    ids.gaClientId = gaClientId;
  }
  if (!url.includes('content') && !url.includes('yotpo')) {
    // add our own LG id if GA id is not present
    ids.lgClientId = getStorage('lgClientId');
  }

  return ids;
};

const apiMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  if (action.type !== AXIOS) return;

  // Get variables from payload
  const { url, method, data, onSuccess, onFailure, setLoading, cancelToken } = action.payload;

  // Create headers config
  let headers = {};

  if (!url.includes('content') && !url.includes('yotpo')) {
    headers = { ...headers, ...getUtmParams() };
  }

  headers = { ...headers, ...getUserIds(url) };

  // Set authDomain in header if exists
  if (!action.payload.url.includes('content') && !url.includes('yotpo') && authDomain) {
    headers.authDomain = authDomain;
  }

  // axios default configs
  axios.defaults.baseURL = gcsUrlPrefix || '';
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  // if setLoading cb exists then set to true
  if (setLoading) dispatch(setLoading(true));

  const makeRequest = () => {
    // Params for 'get/delete', data for 'post' etc
    const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

    axios
      .request({
        url,
        method,
        headers,
        ...getCredentialsOption(url),
        [dataOrParams]: data,
        cancelToken,
      })
      .then(({ data }) => {
        onSuccess && dispatch(onSuccess(data));

        // if setLoading cb exists then set to false
        if (setLoading) dispatch(setLoading(false));
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled: ', error.message);
        } else {
          // handle error

          logError(error);
          // if setLoading cb exists then set to false
          if (setLoading) dispatch(setLoading(false));

          if (onFailure) {
            dispatch(onFailure(error?.response?.data || error?.message || error));
          } else if (error?.response?.status === HTTP_CODE_EXPECTATION_FAILED) {
            dispatch(setOpenModal('error'));
            dispatch(
              setLabel({
                id: 'error',
                label: error?.response?.data?.message,
              })
            );
          }
        }
      });
  };

  // Set refreshed authToken in header if exists before making request
  if (getState().user.authToken && !url.includes('yotpo') && !url.includes('content')) {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      // use authToken from state - not ideal as it could be stale
      headers.idToken = getState().user.authToken;
      makeRequest();
      return;
    }
    user
      .getIdToken()
      .then((token) => {
        headers.idToken = token;
        makeRequest();
      })
      .catch((error) => {
        logError(error);
      });
  } else {
    makeRequest();
  }
};

export default apiMiddleware;
