import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const FedExPopUpStyled = styled.div.attrs({
  className: 'FedExPopUp',
})`
  .ButtonText {
    color: ${({ theme }) => theme.farmProject.dark_gray};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.farmProject.dark_gray};
    margin-top: 0.5rem;
  }

  .ModalInner {
    .Button {
      width: 11rem;
      margin-bottom: 0;
    }

    > .Text {
      &:last-of-type {
        margin-bottom: 0.5rem;
      }
    }

    > span {
      font-family: ${({ theme }) => theme.farmProject.font_family};
      margin-top: 0.75rem;
      margin-bottom: 1rem;
    }
  }

  @media ${breakPoints.medium_break} {
    .modal__Content {
      padding-top: 4rem;
      padding-bottom: 4rem;

      .ModalInner {
        max-width: 29.5rem;

        > .Title {
          margin-top: 1.375rem;
          margin-bottom: 2rem;
        }

        > span {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const AddressWrapper = styled.div.attrs({
  className: 'AddressWrapper',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    display: block;
  }

  .Text {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .ButtonText {
    margin: 0;
  }

  @media ${breakPoints.medium_break} {
    margin: 1rem 0;

    .Address {
      margin-bottom: 1.5rem;
      span {
        margin-bottom: 0;
      }
    }
  }
`;

export const Address = styled.div.attrs({
  className: 'Address',
})`
  width: 100%;
  margin-bottom: 1rem;
`;
