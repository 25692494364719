import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import breakPoints from 'theme/Media';

export const modifiers = {
  imageBackground: ({ theme }) => ({
    styles: `

    .Image {
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      img {
        object-fit: cover;
        object-position: 60%;
        height: 130%;
      }
    }

      .QuoteIcon {
        display: block;
      }

      .RateStarsWrapper {
        display: none;
      }

      .Text {
        color: ${theme.farmProject.dark_gray} !important;
      }
    `,
  }),
  darkBackground: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.dark_gray};

      .QuoteIcon {
        display: none;
      }

      .RateStarsWrapper {
        display: flex;
      }

      .Button {
        background-color: ${theme.farmProject.gray_3};

        span {
          color: ${theme.farmProject.dark_green};
        }

        &:hover {
          border: solid 1px ${theme.farmProject.gray_3};
          background-color: transparent;
          span {
            color: ${theme.farmProject.gray_3};
          }
        }
      }
    `,
  }),
};

export const CustomerReviewStyled = styled.div.attrs({
  className: 'CustomerReviewStyled',
})`
  padding: 7rem 2rem;
  margin: auto;
  height: 100%;
  max-height: 18rem;
  min-height: 18rem;

  .Image {
    display: none;
  }

  .Text {
    display: block;
    text-align: center;
    letter-spacing: normal;
    color: white;

    &:first-of-type {
      font-size: 1.375rem;
      max-width: 28rem;
      margin: auto;
      margin-bottom: 1.25rem;
    }
  }

  .Button {
    margin: auto;
    margin-top: 2rem;
  }

  .QuoteIcon {
    display: none;
  }

  .RateStarsWrapper {
    margin-bottom: 2rem;
  }

  .QuoteIcon,
  .RateStarsWrapper {
    margin-top: -4rem;
  }

  @media ${breakPoints.large_break} {
    .QuoteIcon {
      margin-top: -2rem;
    }

    .RateStarsWrapper {
      margin-top: -1.7rem;
    }
  }

  ${applyStyleModifiers(modifiers)};
`;

export const CustomerReviewSlideStyled = styled.div.attrs({
  className: 'CustomerReviewSlideStyled',
})`
  padding: 0.75rem;

  .Text {
    font-size: 14px;
    max-width: 15rem;
    margin: 2rem auto 0;
  }
`;

export const QuoteIcon = styled.div.attrs({
  className: 'QuoteIcon',
})`
  .Icon {
    width: 10rem;
    height: 2rem;
    margin: auto;
    margin-bottom: 1.5rem;

    svg {
      width: auto;
      max-width: 6rem;
      height: 90%;
      fill: ${({ theme }) => theme.farmProject.green};
    }
  }
`;
