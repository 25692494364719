import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { Flex } from 'components';

const modifiers = {
  disabled: () => ({
    styles: `
      pointer-events: none;
      .Icon {
        pointer-events: none;
      }
    `,
  }),
};

export default styled(Flex)`
  margin: 0 0 0.5rem 0;
  padding: 0.5rem 0;
  width: 100%;
  height: 2.25rem;
  border-top: 1px solid ${({ theme }) => theme.farmProject.gray_1};
  .Icon {
    margin: 0 0.25rem;
    svg {
      stroke: none;
    }
  }
  .Like,
  .Dislike {
    svg {
      width: 63%;
    }
  }

  ${applyStyleModifiers(modifiers)};
`;
