import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';

import CheckboxBase, { modifiers } from '@lettucegrow/atoms.checkbox';

const Checkbox = ({ label, field, onChange, modifiers, id, value, checked, onBlur, formValues, dataNw }) => {
  const handleChange = (ev) => {
    field?.onChange?.(ev);
    onChange && onChange(ev);
  };

  const handleBlur = (ev) => {
    field?.onBlur?.(ev, formValues);
    onBlur?.(ev, formValues);
  };

  return (
    <CheckboxBase
      modifiers={modifiers}
      onChange={handleChange}
      onBlur={handleBlur}
      label={label}
      id={id}
      value={value || field.value}
      checked={checked}
      name={field.name}
      dataNw={dataNw}
    />
  );
};

Checkbox.defaultProps = {
  field: {},
};

Checkbox.propTypes = {
  label: PropTypes.any,
  field: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  modifiers: styleModifierPropTypes(modifiers),
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  checked: PropTypes.bool,
  formValues: PropTypes.object,
  dataNw: PropTypes.string,
};

export default Checkbox;
