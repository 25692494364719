import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'elements';

import ImageGatsby, { GatsbyImageProps, GatsbyImageDefaultProps } from './ImageGatsby';

const ImageGasbyLink = (props) => {
  const { imageRouteLink, target, eventData, ...imageProps } = props;
  const targetProp = target ? { target } : {};

  return (
    <Link href={imageRouteLink} eventData={eventData} {...targetProp}>
      <ImageGatsby {...imageProps} />
    </Link>
  );
};

ImageGasbyLink.propTypes = {
  imageRouteLink: PropTypes.string.isRequired,
  target: PropTypes.string,
  eventData: PropTypes.object,
  ...GatsbyImageProps,
};

ImageGasbyLink.defaultProps = {
  target: null,
  ...GatsbyImageDefaultProps,
};

export default ImageGasbyLink;
