import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _partition from 'lodash/partition';
import { graphql, StaticQuery } from 'gatsby';

import { SlimBanner, Swiper, Modal } from 'components';
import { Title, Text, Link, Icon, ButtonText } from 'elements';
import MessageBannerStyled from './MessageBanner.styled';
import InfoIcon from 'elements/icon/assets/information-icon.svg';

import paths from 'constants/paths';
import { useBreakpoint, useQueryParams } from 'utils/hooks';
import { getLoginState, getCustomer } from 'reduxState/user';
import { getContentful } from 'reduxState/contentful';

const swiperConfig = {
  slidesPerView: 1,
  autoplay: {
    delay: 4000,
  },
  loop: true,
};

const contentQuery = graphql`
  query {
    contentfulSectionBlocks(contentful_id: { eq: "2jRn8BRX9EHvCl6gejBnvg" }) {
      type
      percentages {
        ... on ContentfulSectionSimpleText {
          contentful_id
          name
          primaryButton {
            ... on ContentfulCallToAction {
              name
              linkText
              linkUrl
            }
          }
          body {
            body
          }
          subSectionHeaders {
            ... on ContentfulGeneralContentBlock {
              subText
              subTextBody {
                subTextBody
              }
            }
          }
          additionalButtons {
            linkText
            linkUrl
          }
        }
      }
    }
  }
`;

export const PureMessageBanner = ({ isLoggedIn, customer, hasPromo99, isMobile, location, data }) => {
  const contentful = useSelector(getContentful);
  const [showModal, setShowModal] = useState(false);
  const [popupData, setPopupData] = useState({});

  swiperConfig.autoplay.delay = (data.contentfulSectionBlocks.type && Number(data.contentfulSectionBlocks.type)) || 4000;

  const bannerItems = useMemo(() => {
    const blocks = data.contentfulSectionBlocks.percentages;
    let items = [];
    const [referralMessage, messages] = _partition(blocks, (block) => block.contentful_id === '2cJ25uZ9SBFsWp0z9V5VSI');

    const farmstandLink =
      location.pathname === paths.FARMSTAND || location.pathname === paths.SHOP_PRODUCTS
        ? `${location.pathname}#shop-farmstands`
        : paths.FARMSTAND;

    isLoggedIn &&
      customer?.referralCode &&
      items.push({
        id: referralMessage[0].contentful_id,
        content: `${referralMessage[0]?.body?.body}${isMobile ? '<br>' : ' - '}SHARE YOUR CODES: <b>${customer.referralCode}</b></p>`,
        popup: null,
        CTA: null,
      });

    hasPromo99 &&
      items.push({
        id: `message-banner-key-2`,
        content: `99% Invisible Fans - Use Code <b>99percentpod</b> for an Exclusive Discount!`,
        popup: null,
        CTA: {
          url: paths.SHOP_PRODUCTS,
          label: 'SHOP NOW',
          eventData: {
            action: 'SHOP NOW',
          },
        },
      });

    if (location.pathname === paths.CHECKOUT) {
      items.push({
        id: `message-banner-key-3`,
        content: contentful.messages?.LG_returnPolicy,
        popup: null,
        CTA: null,
      });
    } else {
      messages.forEach((block) => {
        const linkText = block.primaryButton?.linkText;
        const linkUrl = block.primaryButton?.linkUrl;
        const link = linkUrl === paths.FARMSTAND ? farmstandLink : linkUrl;
        const hasPopup = block?.subSectionHeaders?.length > 0;

        items.push({
          id: block.contentful_id,
          content: block.body?.body,
          popup: !hasPopup
            ? null
            : {
                title: block?.subSectionHeaders?.[0]?.subText,
                content: block?.subSectionHeaders?.[0]?.subTextBody?.subTextBody,
                cta: block?.additionalButtons?.[0],
              },
          CTA: {
            url: link,
            label: linkText,
            eventData: {
              action: linkText,
            },
          },
        });
      });
    }
    return items;
  }, [
    contentful.messages?.LG_returnPolicy,
    isLoggedIn,
    hasPromo99,
    isMobile,
    customer.referralCode,
    location.pathname,
    data.contentfulSectionBlocks,
  ]);

  const handleModalOpen = (popup) => {
    setPopupData(popup);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setPopupData({});
    setShowModal(false);
  };

  const renderItem = (item) => {
    return (
      <SlimBanner key={item.id} modifiers='inverted'>
        <Text isHTML modifiers={['brandFont', 'small', 'inverted']} content={item.content} as='span' />
        {!!item.popup && (
          <Icon modifiers={['small', 'inverted']} onClick={() => handleModalOpen(item.popup)}>
            <InfoIcon />
          </Icon>
        )}
        {item.CTA !== null && (
          <Link
            modifiers={['underline', 'accentColor', 'small']}
            content={item.CTA.label}
            href={item.CTA.url}
            eventData={item.CTA?.eventData}
          />
        )}
      </SlimBanner>
    );
  };

  return (
    <MessageBannerStyled>
      {<Swiper items={bannerItems} swiperProps={swiperConfig} renderSlideCard={renderItem} />}

      <Modal isOpen={showModal} onCloseAction={handleModalClose}>
        <Title content={popupData?.title} />
        <Text isHTML content={popupData?.content} />
        {popupData?.cta && (
          <ButtonText modifiers={['underline']} label={popupData.cta.linkText} href={popupData.cta.linkUrl} onClick={handleModalClose} />
        )}
      </Modal>
    </MessageBannerStyled>
  );
};

PureMessageBanner.propTypes = {
  isLoggedIn: PropTypes.bool,
  customer: PropTypes.object,
  hasPromo99: PropTypes.bool,
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  data: PropTypes.shape({
    contentfulSectionBlocks: PropTypes.shape({
      type: PropTypes.any, // contentful hack to use existing field
      percentages: PropTypes.array,
    }),
  }),
};
PureMessageBanner.defaultProps = {
  isLoggedIn: false,
  customer: {},
  hasPromo99: false,
  isMobile: true,
  location: {},
  data: {},
};

const MessageBanner = ({ location }) => {
  const { source } = useQueryParams('pc');
  const hasPromo99 = source === '99pi';
  const isLoggedIn = useSelector(getLoginState);
  const viewportSize = useBreakpoint();
  const isMobile = viewportSize === 'SM';
  const customer = useSelector(getCustomer);
  return (
    <StaticQuery
      query={contentQuery}
      render={(data) => {
        return (
          <PureMessageBanner
            hasPromo99={hasPromo99}
            isLoggedIn={isLoggedIn}
            isMobile={isMobile}
            customer={customer}
            data={data}
            location={location}
          />
        );
      }}
    ></StaticQuery>
  );
};
MessageBanner.defaultProps = {};
MessageBanner.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default MessageBanner;
