const colors = {
    ERROR: 'error',
    SUCCESS: 'success',
    GREEN: 'green',
    WHITE: 'white',
    GREY: 'grey',
    BLACK: 'black',
    BLUE: 'blue',
    YELLOW: 'yellow',
    DARK_ORANGE: 'dark-orange',
};

colors.ALL = Object.values(colors);

export default colors;
