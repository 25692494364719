import { createSelector } from '@reduxjs/toolkit';
import { getTotalAutofillPendingOrderSeedlings } from 'reduxState/cart';
import {
  getAutofillPreferences,
  getHasPendingOrdersSeedlings,
  getTotalSuggestedSeedlings,
  getHasPurchasedOrAddedFarmstand,
  getLoginState,
} from 'reduxState/user';

export const getAutofillData = createSelector(
  [
    getTotalAutofillPendingOrderSeedlings,
    getAutofillPreferences,
    getHasPendingOrdersSeedlings,
    getTotalSuggestedSeedlings,
    getHasPurchasedOrAddedFarmstand,
    getLoginState,
  ],
  (
    totalAutofillPendingOrderSeedlings,
    autofillPreferences,
    hasPendingOrdersSeedlings,
    totalSuggestedSeedlings,
    hasFarmstands,
    isLoggedIn
  ) => {
    const isAutofillOrder = autofillPreferences?.enabled && isLoggedIn;
    const isRecommendedOrder = !autofillPreferences?.enabled && isLoggedIn;
    const isEmptyAutofillOrder = !totalAutofillPendingOrderSeedlings || hasPendingOrdersSeedlings;
    const isEmptyRecommendedOrder = !totalSuggestedSeedlings || totalSuggestedSeedlings < 6 || hasPendingOrdersSeedlings || !hasFarmstands;
    const isEmptyOrder = isAutofillOrder ? (isEmptyAutofillOrder ? isEmptyRecommendedOrder : false) : isEmptyRecommendedOrder;

    return {
      isAutofillOrder,
      isRecommendedOrder,
      isEmptyOrder,
      isEmptyAutofillOrder,
      isEmptyRecommendedOrder,
      hasPendingOrdersSeedlings,
      totalAutofillPendingOrderSeedlings,
      totalSuggestedSeedlings,
    };
  }
);
