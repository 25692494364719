const contentful = require('contentful');
const queryString = require('query-string');
const _isEqual = require('lodash/isEqual');
const axios = require('axios');

const envConfig = require('./envConfig');

/* 
* HEADS UP 
  client function checks our self-hosted cached content map for cached entry key
  - if cached map has key that matches params, it uses the map value as url path in axios request to our own hosted content
  - else (fallback) to hit our contentful proxy which depending on the environment uses the preview or delivery contentful API
  - navigating to /disable-content-cache will set flag in redux to always hit proxy directly
  - statically generated pages always hit contentful proxy at build time
*/

const contentfulHost =
  envConfig.environment === 'development'
    ? 'dev.lettucegrow.com'
    : envConfig.environment === 'release'
    ? 'staging.lettucegrow.com'
    : envConfig.environment === 'production'
    ? 'lettucegrow.com'
    : `${envConfig.environment}.lettucegrow.com`;

const _client = contentful.createClient({
  space: process.env.GATSBY_CONTENT_SPACE,
  accessToken: process.env.GATSBY_CONTENT_PROXY_TOKEN,
  host: contentfulHost,
  basePath: 'app/contentfulproxy',
});

const getCachedContentEntry = (cachedContentMap, contentParams) => {
  // ensure param values are stringified before comparing with JSON
  const sanitizedParams = Object.keys(contentParams).reduce((acc, key) => {
    if (contentParams[key] !== undefined) {
      acc[key] = contentParams[key].toString();
    }
    return acc;
  }, {});

  const cachedKeys = Object.keys(cachedContentMap);
  const cachedKey = contentParams ? cachedKeys.find((key) => _isEqual(queryString.parse(key), sanitizedParams)) : '';
  return cachedContentMap[cachedKey];
};

const client = (params, cacheMap) => {
  // early return for force disabled
  if (!cacheMap) return _client;

  const cachedEntry = getCachedContentEntry(cacheMap, params);

  // entry is found in cache map! Hit FB hosted cache with entry key
  if (cachedEntry) {
    const contentHost = envConfig.contentCacheUrl;
    return {
      getEntries: () => {
        return axios.get(`${contentHost}/${cachedEntry}`, {}).then((response) => _client.parseEntries(response.data));
      },
      getEntry: () => {
        console.error('getEntry NOT currently supported for content cache, please add support in "contentful-client"');
      },
    };
  }

  // no cached entry found, fallback to contentful api
  console.warn('NO CONTENT CACHE ENTRY FOUND FOR:', params);
  return _client;
};

module.exports = client;
