import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { navigate } from 'gatsby';

import { setReservationCode } from 'reduxState/reservation/reservation';
import { setUserValue } from 'reduxState/user';
import { setDiscount, setUrlDiscount } from 'reduxState/cart';

import growingEnvironments from 'constants/growingEnvironments';
const { OUTDOOR, INDOOR } = growingEnvironments;
const isValidEnvironment = (environment) => environment === OUTDOOR || environment === INDOOR;

/**
 * * useQueryParams - Custom hook to grab query params and store values in redux on page load
 * */
export default function useQueryParams(shouldMonitor = []) {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = queryString.parse(location.search || '');
  let params;

  // location does not parse queries after hash/anchor - so check the hash property as well for queries
  if (location.hash) {
    const query = location.hash.split('?');
    params = { ...searchParams, ...queryString.parse(query[1] || '') };
  } else {
    params = searchParams;
  }

  const reservationCode = useSelector((state) => state.reservation.code);
  const isCatalogFetched = useSelector((state) => state.catalog.isCatalogFetched);

  if (!isValidEnvironment(params?.environment?.toUpperCase())) {
    delete params.environment;
  }

  // Check if promo code or email is present and store in redux
  useEffect(() => {
    if (!shouldMonitor.includes('pc')) return;
    if (params.pc) {
      dispatch(setDiscount(params.pc));
      dispatch(setUrlDiscount(params.pc));
    }
    if (params.email) dispatch(setUserValue({ label: 'email', value: params.email }));
    // eslint-disable-next-line
  }, [params.pc]);

  useEffect(() => {
    if (shouldMonitor.includes('res') && isCatalogFetched && params.res && params.res !== reservationCode) {
      navigate(location.pathname);
      dispatch(setReservationCode(params.res));
    }
  }, [shouldMonitor, dispatch, location.pathname, params.res, reservationCode, isCatalogFetched]);

  return { promoCode: params.pc, source: params.utm_source, res: params.res, environment: params.environment?.toUpperCase() };
}
