import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text, ButtonText } from 'elements';

import { ToggleStyled, ToggleInner, ButtonGroup } from './Toggle.styled';

const Toggle = ({ startOn = true, labelOn, labelOff, onChange, eventDataActive, eventDataInactive }) => {
  const [isOn, setIsOn] = useState(startOn);
  const defaultOnModifiers = isOn ? ['brand', 'bold'] : 'secondary';
  const defaultOffModifiers = isOn ? 'secondary' : ['brand', 'bold'];
  const onClickHandle = (toggleState) => {
    setIsOn(toggleState);
    onChange && onChange(toggleState);
  };

  useEffect(() => {
    if (startOn !== isOn) setIsOn(startOn);
    //eslint-disable-next-line
  }, [startOn]);

  return (
    <ToggleStyled>
      <ToggleInner>
        <ButtonGroup modifiers={isOn && 'on'}>
          {!!labelOn && (
            <ButtonText onClick={() => onClickHandle(true)} modifiers={defaultOnModifiers} eventData={eventDataActive}>
              {typeof labelOn === 'string' ? <Text content={labelOn} modifiers={['brandFont']} /> : labelOn}
            </ButtonText>
          )}
          {!!labelOff && (
            <ButtonText onClick={() => onClickHandle(false)} modifiers={defaultOffModifiers} eventData={eventDataInactive}>
              {typeof labelOff === 'string' ? <Text content={labelOff} modifiers={['brandFont']} /> : labelOff}
            </ButtonText>
          )}
        </ButtonGroup>
      </ToggleInner>
    </ToggleStyled>
  );
};

Toggle.propTypes = {
  startOn: PropTypes.bool,
  labelOn: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelOff: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  eventDataActive: PropTypes.shape({
    action: PropTypes.string,
    label: PropTypes.string,
  }),
  eventDataInactive: PropTypes.shape({
    action: PropTypes.string,
    label: PropTypes.string,
  }),
};

Toggle.defaultProps = {
  eventDataActive: {},
  eventDataInactive: {},
};

export default Toggle;
