import styled from 'styled-components';

export const ZipMessageErrorStyled = styled.div.attrs({
  className: 'ZipMessageError',
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
`;
