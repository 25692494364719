import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TooltipStyled, TipIconWrapper } from './Tooltip.styled';

import { Icon, Text, Link } from 'elements';
import InformationIcon from 'elements/icon/assets/information-icon.svg';
import { useBreakpoint } from 'utils/hooks';

const Tooltip = ({ text, iconModifiers, callOut }) => {
  const [isTipOpen, setIsTipOpen] = useState(false);
  const screenSize = useBreakpoint();
  const isNotDesktop = screenSize === 'SM' || screenSize === 'MD';
  const showTooltip = isNotDesktop && isTipOpen;
  const modifiers = ['backgroundGray2', showTooltip && 'showTip'];

  const onClickTooltip = () => {
    setIsTipOpen(!isTipOpen);
  };

  return (
    <TipIconWrapper onClick={onClickTooltip} modifiers={modifiers}>
      {!!callOut && <Link modifiers={['brandColor', 'underline', 'small']}>{callOut}</Link>}
      <Icon modifiers={iconModifiers}>
        <InformationIcon />
      </Icon>
      <TooltipStyled>
        <Text>{text}</Text>
      </TooltipStyled>
    </TipIconWrapper>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  iconModifiers: PropTypes.any,
  callOut: PropTypes.string,
};

export default Tooltip;
