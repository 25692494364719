import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme/Media';

export const modifiers = {
  shortInfoCard: ({ theme }) => ({
    styles: `
      .CardInner {
        padding-bottom: 2rem;
        height: 24rem;

        .Title {
          text-align: center;
        }

        .RecipeInfo {
          > span {
            display: none;
          }

          .RecipeTime {
            margin: 0;
            margin-top: 0.125rem;
            justify-content: center;
          } 
        }

        > .Text {
          display: none;
        }

        > .Button {
          display: none;
          border: solid 4px red;
        }
      }

      a {
        cursor: pointer;
        transition: background-color 0.25s ease;
        
        &:hover {
          background-color: ${theme.farmProject.gray_2};
          transition: all easy-in-out 1s;
        }
      }
    `,
  }),
};

export const CardRecipeStyled = styled.div.attrs({
  className: 'CardRecipeStyled',
})`
  padding: 0.25rem;
  width: 100%;
  max-width: 20rem;
  background-color: transparent;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  ${applyStyleModifiers(modifiers)};
`;

export const CardInner = styled.div.attrs({
  className: 'CardInner',
})`
  padding: 1rem;
  background-color: #f5f6f4;
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 29.75rem;
  position: relative;

  .Image {
    height: 14rem;
    margin-bottom: 1rem;

    picture {
      img {
        height: 110%;
        width: auto;
      }
    }
  }

  > .Title {
    min-height: 3.2rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Button {
    width: calc(100% - 2rem);
    margin-top: 1rem;

    position: absolute;
    top: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
  }

  @media ${breakPoints.large_break} {
    > .Title {
      font-size: 1.125rem;
      text-align: center;
    }
  }
`;

export const RecipeInfo = styled.div.attrs({
  className: 'RecipeInfo',
})`
  display: flex;
  flex-direction: column;

  @media ${breakPoints.large_break} {
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;

    > span {
      font-size: 0.75rem;
    }
  }

  > .Text {
    font-weight: bold;
  }

  .Text {
    line-height: normal;
  }
`;

export const RecipeTime = styled.div.attrs({
  className: 'RecipeTime',
})`
  display: flex;
  align-items: center;
  margin: 0.5rem 0 1rem;

  @media ${breakPoints.large_break} {
    margin: auto;
    margin-top: 0.25rem;

    .Text {
      font-size: 0.75rem;
    }
  }

  .Icon {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.25rem;

    svg {
      width: 90%;
      height: 90%;
    }
  }
`;
