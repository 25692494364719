import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme/Media';

export const modifiers = {
  yellow: ({ theme }) => ({
    styles: `  
      background-color: ${theme.farmProject.yellow};
      border-color: ${theme.farmProject.yellow};
      .Text {
        color: ${theme.farmProject.dark_gray};
      }
    `,
  }),
  green: ({ theme }) => ({
    styles: `  
      background-color: ${theme.farmProject.green};
      border-color: ${theme.farmProject.green};
      .Text {
        color: ${theme.farmProject.white};
      }
    `,
  }),
  lightGreenNoBorder: ({ theme }) => ({
    styles: `
      border-width: 0px;
      background-color: ${theme.farmProject.light_green_5};
      .Text {
        color: ${theme.farmProject.dark_green_3};
      }
    `,
  }),
  red: ({ theme }) => ({
    styles: `  
      background-color: ${theme.farmProject.red};
      border-color: ${theme.farmProject.red};
      .Text {
        color: ${theme.farmProject.white};
      }
    `,
  }),
  lightGray: ({ theme }) => ({
    styles: `  
      background-color: ${theme.farmProject.light_gray};
      .Text {
        color: ${theme.farmProject.white};
      }
    `,
  }),
  darkGray: ({ theme }) => ({
    styles: `  
      background-color: ${theme.farmProject.dark_gray};
      .Text {
        color: ${theme.farmProject.white};
      }
    `,
  }),
  whiteFont: () => ({
    styles: `  
      .Text {
        color: white;
      }
    `,
  }),
  smallFontBold: () => ({
    styles: `
      .Text {
        font-size: 0.75rem;
        font-weight: 700;
      }
    `,
  }),
  transparentBackground: ({ theme }) => ({
    styles: `  
      background-color: transparent;
      .Text {
        color: ${theme.farmProject.dark_gray};
      }
    `,
  }),
  greenInverted: ({ theme }) => ({
    styles: `  
      padding: 2px 12px;
      background-color: ${theme.farmProject.light_green_5};
      display: inline-block;
      border: none;
      border-radius: 20px;
      
      .Text {
        font-size: 0.75rem;
        line-height: 1.125rem;
        letter-spacing: 0.1em;
        font-weight: 500;
        color: ${theme.farmProject.lg_green_900};
      }
    `,
  }),
};

export const PillLabelStyled = styled.div.attrs({
  className: 'PillLabelStyled',
})`
  background-color: ${({ theme }) => theme.farmProject.green};
  border: solid 1px ${({ theme }) => theme.farmProject.green};
  padding: 0.25rem 0.75rem;
  width: fit-content;
  border-radius: 1.5rem;

  .Text {
    color: white;
  }

  @media ${breakPoints.large_break} {
    .Text {
      font-size: 0.75rem;
    }
  }

  ${applyStyleModifiers(modifiers)};
`;
