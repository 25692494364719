import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Image, Text, GatsbyImage, Link } from 'elements';

import { GlowingRingCallStyled, GlowingCallInner, GlowingCallInformation } from './FarmGlowRings.styled';

import { formatDynamicDecimalPrice } from 'utils/cart-utils';
import paths from 'constants/paths';

const defaultName = 'Glow Rings™';
const defaultGR = paths.shopPages.GLOW_RINGS.SIZE_4;
const glowRingsPaths = Object.values(paths.shopPages.GLOW_RINGS);

const GlowingRingsCall = ({
  title,
  body,
  isGRAdded,
  image = '',
  imageAlt,
  imageGatsby,
  glowRing,
  onSelectGlowRing,
  hasFarmstand = false,
}) => {
  const glowRingLink = glowRingsPaths.find((e) => e.includes(glowRing?.slug)) || defaultGR;
  const { name, priceCents: price } = glowRing || {};
  const fixedName = name === defaultName ? `${defaultName} (4-ring)` : name || defaultName;
  const hasFarmAndGR = hasFarmstand && isGRAdded;
  const buttonPrice = hasFarmstand && price ? `(${formatDynamicDecimalPrice(price)})` : '';
  const buttonText = `${hasFarmAndGR ? 'Glow Rings Added for' : 'Add Glow Rings' || ''} ${buttonPrice}`;
  const isButtonInactive = !hasFarmAndGR;

  const onAddGR = () => {
    onSelectGlowRing(!isGRAdded);
  };

  return (
    <GlowingRingCallStyled>
      <Text modifiers={['brandFont', 'noLineHeight']} content={title} isHTML />
      <GlowingCallInner>
        {imageGatsby && <GatsbyImage image={imageGatsby} alt={imageAlt} />}
        {!imageGatsby && image && <Image picture={image} alt={imageAlt} />}
        <GlowingCallInformation>
          <Link as='h1' href={glowRingLink} className='Title' content={fixedName} modifiers={['brandColor']} />
          <Text modifiers={['brandFont', 'noLineHeight']} content={body} isHTML />
          <Checkbox
            label={buttonText}
            onChange={onAddGR}
            field={{ name: 'isGRAdded', value: !isButtonInactive && isGRAdded }}
            modifiers={['darkGray', 'leftAlign', 'small', !hasFarmstand && 'disabled']}
            value={isGRAdded}
          />
        </GlowingCallInformation>
      </GlowingCallInner>
    </GlowingRingCallStyled>
  );
};

GlowingRingsCall.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  imageGatsby: PropTypes.object,
  primaryButton: PropTypes.object,
  glowRing: PropTypes.object,
  onSelectGlowRing: PropTypes.func,
  hasFarmstand: PropTypes.bool,
  isGRAdded: PropTypes.bool,
};

export default GlowingRingsCall;
