import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { AddToCart, PillLabel, TextTooltipWrapper } from 'components';
import { Text, Image, ButtonText } from 'elements';

import { SeedlingCardStyled, SeedlingCardInner, BundleInfo, ImageWrapper, CardFooter } from './SeedlingCard.styled';

import {
  getSeedShipLine,
  getSeedWarningLine,
  isSeedAvailable,
  isSeedOutOfStock,
  isSeedShoppableAndOutOfSeason,
} from 'utils/seedlings-utils';
import growingEnvironments from 'constants/growingEnvironments';
import { formatDynamicDecimalPrice } from 'utils/cart-utils';

const { OUTDOOR, INDOOR } = growingEnvironments;

const PLACEHOLDER_IMAGE =
  'https://images.ctfassets.net/tjhwhh07rwer/4vtw9IJNQadFm0nJHWXPE0/5ccf4d2d25d85e3cbc548b3b20f71a57/SeedlingSide.jpeg';

const SeedlingCard = ({ catalog = {}, content = {}, modifiers, toggleQuickViewModal, setQuickView }) => {
  const reservation = useSelector(({ reservation }) => reservation) || {};
  const messages = useSelector(({ contentful }) => contentful.messages) || {};
  const userEnv = useSelector(({ user }) => user.userSetEnvironment);
  const catalogEnv = useSelector(({ catalog }) => catalog.packRecommendation?.environment);
  const userEnvironment = userEnv === 'DEFAULT' ? (catalogEnv === INDOOR ? INDOOR : OUTDOOR) : userEnv;

  const { isFeatured, indoorApproved, isLimitedRelease } = content?.fields || {};
  const { name, imageUrl } = catalog || {};
  const seedlingPicture = imageUrl || PLACEHOLDER_IMAGE;
  const isNotGoodForIndoor = userEnvironment === INDOOR && !indoorApproved;

  const { label: warnLabel, tooltip: warnTooltip } = getSeedWarningLine(catalog, messages, indoorApproved, userEnvironment) || {};
  const { label: shipLabel } = getSeedShipLine(catalog);
  const isReservedItem = reservation?.items?.find?.(({ sku }) => sku === catalog?.sku);
  const isBuyableAndOutOfSeason = isSeedShoppableAndOutOfSeason(catalog);
  const isOutOfStock = isSeedOutOfStock(catalog);
  const shouldShowCTA = isSeedAvailable(catalog) || isBuyableAndOutOfSeason || isOutOfStock;
  const shouldShowWarn = !!warnLabel && !isReservedItem;
  const shouldShowShipText = !!shipLabel && !isReservedItem;

  const handleModal = () => {
    toggleQuickViewModal();
    setQuickView({ data: { plants: [catalog] }, showCTA: shouldShowCTA });
  };

  return (
    <SeedlingCardStyled modifiers={modifiers}>
      <SeedlingCardInner>
        {(() => {
          if (isLimitedRelease) {
            return <PillLabel modifiers='green' content='LIMITED RELEASE' />;
          } else if (isFeatured) {
            return <PillLabel modifiers='yellow' content='FEATURED' />;
          } else {
            return null;
          }
        })()}
        <ImageWrapper>
          {!!seedlingPicture && (
            <Image
              alt={`Click here to get more details about: ${name}`}
              picture={seedlingPicture}
              nativeWidth={200}
              eventData={{ action: `Image: ${name?.toUpperCase?.()}` }}
              onClick={handleModal}
            />
          )}
        </ImageWrapper>
        <BundleInfo>
          <CardFooter>
            <ButtonText eventData={{ action: `Text: ${name?.toUpperCase?.()}` }} onClick={handleModal}>
              <Text modifiers={['xLarge', 'brandFont', 'noLineHeight']} content={name?.toUpperCase?.()} />
            </ButtonText>
            <Text modifiers={['brandFont', 'left']}>{formatDynamicDecimalPrice(catalog.priceCents)}</Text>
            {shouldShowCTA && <AddToCart item={catalog} isSoldOut={isOutOfStock} showPrice={false} />}
          </CardFooter>
        </BundleInfo>
        {shouldShowWarn && (
          <TextTooltipWrapper
            label={warnLabel}
            isLabelHTML={isNotGoodForIndoor}
            tooltipText={warnTooltip}
            labelModifiers={['small', 'brandColor']}
          />
        )}
        {shouldShowShipText && <TextTooltipWrapper label={shipLabel} labelModifiers={['small', 'brandColor']} />}
      </SeedlingCardInner>
    </SeedlingCardStyled>
  );
};

SeedlingCard.propTypes = {
  catalog: PropTypes.object,
  content: PropTypes.object,
  modifiers: PropTypes.any,
  toggleQuickViewModal: PropTypes.func,
  setQuickView: PropTypes.func,
};

export default SeedlingCard;
