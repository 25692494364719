// NOTE: For testing the structures below: https://developers.google.com/search/docs/advanced/structured-data

const seoData = {
  12: {
    title: '12-plant farmstand',
    googleMerchant: `{
      "@context": "http://schema.org",
      "@type": "Product",
      "image":
        "https://images.ctfassets.net/tjhwhh07rwer/3rwWNanN8EamkSDpYHibSZ/5c82c05a1105ec55d7c276e7e7761040/Farmstand_12.png?fm=webp&w=346",
      "url": "https://www.lettucegrow.com/the-farmstand/12",
      "name": "12-Plant Farmstand",
      "gtin": "00850018498074",
      "sku": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTE",
      "offers": {
        "@type": "Offer",
        "priceCurrency": "USD",
        "price": "399.00",
        "gtin": "00850018498074",
        "productID": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTE",
        "sku": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTE",
        "url": "https://www.lettucegrow.com/the-farmstand/12",
        "category": "Farmstand"
      }
    }`,
  },
  18: {
    title: '18-plant farmstand',
    googleMerchant: `{
      "@context": "http://schema.org/",
      "@type": "Product",
      "image":
        "https://images.ctfassets.net/tjhwhh07rwer/7wflaParSnRSJcDnWEowgz/04a6cab41b18c4e552e082a33b656bde/Farmstand_18.png?fm=webp&w=346",
      "url": "https://www.lettucegrow.com/the-farmstand/18",
      "name": "18-Plant Farmstand",
      "gtin": "00850018498081",
      "sku": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTEx",
      "offers": {
        "@type": "Offer",
        "priceCurrency": "USD",
        "price": "474.00",
        "gtin": "00850018498081",
        "productID": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTEx",
        "sku": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTEx",
        "url": "https://www.lettucegrow.com/the-farmstand/18",
        "category": "Farmstand"
      }
    }`,
  },
  24: {
    title: '24-plant farmstand',
    googleMerchant: `{
      "@context": "http://schema.org/",
      "@type": "Product",
      "image":
        "https://images.ctfassets.net/tjhwhh07rwer/1WE3lEbYrvFySEjyagE0wq/7feee39c67454f024b911866500f0da2/Farmstand_24.png?fm=webp&w=346",
      "url": "https://www.lettucegrow.com/the-farmstand/24",
      "name": "24-Plant Farmstand",
      "gtin": "00850018498098",
      "sku": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTE",
      "offers": {
        "@type": "Offer",
        "priceCurrency": "USD",
        "price": "549.00",
        "gtin": "00850018498098",
        "productID": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTE",
        "sku": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTE",
        "url": "https://www.lettucegrow.com/the-farmstand/24",
        "category": "Farmstand",
      }
    }`,
  },
  30: {
    title: '30-plant farmstand',
    googleMerchant: `{
      "@context": "http://schema.org/",
      "@type": "Product",
      "image":
        "https://images.ctfassets.net/tjhwhh07rwer/29QX3ZQ6f5AhDYvkUZpYav/e3bbee6bf3b4b6ceb354f78b29e2ca52/Farmstand_30.png?fm=webp&w=346",
      "url": "https://www.lettucegrow.com/the-farmstand/30",
      "name": "30-Plant Farmstand",
      "gtin": "00850018498104",
      "sku": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTIxODg1MQ",
      "offers": {
        "@type": "Offer",
        "priceCurrency": "USD",
        "price": "624.00",
        "gtin": "00850018498104",
        "productID": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTIxODg1MQ",
        "sku": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTIxODg1MQ",
        "url": "https://www.lettucegrow.com/the-farmstand/30",
        "category": "Farmstand"
      }
    }`,
  },
  36: {
    title: '36-plant farmstand',
    googleMerchant: `{
      "@context": "http://schema.org/",
      "@type": "Product",
      "image":
        "https://images.ctfassets.net/tjhwhh07rwer/6yHiYKUN6m3Iq59wen22bT/8c6d14a3eaf6026c51964bd3aa241afc/Farmstand_36.png?fm=webp&w=346",
      "url": "https://www.lettucegrow.com/the-farmstand/36",
      "name": "36-Plant Farmstand",
      "gtin": "00850018498111",
      "sku": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTI",
      "offers": {
        "@type": "Offer",
        "priceCurrency": "USD",
        "price": "699.00",
        "gtin": "00850018498111",
        "productID": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTI",
        "sku": "Z3Jvdy5kYi5EZXZpY2VUeXBlLTI",
        "url": "https://www.lettucegrow.com/the-farmstand/36",
        "category": "Farmstand"
      }
    }`,
  },
};
export default seoData;
