import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import IconClose from 'elements/icon/assets/close.svg';

import { ModalStyled, ModalBackdrop, ModalWrapper, ModalContent, ModalCloseButton, ModalInner } from './Modal.styled';
import { useToggleBodyScrolling } from 'utils/hooks';

const Modal = ({ children, isOpen, onCloseAction, size, position, modalType, dataNw, className }) => {
  useToggleBodyScrolling(isOpen);

  useEffect(() => {
    !!isOpen && addListeners();

    return () => removeListeners();
  }, [isOpen]);

  const onClose = (e) => {
    if (e.keyCode === 27)
      onCloseAction();
  }

  const addListeners = () => {
    document?.addEventListener('keydown', onClose);
  }

  const removeListeners = () => {
    document?.removeEventListener('keydown', onClose);
  }

  return (
    <ModalStyled modifiers={isOpen ? 'show' : 'hide'} className={className}>
      <ModalWrapper modifiers={position}>
        <ModalBackdrop onClick={onCloseAction} />
        <ModalContent modifiers={[size, modalType]} data-nw={dataNw}>
          <ModalCloseButton aria-label='Close modal' autoFocus onClick={onCloseAction}>
            <IconClose />
          </ModalCloseButton>
          <ModalInner>{children}</ModalInner>
        </ModalContent>
      </ModalWrapper>
    </ModalStyled>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large', 'fitContent']),
  modalType: PropTypes.oneOf(['normal', 'scroll', 'bundleModal']),
  position: PropTypes.any,
  onCloseAction: PropTypes.func.isRequired,
  children: PropTypes.node,
  dataNw: PropTypes.string,
};

Modal.defaultProps = {
  isOpen: false,
  modalType: 'normal'
};

export default Modal;
