import styled from 'styled-components';

import { LinkStyled } from 'elements/link/Link.styled';

export default styled.main.attrs({
  className: 'Main',
})`
  width: 100%;
  height: calc(100% - 3.9rem);
  box-sizing: border-box;
  position: relative;

  .UsableNetAssistive {
    padding-left: 0.5rem;
    &:focus {
      outline: 1px solid blue !important; // needed to ensure accessibility standards met at all times
    }
  }
`;

export const UsableNetLink = styled(LinkStyled).attrs({ className: 'UsableNetLink ' })`
  &.Link {
    margin-left: 0.8rem;
  }
  &:focus {
    outline: 1px solid blue !important; // needed to ensure accessibility standards met at all times
  }
`;
