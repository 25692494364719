import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCountriesState, fetchUserCountry } from 'app/reduxState/globalUI';

/**
 * @typedef {Object} CountriesState
 * @property {Array} countries - list of countries
 * @property {boolean} isCountriesFetched - flag that indicates if the list was already fetched
 * @property {boolean} isFetchingCountries - flag that indicates if the list is being fetched
 */

/**
 * * useCountries - Custom hook to fetch Countries data
 * @param  {boolean} shouldFetch - flag that indicates if the countries list should be fetched or not. Defaults to true.
 *
 * @return {CountriesState} an object with countries state from globalUI reducer
 * */

export default function useCountries() {
  const dispatch = useDispatch();
  const { countries, isFetchingCountries, isCountriesFetched } = useSelector(getCountriesState);

  // Fetch countries only if it wasn't fetched yet and it is not fetching at the moment
  useEffect(() => {
    if (!isFetchingCountries && !isCountriesFetched) dispatch(fetchUserCountry());
  }, []);

  return { countries };
}
