import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { SquaredButton, Text, Title } from 'elements';
import { useIsInViewport } from 'utils/hooks';
import { SeasonNumbersSection, ContentWrapper } from 'staticPages/home/Home.styled';
import { ContentSection } from 'components';

const OdometerCounter = lazy(() => import('./OdometerCounter'));

const ImpactStats = ({
  title,
  body,
  callToAction,
  fruitsAndVeggiesText,
  gallonsOfWaterText,
  numFruitsVeggies,
  numGallonsOfWater,
  milkJugsBody,
  numMilkJugs,
  headline,
  isDocumentReady,
}) => {
  const [isInViewport, targetRef] = useIsInViewport();
  const ctaLabel = callToAction?.fields?.linkText || callToAction?.fields?.text;
  const ctaHref = callToAction?.fields?.linkUrl || callToAction?.fields?.url;

  return (
    <div ref={targetRef}>
      <SeasonNumbersSection>
        <ContentSection>
          <ContentWrapper>
            <Title content={title} modifiers='secondarySmall' as='h3' />
            <Text content={body} />
            <SquaredButton as='a' label={ctaLabel} href={ctaHref} eventData={{ action: ctaLabel }} />
          </ContentWrapper>
          {isDocumentReady && (
            <Suspense fallback={<div />}>
              <OdometerCounter
                headline={headline}
                numMilkJugs={numMilkJugs}
                numGallonsOfWater={numGallonsOfWater}
                numFruitsVeggies={numFruitsVeggies}
                fruitsAndVeggiesText={fruitsAndVeggiesText}
                gallonsOfWaterText={gallonsOfWaterText}
                milkJugsBody={milkJugsBody}
                isInViewport={isInViewport}
              />
            </Suspense>
          )}
        </ContentSection>
      </SeasonNumbersSection>
    </div>
  );
};

ImpactStats.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  headline: PropTypes.string,
  callToAction: PropTypes.object,
  fruitsAndVeggiesText: PropTypes.string,
  gallonsOfWaterText: PropTypes.string,
  numFruitsVeggies: PropTypes.number,
  numGallonsOfWater: PropTypes.number,
  milkJugsBody: PropTypes.string,
  numMilkJugs: PropTypes.number,
  isDocumentReady: PropTypes.bool,
};

export default ImpactStats;
