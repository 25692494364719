import React from 'react';
import PropTypes from 'prop-types';

import { ButtonText, Icon, Text } from 'elements';
import { Flex } from 'components';

import { BannerWrapper } from './RentalProgramBanner.styled';

import FarmFruitsIcon from 'elements/icon/assets/FarmFruits.svg';

const RentalProgramBanner = ({ title, body, cta, shouldShow }) => {
  return (
    <BannerWrapper shouldShow={shouldShow} modifiers={['row', 'alignStart']}>
      <Flex className='contentWrapper' modifiers={['directionColumn', 'flex1']}>
        <Text className='title' content={title} modifiers={['xLarge', 'brandFont', 'noLineHeight', 'semibold']} />
        <Text className='body' content={body} modifiers={['brandFont', 'light', 'noLineHeight', 'darkGreenColor']} />
        <ButtonText as='a' label={cta?.linkText} href={cta?.linkUrl} target={cta?.target} modifiers={['underline', 'textLeft']} />
      </Flex>
      <Flex className='iconWrapper' modifiers={['flex0', 'alignCenter', 'justifyCenter']}>
        <Icon>
          <FarmFruitsIcon />
        </Icon>
      </Flex>
    </BannerWrapper>
  );
};

RentalProgramBanner.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  cta: PropTypes.object,
  shouldShow: PropTypes.bool,
};

export default RentalProgramBanner;
