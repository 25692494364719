import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TooltipStyled, TipIconWrapper } from './Tooltip.styled';

import { Icon, Text } from 'elements';
import InformationIcon from 'elements/icon/assets/information-icon.svg';
import { useBreakpoint } from 'utils/hooks';

const Tooltip = ({ text, iconModifiers, contentModifiers, uncontrolled }) => {
  const [isTipOpen, setIsTipOpen] = useState(false);
  const screenSize = useBreakpoint();
  const isNotDesktop = screenSize === 'SM' || screenSize === 'MD';
  const showTooltip = isNotDesktop && isTipOpen;
  const modifiers = ['backgroundGray2', showTooltip && 'showTip'];

  const onClickTooltip = () => {
    if (!uncontrolled) {
      setIsTipOpen(!isTipOpen);
    }
  };

  return (
    <TipIconWrapper onClick={onClickTooltip} modifiers={modifiers} tabIndex={0}>
      <Icon modifiers={iconModifiers}>
        <InformationIcon />
      </Icon>
      <TooltipStyled modifiers={contentModifiers}>
        <Text isHTML modifiers={['tiny', 'brandFont', 'darkGrayColor']} content={text} />
      </TooltipStyled>
    </TipIconWrapper>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  iconModifiers: PropTypes.any,
  uncontrolled: PropTypes.bool,
};

Tooltip.defaultProps = {
  uncontrolled: false
};

export default Tooltip;
