import styled from 'styled-components/macro';
import breakPoints from 'theme/Media';

export const ModalVideoWrapper = styled.div.attrs({ className: 'ModalVideoWrapper' })`
  > .ComponentModal {
    .ModalWrapper {
      .modal__backdrop {
        background: rgba(0, 0, 0, 0.6);
      }
      .modal__Content {
        padding: 0;
        overflow: visible;
        background-color: transparent;

        button {
          background-color: ${({ theme }) => theme.farmProject.gray_3};
          padding: 0.5rem;
          border-radius: 0.125rem;
          right: -0.8rem;
          top: -0.8rem;
          cursor: pointer;

          svg {
            width: 1rem;
            height: 1rem;
          }
        }

        .ModalInner {
          max-width: unset;

          .ResponsiveEmbed {
            width: 100%;
            padding: 25%;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    padding: 4rem 1rem;
    max-width: 64rem;
    margin: auto;

    > .ComponentModal {
      .ModalWrapper {
        .modal__Content {
          max-width: 60rem;

          button {
            top: -0.8rem;
            opacity: 1;
            right: -0.8rem;
          }
        }
      }
    }
  }
`;
