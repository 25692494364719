import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { paths } from 'constants/index';

import { Title, Text, Icon, Link } from 'elements';
import { ContentSection, Swiper } from 'components';

import UserReviewCard from './components/user-review-card/UserReviewCard';
import SocialPost from './components/social-post/SocialPost';

import Facebook from 'elements/icon/assets/facebook.svg';
import Instagram from 'elements/icon/assets/instagram.svg';
import Youtube from 'elements/icon/assets/youtube.svg';
import TikTok from 'elements/icon/assets/tik-tok.svg';

import {
  UserGeneratedContentWrapper,
  UserContentComponentStyled,
  SwiperWrapper,
  TitleWrapper,
  SocialMediaLinks,
  SocialMediaCall,
} from './UserGeneratedContent.styled';

const UserGeneratedContent = ({ title, subtitle, socialMediaCall, post }) => {
  const swiperConfig = {
    centeredSlides: true,
    slidesPerView: 1.2,
    spaceBetween: 16,
    breakpoints: {
      320: {
        slidesPerView: 1.2,
        spaceBetween: 16,
      },
      480: {
        slidesPerView: 1.8,
        spaceBetween: 16,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 16,
        centeredSlides: true,
      },
      768: {
        slidesPerView: 2.5,
        spaceBetween: 16,
        centeredSlides: true,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 16,
        centeredSlides: false,
        slidesPerColumn: 4,
        slidesPerColumnFill: 'row',
      },
    },
  };

  const renderCards = (item, key) => {
    return key % 2 === 0 ? (
      <SocialPost
        key={key}
        picturePost={item?.fields?.mediaContent?.fields?.file?.url}
        alt={item?.fields?.mediaContent?.fields?.description}
        userName={item?.fields?.title}
        userHandle={item?.fields?.headline}
      />
    ) : (
      <UserReviewCard key={key} userName={item?.fields?.title} userLocation={item?.fields?.headline} userReview={item?.fields?.body} />
    );
  };

  return (
    <UserGeneratedContentWrapper>
      <ContentSection>
        <UserContentComponentStyled>
          <TitleWrapper>
            <Title as='h2' modifiers={['tertiaryXSmall', 'semiBold', 'lineHeightNormal', 'inverted']} content={subtitle} />
            <Title as='h2' modifiers={['secondarySmall', 'inverted']} content={title} />
          </TitleWrapper>
          <SwiperWrapper>
            <Swiper items={post} swiperProps={swiperConfig} renderSlideCard={renderCards} />
          </SwiperWrapper>
          <SocialMediaCall>
            <Title as='h2' modifiers={['tiny', 'semiBold', 'inverted']} content={socialMediaCall} />
            <SocialMediaLinks>
              <Link href={paths.EXTERNAL_FACEBOOK} target='_blank' ariaAttributes={{ 'aria-label': 'Facebook Link' }}>
                <Icon>
                  <Facebook />
                </Icon>
              </Link>
              <Link href={paths.EXTERNAL_YOUTUBE} target='_blank' ariaAttributes={{ 'aria-label': 'Youtube Link' }}>
                <Icon>
                  <Youtube />
                </Icon>
              </Link>
              <Link href={paths.EXTERNAL_INSTAGRAM} target='_blank' ariaAttributes={{ 'aria-label': 'Instagram Link' }}>
                <Icon>
                  <Instagram />
                </Icon>
              </Link>
              <Link href={paths.EXTERNAL_TIKTOK} target='_blank' ariaAttributes={{ 'aria-label': 'TikTok Link' }}>
                <Icon>
                  <TikTok />
                </Icon>
              </Link>
            </SocialMediaLinks>
          </SocialMediaCall>
        </UserContentComponentStyled>
      </ContentSection>
    </UserGeneratedContentWrapper>
  );
};

UserGeneratedContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  socialMediaCall: PropTypes.string,
};

export default UserGeneratedContent;
