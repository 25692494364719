/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { ContentSection } from 'components';
import { Text, Title, Icon } from 'elements';

import GrowIndoor from 'elements/icon/assets/grow-indoor-icon.svg';
import GrowOutdoor from 'elements/icon/assets/grow-outdoor-icon.svg';

import { GrowInOutSection, OptionsGrow, Option, Shape, OptionInformation } from './GrowIn.styled';

const OUTDOOR_ID = '5ZD6F3UP26LbN4fEy0h97m';

const Item = ({ percentages }) =>
  percentages.map((item) => {
    const id = _get(item, 'sys.id');
    const title = _get(item, 'fields.title');
    const bulletPoints = _get(item, 'fields.bulletPoints');

    return (
      <Option key={id}>
        <Shape>
          <Icon>{id === OUTDOOR_ID ? <GrowOutdoor /> : <GrowIndoor />}</Icon>
        </Shape>
        <OptionInformation>
          <Title as='h5' content={title} />
          {bulletPoints.map((bullet) => (
            <Text key={_get(bullet, 'sys.id')} content={_get(bullet, 'fields.text')} />
          ))}
        </OptionInformation>
      </Option>
    );
  });

const GrowIn = ({ title, blocks }) => {
  return (
    <GrowInOutSection>
      <ContentSection>
        <Title as='h3' modifiers='secondarySmall' content={title} />
        <OptionsGrow>
          <Item percentages={blocks} />
        </OptionsGrow>
      </ContentSection>
    </GrowInOutSection>
  );
};

GrowIn.propTypes = {
  title: PropTypes.string,
  blocks: PropTypes.array,
};

export default GrowIn;
