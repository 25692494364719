const VEGETABLES = 'Fruits & Vegetables';
const LEAFY = 'Leafy Greens';
const HERBS = 'Herbs';
const SPECIAL = 'Special Varieties';

const plantsCategories = {
  VEGETABLES,
  LEAFY,
  HERBS,
  SPECIAL,
};

export default plantsCategories;
