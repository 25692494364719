import React, { useRef, useState, useEffect } from 'react';
import { ButtonText, Text, Icon, SquaredButton } from 'elements';
import { Flex, PromoPrice } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogSeedlings } from 'reduxState/catalog';
import {
  openCartModal,
  getIsBuyNowLoaded,
  getIsBuyNowFetching,
  loadBuyNowPreview,
  getBuyNow,
  addBulkItems,
  replaceSeedlings,
  getTotalBuyNowItems,
  getTotalBuyNowSeedlings,
  getNumSeedlingsInCart,
  submitRecommendedOrder,
  getIsAutofillLoaded,
  fetchAutofillPendingOrders,
  getIsAutofillFetching,
  getTotalAutofillPendingOrderItems,
  submitAutofillOrder,
  getAutofillPendingOrder,
} from 'reduxState/cart';
import {
  fetchMyOrders,
  fetchUserDevices,
  getAreUserDevicesFetched,
  getAutofillPreferences,
  getUserCreditCard,
  fetchRecommendedOrder,
  getUserZip,
} from 'reduxState/user';
import { getAutofillData } from 'reduxState/globalSelectors';
import { CHOOSE_FOR_ME_MODAL } from 'constants/contentful';
import { formatPrice, getVouchersCount } from 'utils/cart-utils';
import { useContent, useSwipe, useBreakpoint } from 'utils/hooks';
import { useLocation } from '@reach/router';

import ChevronLeft from 'elements/icon/assets/left.svg';
import Chevron from 'elements/icon/assets/chevron.svg';
import LoadingIcon from 'assets/images/loading.svg';

import { navigate } from 'gatsby';
import paths from 'constants/paths';
import { ErrorModal, SelectBundleModal } from 'components/modal';
import { trackClick } from 'utils/googleTagManager';

import {
  FixedOneClickPayment,
  SectionAnimated,
  SectionTop,
  SectionMiddle,
  SectionBottom,
  WrapOrderBtn,
  BgOverlay,
  SummaryOrder,
  WrapLoading,
  WrapPersonalize,
} from './StickyOrderSummary.styled';

const StickyOrderSummary = () => {
  const [showChooseModal, setShowChooseModal] = useState(false);
  const [disableTotalCTA, setDisableTotalCTA] = useState(false);
  const dispatch = useDispatch();
  const modalChooseContent = useContent(CHOOSE_FOR_ME_MODAL);
  const seedlings = useSelector(getCatalogSeedlings);
  const buyNow = useSelector(getBuyNow);
  const totalBuyNowItems = useSelector(getTotalBuyNowItems);
  const totalBuyNowSeedlings = useSelector(getTotalBuyNowSeedlings);
  const totalAutofillPendingOrderItems = useSelector(getTotalAutofillPendingOrderItems);
  const autofillPendingOrder = useSelector(getAutofillPendingOrder);

  const [showSummary, setShowSummary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const summaryRef = useRef(null);
  const location = useLocation();
  const breakpoint = useBreakpoint();
  const seedlingsInCartQty = useSelector(getNumSeedlingsInCart);
  const areDevicesFetched = useSelector(getAreUserDevicesFetched);
  const autofillPreferences = useSelector(getAutofillPreferences);

  const isBuyNowFetching = useSelector(getIsBuyNowFetching);
  const isAutofillFetching = useSelector(getIsAutofillFetching);

  const isBuyNowLoaded = useSelector(getIsBuyNowLoaded);
  const isAutofillLoaded = useSelector(getIsAutofillLoaded);
  const userZip = useSelector(getUserZip);

  const { isAutofillOrder, isEmptyAutofillOrder, totalSuggestedSeedlings } = useSelector(getAutofillData);

  useSwipe(summaryRef, setShowSummary);

  const toggleChooseModal = () => {
    setShowChooseModal(!showChooseModal);
  };

  const chooseForMe = (type) => () => {
    const items = buyNow.items.map((item) => ({
      ...seedlings[item.sku],
      qty: item.qty,
    }));

    const action = type === 'replace' ? replaceSeedlings({ items }) : addBulkItems({ items });
    dispatch(action);
    if (showChooseModal) toggleChooseModal();
    dispatch(openCartModal());
  };

  const goToOpenCart = () => {
    if (seedlingsInCartQty > 0) {
      toggleChooseModal();
    } else {
      chooseForMe('add')();
    }
    setShowSummary(false);
  };

  const processPayment = () => {
    if (isAutofillOrder && !isEmptyAutofillOrder) {
      dispatch(submitAutofillOrder({ pendingOrderId: autofillPendingOrder.id, setIsLoading }));
    } else {
      dispatch(submitRecommendedOrder({ items: buyNow.items, setIsLoading }));
    }
  };

  useEffect(() => {
    dispatch(fetchMyOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(loadBuyNowPreview());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!areDevicesFetched) dispatch(fetchUserDevices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!isBuyNowLoaded && !isAutofillOrder) {
      dispatch(fetchRecommendedOrder());
    }
  }, [dispatch, isBuyNowLoaded, isAutofillOrder]);

  useEffect(() => {
    if (!isAutofillLoaded && isAutofillOrder) {
      dispatch(fetchAutofillPendingOrders());
    }
  }, [dispatch, isAutofillLoaded, isAutofillOrder]);

  useEffect(() => {
    if (totalBuyNowItems === 0) {
      setDisableTotalCTA(true);
    } else {
      setDisableTotalCTA(false);
    }
  }, [totalBuyNowItems]);

  useEffect(() => {
    if (showSummary) {
      trackClick({ action: 'recorder', label: isFYF ? 'personalize_order_summary' : 'order_summary' });
    }
  }, [showSummary, isFYF]);

  const subTotal = buyNow.subtotalCents / 100;
  const shipping = buyNow.shippingCents / 100;
  const tax = buyNow.taxCents / 100;
  const total = buyNow.totalCents / 100;
  const totalVouchersUsedQty = getVouchersCount(buyNow.voucherUses);

  const isFYF = location.pathname.includes(paths.PERSONALIZE_RECOMMENDATION);

  const CCOnFile = useSelector(getUserCreditCard);

  return (
    <>
      <ErrorModal />
      <SelectBundleModal
        show={showChooseModal}
        title={modalChooseContent?.[0]?.fields?.title || ''}
        body={modalChooseContent?.[0]?.fields?.body || ''}
        onReplace={chooseForMe('replace')}
        onAdd={chooseForMe('add')}
        onCloseAction={toggleChooseModal}
      />

      <BgOverlay show={showSummary} onClick={() => setShowSummary(false)} />

      <FixedOneClickPayment className={showSummary ? 'show' : ''} ref={summaryRef}>
        <SectionAnimated>
          <SectionTop>
            <Icon modifiers={['xSmall', !showSummary && 'rotate180']} onClick={() => setShowSummary(!showSummary)}>
              <Chevron />
            </Icon>
            <Text
              modifiers={['brandFont', 'center', 'noLetterSpace']}
              content={breakpoint !== 'SM' && breakpoint !== 'MD' ? 'ORDER SUMMARY' : 'SWIPE UP FOR ORDER SUMMARY'}
            />
          </SectionTop>
          <SectionMiddle>
            <SummaryOrder hasVouchers={!!totalVouchersUsedQty}>
              {buyNow.items.length > 0 &&
                buyNow.items.map((item) => (
                  <Flex modifiers={['spaceBetween']} className='OrderDetail' key={item.sku}>
                    <Text modifiers={['brandFont', 'noLetterSpace']} className='Qty'>
                      {item.qty}
                    </Text>
                    <Text modifiers={['brandFont', 'noLetterSpace']} className='Name'>
                      {item.name}
                    </Text>
                    <Text modifiers={['brandFont', 'noLetterSpace']} className='Price'>
                      {formatPrice((item.priceCents * item.qty) / 100)}
                    </Text>
                  </Flex>
                ))}

              {!!totalVouchersUsedQty && (
                <Flex modifiers={['spaceBetween']} className='OrderDetail' key='voucher_credits_applied'>
                  <Text modifiers={['brandFont', 'noLetterSpace', 'italic']} className='VoucherCredits'>
                    {totalVouchersUsedQty} CREDITS APPLIED
                  </Text>
                </Flex>
              )}
              <hr />
              <Flex modifiers={['spaceBetween']} className='SubTotal'>
                <Text modifiers={['brandFont', 'noLetterSpace', 'bold']}>SUBTOTAL</Text>
                <Flex>
                  {!!buyNow.promoCents || !!buyNow.voucherCents ? (
                    <PromoPrice
                      oldPrice={formatPrice(subTotal)}
                      newPrice={formatPrice(Math.max(0, (buyNow.subtotalCents - buyNow.promoCents - buyNow.voucherCents) / 100))}
                    />
                  ) : (
                    <Text modifiers={['brandFont', 'noLetterSpace', 'bold']}>{formatPrice(subTotal)}</Text>
                  )}
                </Flex>
              </Flex>
              <Flex modifiers={['spaceBetween']} className='Shipping'>
                <Text modifiers={['brandFont', 'noLetterSpace']}>SHIPPING</Text>
                <Text modifiers={['brandFont', 'noLetterSpace']}>{formatPrice(shipping)}</Text>
              </Flex>
              <Flex modifiers={['spaceBetween']} className='Tax'>
                <Text modifiers={['brandFont', 'noLetterSpace']}>
                  ESTIMATED TAX <small>(based on zip code {userZip ? userZip : '73301'})</small>
                </Text>
                <Text modifiers={['brandFont', 'noLetterSpace']}>{formatPrice(tax)}</Text>
              </Flex>

              {(!!buyNow.giftCents || !!buyNow.creditCents) && (
                <Flex modifiers={['spaceBetween']} className='Gifts'>
                  <Text modifiers={['brandFont', 'noLetterSpace']}>GIFTS & CREDITS</Text>
                  <Text modifiers={['brandFont', 'noLetterSpace']}>-{formatPrice(buyNow.giftCents + buyNow.creditCents / 100)}</Text>
                </Flex>
              )}

              <Flex modifiers={['spaceBetween']} className='Total'>
                <Text modifiers={['brandFont', 'noLetterSpace', 'brandColor']}>TOTAL</Text>
                <Text modifiers={['brandFont', 'noLetterSpace', 'brandColor']}>{formatPrice(total)}</Text>
              </Flex>
            </SummaryOrder>
          </SectionMiddle>
        </SectionAnimated>

        <SectionBottom isFYF={isFYF}>
          <ButtonText
            id='BackToFarm'
            onClick={() => {
              const backPath = isFYF ? paths.RECOMMENDED_ORDER_DETAILS : `${paths.MY_FARM}/#my-recommended-order`;
              navigate(backPath);
            }}
            eventData={{ action: 'recorder', label: isFYF ? 'personalize_back_to_myfarm' : 'back_to_myfarm' }}
          >
            <Icon modifiers='small'>
              <ChevronLeft />
            </Icon>
            <Text content={isFYF ? 'BACK' : 'BACK TO MY FARM'} modifiers={['brandFont']} />
          </ButtonText>

          {isFYF && (
            <WrapPersonalize>
              <Text modifiers={['brandFont', 'noLetterSpace']} className='Label'>
                PERSONALIZE YOUR ORDER
              </Text>
              <Text modifiers={['brandFont', 'noLetterSpace', 'brandColor']} className='Amount'>
                {autofillPreferences?.enabled
                  ? !isEmptyAutofillOrder
                    ? `${totalAutofillPendingOrderItems} / ${totalAutofillPendingOrderItems}`
                    : `${totalBuyNowSeedlings} / ${totalSuggestedSeedlings}`
                  : `${totalBuyNowSeedlings} / ${totalSuggestedSeedlings}`}
              </Text>
            </WrapPersonalize>
          )}

          <WrapOrderBtn>
            {isAutofillOrder && (
              <Text className='OrderEarly' modifiers={['brandFont', 'noLetterSpace', 'small']}>
                Want to order early?
              </Text>
            )}
            {!isLoading && !isBuyNowFetching && !isAutofillFetching ? (
              CCOnFile?.id ? (
                <SquaredButton
                  className='SquaredButton'
                  modifiers={['tertiary', disableTotalCTA ? 'disabled' : '']}
                  label='ORDER NOW'
                  onClick={processPayment}
                  eventData={{ action: 'recorder', label: isFYF ? 'personalize_order_now' : 'order_now' }}
                >
                  <span>{formatPrice(total)}</span>
                </SquaredButton>
              ) : (
                <SquaredButton
                  className='SquaredButton'
                  onClick={goToOpenCart}
                  label='ADD TO CART'
                  modifiers={['tertiary', disableTotalCTA ? 'disabled' : '']}
                  dataNw='checkout-button'
                  eventData={{ action: 'recorder', label: isFYF ? 'personalize_complete_order' : 'complete_order' }}
                >
                  <span>{formatPrice(total)}</span>
                </SquaredButton>
              )
            ) : (
              <WrapLoading>
                <LoadingIcon />
              </WrapLoading>
            )}
            {CCOnFile?.id && (
              <Text modifiers={['brandFont', 'small', 'noLetterSpace']}>
                Your Credit Card ending in <strong>{CCOnFile?.last4}</strong> will be charged
              </Text>
            )}
          </WrapOrderBtn>
        </SectionBottom>
      </FixedOneClickPayment>
    </>
  );
};

export default StickyOrderSummary;
