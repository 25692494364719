import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const SocialPostStyled = styled.div.attrs({
  className: 'SocialPostStyled',
  tabIndex: '0',
})`
  /* max-width: 17.813rem; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
  background-color: white;

  > .Image {
    height: 18rem;
    width: 100%;

    picture {
      img {
        width: 115%;
      }
    }

    @media ${breakPoints.large_break} {
      picture {
        img {
          width: 130%;
        }
      }
    }

    @media ${breakPoints.xLarge_break} {
      picture {
        img {
          width: 110%;
        }
      }
    }
  }

  .Title {
    text-transform: normal;
  }

  .Text + .Text {
    margin-top: 0.5rem;
  }
`;

export const SocialInformation = styled.div.attrs({
  className: 'SocialInformation',
})`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.125rem;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  height: 7.75rem;
  align-items: flex-start;

  .Image {
    height: 3rem;
    width: 3rem;
    border-radius: 100%;
  }
`;

export const UserInfo = styled.div.attrs({
  className: 'UserInfo',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .Title {
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 11.5rem;
    overflow: hidden;
    text-align: left;
  }

  .Text {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`;
