import styled from 'styled-components';

import { Flex } from 'components';

import breakPoints from 'theme/Media';

export const BannerWrapper = styled(Flex)`
  margin: auto;
  align-items: center;
  padding: 1.25rem 1rem;
  max-width: 30rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.farmProject.gray_3};

  .title {
    margin-bottom: 0.5rem;
  }

  .visualizerCTA {
    width: fit-content;
    margin-top: 1rem;
  }

  .qrCodeContainer {
    display: none;
  }

  @media ${breakPoints.large_break} {
    max-width: unset;
    margin-top: 1rem;

    .visualizerCTA {
      display: none;
    }

    .qrCodeContainer {
      display: flex;
      margin-left: 2rem;
    }
  }
`;
