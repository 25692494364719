import { useEffect, useState } from 'react';

const getNodeBounds = (nodeIdOrClass, index = null) => {
  if (typeof document === 'undefined') return {};
  if (index !== null) return document.querySelectorAll(nodeIdOrClass)?.[index]?.getBoundingClientRect?.() || {};
  return document.querySelector(nodeIdOrClass)?.getBoundingClientRect?.() || {};
};

const getNavHeight = () => getNodeBounds('.Navigation').height || 0;

const useCarouselPosition = () => {
  const [shouldBeFixed, setShouldBeFixed] = useState(false);
  const [shouldBeAbsolute, setShouldBeAbsolute] = useState(false);
  let navHeight = getNavHeight();

  useEffect(() => {
    if (typeof window !== 'undefined') window.addEventListener('scroll', updatePositionValues);

    return () => {
      if (typeof window !== 'undefined') window.removeEventListener('scroll', updatePositionValues);
    };

    // eslint-disable-next-line
  }, []);

  const updatePositionValues = () => {
    if (typeof window === 'undefined') return;
    if (!navHeight) navHeight = getNavHeight();

    const carouselTopFixed = getNodeBounds('.ProductInformation').top || 0;
    const carouselTopDynamic = getNodeBounds('.ProductHeroSliderStyled').top || 0;
    const thumbsBottom = getNodeBounds('.HeroSliderSecondary').bottom || 0;
    const savingTop = getNodeBounds('.HeroDetailStyled').bottom;

    if (savingTop - thumbsBottom <= 140) {
      const isNavAbove = carouselTopDynamic > navHeight + 1;
      setShouldBeFixed(isNavAbove);
      setShouldBeAbsolute(!isNavAbove);
    } else if (carouselTopFixed <= navHeight) {
      setShouldBeFixed(true);
      setShouldBeAbsolute(false);
    } else if (carouselTopFixed > navHeight) {
      setShouldBeFixed(false);
      setShouldBeAbsolute(false);
    }
  };

  return { shouldBeFixed, shouldBeAbsolute, navHeight };
};

export default useCarouselPosition;
