import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { navigate } from 'gatsby';

import useContent from 'utils/hooks/useContent';
import { useAuth } from 'utils/hooks/useAuth';
import { LOGIN_PAGE } from 'constants/contentful';

import { Input, SquaredButton, Title, Text } from 'elements';
import { Modal } from '../index';

import { ResetPasswordStyled } from './ResetPassoword.styled';

const REQ_MSG = 'Please complete all required fields.';

const ResetPassword = ({ isOpen, onCloseAction }) => {
  const user = useSelector((state) => state.user);
  const messages = useSelector((state) => state.contentful.messages);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const auth = useAuth();
  const content = useContent(LOGIN_PAGE, isOpen);
  const location = useLocation();

  useEffect(() => {
    if (!isOpen && isSubmitted) {
      setIsSubmitted(false);
    }
  }, [isOpen, isSubmitted]);

  const onSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
    // *** Form Submit Started ***
    const resetSent = await auth.sendPasswordResetEmail(values.email);
    if (!resetSent) {
      // PW Reset failed
      setErrors({ email: 'Could not reset your password. Please try again or contact us.' });
      setSubmitting(false);
      return;
    }

    // send GTM event
    window.dataLayer?.push({
      event: 'formSubmitted',
      formId: 'reset-password',
    });

    setSubmitting(false);
    setIsSubmitted(true);
    resetForm();
    location?.state?.passwordResetRetry && navigate(location.pathname); //clear location state
  };

  if (!content) return null;
  const blocks = _get(content[0].fields, 'sections[0].fields.percentages');
  const resetContent = blocks ? blocks.find((block) => block.sys.id === 'SdzUKFfLbzBRGXowlXpnn') : null;

  if (!resetContent) return null;
  return (
    <ResetPasswordStyled>
      <Modal isOpen={isOpen} onCloseAction={onCloseAction}>
        <Title as='h2' content={resetContent.fields.subText} />
        {location.state && location.state.passwordResetRetry && messages && (
          <>
            <Text modifiers='errorColor'>{messages.LGE_passwordResetExpired}</Text>
            <br></br>
          </>
        )}
        <Text
          modifiers={['xLarge', isSubmitted && 'brandColor']}
          content={isSubmitted ? messages?.LG_reset_password_success : resetContent.fields.subTextBody}
        />
        <Formik
          onSubmit={onSubmit}
          initialValues={{ email: user.email }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Please enter a valid email address.').required(REQ_MSG),
          })}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <Field required variant='filled' name='email' type='email' label='E-mail' helperText='*Required' component={Input} />
              <SquaredButton
                type='submit'
                label={isSubmitting ? '...SUBMITTING' : isSubmitted ? 'SUBMITTED!' : 'SUBMIT'}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </Modal>
    </ResetPasswordStyled>
  );
};

ResetPassword.propTypes = {
  isOpen: PropTypes.bool,
  onCloseAction: PropTypes.func,
};

export default ResetPassword;
