import React, { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from '@reach/router';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import seoData from './seoData.js';

import { Text, ButtonText } from 'elements';
import Head from 'components/Head';
import SizeModal from '../size-modal/SizeModal';
import { FarmstandSizeStyled, SizeButtonGroup, SelectSizeButton, LabelWrapper } from './FarmSelectSize.styled';

import paths from 'constants/paths';
import { HALO_LIGHTS_ORDERED_SKUS } from 'constants/sku.js';
import { BundleContext, setSelectedBundleSize } from 'app/pages/promotion-page/utils/bundleContext';
import { getFarmDefaultSize } from 'reduxState/globalUI';

const FarmstandSize = ({
  title,
  data = [],
  glowRingSlugs = [],
  onSelectFarmstand,
  imgSliderRefs,
  glowRingsNumberLabel = '',
  hasFarmstand = false,
  isBundlePage = false,
  defaultSize,
  farmIndex,
  sizeModalContent,
}) => {
  const dispatch = useContext(BundleContext)?.dispatch;
  const history = useHistory();
  const selectedFarm = useSelector(getFarmDefaultSize);
  const [isSizeModalOpen, setIsSizeModalOpen] = useState(false);
  const farms = data?.map((e) => e?.size?.toString?.()) || [];
  const isGlowRing = !_isEmpty(glowRingSlugs);
  const slugParam = useMatch(`${paths.SHOP_PRODUCTS}/:slug`)?.slug;
  const slugIndex = glowRingSlugs?.findIndex((e) => e === slugParam);

  useEffect(() => {
    if (!isGlowRing && !_isEmpty(farms) && farmIndex > -1) {
      const SHOULD_TRACK = false;
      onSelectFarmstand && onSelectFarmstand(farmIndex, SHOULD_TRACK);
      handleImageSlider(farmIndex);
    }
    if (isGlowRing && !!slugParam && selectedFarm !== slugIndex) {
      const SHOULD_TRACK = false;
      onSelectFarmstand && onSelectFarmstand(slugIndex, SHOULD_TRACK);
      handleImageSlider(slugIndex);
    }
    // eslint-disable-next-line
  }, [isGlowRing, slugIndex]);

  const handleImageSlider = (index) => {
    imgSliderRefs?.forEach?.((imgSliderRef) => {
      const imageId = isGlowRing ? HALO_LIGHTS_ORDERED_SKUS[index] : data[index]?.id;
      if (imgSliderRef?.current?.onSelectFarmstand && imageId) {
        const SHOULD_TRACK = false;
        imgSliderRef.current.onSelectFarmstand(imageId, SHOULD_TRACK);
      }
    });
  };

  const onClickFarmSize = (index) => () => {
    const size = data[index]?.size;
    handleImageSlider(index);
    if (isBundlePage && size) dispatch(setSelectedBundleSize(size));
    onSelectFarmstand && onSelectFarmstand(index);
    const href = isGlowRing ? `${paths.SHOP_PRODUCTS}/${glowRingSlugs[index]}` : defaultSize ? `${paths.FARMSTAND}` : null;
    if (href) history.push(href);
  };

  const handleOpenSizeModal = () => {
    setIsSizeModalOpen(true);
  };

  const handleSizeModalClose = () => {
    setIsSizeModalOpen(false);
  };

  const renderSizeButton = (button, index) => {
    const isSelected = selectedFarm?.toString() === button?.toString();
    const modifiers = [isSelected ? 'primaryNoHover' : 'invertedNoHover'];

    return (
      <SelectSizeButton
        key={index}
        modifiers={modifiers}
        label={button}
        dataNw={`farmstand-size-${button}`}
        onClick={onClickFarmSize(index)}
        ariaLabel={`${button}-plant farmstand`}
        ariaSelected={isSelected ? 'true' : 'false'}
        eventData={{ action: `Farmstand Size: ${button}` }}
      />
    );
  };
  return (
    <>
      {defaultSize && (
        <Head title={seoData[defaultSize]?.title}>
          <script type='application/ld+json'>{seoData[defaultSize]?.googleMerchant}</script>
        </Head>
      )}
      <FarmstandSizeStyled>
        <Text modifiers={['brandFont', 'bold', 'small']} content={title} as='span' />
        &nbsp;
        {sizeModalContent?.fields?.title && (
          <ButtonText label={sizeModalContent?.fields?.title} onClick={handleOpenSizeModal} modifiers={['underline', 'compact', 'bold']} />
        )}
        <SizeButtonGroup>{farms.map(renderSizeButton)}</SizeButtonGroup>
        <LabelWrapper>
          {isGlowRing && (
            <Text modifiers={[!hasFarmstand && 'midGrayColor', 'brandFont']} isHMTL content={glowRingsNumberLabel + '\u2122'}></Text>
          )}
        </LabelWrapper>
      </FarmstandSizeStyled>
      {sizeModalContent?.fields?.title && (
        <SizeModal
          isOpen={isSizeModalOpen}
          onClose={handleSizeModalClose}
          title={sizeModalContent?.fields?.headline}
          body={sizeModalContent?.fields?.body}
        />
      )}
    </>
  );
};

FarmstandSize.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  glowRingSlugs: PropTypes.array,
  onSelectFarmstand: PropTypes.func,
  imgSliderRefs: PropTypes.array,
  glowRingsNumberLabel: PropTypes.string,
  isGlowRing: PropTypes.bool,
  hasFarmstand: PropTypes.bool,
  isBundlePage: PropTypes.bool,
  defaultSize: PropTypes.string, // for size path in URL in statically generated page
  farmIndex: PropTypes.number,
  sizeModalContent: PropTypes.object,
};

export default FarmstandSize;
