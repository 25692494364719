import { getField } from './contentful-utils';

export const getSeedlingsListFromBundle = (item, content) => {
  if (!item || !content?.length) return [];

  const bundleSeedlingsList = content?.find?.((e) => e.sys.id === item.sku)?.fields?.plantsLinks;

  const reduceSeedlingsToQty = (acc, e) => {
    if (acc[e.sys.id]?.qty) acc[e.sys.id].qty += 1;
    else acc[e.sys.id] = { name: e.fields?.friendlyName, qty: 1, sku: e.sys?.id };
    return acc;
  };
  const seedlingsQty = bundleSeedlingsList?.reduce?.(reduceSeedlingsToQty, {}) || {};
  return Object.values(seedlingsQty) || [];
};

export const getSeedlingsListFromCustomBundle = (skus = [], content = [], catalog = {}) => {
  if (!skus.length || (!content?.length && !Object.keys(catalog || {}).length)) return [];

  return skus.reduce((acc, sku) => {
    const contentName = content?.find((e) => e.sys.id === sku)?.fields?.friendlyName;
    const catalogName = catalog?.[sku]?.name;
    const seedlingRegistry = acc.find((e) => e?.sku === sku);

    if (!seedlingRegistry) {
      let defaultName = 'Seedling';
      if (contentName) {
        defaultName = contentName;
      }
      if (catalogName) {
        defaultName = catalogName;
      }
      acc.push({ sku, name: defaultName, qty: 1 });
    } else {
      seedlingRegistry.qty += 1;
    }
    return acc;
  }, []);
};

export const getBundleQtyOfSubcategory = ({ skus = [], catalog = {} }) => {
  const qtyMap = skus.reduce((acc, sku) => {
    const skuSubcategory = catalog[sku]?.subcategory;
    if (!skuSubcategory) return acc;

    if (!acc[skuSubcategory]) {
      acc[skuSubcategory] = {
        name: skuSubcategory,
        qty: 1,
      };
    } else acc[skuSubcategory].qty += 1;

    return acc;
  }, {});

  return Object.values(qtyMap);
};

export const formatSeedlingsList = (seeds = []) => {
  return seeds?.reduce?.((acc, seed, i) => `${acc}${i > 0 ? ', ' : ''}${seed.name ? seed.name : 'Seedling'} (${seed.qty})`, '') || '';
};

export const bundleFeaturedSort = (content = []) => (a, b) => {
  const isAFeatured = !!getField(a, 'isBundleFeatured', content);
  const isBFeatured = !!getField(b, 'isBundleFeatured', content);
  if (isAFeatured && !isBFeatured) return -1;
  else if (!isAFeatured && isBFeatured) return 1;
  else return 0;
};

export const bundleEnvSort = (content = []) => (a, b) => {
  const isAIndoor = !!getField(a, 'indoorApproved', content);
  const isBIndoor = !!getField(b, 'indoorApproved', content);
  if (isAIndoor && !isBIndoor) return -1;
  else if (!isAIndoor && isBIndoor) return 1;
  else return 0;
};
