const icons = {
  ARROW_RIGHT: 'arrow-right',
  ARROW_LEFT: 'arrow-left',
  ARROW_DOWN: 'full-arrow-down',
  CART: 'cart',
  CHECK: 'check',
  CONTACT_US: 'contact-us',
  DASHES: 'dashes',
  DIFFICULTY: 'difficulty',
  DRAW_CURVE_ARROW_LEFT: 'draw-curve-arrow-left',
  DRAW_CURVE_ARROW_RIGHT: 'draw-curve-arrow-right',
  DRAW_ARROW_RIGHT: 'draw-arrow-right',
  LOOP_ARROW_RIGHT: 'loop-arrow-right',
  DRAW_ARROW_CHEVRON_RIGHT: 'draw-arrow-chevron-right',
  CHEVRON_RIGHT: 'cal-arrow-right',
  CHEVRON_LEFT: 'cal-arrow-left',
  CHEVRON_UP: 'cal-arrow-up',
  CHEVRON_DOWN: 'cal-arrow-down',
  CLOSE: 'close',
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  INSTAGRAM_THIN: 'instagram-thin',
  YOUTUBE_THIN: 'youtube-thin',
  FACEBOOK_THIN: 'facebook-thin',
  TWITTER_THIN: 'twitter-thin',
  PLUS_THIN: 'plus-thin',
  MINUS_THIN: 'minus-thin',
  MEASUREMENT: 'measurement',
  INFO: 'info',
  PROMO: 'promo',
  PRINT: 'print',
  RESIZE: 'resize',
  SERVINGS: 'servings',
  TIMER: 'timer',
  QUOTATION_MARK: 'quotation-mark',
  PAUSE_PACKAGE: 'pause-package',
  WARNING: 'warning',
  LOCATION: 'location',
};

icons.ALL = Object.values(icons);

export default icons;
