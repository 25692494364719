const positions = {
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
    BOTTOM: 'bottom',
    BOTTOM_RIGHT: 'bottom-right',
    BOTTOM_LEFT: 'bottom-left',
};

positions.ALL = Object.values(positions);

export default positions;
