import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

import { Text, Link } from 'elements';

import { AffirmWrapper } from './AffirmMessage.styled';

const AffirmMessage = ({ priceCents = 0 }) => {
  useEffect(() => {
    const onAmountChange = (newAmount) => {
      if (newAmount) return window?.affirm?.ui?.refresh?.();
    };

    onAmountChange(priceCents);
  }, [priceCents]);

  if (!priceCents || typeof priceCents !== 'number') return null;

  return (
    <AffirmWrapper>
      <Link
        modifiers={['fontFamilyText', 'medium', 'darkGrayColor', 'underline']}
        className='affirm-product-modal'
        dataAttributes={{
          'data-page-type': 'cart',
          'data-amount': priceCents,
        }}
        eventData={{ action: `Affirm: Learn More` }}
      >
        <Text>As low as ${Math.ceil(priceCents / 100 / 12)}/month with</Text>
        <StaticImage src='../../app/pages/checkout/assets/affirm.png' alt='affirm logo' placeholder='blurred' />
      </Link>
    </AffirmWrapper>
  );
};

AffirmMessage.propTypes = {
  priceCents: PropTypes.number.isRequired,
};

export default AffirmMessage;
