import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';

import { fetchCatalog, getCatalog } from 'reduxState/catalog';
import { getLoginState } from 'reduxState/user';

/**
 * * useCatalog - Custom hook to fetch data from catalog, store in redux
 *
 * @return {object} catalog data from redux
 * */
export default function useCatalog() {
  // Get redux dispatch function
  const dispatch = useDispatch();
  const catalog = useSelector(getCatalog);
  const user = useSelector((state) => state.user);
  const isLoggedIn = useSelector(getLoginState);
  const location = useLocation();
  const didMount = useRef(false);

  // Only re-fetch catalog when certain user values change after initial mount
  useEffect(() => {
    if (catalog.isCatalogFetched && didMount.current) {
      const payload = { zip: user.zip, email: user.email, isLoggedIn, isZipOverride: true, environment: user.userSetEnvironment };
      dispatch(fetchCatalog(payload));
    } else if (!didMount.current) didMount.current = true;
    /* eslint-disable-next-line */
  }, [dispatch, user.zip, user.email, user.userSetEnvironment, isLoggedIn]);

  // Only fetch catalog on page navigation if catalog and is not yet fetched
  useEffect(() => {
    if (!catalog.isCatalogFetched && !user.isAuthenticationPending) {
      const payload = { zip: user.zip, email: user.email, isLoggedIn, isZipOverride: false, environment: user.userSetEnvironment };
      dispatch(fetchCatalog(payload));
    }
    /* eslint-disable-next-line */
  }, [dispatch, location, user.isAuthenticationPending]);

  return catalog;
}
