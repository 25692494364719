import styled from 'styled-components/macro';
import breakPoints from 'theme/Media';

export const FormWrapper = styled.div.attrs({
  className: 'FormWrapper',
})`
  padding: 0;
  width: 100%;
`;

export const FormGroup = styled.div.attrs({
  className: 'FormGroup',
})`
  margin-bottom: 1rem;

  .PhotoContainer {
    a {
      color: ${({ theme }) => theme.farmProject.brand_primary};
    }
    .Text:first-of-type {
      line-height: normal;
    }
    .PhotoWrapper {
      overflow: hidden;
    }
  }

  .FarmstandSizeStyled,
  .EnvSelectorStyled,
  .HeaterSelectorStyled,
  .LightsSelectorStyled {
    .Text {
      align-self: flex-start;
      text-align: left;
    }
    .Button {
      &:not(:last-of-type) {
        margin: 0 0.5rem 0 0;
      }
      &:last-of-type {
        margin: 0;
      }
    }
    .SelectSizeButton {
      width: 3rem;
      height: 3rem;
    }
  }

  .InputStyled {
    div {
      width: 100%;
      max-width: 100%;
    }
  }

  .buttonGroup {
    margin-top: 0.25rem;

    .ButtonText {
      padding: 0;
      margin-bottom: 0.5rem;
      span {
        color: ${({ theme }) => theme.farmProject.red};
      }
    }

    .ButtonText {
      padding: 0;
      margin-bottom: 0.5rem;
      span {
        color: ${({ theme }) => theme.farmProject.red};
      }
    }

    .Button {
      width: 100%;
      max-width: 13rem;
      height: 2.5rem;
      margin: 0;

      span {
        font-size: 0.8rem;
      }
    }

    .CancelButton {
      border-color: #000;
      margin-right: 0.5rem;
    }

    .submitButton {
      margin-left: 0.5rem;
    }
  }
`;

export const WrapFields = styled.div.attrs({
  className: 'WrapFields',
})`
  padding-left: 0;
  padding-right: 0;
  height: 30rem;
  overflow-y: auto;

  .AddPhotoBtnGroup {
    justify-content: center;
    margin: 0;
    max-width: none;

    .AddPhotoBtn {
      border: none;
      width: 7rem;
      height: 5rem;
    }
  }

  @media ${breakPoints.medium_break} {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .InputStyled {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const TextWrapper = styled.div.attrs({
  className: 'TextWrapper',
})`
  margin: auto;

  .Title {
    margin-top: 2rem;
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
  }

  h3 {
    font-size: 1rem;
  }

  @media ${breakPoints.medium_break} {
    .Title {
      margin-top: 3.5rem;
    }
  }
`;
