import React from 'react';
import PropTypes from 'prop-types';

import { Title, Text, Icon } from 'elements';
import { StarRating } from 'components';

import { UserReviewCardStyled, NameCheck } from './UserReviewCard.styled';

import Check from 'elements/icon/assets/tick.svg';
const UserReviewCard = ({ userName, userLocation, userReview }) => {
  return (
    <UserReviewCardStyled>
      <StarRating readOnly={true} rating={5} modifiers={['yellowColor', 'smallStars']} />
      <NameCheck>
        <Title as='h5' modifiers={['xxSmall', 'bold']} content={userName} />
        <Icon modifiers={['xSmall', 'rounded']}>
          <Check />
        </Icon>
      </NameCheck>

      {!!userLocation && <Text as='span' modifiers={['brandFont', 'lightGrayColor', 'small', 'left']} content={userLocation} />}
      <Text as='span' modifiers={['left', 'noLineHeight']} content={userReview} />
    </UserReviewCardStyled>
  );
};

UserReviewCard.prototype = {
  userName: PropTypes.string,
  userLocation: PropTypes.string,
  userReview: PropTypes.string,
};

export default UserReviewCard;
