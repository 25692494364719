import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import { Accordion, Flex } from 'components';
import { Link, GatsbyImage, ButtonText, Input, Icon } from 'elements';
import SearchIcon from 'elements/icon/assets/search.svg';
import Account from '../account/Account';
import PlusIcon from 'elements/icon/assets/plus.svg';
import MinusIcon from 'elements/icon/assets/minus.svg';

import paths from 'constants/paths';

const MobileNav = ({ links, activeNavLink, onLinkClick }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (ev) => {
    setSearchTerm(ev.target.value);
  };

  const handleEnter = (ev) => {
    if (ev?.keyCode === 13) {
      handleSearch();
    }
  };

  const handleSearch = () => {
    navigate(`${paths.SEARCH_RESULTS}?q=${searchTerm}`);
  };

  const isLinkActive = (link) => {
    return link.title?.toUpperCase() === activeNavLink?.toUpperCase();
  };

  const getSubLinks = (ctas) => {
    return ctas.map((cta) => {
      if (cta.accent) {
        return (
          <Link key={cta.linkUrl} href={cta.linkUrl} className='SubLinkImage'>
            <GatsbyImage image={cta.accent.gatsbyImageData} />
            <ButtonText key={cta.linkText} label={cta.linkText} href={cta.linkUrl} modifiers={['secondary', 'small']} />
          </Link>
        );
      }
      return <ButtonText key={cta.linkText} label={cta.linkText} href={cta.linkUrl} modifiers={['secondary', 'small']} />;
    });
  };
  return (
    <Flex className='MobileNav' modifiers={['alignCenter', 'directionColumn']}>
      <Flex modifiers={['alignCenter']} className='Search'>
        <Input value={searchTerm} onKeyDown={handleEnter} onChange={handleSearchChange} modifiers='clear' placeholder='Search ...' />
        <Icon modifiers='small' onClick={handleSearch}>
          <SearchIcon />
        </Icon>
      </Flex>
      <ul className='MobileNavPageLinks'>
        {links.map((link) => {
          const subLinks = getSubLinks(link.callToActions);
          const hasImagesInSubLinks = link.callToActions.find((cta) => cta.accent);
          return (
            <React.Fragment key={link.title}>
              <Flex as='li' modifiers={['spaceBetween', 'alignCenter']}>
                <ButtonText label={link.title?.toUpperCase()} href={link.subTitle?.subTitle} modifiers={'small'} />
                {!hasImagesInSubLinks && (
                  <Icon modifiers='small' onClick={onLinkClick(link.title)}>
                    {isLinkActive(link) ? <MinusIcon /> : <PlusIcon />}
                  </Icon>
                )}
              </Flex>
              {hasImagesInSubLinks ? (
                <Flex className='SubLinkWrapper'>{subLinks}</Flex>
              ) : (
                <Accordion content={subLinks} isOpen={isLinkActive(link)} clearance={16} />
              )}
            </React.Fragment>
          );
        })}
      </ul>
      <Account />
    </Flex>
  );
};

MobileNav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subTitle: PropTypes.object,
      callToActions: PropTypes.arrayOf(
        PropTypes.shape({
          linkText: PropTypes.string,
          linkUrl: PropTypes.string,
          accent: PropTypes.object,
        })
      ),
    })
  ),
  activeNavLink: PropTypes.string,
  onLinkClick: PropTypes.func,
};

export default MobileNav;
