import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

import breakpoints from 'constants/breakpoints';

import newBreakpoints from 'theme/Media';

import SectionHeadline from 'components/deprecated/SectionHeadline';

import { Icon } from 'elements';
import CloseIcon from 'elements/icon/assets/close.svg';

const modifiers = {
  left: (props) => ({
    styles: `
      right: auto;
      left: ${props.isOpen ? '0' : '-100vw'};
      transition: left 300ms ease-in;
    `,
  }),
};

const Container = styled.div`
  touch-action: manipulation;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: fixed;
  z-index: 2023;
  top: 0rem;
  right: ${(props) => (props.isOpen ? '0' : '-100vw')};
  height: 100%;
  width: 100%;
  padding: 10px 20px 0 20px;
  background: #fff;
  transition: right 300ms ease-in;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  -ms-overflow-style: none;
  background-color: ${({ theme, useBackgroundTheme }) => (useBackgroundTheme ? theme.farmProject.gray_2 : 'white')};
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  @media ${newBreakpoints.large_break} {
    top: 0;
    right: ${(props) => (props.isOpen ? '0' : '-33vw')};
    width: 33vw;

    .FooterContainer {
      width: 33vw;
    }
  }
  ${applyStyleModifiers(modifiers)};
`;

const Wrapper = styled.div.attrs({
  className: 'Wrapper',
})`
  overflow: visible;
  padding-bottom: 20rem;

  .PromoCodeContainer {
    border: none;
    border-top: 2px solid ${({ theme }) => theme.farmProject.light_gray};
    padding-top: 1.5rem;
  }
`;

const HeaderWrapper = styled.div`
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const Header = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  padding-top: ${(props) => (props.headerPaddingTop ? props.headerPaddingTop : '0.5rem')};

  padding-bottom: 14px;
  border-bottom: 2px solid ${({ theme }) => theme.farmProject.light_gray};

  button {
    position: absolute;
    right: 0;
    font-size: 2rem;
    border: none;
    padding: 0;
    cursor: pointer;
    text-rendering: auto;
    background: transparent;
    color: ${({ theme }) => theme.farmProject.light_gray};

    .Icon {
      pointer-events: none;
      width: 1.25rem;
      height: 1.25rem;
      polygon {
        fill: ${({ theme }) => theme.farmProject.dark_gray};
      }
    }
  }
`;

const Headline = styled(SectionHeadline)`
  font-size: 1.375rem;
  font-weight: normal;
  font-family: ${({ theme }) => theme.farmProject.font_family};
  letter-spacing: 2.2px;
  line-height: normal;
  color: ${({ theme }) => theme.farmProject.dark_gray};

  @media (min-width: ${breakpoints.MOBILE}px) {
    font-size: 1.5rem;
  }
`;

/**
 * * Side Drawer component for Lettuce Grow shopping experience
 *
 * @param {bool} isOpen - is the drawer open or closed?
 * @param {string} headline - the text displayed at the top of the side drawer, when it's open
 * @param {string} headerPaddingTop - padding to be used for the top of the header
 * @param {function} closeModal - function to handle the closing of the react modal in which the side drawer is contained
 * @param {element} children - HTML/react elements we want to render inside our side drawer, underneath our header
 * @param {string, array} modifiers
 */

const SideDrawer = ({ closeModal, isOpen, children, headline, headerPaddingTop, useBackgroundTheme, modifiers }) => {
  return (
    <Container key='side-drawer' isOpen={isOpen} useBackgroundTheme={useBackgroundTheme} modifiers={modifiers}>
      <Wrapper>
        <HeaderWrapper className='headerWrapperDrawer'>
          <Header className='headerDrawer' headerPaddingTop={headerPaddingTop}>
            <button onClick={closeModal}>
              <Icon>
                <CloseIcon />
              </Icon>
            </button>
            <Headline className='headlineDrawer'>{headline}</Headline>
          </Header>
        </HeaderWrapper>
        {children}
      </Wrapper>
    </Container>
  );
};

SideDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  headerPaddingTop: PropTypes.string,
  headline: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]),
  useBackgroundTheme: PropTypes.bool,
  modifiers: PropTypes.any,
};

export default SideDrawer;
