import { environment } from 'utils/envConfig';
import { isAxiosError } from 'axios';

// Do not log third-party requests with status 0 (cancelled/aborted) to logRocket
const getIsIgnored = (error) => {
  return (
    (error?.response?.status === 0 || error?.request?.status === 0) &&
    !error?.config?.url?.includes('lettucegrow') &&
    !error?.config?.url?.includes('app/')
  );
};

export default function logError(error) {
  let readableError;
  if (isAxiosError(error)) {
    const errData = error.toJSON();
    readableError = errData;
  } else {
    readableError = error;
  }
  if (environment === 'production') {
    if (getIsIgnored(error)) return;
    window.LogRocket && window.LogRocket.captureException(readableError);
  }

  console.error(readableError);
}
