import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LGTagManager from 'utils/googleTagManager';

import { getLoginState, getEmail, getCAPIUserInfo } from 'reduxState/user';

/**
 * * useGoogleTagManager - Custom hook push data to the dataLayer for GTM
 * @param {string} event - this is the string we check for in our custom trigger in the 'Event name' field of GTM
 * @param {string} url - this string is pushed to the 'url' key in the dataLayer. It can be pulled into a Google Tag with the 'dlv - url' variable
 * @param {bool} fireOn - the condition on which data should be pushed to the data layer
 * @param {object} params - some events require us to pass along additional key/value pairs so we do that with this parameter
 */

const useGoogleTagManager = (event, url, params, shouldFire = true) => {
  const _params = params || {};
  const userEmail = useSelector(getEmail);
  const capiData = useSelector(getCAPIUserInfo);
  const isLoggedIn = useSelector(getLoginState);

  useEffect(() => {
    if (shouldFire) {
      if (event === 'Cartview') {
        // need to push this as a pageView for gtm to register cart views as a pageview
        LGTagManager.pushPath('/modal/cart', _params, userEmail, isLoggedIn, capiData);
      }
      const dataLayerObject = {
        event,
        url,
      };
      for (let key in _params) {
        if (Object.prototype.hasOwnProperty.call(_params, key)) {
          dataLayerObject[key] = _params[key];
        }
      }
      window.dataLayer?.push(dataLayerObject);
    }
  }, [shouldFire]); // eslint-disable-line
};

export default useGoogleTagManager;
