import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const ModalReviewWrapper = styled.div.attrs({
  className: 'ModalReviewWrapper',
})`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 2.5rem;
  max-width: 20rem;

  @media ${breakPoints.medium_break} {
    padding: 2.5rem 1rem 0;
    max-width: 30rem;
  }

  @media ${breakPoints.large_break} {
    padding: 2.5rem 1rem;
    max-width: 60rem;
    flex-direction: row;
    justify-content: center;
  }

  .Image {
    max-height: 35rem;

    picture {
      img {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }
    }

    @media ${breakPoints.medium_break} {
      picture {
        img {
          margin: auto;
        }
      }
    }

    @media ${breakPoints.large_break} {
      picture {
        img {
          max-width: 35rem;
        }
      }
    }
  }

  .Review {
    padding-top: 1.5rem;
    max-width: 25rem;
    margin: auto;
    width: 100%;
    margin-left: 0;

    @media ${breakPoints.large_break} {
      flex-direction: column;
      box-sizing: border-box;
      flex-wrap: nowrap;
      width: 50%;
      justify-content: flex-start;
      padding-left: 1rem;
      padding-top: 0;
      height: 100%;
      margin-top: 0;
    }

    .CustomerInfo {
      margin-bottom: 1rem;
      width: 100%;
      box-sizing: border-box;

      .ProductInfo {
        padding-top: 0;
      }
    }

    .ReviewContentStyled {
      margin-top: 0;
      width: 100%;
      display: none;

      .Text {
        margin-bottom: 0.25rem;
      }

      .Text + .Text {
        margin-top: 0;
      }

      @media ${breakPoints.large_break} {
        display: block;
      }
    }

    .ReviewFooterStyled {
      margin-top: 1.5rem;
      display: none;

      @media ${breakPoints.large_break} {
        display: flex;
      }
    }
  }
`;

export const SliderWrapper = styled.div.attrs({
  className: 'SliderWrapper',
})`
  box-sizing: border-box;
  width: 100%;

  @media ${breakPoints.large_break} {
    width: 50%;
  }

  .swiper-container {
    .swiper-button-prev {
      top: 47%;
      left: -3px;
      width: 1.8rem;
      height: 1.6rem;

      &::after {
        width: 2rem;
        height: 2rem;
        content: url('icons/left-white.svg');
      }
    }

    .swiper-button-next {
      top: 47%;
      right: 6px;
      width: 1.8rem;
      height: 1.6rem;

      &::after {
        width: 2rem;
        height: 2rem;
        content: url('icons/right-white.svg');
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: white;
      }
    }
  }
`;
