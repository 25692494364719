import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components/macro';

import { ButtonText, Link, Image, ResponsiveEmbed } from 'elements';
import Text from 'components/deprecated/elements/Text';

import sizes from 'constants/sizes';

const getMessageByCode = (code, content) => {
  if (!content) return;
  const message = content[code];
  return message ? message : '';
};

const ExtendedButton = styled(ButtonText)`
  margin: 3em 0;
  text-align: center;
`;

const ExtendedImage = styled(Image)`
  max-width: 100%;
`;

const parseRichText = (richText, references) => {
  // create an reference map
  // https://www.contentful.com/blog/2021/04/14/rendering-linked-assets-entries-in-contentful/
  const referenceMap = new Map();

  if (references) {
    // loop through the assets and add them to the map
    for (const reference of references) {
      referenceMap.set(reference.contentful_id, reference);
    }
  }

  const options = {
    renderNode: {
      [BLOCKS.HEADING_6]: (node, children) => {
        return <h6 dangerouslySetInnerHTML={{ __html: children[0] }} />;
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return <h5 dangerouslySetInnerHTML={{ __html: children[0] }} />;
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return <h4 dangerouslySetInnerHTML={{ __html: children[0] }} />;
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 dangerouslySetInnerHTML={{ __html: children[0] }} />;
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 dangerouslySetInnerHTML={{ __html: children[0] }} />;
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return <h1 dangerouslySetInnerHTML={{ __html: children[0] }} />;
      },
      [BLOCKS.QUOTE]: (node) => {
        return <blockquote dangerouslySetInnerHTML={{ __html: get(node, 'content[0].content[0].value') }} />;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return children.map((child, idx) => {
          if (child.props && child.props.href) {
            return (
              <Link key={idx} modifiers={['darkGrayColor', 'fontFamilyText', 'underline']} href={child.props.href}>
                {child.props.children}
              </Link>
            );
          }
          return (
            <Text key={idx} size={sizes.SMALLISH} asHTML={typeof child === 'string'} isInline>
              {child}
            </Text>
          );
        });
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (references) {
          const reference = referenceMap.get(node.data.target.sys.id);

          return <Image picture={reference.file?.url} alt={reference.title} className={reference.description} />;
        } else {
          return (
            <Image
              picture={node.data.target.fields?.file?.url}
              alt={node.data.target.fields?.title}
              className={node.data.target.fields?.description}
            />
          );
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (references) {
          const reference = referenceMap.get(node.data.target.sys.id);
          const referenceType = reference.__typename;
          if (referenceType === 'ContentfulCallToAction') {
            if (!_isEmpty(reference.accent)) {
              return (
                <Link href={reference.linkUrl}>
                  <ExtendedImage picture={reference.accent.file.url} alt='' className='BlogCta' />;
                </Link>
              );
            } else {
              return <ExtendedButton label={reference.linkText} href={reference.linkUrl} />;
            }
          } else {
            return <ResponsiveEmbed embedCode={reference.embedCode.embedCode} />;
          }
        } else {
          const entry = node.data.target;
          const entryType = entry ? get(entry, 'sys.contentType.sys.id') : null;
          if (entryType === 'callToAction') {
            if (!_isEmpty(entry.fields.accent)) {
              return (
                <Link href={entry.fields.linkUrl}>
                  <ExtendedImage picture={entry.fields.accent.fields.file.url} alt='' className='BlogCta' />;
                </Link>
              );
            } else {
              return <ExtendedButton label={entry.fields.linkText} href={entry.fields.linkUrl} />;
            }
          } else {
            return <ResponsiveEmbed embedCode={node.data.target.fields.embedCode} />;
          }
        }
      },
    },
  };

  return documentToReactComponents(richText, options);
};

export const getField = (item = {}, fieldName = '', content = []) => {
  const fields = item?.[fieldName] ? item : item?.fields ? item.fields : content?.find?.((e) => e.sys.id === item?.sku)?.fields;
  return fields?.[fieldName] || null;
};

export const getImageUrl = (imageField = {}) => imageField?.fields?.file?.url || '';

export const getContentfulEntryType = (entry = {}) => entry?.sys?.contentType?.sys?.id || '';

export const getContentfulEntryLgType = (entry = {}) => entry?.fields?.lgType?.fields.lgTypeId || '';

// HEADS UP - this includes a video embed <iframe> as a video asset
export const isVideoAsset = ({ file }) => file?.contentType?.includes('video') || file?.contentType?.includes('text/html');

export { getMessageByCode, parseRichText };
