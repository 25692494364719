import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';
import { navigate } from 'gatsby';

import { prodLinkPathRegex } from 'utils/pattern-utils';
import TextBase, { modifiers as textModifiers } from '@lettucegrow/atoms.text';
import { trackClick } from 'utils/googleTagManager';

const Text = ({ id, className, content, children, modifiers, as, isHTML, size, onRef, dataNw }) => {
  const handleLinkClick = (href, target, innerHTML) => (ev) => {
    const isHardCodedProdLink = href?.includes('lettucegrow.com') && !href?.includes('@');
    const isNewTab = target?.includes('blank');
    const isExternalOrActionLink = href && (href?.includes('http') || !href?.startsWith('/'));
    ev.preventDefault();

    innerHTML && trackClick({ action: innerHTML });

    if (isNewTab) {
      window.open(href);
    } else if (isHardCodedProdLink) {
      const pathPattern = prodLinkPathRegex;
      const path = href.match(pathPattern);
      path && path[1] && navigate(path[1]);
    } else if (isExternalOrActionLink) {
      window.location.href = href;
    } else {
      href && navigate(href);
    }
  };

  return (
    <TextBase
      id={id}
      className={className}
      modifiers={modifiers}
      content={content}
      as={as}
      isHTML={isHTML}
      size={size}
      onRef={onRef}
      dataNw={dataNw}
      onLinkClick={handleLinkClick}
    >
      {children}
    </TextBase>
  );
};

Text.defaultProps = {
  id: '',
  content: '',
  modifiers: '',
  as: '',
  size: '',
  dataNw: null,
};

Text.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element, PropTypes.number]),
  children: PropTypes.any,
  modifiers: styleModifierPropTypes(textModifiers),
  as: PropTypes.string,
  size: PropTypes.string,
  onRef: PropTypes.func,
  dataNw: PropTypes.string,
  isHTML: PropTypes.bool,
};

export default Text;
