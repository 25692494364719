import { useEffect } from 'react';
import * as Yup from 'yup';
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import { v1 as uuidv1 } from 'uuid';

import { gcsUrlPrefix, authDomain, lgUrlPrefix } from 'utils/envConfig';
import { setCartValue, verifyAddress, checkoutSucceeded } from 'reduxState/cart';
import useContent from 'utils/hooks/useContent';
import { phoneNumberRegex } from 'utils/pattern-utils';
import { TERMS_OF_SERVICE, GIFT_CARD } from 'constants/contentful';
import eventTrackingTypes from 'constants/eventTrackingTypes';
import { dbFormatPhone } from 'utils/form-utils';
import { formatForUTCString } from 'utils/date-utils';
import { fetchMyFarm, fetchUserDevices } from 'reduxState/user';

import paths from 'constants/paths';
import shopCategories from 'constants/shopCategories';
import storageKeys from 'constants/storageKeys';
import { getStorage } from 'utils/storageManager';
import { trackEnhancedPurchase, trackClick, trackFarmstandPurchase, trackBuyablePurchase } from 'utils/googleTagManager';
import { shopSubCategories } from 'constants/index';
import growingEnvironments from 'constants/growingEnvironments';

const getShippingAddress = ({ shouldOverrideAddress, values, cart }) => {
  return {
    line1: shouldOverrideAddress ? values.address : cart.verifiedAddress.address,
    line2: shouldOverrideAddress ? values.postStop : cart.verifiedAddress.postStop,
    city: shouldOverrideAddress ? values.city : cart.verifiedAddress.city,
    state: shouldOverrideAddress ? values.state : cart.verifiedAddress.state,
    zipcode: shouldOverrideAddress ? values.zip : cart.verifiedAddress.zip,
  };
};

/**
 * * createStripeOrder - Returns the order object for Stripe orders
 * @param {*} values - values from checkout form
 * @param {bool} shouldOverrideAddress - flag to show ignoring verify address suggestion
 * @param {*} stripeToken - stripe token
 */
export function createStripeOrder(
  values,
  shouldOverrideAddress,
  stripeToken,
  cart,
  user,
  reservation,
  catalogEnvironment,
  isPOSOrder = false
) {
  const userEnvironment = user.userSetEnvironment === 'DEFAULT' ? catalogEnvironment : user.userSetEnvironment;
  const sendOnEpochDate = formatForUTCString(values.sendOn);
  const shippingAddress = getShippingAddress({ shouldOverrideAddress, values, cart });
  const hasFarmV2 = !!cart.items.find((e) => e?.subcategory === shopSubCategories.NOOK);

  // Create the order checkout object
  const order = {
    //shipping contact
    shipping: {
      name: {
        first: values.firstName,
        last: values.lastName,
      },
      address: shippingAddress,
      email: values.email,
      phone_number: dbFormatPhone(values.phone.trim()),
    },

    // Metadata
    metadata: {
      termsOfServiceVersion: cart.termsOfServiceVersion,
      addressOverride: shouldOverrideAddress,
      guest: user.isGuest,
      mobileWeb: user.isFromMobile,
      reservation: reservation.code,
      shippingOptions: cart.selectedShippingOptions.reduce((acc, option) => {
        acc[option.category] = option.option.id;
        return acc;
      }, {}),
      // Gifting options
      toEmail: cart.isGift ? values.toEmail : '',
      message: cart.isGift ? values.message : '',
      sendOn: cart.isGift ? sendOnEpochDate.valueOf() : '',
      toName: cart.isGift ? values.toName : '',
      fromName: cart.isGift ? values.fromName : '',
      notifyGiftReceiver: cart.isGift ? values.notifyGiftReceiver : '',
      env: hasFarmV2 ? growingEnvironments.INDOOR : userEnvironment,
    },

    // cart
    items: [],
    // stripe token
    ...(isPOSOrder ? {} : { stripeToken }),

    // if BE should do validations for possible duplicated orders or not
    checkForDuplicateOrder: false,
  };

  // Go through cart and build the line items
  cart.items.forEach((item) => {
    if (item.sku === GIFT_CARD.id) {
      order.items.push({
        sku: item.sku,
        qty: item.qty,
        meta: {
          imageUrl: item.imageUrl,
          amountCents: item.priceCents,
        },
      });
    } else {
      order.items.push({
        sku: item.sku,
        qty: item.qty,
      });
    }
  });

  // Check for promocodes / gift cards
  const codes = Object.keys(cart.discounts);
  if (codes.length) {
    order.discounts = {};
    codes.forEach((code) => {
      order.discounts[code] = {
        discount_amount: 0,
      };
    });
  }

  // return the order object
  return order;
}

/**
 * * createAffirmOrder - Returns the order object for Affirm orders
 * @param {*} values - values from checkout form
 * @param {bool} shouldOverrideAddress - flag to show ignoring verify address suggestion
 */
export function createAffirmOrder(values, shouldOverrideAddress, cart, user, reservation) {
  // Create the order checkout object
  const order = {
    // Affirm stuff
    config: {
      financial_product_key: 'WL9NMQA1MYHL6BXX', // LG Affirm financial product key
    },
    merchant: {
      // If gcsUrlPrefix = '' then we're on local. Need to set to staging so we can test on local
      user_confirmation_url: `${gcsUrlPrefix ? gcsUrlPrefix : 'https://staging.lettucegrow.com'}/app/affirm/confirmCart`,
      user_cancel_url: lgUrlPrefix + paths.CHECKOUT,
      user_confirmation_url_action: 'POST',
    },

    //shipping contact
    shipping: {
      name: {
        first: values.firstName,
        last: values.lastName,
      },
      address: getShippingAddress({ shouldOverrideAddress, cart, values }),
      email: values.email,
      phone_number: dbFormatPhone(values.phone.trim()),
    },

    // Metadata
    metadata: {
      // mode: 'modal', // Sets Affirm to modal mode
      authDomain: authDomain,
      termsOfServiceVersion: cart.termsOfServiceVersion,
      addressOverride: shouldOverrideAddress,
      guest: user.isGuest,
      mobileWeb: user.isFromMobile,
      reservation: reservation.code,
      shippingOptions: cart.selectedShippingOptions.reduce((acc, option) => {
        acc[option.category] = option.option.id;
        return acc;
      }, {}),
      // Gifting options
      toEmail: cart.isGift ? values.toEmail : '',
      fromName: cart.isGift ? values.fromName : '',
      message: cart.isGift ? values.message : '',
      sendOn: cart.isGift ? formatForUTCString(values.sendOn).valueOf() : '',
      toName: cart.isGift ? values.toName : '',
      notifyGiftReceiver: cart.isGift ? values.notifyGiftReceiver : '',
    },

    // cart
    items: [],

    // pricing / charge amount
    currency: 'USD',

    tax_amount: cart.taxCents,
    shipping_amount: cart.shippingCents,
    total: cart.totalCents,
  };

  // Go through cart and build the line items
  cart.items.forEach((item) => {
    if (item.sku === GIFT_CARD.id) {
      // push gift Card item. need meta
      order.items.push({
        sku: item.sku,
        display_name: item.name,
        unit_price: item.priceCents,
        qty: item.qty,
        item_image_url: item.imageUrl,
        item_url: gcsUrlPrefix + '/the-farmstand',
        meta: {
          imageUrl: item.imageUrl,
          amountCents: item.priceCents,
        },
      });
    } else {
      order.items.push({
        sku: item.sku,
        display_name: item.name,
        unit_price: item.priceCents,
        qty: item.qty,
        item_image_url: item.imageUrl,
        item_url: gcsUrlPrefix + '/the-farmstand',
      });
    }
  });

  // Check for promocodes / gift cards
  const codes = Object.keys(cart.discounts);
  if (codes.length) {
    order.discounts = {};
    codes.forEach((code) => {
      order.discounts[code] = {
        discount_amount: 0,
      };
    });
  }

  return order;
}

/**
 * * fetchTermsVersion - Get terms of service version from redux and fetch if not already there.
 */
export const useFetchTermsVersion = (termsOfServiceVersion, dispatch) => {
  const content = useContent(TERMS_OF_SERVICE);
  const terms = termsOfServiceVersion;

  // Set terms of service in redux cart
  useEffect(() => {
    if (content && !terms) {
      dispatch(setCartValue({ label: 'termsOfServiceVersion', value: content[0].sys.revision }));
    }
  }, [content, terms, dispatch]);
};

const REQ_MSG = 'Please complete all required fields.';

/**
 * * getYupValidation
 * @returns {object} Checkout form Yup validation object
 */
export const getYupValidation = (cart, needsShipping, messages, isPOS = false) => {
  const isGift = cart.isGift;
  const needsBilling = cart.totalCents && cart.paymentType === 'credit';
  const toEmailValidation = isGift
    ? Yup.string().email('Please enter a valid email address.').required(REQ_MSG)
    : Yup.string().email('Please enter a valid email address.');
  const shippingValidation = needsShipping
    ? {
        firstName: Yup.string().trim().required(REQ_MSG),
        lastName: Yup.string().trim().required(REQ_MSG),
        address: Yup.string().trim().required(REQ_MSG),
        city: Yup.string().trim().required(REQ_MSG),
        state: Yup.string().trim().required(REQ_MSG),
        zip: Yup.string()
          .trim()
          .required(REQ_MSG)
          .matches(/^[0-9]{5}$/, 'Please enter a valid 5 digit zip code.'),
        phone: Yup.string().required(REQ_MSG).matches(phoneNumberRegex, messages?.LG_invalid_phone_number),
      }
    : {};
  const billingValidation = needsBilling
    ? {
        billingFirstName: Yup.string().trim().required(REQ_MSG),
        billingLastName: Yup.string().trim().required(REQ_MSG),
        billingAddress: Yup.string().trim().required(REQ_MSG),
        billingCity: Yup.string().trim().required(REQ_MSG),
        billingState: Yup.string().trim().required(REQ_MSG),
        billingZip: Yup.string()
          .trim()
          .required(REQ_MSG)
          .matches(/^[0-9]{5}$/, 'Please enter a valid 5 digit zip code.'),
      }
    : {};

  const posValidation = isPOS
    ? {
        terminal: Yup.string().required(REQ_MSG),
        location: Yup.string().required(REQ_MSG),
        agentEmail: Yup.string().required(REQ_MSG),
      }
    : {};

  return Yup.object().shape(
    Object.assign(
      {},
      {
        email: Yup.string().email('Please enter a valid email address.').required(REQ_MSG),
        toEmail: toEmailValidation,
        message: Yup.string().max(200, 'Gift message must be less than 200 characters'),
      },
      shippingValidation,
      billingValidation,
      posValidation
    )
  );
};

export const debounceVerifyAddress = _debounce(
  function (values, dispatch) {
    const { address, postStop, city, state, zip } = values;
    dispatch(verifyAddress({ address, postStop, city, state, zip }));
  },
  500,
  {
    leading: true,
    trailing: false,
  }
);

/**
 * * getInitialValues
 * @returns {object} Checkout form initial values object
 */
export const getInitialValues = (user, cart, dispatch) => {
  // get user from redux
  const { email, firstName, lastName, address, postStop, city, state, zip, phone } = user;
  const { toEmail, message, fromName, toName, sendOn, notifyGiftReceiver } = getStorage(storageKeys.CART) || {};

  const onlyGiftCardInCart = cart && cart.items && cart.items.length === 1 && cart.items[0].sku === GIFT_CARD.id;

  // run verify address if we haven't already and values are valid
  if (!cart.hasCalledVerifiedAddress && !onlyGiftCardInCart && address && city && state && zip && zip.length === 5) {
    debounceVerifyAddress({ address, postStop, city, state, zip }, dispatch);
  }

  return {
    email,
    firstName: onlyGiftCardInCart ? '' : firstName,
    lastName: onlyGiftCardInCart ? '' : lastName,
    address: onlyGiftCardInCart ? '' : address,
    postStop: onlyGiftCardInCart ? '' : postStop,
    city: onlyGiftCardInCart ? '' : city,
    state: onlyGiftCardInCart ? '' : state,
    zip: zip || '73301',
    phone: onlyGiftCardInCart ? '' : phone,
    toEmail: toEmail || '',
    message: message || '',
    fromName: fromName || '',
    toName: toName || '',
    sendOn: sendOn || Date.now(),
    notifyGiftReceiver: notifyGiftReceiver || false,
    billingFirstName: '',
    billingLastName: '',
    billingAddress: '',
    billingPostStop: '',
    billingCity: '',
    billingState: '',
    billingZip: '',
  };
};

/**
 * * postOrderCleanup
 * Run this after order is submitted successfully.
 */

export const postOrderCleanup = ({
  order: { shipping, items, longId, orderNumber, totalCents, taxCents, shippingCents, discounts, eventPayMethod },
  dispatch,
  isLoggedIn,
  capiData = {},
  customer,
}) => {
  if (eventPayMethod) trackClick({ action: `COMPLETE ORDER - VALID WITH ${eventPayMethod.toUpperCase()}` });

  const farmstandItems = items.filter((item) => item.category === shopCategories.FARMSTAND);
  const supplyItems = items.filter((item) => item.category === shopCategories.SUPPLIES || item.category === shopCategories.EXTENSION);
  const seedlingItems = items.filter((item) => item.category === shopCategories.SEEDLINGS);
  const bundleItems = items.filter((item) => item.category === shopCategories.BUNDLES || item.category === shopCategories.CUSTOM_BUNDLES);
  const giftcardItems = items.filter((item) => item.category === shopCategories.GIFT);
  // Push form submit event to GTM
  window.dataLayer?.push({
    event: 'formSubmitted',
    formId: 'checkout',
    purchaseValue: `${totalCents / 100}`,
    itemsInCart: items.map((item) => ({
      name: item.name,
      id: item.sku,
      price: `${(item.priceCents / 100).toFixed(2)}`,
      quantity: item.qty,
    })),
    email: shipping.email.toLowerCase(),
    firstName: shipping.name ? shipping.name.first : '',
    lastName: shipping.name ? shipping.name.last : '',
    zip: shipping.address ? shipping.address.zipcode : '',
    city: shipping.address ? shipping.address.city : '',
    state: shipping.address ? shipping.address.state : '',
  });

  trackEnhancedPurchase({
    orderId: longId,
    actionField: {
      id: orderNumber || `DEFAULT_${uuidv1()}`, // Transaction ID. Required for purchases and refunds.
      revenue: `${(totalCents / 100).toFixed(2)}`, // Total transaction value (incl. tax and shipping)
      tax: `${(taxCents / 100).toFixed(2)}`,
      shipping: `${(shippingCents / 100).toFixed(2)}`,
      coupon: !_isEmpty(Object.keys(discounts)) ? Object.keys(discounts)[0] : '',
    },
    customerIdLong: customer.idLong,
    products: items,
  });

  if (farmstandItems.length) {
    const totalPriceCents = farmstandItems.reduce((total, item) => (total += item.priceCents), 0);
    trackFarmstandPurchase({ price: totalPriceCents, capiData });
  }

  if (supplyItems.length) {
    trackBuyablePurchase({ eventNameType: eventTrackingTypes.supplies.name, items: supplyItems });
  }

  if (seedlingItems.length) {
    trackBuyablePurchase({ eventNameType: eventTrackingTypes.seedlings.name, items: seedlingItems });
  }

  if (bundleItems.length) {
    trackBuyablePurchase({ eventNameType: eventTrackingTypes.bundles.name, items: bundleItems });
  }

  if (giftcardItems.length) {
    trackBuyablePurchase({ eventNameType: eventTrackingTypes.giftcards.name, items: giftcardItems });
  }

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  if (dispatch) {
    dispatch(checkoutSucceeded({ orderId: orderNumber, paymentMethod: eventPayMethod }));
    if (isLoggedIn) {
      dispatch(fetchMyFarm());
      if (farmstandItems.length) dispatch(fetchUserDevices());
    }
  }
};

export const onSubmitFieldValidation = ({ values, cart }) => {
  if (cart.isGift && !values.toEmail) {
    return { toEmail: 'Please complete all required fields.' };
  }

  const sendOnEpochDate = formatForUTCString(values.sendOn);
  const minDate = formatForUTCString(cart.minGiftDate);
  if (cart.isGift && sendOnEpochDate.valueOf() < minDate.valueOf()) {
    return { form: 'Gift date cannot be a date in the past' };
  }

  return null;
};
