import DateFnsUtils from '@date-io/date-fns';

export function checkSnoozeStatus(snoozeUntil) {
  const today = new Date();
  const snoozeDate = snoozeUntil ? new Date(snoozeUntil) : false;
  const isSnoozed = snoozeDate ? snoozeDate.getTime() > today.getTime() : false;

  return {
    isSnoozed,
    snoozeDate,
  };
}

export function formatAutofillProcessDate(isEmptyAutofillOrder, processDate) {
  const dateFns = new DateFnsUtils();

  const autofillInitDate =
    !isEmptyAutofillOrder && processDate ? dateFns.format(dateFns.addDays(new Date(processDate), 1), 'LLLL dd, yyyy') : '';
  const estimatedDeliveryDate =
    !isEmptyAutofillOrder && processDate ? dateFns.format(dateFns.addDays(new Date(processDate), 2), 'LLLL dd, yyyy') : '';
  const lastChangeDate =
    !isEmptyAutofillOrder && processDate ? dateFns.format(dateFns.addDays(new Date(processDate), -1), 'LLLL dd, yyyy') : '';

  return {
    autofillInitDate,
    estimatedDeliveryDate,
    lastChangeDate,
  };
}
