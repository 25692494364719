import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { ShopMenu } from 'constants';
import { WrapShopButton, ShopButton, ShopDropdown, WrapImage } from './Shop.styled';
import ChevronWhite from 'elements/icon/assets/chevron-white.svg';
import ChevronGreen from 'elements/icon/assets/chevron-green.svg';
import { Icon } from 'elements';
import { trackClick } from 'utils/googleTagManager';

//import images
import menuImages from './assets/menu';

const Shop = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [pointerSupport, setPointerSupport] = useState(false);

  const handleSubMenu = () => {
    if (!pointerSupport) {
      trackClick({ action: `SHOP` });
      setIsSubMenuOpen(!isSubMenuOpen);
    }
  };

  const adjustShopDropdown = () => {
    const pointerFineSupported = window.matchMedia('(pointer: fine)').matches;
    setPointerSupport(pointerFineSupported);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      adjustShopDropdown();
      window.addEventListener('resize', adjustShopDropdown);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', adjustShopDropdown);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <WrapShopButton className={isSubMenuOpen ? 'active' : ''}>
      <ShopButton label='SHOP' modifiers='tertiary' dataNw='shop-button' onClick={handleSubMenu}>
        <Icon>{isSubMenuOpen ? <ChevronGreen /> : <ChevronWhite />}</Icon>
      </ShopButton>
      <ShopDropdown isOpen={isSubMenuOpen}>
        {ShopMenu &&
          ShopMenu.map((link, index) => (
            <li key={link.label}>
              <Link
                to={link.path}
                onClick={() => {
                  trackClick({ action: `shop -${link.label}` });
                  setIsSubMenuOpen(false);
                }}
              >
                <WrapImage>
                  <img src={menuImages[index].image} alt={link.label} />
                  <img src={menuImages[index].hover} alt={link.label} className='hover' />
                </WrapImage>
                <span>{link.label}</span>
              </Link>
            </li>
          ))}
      </ShopDropdown>
    </WrapShopButton>
  );
};

export default Shop;
