import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';

import IconBase, { modifiers } from '@lettucegrow/atoms.icon';

const Icon = ({ modifiers, children, onClick, className }) => (
  <IconBase modifiers={modifiers} onClick={onClick} tabIndex={onClick ? 0 : -1} className={className}>
    {children}
  </IconBase>
);

Icon.propTypes = {
  modifiers: styleModifierPropTypes(modifiers),
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Icon;
