import styled from 'styled-components/macro';

import breakPoints from 'theme/Media';

export const AffirmWrapper = styled.div.attrs({
  className: 'AffirmWrapper',
})`
  display: flex;
  flex-shrink: 1;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  margin: 0.5rem 0 1rem;
  box-sizing: border-box;

  @media ${breakPoints.small_break} {
    margin: 0;
  }

  .gatsby-image-wrapper {
    height: auto;
    width: 2.55rem;
    margin: 0 0.375rem 0 0.25rem;
  }

  .Link {
    display: inline-flex;
    width: 100%;
    align-items: baseline;
  }

  .Text,
  .Link {
    line-height: normal;
  }
`;
