import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Icon } from 'elements';
import ThumbsUp from 'elements/icon/assets/thumbs-up.svg';
import ThumbsDown from 'elements/icon/assets/thumbs-down.svg';
import ThumbsDownSolid from 'elements/icon/assets/thumbs-down-solid.svg';
import ThumbsUpSolid from 'elements/icon/assets/thumbs-up-solid.svg';
import SeedlingVoteStyled from './SeedlingVote.styled';

import { setOpenModal, MODALS } from 'reduxState/modal';
import { getLoginState } from 'reduxState/user';

import { optimisticVote, seedlingUpVote, seedlingDownVote, getSeedlingPreference, resetVote } from 'reduxState/userPreferences';

const SeedlingVote = ({ itemId, disabled, customLiked, onCustomLike, onCustomDislike, shouldUseCustomProps, shouldHideUnselected }) => {
  const isLoggedIn = useSelector(getLoginState);
  const preference = useSelector((state) => getSeedlingPreference(state, itemId));
  const dispatch = useDispatch();
  const isLiked = shouldUseCustomProps ? customLiked === true : preference && preference.liked;
  const isDisliked = shouldUseCustomProps ? customLiked === false : preference && !preference.liked;

  const handleLike = () => {
    if (!isLoggedIn) {
      dispatch(setOpenModal(MODALS.LOGIN));
    } else {
      if (isLiked) {
        if (!preference?.id) return; // if data has not been updated yet... don't take any action to reset/remove pref
        // reset vote
        dispatch(optimisticVote({ plantTypeId: itemId, vote: null }));
        dispatch(resetVote(preference.id));
      } else {
        dispatch(seedlingUpVote(itemId));
        dispatch(optimisticVote({ plantTypeId: itemId, vote: true }));
      }
    }
  };
  const handleDisLike = () => {
    if (!isLoggedIn) {
      dispatch(setOpenModal(MODALS.LOGIN));
    } else {
      if (isDisliked) {
        if (!preference?.id) return; // if data has not been updated yet... don't take any action to reset/remove pref
        // reset vote
        dispatch(resetVote(preference.id));
        dispatch(optimisticVote({ plantTypeId: itemId, vote: null }));
      } else {
        dispatch(seedlingDownVote(itemId));
        dispatch(optimisticVote({ plantTypeId: itemId, vote: false }));
      }
    }
  };

  const onLike = shouldUseCustomProps ? onCustomLike : handleLike;
  const onDislike = shouldUseCustomProps ? onCustomDislike : handleDisLike;

  return (
    <SeedlingVoteStyled className='SeedlingVote' modifiers={['justifyCenter', 'alignCenter', disabled && 'disabled']}>
      {(!shouldHideUnselected || isLiked) && (
        <Icon modifiers={['brandColor', 'medium']} onClick={onLike} className={`${isLiked ? 'Liked' : 'Like'}`}>
          {isLiked ? <ThumbsUpSolid /> : <ThumbsUp />}
        </Icon>
      )}
      {(!shouldHideUnselected || isDisliked) && (
        <Icon modifiers={['darkGrayColor', 'medium']} onClick={onDislike} className={`${isDisliked ? 'Disliked' : 'Dislike'}`}>
          {isDisliked ? <ThumbsDownSolid /> : <ThumbsDown />}
        </Icon>
      )}
    </SeedlingVoteStyled>
  );
};

SeedlingVote.defaultProps = {
  disabled: false,
  shouldUseCustomProps: false,
};

SeedlingVote.propTypes = {
  itemId: PropTypes.string,
  disabled: PropTypes.bool,
  customLiked: PropTypes.any,
  onCustomLike: PropTypes.func,
  onCustomDislike: PropTypes.func,
  shouldUseCustomProps: PropTypes.bool,
  shouldHideUnselected: PropTypes.bool,
};

export default SeedlingVote;
