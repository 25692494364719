import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Text, ButtonText } from 'elements';
import {
  FarmstandSizeStyled,
  SizeButtonGroup,
  SelectSizeButton,
} from 'components/farm-product-detail/components/farm-select-size/FarmSelectSize.styled';
import { SizeModal } from 'components/farm-product-detail/components';

const FarmstandSize = ({ title, data = [], onSelectFarmstand, selectedSize, sizeModalContent }) => {
  const [isSizeModalOpen, setIsSizeModalOpen] = useState(false);
  const sizes = data.map((e) => e.size?.toString?.());

  const onClickFarmSize = (size) => () => onSelectFarmstand?.(size);

  const handleOpenSizeModal = () => {
    setIsSizeModalOpen(true);
  };

  const handleSizeModalClose = () => {
    setIsSizeModalOpen(false);
  };

  const renderSizeButton = (button, index) => {
    const isSelected = selectedSize?.toString() === button?.toString();
    const size = sizes[index];

    return (
      <SelectSizeButton
        key={index}
        modifiers={isSelected ? 'primaryNoHover' : 'invertedNoHover'}
        label={button}
        dataNw={`farmstand-size-${button}`}
        onClick={onClickFarmSize(size)}
        ariaLabel={`${button}-plant farmstand`}
        ariaSelected={isSelected ? 'true' : 'false'}
        eventData={{ action: `Farmstand Size: ${button}` }}
      />
    );
  };
  return (
    <>
      <FarmstandSizeStyled>
        <Text modifiers={['brandFont', 'bold', 'small']} content={title} as='span' />
        &nbsp;
        {sizeModalContent?.fields?.title && (
          <ButtonText label={sizeModalContent?.fields?.title} onClick={handleOpenSizeModal} modifiers={['underline', 'compact', 'bold']} />
        )}
        <SizeButtonGroup>{sizes.map(renderSizeButton)}</SizeButtonGroup>
      </FarmstandSizeStyled>
      {sizeModalContent?.fields?.title && (
        <SizeModal
          isOpen={isSizeModalOpen}
          onClose={handleSizeModalClose}
          title={sizeModalContent?.fields?.headline}
          body={sizeModalContent?.fields?.body}
        />
      )}
    </>
  );
};

FarmstandSize.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  onSelectFarmstand: PropTypes.func,
  selectedSize: PropTypes.string,
  sizeModalContent: PropTypes.object,
};

export default FarmstandSize;
