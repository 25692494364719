import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import marked from 'marked';
import styled from 'styled-components/macro';
import _isEqual from 'lodash/isEqual';
import { navigate } from 'gatsby';

import sizes from 'constants/sizes';
import theme from 'utils/theme';
import { prodLinkPathRegex } from 'utils/pattern-utils';

// needed for backwards compat.
const sizeMap = {
  mini: '0.5rem',
  tiny: '0.6rem',
  small: '0.7rem',
  smallish: '0.8rem',
  base: '0.875rem',
  normal: '1rem',
  medium: '1.1rem',
  mediumish: '1.2rem',
  large: '1.3rem',
  xlarge: '1.5rem',
  xxlarge: '1.7rem',
};

const Inner = styled.div`
  color: ${(props) => props.color};
  font-size: ${(props) => sizeMap[props.size] || props.size};
  font-family: ${(props) => props.fontFamily};
  * {
    font-size: ${(props) => sizeMap[props.size] || props.size};
    font-family: ${(props) => props.fontFamily};
  }
`;

class Text extends Component {
  constructor() {
    super();
    this.textRef = null;
  }

  componentDidMount() {
    if (!this.props.asHTML) return;

    // parse <a> tags and handle linking with router
    const links = this.textRef ? this.textRef.querySelectorAll('a') : [];
    [...links].forEach((linkNode) => {
      linkNode.addEventListener('click', this.handleLinkClick(linkNode.getAttribute('href'), linkNode.getAttribute('target')));
      linkNode.removeAttribute('href');
    });
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.children, this.props.children) && this.props.asHTML) {
      // parse <a> tags and handle linking with router
      const links = this.textRef ? this.textRef.querySelectorAll('a') : [];
      [...links].forEach((linkNode) => {
        linkNode.addEventListener('click', this.handleLinkClick(linkNode.getAttribute('href'), linkNode.getAttribute('target')));
        linkNode.removeAttribute('href');
      });
    }
  }

  componentWillUnmount() {
    const links = this.textRef ? this.textRef.querySelectorAll('a') : [];

    [...links].forEach((linkNode) => {
      linkNode.removeEventListener('click', this.handleLinkClick(linkNode.getAttribute('href'), linkNode.getAttribute('target')));
    });
  }

  handleLinkClick(href, target) {
    const isHardCodedProdLink = href?.includes('lettucegrow.com') && !href?.includes('@');
    const isNewTab = target?.includes('blank');
    const isExternalOrActionLink = href && (href?.includes('http') || !href?.startsWith('/'));

    return () => {
      if (isNewTab) {
        href && window.open(href);
      } else if (isHardCodedProdLink) {
        const pathPattern = prodLinkPathRegex;
        const path = href.match(pathPattern);
        path?.[1] && navigate(path[1]);
      } else if (isExternalOrActionLink) {
        window.location.href = href;
      } else {
        href && navigate(href);
      }
    };
  }

  render() {
    if (!this.props.children) return null;
    const classNames = cx(`lg-text lg-text--${this.props.size}`, this.props.className, {
      'lg-text--bold': this.props.isBold,
      'lg-text--inline': this.props.isInline,
      'lg-text--uppercase': this.props.isUppercase,
      'lg-text--italic': this.props.isItalic,
      'lg-text--underlined': this.props.isUnderlined,
      [`lg-text--${this.props.color}`]: this.props.color,
    });
    if (this.props.asHTML) {
      return (
        <Inner
          size={this.props.size}
          color={this.props.color}
          fontFamily={this.props.fontFamily}
          className={classNames}
          dangerouslySetInnerHTML={{ __html: marked(this.props.children) }}
          ref={(node) => (this.textRef = node)}
        />
      );
    }
    return typeof this.props.children === 'string' ? (
      <Inner as='p' size={this.props.size} fontFamily={this.props.fontFamily} className={classNames} color={this.props.color}>
        {this.props.children}
      </Inner>
    ) : (
      <Inner className={classNames} size={this.props.size} fontFamily={this.props.fontFamily} color={this.props.color}>
        {this.props.children}
      </Inner>
    );
  }
}

Text.defaultProps = {
  size: sizes.NORMAL,
  color: undefined,
  fontFamily: null,
  asHTML: false,
  isBold: false,
  isInline: false,
  isItalic: false,
  isUppercase: false,
  isUnderlined: false,
  className: '',
  children: '',
};

Text.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  fontFamily: PropTypes.oneOf(theme.font.all),
  asHTML: PropTypes.bool,
  isBold: PropTypes.bool,
  isInline: PropTypes.bool,
  isItalic: PropTypes.bool,
  isUppercase: PropTypes.bool,
  isUnderlined: PropTypes.bool,
  className: PropTypes.string,
};

export default Text;
