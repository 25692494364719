import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'elements';

import { PillLabelStyled } from './PillLabel.styled';

const PillLabel = ({ modifiers, content }) => (
  <PillLabelStyled modifiers={modifiers}>
    <Text modifiers={['brandFont', 'small', 'noLineHeight']} content={content} />
  </PillLabelStyled>
);

PillLabel.propTypes = {
  content: PropTypes.string,
  modifiers: PropTypes.any,
};

export default PillLabel;
