import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { Transition, TransitionGroup } from 'react-transition-group';
import { useMediaQuery } from '@material-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _partition from 'lodash/partition';

import {
  closeModal,
  removeItemsOfCategories,
  setHasBERemovedSeedlings,
  fetchCartPreviewError,
  getNotEnoughSeedlingsInCart,
  getCartSideModalToShow,
  getCartHasBERemovedSeedlings,
  getCartTotalCents,
  getCartShipDate,
  getCartSeedlingsExpanded,
  getCartDiscounts,
  getIsLoadingCartPreview,
  getCartManualDiscounts,
  getCartItems,
  openCartModal,
  getCartPreviewError,
  getSeedlingVouchersUsedCount,
  getSeedlingVouchersUsedDiscountCents,
  fetchShippingOptions,
} from 'reduxState/cart';
import { getUserZip } from 'reduxState/user';
import { getCatalogSeedlings, getCatalogBundles } from 'reduxState/catalog';
import SideDrawer from 'components/deprecated/SideDrawer';
import CircleSpinner from 'components/deprecated/CircleSpinner';
import Modal from 'components/deprecated/Modal';
import LineItem from './components/line-item/LineItem';
import CartFooter from './components/cart-footer/CartFooter';
import EmptyCartMessage from './components/empty-cart-message/EmptyCartMessage';
import ShippingUpsell from './components/shipping-upsell/ShippingUpsell';
import AccordionSection from 'components/deprecated/elements/AccordionSection';
import { PromoBanner, PromoContainer } from 'components';
import PromoCodeForm from 'components/deprecated/PromoCodeForm';

import useGoogleTagManager from 'utils/hooks/useGoogleTagManager';
import { useContent } from 'utils/hooks';
import { formatPrice } from 'utils/cart-utils';
import { getDayMonthFormat } from 'utils/date-utils';
import breakpoints from 'theme/Media';
import colors from 'constants/styledColors';
import { Modal as RedesignModal } from 'components/modal';

import messages from 'constants/messages';

import { Icon, Text, SquaredButton, Title } from 'elements';
import { Flex, PromoPrice } from 'components';
import ExpandIcon from 'elements/icon/assets/plus.svg';
import CollapseIcon from 'elements/icon/assets/minus.svg';
import RemoveItemButton from '../remove-item-button/RemoveItemButton';

import ZipCodeEditor from 'components/zip-code-editor/ZipCodeEditor';

import shopCategories from 'constants/shopCategories';
import shopSubCategories from 'constants/shopSubCategories';
import shippingOptionCategories from 'constants/shippingOptionCategories';
import { FARMSTANDS_2020 } from 'constants/contentful';

const CartStyled = styled.div`
  .SlimBanner {
    margin: 0 -20px;
    position: fixed;
    margin-top: 3rem;
    z-index: 2;
    box-shadow: ${({ hasPromo }) => (hasPromo ? '0px 8px 20px -18px rgba(0, 0, 0, 0.5)' : 'none')};
    @media ${breakpoints.large_break} {
      width: 33vw;
    }
  }
  .headerDrawer {
    border-bottom: none;
    padding-top: 14px;
  }
  .headerWrapperDrawer {
    width: 100vw;
    position: fixed;
    transform: translate(-20px, -10px);
    padding: 0 20px;
    background: ${({ theme }) => theme.farmProject.gray_2};
    z-index: 2;
    box-shadow: ${({ hasPromo }) => (hasPromo ? 'none' : '0px 8px 20px -18px rgba(0, 0, 0, 0.5)')};

    @media ${breakpoints.large_break} {
      width: 33vw;
    }
  }
`;

const CartItemsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-top: ${({ hasPromo }) => (hasPromo ? '5rem' : '2.5rem')};
  width: 100%;
  flex-grow: 1;
  -webkit-overflow-scrolling: auto;

  .generalItem {
    &:last-of-type {
      border-bottom: ${({ hasSeeds }) => (hasSeeds ? '2px' : '0px')} solid ${({ theme }) => theme.farmProject.light_gray};
    }
  }

  .seedItem {
    &:first-of-type {
      border-top: 1px solid ${colors.GREY};
    }
  }

  .AccordionSection {
    .Accordion {
      transition: none;
    }

    .LineItemDetails:first-of-type {
      .Icon {
        top: 9px;
      }
    }
  }
`;

const CartOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  right: ${(props) => (props.isCartOpen ? '0' : '-100vw')};
  background: rgba(255, 255, 255, 0.8);
  transition: opacity 200ms ease, right 300ms ease-in;
  opacity: ${(props) => (props.isOn ? 1 : 0)};
  pointer-events: ${(props) => (props.isOn ? 'initial' : 'none')};
  @media ${breakpoints.large_break} {
    width: 33vw;
    right: ${(props) => (props.isCartOpen ? '0' : '-33vw')};
  }
`;

const GeneralItemsWrapper = styled.div.attrs({
  className: 'GeneralItemsWrapper',
})`
  .Suggestion {
    margin-top: 2rem;
  }
`;

const HeaderSeedlings = styled.div.attrs({
  className: 'HeaderSeedlings',
})`
  display: flex;
  flex-direction: column;
  padding: 1.35rem 0 1.85rem 0;
  width: 100%;
  opacity: ${({ state }) => (state === 'exiting' ? '0' : '1')};

  .RemoveItemButton {
    align-self: center;
  }

  .Flex {
    gap: 0.85rem;
    margin-bottom: 1rem;
  }
`;

const MoreSeedlings = styled.div.attrs({
  className: 'MoreSeedlings',
})`
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.farmProject.font_family};
  color: ${({ theme }) => theme.farmProject.error};
  margin-top: 0.3rem;
  padding: 0.25rem 0;
  text-align: left;
  margin-top: 1rem;
`;

const HeaderSeedlingsInfo = styled.div.attrs({
  className: 'HeaderSeedlingsInfo',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;
  flex-wrap: wrap;

  .seedlings {
    font-size: 0.875rem;
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-weight: normal;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.farmProject.brand_primary};
    align-self: center;
  }

  .seedsTotal {
    display: flex;

    .Icon {
      pointer-events: none;
      margin-right: 0.7rem;

      polygon {
        fill: ${({ theme }) => theme.farmProject.dark_gray};
      }
    }
  }

  .seedsPrice {
    display: flex;

    .accordionLabel {
      width: 1.6875rem;
      font-size: 0.7rem;
      color: ${({ theme }) => theme.farmProject.light_gray};
    }
  }
`;

const CartUpdatedWrapper = styled.div.attrs({
  className: 'CartUpdatedWrapper',
})`
  padding: 0.625rem 0.3125rem;
  margin-top: 5.25rem;
  border: solid 1px ${({ theme }) => theme.farmProject.yellow};

  .Text {
    text-align: center;
    letter-spacing: 0.2px;
  }
`;

const NoteSeeding = styled.div.attrs({
  className: 'NoteSeeding',
})`
  width: 100%;
  padding-left: 2rem;
  margin-top: 0.25rem;
  * {
    font-family: ${({ theme }) => theme.farmProject.font_family};
  }
  p {
    font-weight: normal;
    font-size: 0.75rem;
    b {
      color: ${({ theme }) => theme.farmProject.green};
      font-weight: normal;
    }
    strong {
      font-weight: bold;
    }
  }
`;

const plantCategories = [shopCategories.SEEDLINGS, shopCategories.BUNDLES];

/**
 * * Cart component for Lettuce Grow shopping experience
 *
 */

const Cart = () => {
  const cartItems = useSelector(getCartItems);
  const sideModalToShow = useSelector(getCartSideModalToShow);
  const hasBERemovedSeedlings = useSelector(getCartHasBERemovedSeedlings);
  const totalCents = useSelector(getCartTotalCents);
  const shipDate = useSelector(getCartShipDate);
  const seedlingsExpanded = useSelector(getCartSeedlingsExpanded);
  const discounts = useSelector(getCartDiscounts);
  const isLoadingCartPreview = useSelector(getIsLoadingCartPreview);
  const manualDiscounts = useSelector(getCartManualDiscounts);
  const cartPreviewError = useSelector(getCartPreviewError);
  const catalogSeedlings = useSelector(getCatalogSeedlings);
  const catalogBundles = useSelector(getCatalogBundles);
  const isCartOpen = sideModalToShow === 'cart';
  const farmstandContent = (useContent(FARMSTANDS_2020, isCartOpen)?.[0]?.fields?.farmstandModels || []).map((e) => ({
    ...(e.fields || {}),
    id: e.sys?.id || '',
  }));
  const seedlingVouchersUsedCount = useSelector(getSeedlingVouchersUsedCount);
  const seedlingVouchersUsedDiscountCents = useSelector(getSeedlingVouchersUsedDiscountCents);
  const userZip = useSelector(getUserZip);
  let [seedlingsItems, generalItems] = _partition(cartItems, (item) => plantCategories.includes(item.category));

  const dispatch = useDispatch();
  const isAfterTabletSize = useMediaQuery(breakpoints.large_break);
  const [showCartUpdatedWrapper, setShowCartUpdatedWrapper] = useState(false);
  const modalWidth = isAfterTabletSize ? '33vw' : '100vw';
  const hasSeeds = seedlingsItems.length > 0;
  seedlingsItems = seedlingsItems.map((plant) => {
    const plantCatalog = (plant.category === shopCategories.BUNDLES ? catalogBundles : catalogSeedlings)[plant.sku];
    const shipsOnDate = plantCatalog?.shipsOnDate || null;
    return { ...plant, shipsOnDate };
  });
  const isNotEnoughSeedlings = useSelector(getNotEnoughSeedlingsInCart);

  useEffect(() => {
    if (isCartOpen && hasBERemovedSeedlings) {
      setShowCartUpdatedWrapper(true);
      dispatch(setHasBERemovedSeedlings(false));
    }
    if (!isCartOpen && showCartUpdatedWrapper) {
      setShowCartUpdatedWrapper(false);
    }
    // eslint-disable-next-line
  }, [isCartOpen, hasBERemovedSeedlings]);

  useEffect(() => {
    if (isCartOpen && cartItems.length > 0) {
      // HEADS UP - add default zip code here b/c this data is just used currently for seedling shipping messaging
      dispatch(fetchShippingOptions({ items: cartItems, zip: userZip || '73301' }));
    }
  }, [cartItems, userZip, isCartOpen, dispatch]);

  useGoogleTagManager(
    'Cartview',
    '/modal/cart',
    {
      itemsInCart: cartItems.map((item) => Object.assign({}, item, { priceCents: formatPrice(item.priceCents / 100, 2, false) })),
      totalCents: formatPrice(totalCents / 100, 2, false),
    },
    sideModalToShow === 'cart'
  );

  if (cartPreviewError?.code === messages?.ZIP_UNSUPPORTED) {
    const closeZipcodeModal = () => {
      dispatch(closeModal());
      dispatch(fetchCartPreviewError({}));
    };
    const goToCart = () => {
      closeZipcodeModal();
      dispatch(openCartModal());
    };
    return (
      <RedesignModal isOpen onCloseAction={closeZipcodeModal}>
        <Title modifiers='small' content='OOPS!' />
        <Text
          content="We don't recognize the zipcode associated with your order. <br />
          Please update your zip."
          isHTML
        />
        <ZipCodeEditor />
        <SquaredButton label='GO TO CART' onClick={goToCart} />
      </RedesignModal>
    );
  }

  const handleClose = () => {
    dispatch(closeModal());
  };

  const removeAllSeedlings = () => {
    dispatch(removeItemsOfCategories(plantCategories));
  };

  // eslint-disable-next-line react/display-name
  const renderHeaderSection = (sectionKey, seedlings) => (onClick, isOpen) => {
    const seedsQty = seedlings.reduce((t, seed) => {
      if (seed.category === shopCategories.BUNDLES) {
        t += seed.plantCount * seed.qty;
      } else {
        t += seed.qty || 0;
      }
      return t;
    }, 0);

    const seedsPrice = seedlings.reduce((t, seed) => (t += seed.priceCents * seed.qty), 0) || 0;
    const seedsPriceFormatted = formatPrice(seedsPrice / 100, 2);
    return (
      <HeaderSeedlings key={sectionKey}>
        <Flex modifiers={['row', 'justifyEnd']}>
          <ShippingUpsell shippingCategory={shippingOptionCategories.PLANT} />
          <RemoveItemButton onClick={removeAllSeedlings} />
        </Flex>
        <HeaderSeedlingsInfo onClick={onClick}>
          <div className='seedsTotal'>
            <Icon modifiers={['small', 'invertedFill']}>{isOpen ? <CollapseIcon /> : <ExpandIcon />}</Icon>
            <Flex modifiers={['directionColumn']}>
              <p className='seedlings'>SEEDLINGS: {seedsQty}</p>
              {!!seedlingVouchersUsedCount && (
                <Text modifiers={['brandFont', 'tiny', 'italic']}>{`${seedlingVouchersUsedCount} CREDIT${
                  seedlingVouchersUsedCount > 1 ? 'S' : ''
                } APPLIED`}</Text>
              )}
            </Flex>
          </div>
          {seedlingVouchersUsedDiscountCents ? (
            <PromoPrice oldPrice={seedsPriceFormatted} newPrice={formatPrice((seedsPrice - seedlingVouchersUsedDiscountCents) / 100, 2)} />
          ) : (
            <div className='seedsPrice'>
              <p className='seedlings'>{seedsPriceFormatted}</p>
            </div>
          )}
          {!!shipDate && (
            <NoteSeeding>
              <p>
                <b>Note:</b> <strong>All your seedlings will ship {getDayMonthFormat(shipDate, true)}.</strong> To receive your order
                faster, remove seedlings with a future availability date.
              </p>
            </NoteSeeding>
          )}
        </HeaderSeedlingsInfo>
        {isNotEnoughSeedlings && <MoreSeedlings>Please add at least 6 seedlings to your cart to proceed.</MoreSeedlings>}
      </HeaderSeedlings>
    );
  };

  const LineGeneralItems = (
    <GeneralItemsWrapper key='generalLineItems'>
      <ShippingUpsell shippingCategory={shippingOptionCategories.OTHER} />
      <TransitionGroup>
        {generalItems.map((item) => {
          const isFarmstand = item.category === shopCategories.FARMSTAND;
          const isV2Item = item.subcategory === shopSubCategories.NOOK;

          const farmstandGifts = isFarmstand ? farmstandContent.find(({ id }) => id === item.sku)?.relatedGiftsIDs : null;
          return (
            <Transition key={item.sku} timeout={200} enter={false}>
              {(state) => (
                <LineItem
                  className='generalItem'
                  closeModal={handleClose}
                  item={item}
                  state={state}
                  isFarmstand={isFarmstand}
                  freeGifts={farmstandGifts}
                  isV2Item={isV2Item}
                />
              )}
            </Transition>
          );
        })}
      </TransitionGroup>
    </GeneralItemsWrapper>
  );

  const LineSeedlingsItems =
    seedlingsItems.length > 0 ? (
      <TransitionGroup key='seedlings-accordion-transition-group'>
        <Transition key='seedlings-accordion' timeout={250} exit={false}>
          <AccordionSection
            key='seedlings-accordion'
            renderCustomTitle={renderHeaderSection('header-seedlings', seedlingsItems)}
            isInitiallyOpen={seedlingsExpanded}
          >
            {seedlingsItems.map((item) => (
              <LineItem key={item.sku} className='seedItem' closeModal={handleClose} item={item} />
            ))}
          </AccordionSection>
        </Transition>
      </TransitionGroup>
    ) : null;

  const lineItems = [LineGeneralItems, LineSeedlingsItems];

  return (
    <Modal
      isOpen={sideModalToShow === 'cart'}
      canClose={true}
      onClose={handleClose}
      isMobileFullscreen
      hasCloseButton={false}
      style={{
        content: {
          position: 'fixed',
          height: '100vh',
          width: modalWidth,
          top: '0',
          right: '0',
          left: 'initial',
          bottom: '0',
          border: 'none',
          borderRadius: '0',
          background: 'transparent',
          padding: '0',
          transform: 'none',
        },
      }}
    >
      <>
        <CartStyled hasPromo={!_isEmpty(discounts)}>
          <SideDrawer key='cart-drawer' closeModal={handleClose} headline='YOUR CART' isOpen={isCartOpen} useBackgroundTheme>
            <CartOverlay isCartOpen={isCartOpen} isOn={isLoadingCartPreview}>
              {isLoadingCartPreview && <CircleSpinner />}
            </CartOverlay>
            {showCartUpdatedWrapper && (
              <CartUpdatedWrapper>
                <Text modifiers={['small', 'brandFont']}>
                  Due to inventory availability, your cart has been <b>updated</b>.
                </Text>
              </CartUpdatedWrapper>
            )}
            <PromoBanner isAbbreviated />
            <CartItemsContainer hasSeeds={hasSeeds} hasPromo={!_isEmpty(discounts)}>
              {cartItems.length > 0 ? (
                lineItems
              ) : (
                <TransitionGroup>
                  <Transition key={'empty-cart-transition'} timeout={250} exit={false}>
                    {(state) => <EmptyCartMessage closeModal={handleClose} state={state} />}
                  </Transition>
                </TransitionGroup>
              )}
            </CartItemsContainer>
            {!!cartItems.length && <PromoCodeForm />}
            {!_isEmpty(discounts) &&
              Object.keys(discounts).map((discount) => (
                <PromoContainer
                  key={discount}
                  discount={discount}
                  discountCents={discounts[discount].discountCents}
                  canRemove={manualDiscounts.includes(discount)}
                />
              ))}
            {cartItems.length > 0 ? <CartFooter closeModal={handleClose} /> : ''}
          </SideDrawer>
        </CartStyled>
      </>
    </Modal>
  );
};

Cart.defaultProps = {};
Cart.propTypes = {};

export default Cart;
