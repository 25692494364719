import styled from 'styled-components';

export const SwiperStyled = styled.div.attrs({
  className: 'SwiperStyled',
})`
  .swiper-button-prev {
    top: 47%;
    width: 1.8rem;
    height: 1.6rem;
    left: -0.25rem;

    &::after {
      width: 2rem;
      height: 2rem;
      content: url('/icons/left.svg');
    }
  }

  .swiper-button-next {
    top: 47%;
    right: -0.4rem;
    width: 1.8rem;
    height: 1.6rem;

    &::after {
      width: 2rem;
      height: 2rem;
      content: url('/icons/right.svg');
    }
  }
`;
