import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { Text, ButtonText } from 'elements';
import { PromoPrice, Flex } from 'components';

import { formatDynamicDecimalPrice, getItemPath } from 'utils/cart-utils';
import { closeModal } from 'reduxState/cart';

const LineItemContainer = styled.li`
  display: block;
  width: 100%;
  margin-bottom: ${({ isNested }) => (isNested ? 0 : '0.7rem')};
  margin-left: ${({ isNested }) => (isNested ? '1rem' : 0)};
`;

const FlexSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${({ isCompact }) => (isCompact ? '0.2rem' : '0.7rem')};
  align-items: ${(props) => (props.alignItems === 'center' ? 'center' : '')};
  .Flex {
    margin-left: 0.75rem;
    .ButtonText {
      line-height: 0.75rem;
      margin-bottom: 0.25rem;
    }
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
`;
const Price = styled(Text)`
  margin-left: auto;
  margin-right: 6px;
`;

/**
 * * A Line Item for the Order Summary on Lettuce Grow
 *
 * @prop {object} item - expects: name, qty, priceCents, and optionally discountedPriceCents
 */

const LineItem = ({ item, priceCentsOverride, slugOverride, itemVouchersUsedCount, itemVouchersUsedDiscountCents }) => {
  const isSeedling = item.category === 'Seedlings';
  const hasDiscount = !!item?.originalPriceCents;
  const isFreeGift = !!item?.originalPriceCents && item?.priceCents === 0;
  const freeGiftLabel = 'FREE GIFT';

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const handleCloseModal = () => {
    //ensure we close the summary modal to ensure document scrolling as expected
    if (cart.sideModalToShow) {
      dispatch(closeModal());
    }
  };

  const getItemPrice = (price, qty) => formatDynamicDecimalPrice(priceCentsOverride ? priceCentsOverride : price * qty, true, 2);
  const getVoucherAdjustedPrice = (price, qty) => {
    return formatDynamicDecimalPrice((priceCentsOverride ? priceCentsOverride : price * qty) - itemVouchersUsedDiscountCents, true, 2);
  };

  return (
    <LineItemContainer isNested={isSeedling}>
      <FlexSection isCompact={isSeedling}>
        {!isSeedling && <Text modifiers={['small', 'bold']}>{item.qty}</Text>}
        <Flex modifiers={['directionColumn', 'alignStart']}>
          <ButtonText
            href={slugOverride || getItemPath(item)}
            label={`${item.name} ${isSeedling && item.qty > 1 ? `x${item.qty}` : ''}`}
            modifiers={['compact', 'secondary', 'textLeft', isSeedling && 'italic']}
            onClick={handleCloseModal}
            type='button'
          />
          {!!itemVouchersUsedCount && !isSeedling && (
            <Text modifiers={['tiny', 'italic']}>{`${itemVouchersUsedCount} CREDIT${itemVouchersUsedCount > 1 ? 'S' : ''} APPLIED`}</Text>
          )}
        </Flex>
        {!isSeedling && (
          <PriceContainer>
            {itemVouchersUsedDiscountCents ? (
              <PromoPrice
                oldPrice={getItemPrice(item.priceCents, item.qty)}
                newPrice={getVoucherAdjustedPrice(item.priceCents, item.qty)}
              />
            ) : !hasDiscount ? (
              <Price modifiers={['small', 'bold']}>{getItemPrice(item.priceCents, item.qty)}</Price>
            ) : (
              hasDiscount && (
                <PromoPrice
                  modifiers='inverted'
                  oldPrice={getItemPrice(item.originalPriceCents, item.qty)}
                  newPrice={isFreeGift ? freeGiftLabel : getItemPrice(item.priceCents, item.qty)}
                />
              )
            )}
          </PriceContainer>
        )}
      </FlexSection>
    </LineItemContainer>
  );
};

LineItem.propTypes = {
  item: PropTypes.object.isRequired,
  priceCentsOverride: PropTypes.number,
  slugOverride: PropTypes.string,
  itemVouchersUsedCount: PropTypes.number,
  itemVouchersUsedDiscountCents: PropTypes.number,
};

export default LineItem;
