import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import { SquaredButton, Text, Title, GatsbyImageLink } from 'elements';
import { ContentSection } from 'components';

import { ContentWrapper } from 'staticPages/home/Home.styled';
import { WeAreHereToHelpSection, ContactListItem } from './WeAreHereToHelp.styled';

const WeAreHereToHelp = ({ title, body, callToAction, contentBlock }) => {
  const imageData = graphql`
    query {
      contentfulSectionStandard(contentful_id: { eq: "zRhQWKx4UIjPhBqNtUlSs" }) {
        mediaContent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;
  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <React.Fragment>
            <WeAreHereToHelpSection>
              <ContentSection modifiers={['leftText']} animation='crossFade'>
                <GatsbyImageLink
                  modifiers='relativePosition'
                  image={data?.contentfulSectionStandard?.mediaContent?.gatsbyImageData}
                  alt={data?.contentfulSectionStandard?.mediaContent?.description}
                  imageRouteLink={callToAction?.fields?.linkUrl}
                />
                <ContentWrapper>
                  <Title content={title} modifiers={['secondarySmall', 'center']} as='h3' />
                  <Text content={body} modifiers='center' />
                  <SquaredButton as='a' label={callToAction?.fields?.linkText} href={callToAction?.fields?.linkUrl} />
                  {!!contentBlock && (
                    <ContactListItem key={contentBlock?.sys?.id}>
                      <Text as='span' modifiers={['brandFont', 'small', 'darkGrayColor']} content={contentBlock?.fields?.body2} isHTML />
                    </ContactListItem>
                  )}
                </ContentWrapper>
              </ContentSection>
            </WeAreHereToHelpSection>
          </React.Fragment>
        );
      }}
    />
  );
};

WeAreHereToHelp.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  callToAction: PropTypes.object,
  contentBlock: PropTypes.object,
};

export default WeAreHereToHelp;
