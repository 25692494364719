import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';

import { AXIOS } from 'constants/redux';
import { GIFT_CARD } from 'constants/contentful';
import { getAffirmMin } from 'reduxState/appSettings';

import { HALO_LIGHTS_SKUS } from 'constants/sku';
import shopCategories from 'constants/shopCategories';
import shopSubCategories from 'constants/shopSubCategories';

const DEFAULT_OBJECT = {};
const { VOUCHERS, FARMSTAND, SEEDLINGS, SUPPLIES, EXTENSION, BUNDLES, CUSTOM_BUNDLES } = shopCategories;
import { getSeedlingVoucherUses, getVouchersCount, getVouchersDiscount, normalizeVoucherUses } from 'utils/cart-utils';

/**
 * * cart - Redux Reducer
 *
 * cart  Content type from Contentful
 *
 * @param state
 *
 */

export const initialState = {
  items: [],
  giftCard: {
    imageUrl: '',
    amount: '',
  },
  giftable: false,
  subtotalCents: 0,
  shippingCents: 0,
  baseShippingCents: 0,
  shippingMessages: [], // returned from /cartPreview for shipping tooltip content
  taxCents: 0,
  discounts: {},
  urlDiscount: null,
  manualDiscounts: [],
  promoCents: 0,
  giftCents: 0,
  giftBalanceCents: 0,
  giftsWithPurchase: [],
  creditCents: 0,
  creditBalanceCents: 0,
  voucherUses: {
    byItemSku: {},
  },
  voucherCents: 0,
  totalCents: 0,
  minGiftDate: null,
  shipDate: null,
  termsOfServiceVersion: '',
  verifiedAddress: {
    address: '',
    postStop: '',
    city: '',
    state: '',
    zip: '73301',
    code: '',
  },
  selectedShippingOptions: [],
  shippingOptions: [],
  isShippingOptionsLoading: false,
  shouldOverrideAddress: false,
  hasVerifiedAddressError: false, // ! Can not submit with this true
  hasCalledVerifiedAddress: false,
  isCheckingOut: false,
  isAltPaymentReady: false,
  sideModalToShow: '',
  cartSeedlingsExpanded: false,
  isGift: false,
  lastAddedFarmstandId: '',
  hasBERemovedSeedlings: false,
  cartPreviewError: {},
  cartPreviewZip: '',
  forceCartPreviewFetchOnCheckout: false,
  isLoadingCartPreview: false,
  paymentType: 'credit',
  canUpdateAddress: true,
  isAltDuplicatedOrder: false,
  isLoadingDuplicateOrder: false,
  // BuyNow data
  buyNow: {
    subtotalCents: 0,
    shippingCents: 0,
    baseShippingCents: 0,
    taxCents: 0,
    totalCents: 0,
    discounts: {},
    voucherUses: [],
    promoValid: false,
    promoCents: 0,
    promoFailure: null,
    giftCents: 0,
    creditCents: 0,
    voucherCents: 0,
    giftBalanceCents: 0,
    creditBalanceCents: 0,
    minGiftDate: null,
    shipDate: null,
    giftsWithPurchase: [],
    seedlingCreditsUsed: 0,
    shippingMessages: [],
    shippingOptionCategories: [],
    items: [],
  },
  isBuyNowLoaded: false,
  isBuyNowFetching: false,
  // AutoFill Orders
  autofill: {
    ordersById: {},
    nextPendingId: '',
    success: false,
    message: '',
    isFetching: false,
    hasFetched: false,
  },
};

// sort and return items in cart
const sortCart = (items) => {
  if (items.length < 2) return items;
  // take items and split them up into two piles
  // farmstands - seedlings
  const farmstands = [];
  const supplies = [];
  const seedlings = [];
  const plantBundleMix = [];
  const misc = [];
  items.forEach((item) => {
    if (item.category === FARMSTAND) {
      farmstands.push(item);
    } else if (item.category === SUPPLIES) {
      supplies.push(item);
    } else if (item.category === SEEDLINGS) {
      seedlings.push(item);
    } else if (item.category === CUSTOM_BUNDLES) {
      plantBundleMix.push(item);
    } else {
      misc.push(item);
    }
  });

  // sort each of these piles (price - price - name)
  const priceDescendingSort = (a, b) => b.priceCents - a.priceCents;

  const sortByName = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };

  if (farmstands.length >= 2) farmstands.sort(sortByName).sort(priceDescendingSort);
  if (supplies.length >= 2) supplies.sort(sortByName).sort(priceDescendingSort);
  if (misc.length >= 2) misc.sort(sortByName).sort(priceDescendingSort);
  if (seedlings.length >= 2) seedlings.sort(sortByName);
  if (plantBundleMix.length >= 2) plantBundleMix.sort(sortByName).sort(priceDescendingSort);
  // spread back into return value in the order that we want!
  return [...farmstands, ...supplies, ...seedlings, ...misc, ...plantBundleMix];
};

const normalizeAutofillPendingOrder = (order) => {
  const { pendingOrderItems, ...rest } = order;
  const itemsDefault = { byId: {}, seedlings: [], supplies: [] };
  const items = pendingOrderItems.reduce((itemsByCategory, { count, buyableName, ...buyable }) => {
    const type = buyable.category === SUPPLIES ? 'supplies' : 'seedlings';
    const item = { ...buyable, qty: count, name: buyableName };
    const itemId = buyable.id?.toString?.();
    return { ...itemsByCategory, [type]: [...itemsByCategory[type], itemId], byId: { ...itemsByCategory.byId, [itemId]: item } };
  }, itemsDefault);
  return { ...rest, items };
};

const normalizeAutofillAllOrders = (orders) => {
  const ordersById = orders.reduce((allOrders, order) => ({ ...allOrders, [order.id]: normalizeAutofillPendingOrder(order) }), {});

  return {
    ordersById,
    nextPendingId: Object.keys(ordersById)?.[0]?.toString?.(),
  };
};

const filterValidAutofillPendingOrders = (orders) => {
  return orders.filter((order) => order.customerEditable);
};

//sort and return autofill pending orders
export const getSortedAutofillPendingOrders = (orders = []) => {
  const sortedOrders = filterValidAutofillPendingOrders(orders)
    .sort((a, b) => new Date(a.initDate) - new Date(b.initDate))
    .sort((a, b) => new Date(a.processDate) - new Date(b.processDate));

  return normalizeAutofillAllOrders(sortedOrders);
};

// reducer, action types, action creators all in 1 createSlice
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addBulkItems(state, { payload }) {
      const { items } = payload;
      items.forEach((item) => {
        if (item.qty > 0) {
          const foundItemIdx = state.items.findIndex((cartItem) => cartItem.sku === item.sku);
          if (foundItemIdx > -1) {
            // Item already exists in cart. Add to qty.
            state.items[foundItemIdx].qty += item.qty;
          } else {
            // Push new item into cart array
            state.items.push(item);
          }
        }
      });
    },
    addBulkItemsAndDiscount(state, { payload }) {
      const { items, discount } = payload;
      items.forEach((item) => {
        if (item.qty > 0) {
          const foundItemIdx = state.items.findIndex((cartItem) => cartItem.sku === item.sku);
          if (foundItemIdx > -1) state.items[foundItemIdx].qty += item.qty;
          else state.items.push(item);
        }
      });

      if (discount) state.discounts = { [discount]: 0, ...state.discounts };
      const isDiscountSaved = state.manualDiscounts.includes(discount);
      if (!isDiscountSaved && discount) state.manualDiscounts.push(discount);
    },
    addBulkItemsAndDiscountArray(state, { payload }) {
      const { items, discount } = payload;

      items.forEach((item) => {
        if (item.qty > 0) {
          const foundItemIdx = state.items.findIndex((cartItem) => cartItem.sku === item.sku);
          if (foundItemIdx > -1) state.items[foundItemIdx].qty += item.qty;
          else state.items.push(item);
        }
      });

      const reducedDiscounts = discount.reduce((acc, curr) => {
        acc[curr] = 0;
        return acc;
      }, {});

      state.discounts = { ...state.discounts, ...reducedDiscounts };
      discount.forEach((item) => {
        const isDiscountSaved = state.manualDiscounts.includes(item);
        if (!isDiscountSaved) state.manualDiscounts.push(item);
      });
    },
    addItem(state, { payload }) {
      // look to see if item exists in cart already
      const foundItemIdx = state.items.findIndex((item) => item.sku === payload.item.sku);
      if (foundItemIdx > -1) {
        // Item already exists in cart.
        if (payload.item.sku === GIFT_CARD.id) {
          // Item is Gift Card. Overwrite old gift card options with this one.
          state.items[foundItemIdx] = payload.item;
        } else {
          // For all other items just update the qty
          state.items[foundItemIdx].qty += payload.item.qty;
        }
      } else {
        // Push new item into cart array
        state.items.push(payload.item);
      }
    },
    replaceSeedlings(state, { payload }) {
      const { items } = payload;
      const categoriesToRemove = [SEEDLINGS, BUNDLES, CUSTOM_BUNDLES];
      const newItems = state.items.filter((item) => !categoriesToRemove.includes(item.category));
      items.forEach((item) => {
        if (item.qty > 0) {
          const foundItemIdx = newItems.findIndex((cartItem) => cartItem.sku === item.sku);
          if (foundItemIdx > -1) {
            // Item already exists in cart. Add to qty.
            newItems[foundItemIdx].qty += item.qty;
          } else {
            // Push new item into cart array
            newItems.push(item);
          }
        }
      });
      state.items = newItems;
    },
    setGiftAmount(state, { payload }) {
      state.giftCard.amount = payload;
    },
    setGiftImage(state, { payload }) {
      state.giftCard.imageUrl = payload;
    },
    removeItem(state, { payload }) {
      state.items = state.items.filter((item) => item.sku !== payload);
    },
    removeItemOfType(state, { payload }) {
      state.items = state.items.filter((item) => item.type !== payload.data);
    },
    removeItemsOfCategories(state, { payload }) {
      state.items = state.items.filter((item) => !payload.includes(item.category));
    },
    updateItem(state, { payload }) {
      state.items.forEach((item) => {
        if (item.sku === payload.sku) {
          return (item.qty = payload.qty);
        }
      });
    },
    setDiscount(state, { payload }) {
      // the BE only applies the first promotion code if there are multiple, so add new promo as first obj entry
      state.discounts = { [payload]: 0, ...state.discounts };

      // track code in manual discounts as well to differentiate between BE credit auto-application
      const isDiscountSaved = state.manualDiscounts.includes(payload);
      if (!isDiscountSaved) state.manualDiscounts.push(payload);
    },
    setUrlDiscount(state, { payload }) {
      state.urlDiscount = payload;
      state.discounts = { [payload]: 0, ...state.discounts };
    },
    removeDiscount(state, { payload }) {
      delete state.discounts[payload];
    },
    setGiftState(state, { payload }) {
      state.isGift = payload;
    },
    toggleGiftCheckoutState(state) {
      state.isGift = !state.isGift;
    },
    // set a value (value) into cart property (label)
    setCartValue(state, { payload }) {
      state[payload.label] = payload.value;
    },
    // set values from Order Preview
    fetchCartPreview(state) {
      state.isLoadingCartPreview = true;
    },
    fetchCartPreviewSuccess(state, { payload }) {
      state.cartPreviewZip = payload.zip;
      state.hasBERemovedSeedlings = getTotalItems(payload.items) < getTotalItems(state.items);
      state.subtotalCents = payload.subtotalCents;
      state.shippingMessages = payload.shippingMessages;
      // state.shippingCents = payload.shippingCents; - HEADS UP - shipping gets set by user interaction now
      state.taxCents = payload.taxCents;
      state.totalCentsChange = payload.totalCents - state.totalCents;
      state.totalCents = payload.totalCents;
      state.voucherCents = payload.voucherCents;
      state.voucherUses = {
        byItemSku: normalizeVoucherUses(payload.voucherUses),
      };
      state.items = sortCart(payload.items);
      state.discounts = payload.discounts;
      state.promoCents = payload.promoCents;
      state.giftable = payload.giftable;
      state.giftCents = payload.giftCents;
      state.giftBalanceCents = payload.giftBalanceCents;
      state.giftsWithPurchase = payload.giftsWithPurchase;
      state.creditCents = payload.creditCents;
      state.creditBalanceCents = payload.creditBalanceCents;
      state.minGiftDate = payload.minGiftDate;
      state.manualDiscounts = state.manualDiscounts.filter((md) => Object.keys(state.discounts).includes(md));
      state.shipDate = payload.shipDate;
      state.isLoadingCartPreview = false;
    },
    fetchCartPreviewError(state, { payload }) {
      state.cartPreviewError = payload;
      state.isLoadingCartPreview = false;
    },
    setLastAddedFarmstandId(state, { payload }) {
      state.lastAddedFarmstandId = payload;
    },
    setHasBERemovedSeedlings(state, { payload }) {
      state.hasBERemovedSeedlings = payload;
    },
    setVerifiedAddress(state, { payload: { address, code } }) {
      state.verifiedAddress = {
        ...address,
        code,
      };
      state.hasCalledVerifiedAddress = true;
    },
    setVerifiedAddressError(state, { payload }) {
      state.hasVerifiedAddressError = !!payload;
    },
    fetchShippingOptions(state) {
      state.isShippingOptionsLoading = true;
    },
    fetchShippingOptionsSuccess(state, { payload }) {
      state.shippingOptions = payload;
      state.isShippingOptionsLoading = false;
    },
    fetchShippingOptionsError(state) {
      state.shippingOptions = initialState.shippingOptions;
    },
    openCartModal(state, { payload }) {
      state.sideModalToShow = 'cart';
      state.cartSeedlingsExpanded = !!payload;
    },
    openOrderSummaryModal(state) {
      state.sideModalToShow = 'orderSummary';
    },
    closeModal(state) {
      state.sideModalToShow = '';
      state.cartSeedlingsExpanded = false;
    },
    clearDiscounts(state) {
      state.discounts = initialState.discounts;
    },
    clearCart() {
      return { ...initialState };
    },
    setIsAltDuplicatedOrder(state, { payload }) {
      state.isAltDuplicatedOrder = payload;
      state.isLoadingDuplicateOrder = false;
    },
    setBuyNow(state, { payload }) {
      state.buyNow = payload;
      state.isBuyNowLoaded = true;
    },
    setBuyNowItems(state, { payload }) {
      state.buyNow.items = payload.map((item) => ({
        sku: item.buyable.sku,
        qty: item.count,
        name: item.buyable.name,
        priceCents: item.buyable.priceCents,
        imageUrl: item.buyable.thumbnailUrl,
        category:
          item.buyable?.type === 'BuyablePlant'
            ? shopCategories.SEEDLINGS
            : item.buyable?.type === 'Buyable'
            ? shopCategories.SUPPLIES
            : '',
      }));
    },
    setIsBuyNowLoaded(state, { payload }) {
      state.isBuyNowLoaded = payload;
    },
    setIsBuyNowFetching(state, { payload }) {
      state.isBuyNowFetching = payload;
    },
    // action that doesn't change store, but only triggers the `/cartPreview` axios request on cartMiddleware.js
    loadBuyNowPreview(state) {
      return state;
    },
    addItemBuyNow(state, { payload }) {
      // look to see if item exists in buyNow items already
      const foundItemIdx = state.buyNow.items.findIndex((item) => item.sku === payload.item.sku);
      if (foundItemIdx > -1) {
        // Update the qty
        state.buyNow.items[foundItemIdx].qty += payload.item.qty;
      } else {
        // Push new item into buyNow.items array
        state.buyNow.items.push(payload.item);
      }
    },
    updateItemBuyNow(state, { payload }) {
      state.buyNow.items.forEach((item) => {
        if (item.sku === payload.sku) {
          return (item.qty = payload.qty);
        }
      });
    },
    removeItemBuyNow(state, { payload }) {
      state.buyNow.items = state.buyNow.items.filter((item) => item.sku !== payload);
    },
    clearBuyNow(state) {
      state.buyNow = initialState.buyNow;
      state.isBuyNowLoaded = false;
      state.isBuyNowFetching = false;
    },
    fetchAutofillPendingOrdersSuccess(state, { payload }) {
      const autofillPendingOrders = getSortedAutofillPendingOrders(payload);

      state.autofill.ordersById = autofillPendingOrders.ordersById;
      state.autofill.nextPendingId = autofillPendingOrders.nextPendingId;
      state.autofill.hasFetched = true;
    },
    setIsAutofillFetching(state, { payload }) {
      state.autofill.isFetching = payload;
    },
    updateAutofillPendingOrdersSuccess(state, { payload }) {
      state.autofill.ordersById[payload.payload.id] = normalizeAutofillPendingOrder(payload.payload);
    },
    clearAutofill(state) {
      state.autofill = initialState.autofill;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkDuplicatedOrderAction.toString(), (state) => {
      state.isLoadingDuplicateOrder = true;
    });
    builder.addCase(submitAltPayment.toString(), (state) => {
      state.isCheckingOut = true;
    });
    builder.addMatcher(isAltPayStallAction, (state /*action*/) => {
      state.isCheckingOut = false;
    });
  },
});

const isAltPayStallAction = (action) => {
  return [cancelPayIntentPoll.toString(), submitAltPaymentFailed.toString()].includes(action.type);
};

// Extract the action creators object and the reducer
const { actions, reducer } = cartSlice;

// Extract and export action creators from slice by name
export const {
  addItem,
  addBulkItems,
  addBulkItemsAndDiscount,
  addBulkItemsAndDiscountArray,
  replaceSeedlings,
  removeItem,
  removeItemOfType,
  removeItemsOfCategories,
  updateItem,
  setDiscount,
  setUrlDiscount,
  removeDiscount,
  setCartValue,
  setCartOrder,
  setVerifiedAddress,
  setVerifiedAddressError,
  fetchShippingOptions,
  fetchShippingOptionsSuccess,
  fetchShippingOptionsError,
  setLastAddedFarmstandId,
  setHasBERemovedSeedlings,
  clearDiscounts,
  clearCart,
  fetchCartPreview,
  fetchCartPreviewSuccess,
  fetchCartPreviewError,
  openCartModal,
  openOrderSummaryModal,
  closeModal,
  toggleGiftCheckoutState,
  setGiftState,
  setGiftAmount,
  setGiftImage,
  setIsAltDuplicatedOrder,
  setBuyNow,
  setBuyNowItems,
  setIsBuyNowLoaded,
  setIsBuyNowFetching,
  loadBuyNowPreview,
  addItemBuyNow,
  updateItemBuyNow,
  removeItemBuyNow,
  clearBuyNow,
  fetchAutofillPendingOrdersSuccess,
  setIsAutofillFetching,
  updateAutofillPendingOrdersSuccess,
  clearAutofill,
} = actions;

export const doLogout = ({ auth }) => ({ type: 'LOGOUT', payload: { auth } });

export const giftValuesChanged = createAction('cart/GIFT_VALUES_CHANGED');

export const checkDuplicatedOrderAction = createAction('cart/CHECK_DUPLICATED_ORDER');

export const cancelPayIntentPoll = createAction('cart/CANCEL_PAY_INTENT_POLL');
export const payIntentFailed = createAction('cart/PAY_INTENT_FAILED');
export const payIntentSucceeded = createAction('cart/PAY_INTENT_SUCCEEDED');
export const submitAltPayment = createAction('cart/SUBMIT_ALT_PAY');
export const submitAltPaymentSucceeded = createAction('cart/SUBMIT_ALT_PAY_SUCCEEDED');
export const submitAltPaymentFailed = createAction('cart/SUBMIT_ALT_PAY_FAILED');
export const retrySubmitAltPayment = createAction('cart/RETRY_SUBMIT_ALT_PAY');
export const submitRecommendedOrder = createAction('cart/SUBMIT_RECOMMENDED_ORDER');
export const submitAutofillOrder = createAction('cart/SUBMIT_AUTOFILL_ORDER');
export const checkoutSucceeded = createAction('cart/CHECKOUT_SUCCEEDED');

/**
 * Just for local use of fetchCartPreviewSuccess action
 */
const getTotalItems = (items) => {
  return items?.reduce((acc, curr) => acc + curr.qty, 0);
};

// Minimum # of seedlings that need to be in cart to checkout
const MIN_NUM_SEEDLINGS = 1;

export const getCart = createSelector([(state) => state.cart], (cart) => cart);

export const getCartItems = createSelector(getCart, (cart) => cart.items);

export const getSortedCartItems = createSelector(getCartItems, (cartItems) => sortCart(cartItems));

export const getCartSideModalToShow = createSelector(getCart, (cart) => cart.sideModalToShow);

export const getCartHasBERemovedSeedlings = createSelector(getCart, (cart) => cart.hasBERemovedSeedlings);

export const getCartTotalCents = createSelector(getCart, (cart) => cart.totalCents);

export const getLastAddedFarmId = createSelector(getCart, (cart) => cart.lastAddedFarmstandId || null);

export const getCartVoucherCents = createSelector(getCart, (cart) => cart.voucherCents);
export const getVoucherUses = createSelector(getCart, (cart) => cart.voucherUses.byItemSku);
export const getVoucherUsesByItemSku = createSelector(
  [getVoucherUses, (_, itemSku) => itemSku],
  (byItemSku, itemSku) => byItemSku[itemSku]
);
export const getVouchersUsedCountByItemSku = createSelector((state, itemSku) => getVoucherUsesByItemSku(state, itemSku), getVouchersCount);
export const getVouchersUsedDiscountCentsByItemSku = createSelector(
  (state, itemSku) => getVoucherUsesByItemSku(state, itemSku),
  getVouchersDiscount
);
// get all voucher uses for individually added seedlings in cart
export const getSeedlingVouchersUsed = createSelector([getCartItems, getVoucherUses], getSeedlingVoucherUses);
export const getSeedlingVouchersUsedCount = createSelector(getSeedlingVouchersUsed, getVouchersCount);
export const getSeedlingVouchersUsedDiscountCents = createSelector(getSeedlingVouchersUsed, getVouchersDiscount);

export const getCartSubTotalCents = createSelector(getCart, (cart) => cart.subtotalCents);

export const getCartTaxCents = createSelector(getCart, (cart) => cart.taxCents);

export const getCartShippingCents = createSelector(getCart, (cart) => cart.shippingCents);

export const getCartGiftBalanceCents = createSelector(getCart, (cart) => cart.giftBalanceCents);

export const getCartGiftCents = createSelector(getCart, (cart) => cart.giftCents);

export const getCartCreditBalanceCents = createSelector(getCart, (cart) => cart.creditBalanceCents);

export const getCartCreditCents = createSelector(getCart, (cart) => cart.creditCents);

export const getCartPromoCents = createSelector(getCart, (cart) => cart.promoCents);

export const getCartShipDate = createSelector(getCart, (cart) => cart.shipDate);

export const getCartSeedlingsExpanded = createSelector(getCart, (cart) => cart.cartSeedlingsExpanded);

export const getCartDiscounts = createSelector(getCart, (cart) => cart.discounts);

export const getIsLoadingCartPreview = createSelector(getCart, (cart) => cart.isLoadingCartPreview);

export const getCartManualDiscounts = createSelector(getCart, (cart) => cart.manualDiscounts);

export const getMinGiftDate = createSelector(getCart, (cart) => cart.minGiftDate);

export const getTestGiftSendTime = createSelector(getCart, (cart) => cart.testGiftSendTime);

export const getIsGift = createSelector(getCart, (cart) => cart.isGift);

export const getCartIsGiftable = createSelector(getCart, (cart) => cart.giftable);

export const getCanUpdateAddress = createSelector(getCart, (cart) => cart.canUpdateAddress);

export const getIsCheckingOut = createSelector(getCart, (cart) => cart.isCheckingOut);

export const getPaymentType = createSelector(getCart, (cart) => cart.paymentType);

export const getTermsOfServiceVersion = createSelector(getCart, (cart) => cart.termsOfServiceVersion);

export const getCartPreviewError = createSelector(getCart, (cart) => cart.cartPreviewError);

export const getCartPreviewZip = createSelector(getCart, (cart) => cart.cartPreviewZip);

export const getCartShippingMessages = createSelector(getCart, (cart) => cart.shippingMessages);

export const getSelectedShippingOptions = createSelector(getCart, (cart) => cart.selectedShippingOptions);

export const getVerifiedAddress = createSelector(getCart, (cart) => cart.verifiedAddress);

export const getUrlDiscount = createSelector(getCart, (cart) => cart.urlDiscount);

export const getForceCartPreviewFetchOnCheckout = createSelector(getCart, (cart) => cart.forceCartPreviewFetchOnCheckout);

export const getIsAltDuplicatedOrder = createSelector(getCart, ({ isAltDuplicatedOrder, isLoadingDuplicateOrder }) => ({
  isDuplicatedOrder: isAltDuplicatedOrder,
  isLoadingDuplicateOrder,
}));

export const getBuyNow = createSelector(getCart, (cart) => cart.buyNow);

export const getIsBuyNowLoaded = createSelector(getCart, (cart) => cart.isBuyNowLoaded);

export const getIsBuyNowFetching = createSelector(getCart, (cart) => cart.isBuyNowFetching);

export const getBuyNowItems = createSelector(getBuyNow, (buyNow) => buyNow.items);

export const getBuyNowSeedlings = createSelector(getBuyNowItems, (items) => {
  return items.filter((item) => item.category === shopCategories.SEEDLINGS);
});

export const getBuyNowSupplies = createSelector(getBuyNowItems, (items) => {
  return items.filter((item) => item.category === shopCategories.SUPPLIES);
});

// Selector for getting number of seedlings in cart, counting also the seedlings in the bundle
export const getNumSeedlingsInCart = createSelector(getCartItems, (items) => {
  return items.reduce((acc, curr) => {
    if (curr.category === SEEDLINGS) {
      return acc + curr.qty;
    }
    if (curr.category === BUNDLES || curr.category === CUSTOM_BUNDLES) {
      return acc + curr.qty * curr.plantCount;
    }
    return acc;
  }, 0);
});

export const getOnlyGiftCardInCart = createSelector(getCartItems, (items) => items?.length === 1 && items[0].sku === GIFT_CARD.id);

export const getNotEnoughSeedlingsInCart = createSelector(getNumSeedlingsInCart, (numSeedlings) => {
  return numSeedlings > 0 && numSeedlings < MIN_NUM_SEEDLINGS;
});

// Selector for getting an item in cart
export const getItemInCart = createSelector([getCartItems, (_, sku) => sku], (items, sku) => {
  // If no items in cart, return null
  if (!items) return null;

  // iterate through the current items in the cart, searching for the sku we've been given
  return items.find((cartItem) => cartItem.sku === sku);
});

export const getPlantsInCart = createSelector(getCartItems, (items) => {
  // If no items in cart, return null
  if (!items) return null;

  // iterate through the current items in the cart, searching for all Seedlings and Bundles
  const plantsCategories = [SEEDLINGS, BUNDLES, CUSTOM_BUNDLES];
  return items.filter((cartItem) => plantsCategories.includes(cartItem.category));
});

export const getFarmstandsInCart = createSelector(getCartItems, (items) => {
  if (!items) return null;
  return items.filter((cartItem) => cartItem.category === FARMSTAND);
});

export const getSuppliesInCart = createSelector(getCartItems, (items) => {
  if (!items) return [];

  return items.filter((cartItem) => cartItem.category === SUPPLIES || cartItem.category === EXTENSION);
});

export const getVouchersInCart = createSelector(getCartItems, (items) => {
  if (!items) return [];

  return items.filter((cartItem) => cartItem.category === VOUCHERS);
});

export const getLightsInCart = createSelector(getCartItems, (items) => {
  if (!items) return null;

  return items.filter((cartItem) => HALO_LIGHTS_SKUS.includes(cartItem.sku));
});

// Selector for checking if there is a gift card in the cart
export const getCartHasGiftCard = createSelector(getCartItems, (items) => {
  // If no items in cart, return null
  if (!items) return null;

  // iterate through the current items in the cart, searching for a giftcard
  return items.findIndex((cartItem) => cartItem.sku === GIFT_CARD.id) > -1;
});

// Selector for checking if there is a farmstand in the cart
export const getCartHasFarmstand = createSelector(getCartItems, (items) => {
  // If no items in cart, return null
  if (!items) return null;

  // iterate through the current items in the cart, searching for a farmstand
  return items.findIndex((cartItem) => cartItem.category === FARMSTAND) > -1;
});

export const getV2InCart = createSelector(getCartItems, (items) => {
  return items.findIndex((cartItem) => cartItem.category === FARMSTAND && cartItem.subcategory === shopSubCategories.NOOK) > -1;
});

// Selector to find out whether or not to show affirm
export const getShouldShowAffirm = createSelector(
  [getAffirmMin, getCartHasGiftCard, getCartTotalCents],
  (affirmMin, isGiftCardInCart, cartTotal) => {
    return cartTotal / 100 >= affirmMin && !isGiftCardInCart;
  }
);

export const getShippingOptions = createSelector(getCart, (cart) => cart.shippingOptions);

export const getShippingOptionsById = createSelector(getShippingOptions, (shippingOptions) =>
  Object.fromEntries(shippingOptions.map((option) => [option.category, option]))
);

export const getIsVerifyAddressReady = createSelector([getCart, getOnlyGiftCardInCart], (cart, onlyGiftCardInCart) => {
  return (
    onlyGiftCardInCart ||
    (cart.hasCalledVerifiedAddress && (!cart.hasVerifiedAddressError || (cart.hasVerifiedAddressError && cart.shouldOverrideAddress)))
  );
});

// Selector for getting an item in BuyNow items
export const getItemInBuyNow = createSelector([getBuyNowItems, (_, sku) => sku], (items, sku) => {
  // If no items in buyNow items, return null
  if (!items) return null;

  // iterate through the current items searching for the sku we've been given
  return items.find((item) => item.sku === sku);
});

// Selector for getting number of items in buyNow
export const getTotalBuyNowItems = createSelector(getBuyNowItems, (items) => {
  return items.reduce((total, item) => (total += item.qty), 0);
});

// Selector for getting number of items in buyNow
export const getTotalBuyNowSeedlings = createSelector(getBuyNowItems, (items) => {
  return items.filter((item) => item.category === shopCategories.SEEDLINGS).reduce((total, item) => (total += item.qty), 0);
});
// autofill selectors
export const getAutofill = createSelector(getCart, (cart) => cart.autofill);
export const getIsAutofillLoaded = createSelector(getAutofill, (autofill) => autofill.hasFetched);
export const getIsAutofillFetching = createSelector(getAutofill, (autofill) => autofill.isFetching);
export const getAutofillPendingOrder = createSelector(
  getAutofill,
  (autofill) => autofill.ordersById[autofill.nextPendingId] || DEFAULT_OBJECT
);

export const getItemInAutofillPendingOrder = createSelector([getAutofillPendingOrder, (_, id) => id], (order, id) => {
  return Object.values(order?.items?.byId || DEFAULT_OBJECT).find(({ buyableId }) => buyableId === id);
});

export const getAutofillPendingOrderItems = createSelector(getAutofillPendingOrder, (order) => {
  if (!order?.items?.byId) return [];
  return Object.values(order.items.byId);
});

export const getAutofillPendingOrderSeedlings = createSelector(getAutofillPendingOrder, (order) => {
  if (!order?.items?.byId || !order?.items?.seedlings) return [];
  return order.items.seedlings.map((id) => order.items.byId[id]);
});

export const getAutofillPendingOrderSupplies = createSelector(getAutofillPendingOrder, (order) => {
  if (!order.items?.byId || !order.items?.supplies) return [];
  return order.items.supplies.map((id) => order.items.byId[id]);
});

// Selector for getting number of items in autofill next pending order
export const getTotalAutofillPendingOrderItems = createSelector(getAutofillPendingOrderItems, (items) => {
  if (!items) return 0;
  return items.reduce((total, item) => (total += item.qty), 0);
});

// Selector for getting number of seedlings in autofill next pending order
export const getTotalAutofillPendingOrderSeedlings = createSelector(getAutofillPendingOrderSeedlings, (seedlings) => {
  if (!seedlings) return 0;
  return seedlings.reduce((total, item) => (total += item.qty), 0);
});

/**
 * * verifyAddress - Async Action Creator to hit verify address BE point
 *
 * @param {object} values - address values, address, postStop, city, state, zip
 *
 */
export const verifyAddress = (values) => ({
  type: AXIOS,
  payload: {
    url: '/app/public/verifyShippingAddress',
    method: 'POST',
    withCredentials: true,
    data: values,
    onSuccess: setVerifiedAddress,
    onFailure: setVerifiedAddressError,
  },
});

/**
 * loadRecommendeOrderPreview - Async action creator that returns info about the current user to perform a on click purchase
 */
export const loadRecommendeOrderPreview = (values) => {
  const url = `/app/lgcom/v2/cartPreview`;

  return {
    type: AXIOS,
    payload: {
      url: url,
      data: values,
      withCredentials: true,
      method: 'POST',
      onSuccess: setBuyNow,
      setLoading: setIsBuyNowFetching,
    },
  };
};

/**
 * fetchAutofillPendingOrders - Async Action Creator to hit pendingOrder BE endpoint for autofill pending orders
 */
export const fetchAutofillPendingOrders = (count = 10, statuses = ['PENDING']) => {
  const params = `count=${count}${statuses.reduce((acc, status) => (acc += `&statuses=${status}`), '')}`;
  const url = `/app/lgcom/pendingOrder?${params}`;

  return {
    type: AXIOS,
    payload: {
      url: url,
      method: 'GET',
      onSuccess: fetchAutofillPendingOrdersSuccess,
      setLoading: setIsAutofillFetching,
    },
  };
};

export const addItemToAutofillPendingOrder = ({ pendingOrderId, buyableId, count }) => {
  const params = {
    buyableId,
    count,
  };
  const url = `/app/lgcom/pendingOrder/${pendingOrderId}/item`;

  return {
    type: AXIOS,
    payload: {
      url: url,
      method: 'POST',
      data: params,
      onSuccess: updateAutofillPendingOrdersSuccess,
      setLoading: setIsAutofillFetching,
    },
  };
};

export const updateItemToAutofillPendingOrder = ({ pendingOrderId, itemId, buyableId, count }) => {
  const params = {
    buyableId,
    count,
  };
  const url = `/app/lgcom/pendingOrder/${pendingOrderId}/item/${itemId}`;

  return {
    type: AXIOS,
    payload: {
      url: url,
      method: 'PUT',
      data: params,
      onSuccess: updateAutofillPendingOrdersSuccess,
      setLoading: setIsAutofillFetching,
    },
  };
};

export const deleteTitemFromAutofillPendingOrder = ({ pendingOrderId, itemId }) => {
  const url = `/app/lgcom/pendingOrder/${pendingOrderId}/item/${itemId}`;

  return {
    type: AXIOS,
    payload: {
      url: url,
      method: 'DELETE',
      onSuccess: updateAutofillPendingOrdersSuccess,
      setLoading: setIsAutofillFetching,
    },
  };
};

export const processAutofillPendingOrder = (pendingOrderId) => {
  const url = `/app/lgcom/pendingOrder/${pendingOrderId}/process`;

  return {
    type: AXIOS,
    payload: {
      url: url,
      method: 'POST',
      onSuccess: updateAutofillPendingOrdersSuccess,
      setLoading: setIsAutofillFetching,
    },
  };
};

// Export the reducer as the default
export default reducer;
