const asyncStates = {
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error',
    STATIC: 'static',
};

asyncStates.ALL = Object.values(asyncStates);

export default asyncStates;
