import React, { useEffect } from 'react';
import Modal from '../Modal';
import { Title } from 'elements';

const OSANO_IFRAME_ID = 'osno-dsar-form';

// This is based on the embed script from Osano. Step 5 from https://docs.osano.com/dsar-workflow
const updateOsanoIframeHeight = function (w, d) {
  var t = w.addEventListener ? ['addEventListener', 'message'] : ['attachEvent', 'onmessage'];
  w[t[0]](
    t[1],
    function (e) {
      var iframe = d.getElementById(OSANO_IFRAME_ID);
      if (iframe && e.source && e.origin && e.source === iframe.contentWindow && e.origin === 'https://my.datasubject.com')
        iframe.style.cssText = 'height:' + e.data + 'px;min-width:100%';
    },
    false
  );
};

const OsanoRequestFormModal = ({ isOpen, onCloseAction }) => {
  useEffect(() => {
    if (isOpen) updateOsanoIframeHeight(window, document);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onCloseAction={onCloseAction}>
      <Title content='Do not Sell My Information' />
      <iframe frameBorder='0' id={OSANO_IFRAME_ID} src='https://my.datasubject.com/6olcGSh8XmDF3V0H/12277' width='100%' height='0' scrolling='no' />
    </Modal>
  );
};

export default OsanoRequestFormModal;
