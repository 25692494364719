/**
 * * Footer Component for the Cart on Lettuce Grow
 *
 * @prop {function} closeModal - the function that will close the modal in which the cart lives
 * @prop {object} cart - an object from our redux store representing all cart information
 *
 * ? Should this be refactored to be extensible and used as the Order Summary footer?
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { navigate } from 'gatsby';

import {
  getNotEnoughSeedlingsInCart,
  getShouldShowAffirm,
  getCartSubTotalCents,
  getCartItems,
  getCartPromoCents,
  getCartShippingMessages,
  getCartVoucherCents,
} from 'reduxState/cart';
import paths from 'constants/paths';
import icons from 'constants/icons';
import { formatPrice } from 'utils/cart-utils';
import { trackEnhancedCheckout } from 'utils/googleTagManager';

import { SquaredButton, Text, Tooltip } from 'elements';
import Suggestion from '../suggestion/Suggestion';
import oldBreakpoints from 'constants/breakpoints';

const FooterContainer = styled.section.attrs({
  className: 'FooterContainer',
})`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 1.25rem;
  display: block;
  background: ${({ theme }) => theme.farmProject.gray_2};
  box-sizing: border-box;
  z-index: 1;

  box-shadow: 0px -8px 20px -18px rgba(0, 0, 0, 0.5);
`;

const TotalsContainer = styled.section`
  p {
    font-size: 0.7rem;
    span {
      font-size: 0.6rem;
      font-style: italic;
    }
  }

  p.affirm-product-modal {
    span {
      font-size: 0.8rem;
      font-style: initial;
    }
  }
`;

const FooterLineItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .Text {
    text-align: right;
    width: 100%;
    text-decoration: line-through;
  }

  div {
    text-align: right;
  }
`;

const Total = styled(FooterLineItem)`
  margin: 0 0 1.25rem 0;

  p {
    font-size: 1.2rem;
    font-weight: normal;
    font-family: ${({ theme }) => theme.farmProject.font_family};
  }

  .affirm-preview-text {
    font-size: 0.625rem;
    line-height: normal;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.farmProject.brand_primary};

    span {
      font-style: normal;
    }
  }

  .title-text {
    font-size: 1.25rem;
    line-height: normal;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.farmProject.dark_gray};
    text-align: left;
  }

  .free-shiping-tag {
    font-size: 0.75rem;
    line-height: normal;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.farmProject.dark_gray};
    text-align: left;
    margin-top: 0.125rem;
  }

  .price-text {
    font-size: 1.25rem;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ theme }) => theme.farmProject.brand_primary};
    margin-bottom: 0.25rem;
  }

  .shipping-tooltip {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      font-size: 0.7rem;
    }

    .free-shiping-tag {
      margin: 0;
      font-size: 0.7rem;
    }

    .Icon {
      overflow: visible;

      &:after {
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
        background-color: white;
        margin: 0;
        left: 1.3rem;
        top: -0.2rem;
        z-index: 10;
      }
    }

    .TooltipStyled {
      border-radius: 0;
      background-color: white;
      border: none;
      box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.3);
      top: -1.7rem;
      left: auto;
      transform: translate(1.9rem, -50%);

      .Text {
        font-size: 0.7rem;
        text-decoration: none;
        text-align: left;
        letter-spacing: 0;
      }
    }
  }
`;

const FooterActions = styled.section`
  text-align: center;

  div {
    padding: 0;

    button {
      width: 100%;
      margin: 10px 0;
    }
  }

  .MoreSeedlings {
    padding: 0.5rem 0.125rem;
  }

  > p {
    font-size: 0.75rem;
    font-weight: normal;
    font-family: ${({ theme }) => theme.farmProject.font_family};
    color: ${({ theme }) => theme.farmProject.dark_gray};
    padding-bottom: 18px;
    text-decoration: underline;
  }

  .keep-shopping {
    text-decoration: underline;
    font-weight: normal;
    font-family: ${({ theme }) => theme.farmProject.font_family};
    letter-spacing: 0.6px;
    color: ${({ theme }) => theme.farmProject.dark_gray};
    margin-top: 1.4rem;
    padding-bottom: 4rem;

    @media (min-width: ${oldBreakpoints.MOBILE}px) {
      padding-bottom: 18px;
    }
  }

  .shippingTaxes {
    letter-spacing: 1px;
    text-decoration: none;
  }
`;

const CartFooter = ({ closeModal }) => {
  const subtotalCents = useSelector(getCartSubTotalCents);
  const items = useSelector(getCartItems);
  const promoCents = useSelector(getCartPromoCents);
  const voucherCents = useSelector(getCartVoucherCents);
  const shouldShowAffirm = useSelector(getShouldShowAffirm);
  const isNotEnoughSeedlings = useSelector(getNotEnoughSeedlingsInCart);
  const shippingMessages = useSelector(getCartShippingMessages);
  const shouldShowAffirmTooltip = shouldShowAffirm && !!subtotalCents;
  const shouldShowSubTotal = !!promoCents || !!voucherCents;
  // special case for cart preview where "total" displayed should take promo and voucher discounts into account, but not account credit, tax or shipping
  const previewTotal = Math.max((subtotalCents - promoCents - voucherCents) / 100, 0);

  const closeModalAndPushToDataLayer = () => {
    closeModal();
    navigate(paths.CHECKOUT);
    trackEnhancedCheckout({ actionField: { step: 1 }, products: items });
  };

  useEffect(() => {
    const onAmountChange = (newAmount) => {
      if (newAmount) {
        //Refresh Affirm modal on Cart prices changes!
        window.affirm && window.affirm.ui && window.affirm.ui.refresh && window.affirm.ui.refresh();
      }
    };

    onAmountChange(subtotalCents);
  }, [subtotalCents]);

  return (
    <FooterContainer>
      <TotalsContainer>
        {shouldShowSubTotal && (
          <FooterLineItem>
            <Text modifiers='brandFont'>{formatPrice(subtotalCents / 100)}</Text>
          </FooterLineItem>
        )}
        <Total>
          <div className='text-wrapper'>
            <p className='title-text'>SUBTOTAL</p>
            {!!shippingMessages?.length && (
              <div className='shipping-tooltip'>
                <p className='free-shiping-tag'>Estimated Shipping</p>
                <Tooltip text={shippingMessages?.join(' ')} />
              </div>
            )}
          </div>
          <div>
            <p className='price-text'>{formatPrice(previewTotal)}</p>
            {/* Should we show affirm? */}
            <div hidden={!shouldShowAffirm}>
              <p className='affirm-preview-text'>
                {`Or $${Math.ceil(previewTotal / 12)}/month at 0% APR`}
                <span
                  data-page-type='cart'
                  /**
                   * Initial Affirm data-amount set as $50 to avoid the error:
                   * 'Affirm: data-amount is lower than the minimum ($50).'
                   * This value is ALWAYS going to be replaced with the correct
                   * Cart total price when the cart data is filled.
                   * This was done like this, because the Cart object is asynchronously loaded
                   * from the '/cartPreview' endpoint, and so, right when the Cart Drawer is opened
                   * the cart.subtotalCents is still 0.
                   * When the cart.subtotalCents is updated an affirm refresh is done in the useEffect.
                   * Another problem here, is that a conditional rendering of this Span results in
                   * a problem like sometimes the Affirm modal not being opened when clicking on
                   * the tooltip icon.
                   * FIXME: Find a better a solution to this problem without hardcoing the data-ammount to $50.
                   */
                  data-amount={previewTotal < 50 ? 5000 : previewTotal * 100}
                  className={`affirm-product-modal icon icon-${icons.INFO}`}
                  hidden={!shouldShowAffirmTooltip}
                />
              </p>
            </div>
          </div>
        </Total>
      </TotalsContainer>
      <FooterActions>
        <Suggestion />
        <SquaredButton
          onClick={closeModalAndPushToDataLayer}
          label='CHECKOUT'
          disabled={isNotEnoughSeedlings}
          modifiers={[isNotEnoughSeedlings && 'disabled', 'fluid', 'tertiary']}
          dataNw='checkout-button'
        />
      </FooterActions>
    </FooterContainer>
  );
};

CartFooter.propTypes = {
  closeModal: PropTypes.func,
};

export default CartFooter;
