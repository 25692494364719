import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import MarketingInner from './MarketingContainer.styled.js';

import { useStopLoading } from 'utils/hooks';

const MarketingContainerNav = ({ configId, modifiers, makerSectionId }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://showside.maker.co/smartnav-v2-inline.js';
    script.sync = true;
    document.body.appendChild(script);
  }, []);
  useStopLoading(true);
  return (
    <MarketingInner modifiers={modifiers}>
      <div id='maker-smartnav-inline' data-config={configId} index={makerSectionId}></div>
    </MarketingInner>
  );
};

MarketingContainerNav.propTypes = {
  configId: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  makerSectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MarketingContainerNav;
