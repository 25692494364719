import { useState, useRef, useEffect } from 'react';

/**
 * * useIsInViewport - Custom hook to determine if element is inViewport
 *
 * @param {number} threshold - threshold option (0-1) % of how much target is in view to trigger callback
 *
 * @return {Array} [isInViewport, targetRef]
 * @return {bool} isInViewport - isInViewport is a bool that's true if target is in viewport
 * @return {node} targetRef - callback Ref for target
 *
 * */
export default function useIsInViewport(threshold = 0.5) {
  const [isInViewport, setIsInViewport] = useState(false);
  const [target, setTargetRef] = useState(null);
  const observer = useRef(null);

  useEffect(() => {
    if (!window.IntersectionObserver) {
      setIsInViewport(true);
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    // Create IntersectionObserver
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio < threshold) {
          // If intersectionRatio is 0, the target left viewport.
          setIsInViewport(false);
        } else {
          // target entered viewport
          setIsInViewport(true);
        }
      },
      { threshold }
    );

    const { current: currentObserver } = observer;
    if (target) currentObserver.observe(target);
    return () => currentObserver.disconnect();
  }, [threshold, target]);

  return [isInViewport, setTargetRef];
}
