import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {};

export const ListLinksStyled = styled.ul.attrs({
  className: 'ListLinks',
})`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  box-sizing: border-box;

  li {
    margin: 0.47rem 0;
  }

  ${applyStyleModifiers(modifiers)};
`;
