import styled from 'styled-components/macro';
import breakpoints from 'theme/Media';

export const WrapSwipe = styled.div.attrs({
  className: 'WrapSwipe',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_2};
  box-shadow: 0px 4px 6px rgba(130, 130, 127, 0.2);
  padding: 1rem;
  display: block;
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  z-index: 20;
  position: fixed;
  top: 134px;
  transition: top 0.3s;

  @media ${breakpoints.large_break} {
    display: none;
  }

  &.fixedTop {
    top: 56px;
  }

  a {
    display: inline-block;
    padding: 0.625rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.3125rem;
    border: solid 1px ${({ theme }) => theme.farmProject.light_gray};
    color: ${({ theme }) => theme.farmProject.light_gray};
    font-family: Rubik, sans-serif;

    &.active {
      background-color: ${({ theme }) => theme.farmProject.dark_green};
      color: ${({ theme }) => theme.farmProject.white};
      border-color: ${({ theme }) => theme.farmProject.dark_green};
    }
  }

  a + a {
    margin-left: 2.25rem;
  }
`;

export const Spacer = styled.div.attrs({
  className: 'Spacer',
})`
  width: 100%;
  min-height: 4.375rem;

  @media ${breakpoints.large_break} {
    display: none;
  }
`;
