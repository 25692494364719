import React from 'react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Shop from './Shop';
import AccountMenu from './AccountMenu';
import { List, ListItem } from './Navigation.styled';
import Search from './Search';

import paths from 'constants/paths';

import { trackClick } from 'utils/googleTagManager';
import PillLabel from 'components/pill-label/PillLabel';

const NOOK_LABEL = 'The Farmstand Nook';

const Menu = ({ isOpen, isPageScrolled }) => {
  const location = useLocation();

  const LINKS = [
    {
      label: NOOK_LABEL,
      path: paths.NOOK_PREORDER,
    },
    {
      label: 'The Farmstand',
      path: paths.FARMSTAND,
    },
    {
      label: 'Seedlings',
      path: paths.SEEDLINGS,
    },
    {
      label: 'Resources',
      path: paths.BLOG,
    },
  ];

  return (
    <List isOpen={isOpen} isPageScrolled={isPageScrolled}>
      <Search isExpanded={true} />
      <Shop />
      {LINKS.map((link) => {
        const isNookLink = link.path === paths.NOOK_PREORDER;
        const urlContainsNookLink = location.pathname.includes(paths.NOOK_PREORDER);
        const isActive = urlContainsNookLink ? isNookLink : location.pathname.includes(link.path);

        return (
          <Link key={link.label} to={link.path} onClick={() => trackClick({ action: link.label })}>
            <ListItem isActive={isActive}>
              {link.label}
              {/* NOOK PRE-ORDER Link is only a TEMP feature.
               * Please, don't make any work on top of it.
               * This should be removed after a while - confirm with product: Amy/Ramiz.
               */}
              {isNookLink && <PillLabel content='Pre-order' modifiers={['lightGreenNoBorder', 'smallFontBold']} />}
            </ListItem>
          </Link>
        );
      })}
      <hr />
      <AccountMenu isOpen={true} />
    </List>
  );
};

Menu.defaultProps = {
  isOpen: false,
  isPageScrolled: false,
};

Menu.propTypes = {
  isOpen: PropTypes.bool,
  isPageScrolled: PropTypes.bool,
};

export default Menu;
