import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AccordionStyled from './Accordion.styled';

const Accordion = ({ setHeight, content, isOpen, clearance }) => {
  const [maxHeight, setMaxHeight] = useState('');
  const contentRef = useRef();

  useEffect(() => {
    const getMaxHeight = () => {
      // check for a default height from parent
      if (setHeight) {
        // set state height if not equal
        if (setHeight !== maxHeight) setMaxHeight(setHeight);
      } else if (contentRef?.current?.offsetHeight > maxHeight || !maxHeight) {
        setMaxHeight(contentRef?.current?.offsetHeight + clearance);
      }
    };
    getMaxHeight();
    /* eslint-disable-next-line */
  }, [setHeight, content, contentRef, clearance]);

  return (
    <AccordionStyled maxHeight={isOpen ? maxHeight : 0}>
      <div className='lg-accordion__inner' ref={contentRef}>
        {content}
      </div>
    </AccordionStyled>
  );
};

Accordion.defaultProps = {
  isOpen: false,
  clearance: 0,
};

Accordion.propTypes = {
  content: PropTypes.any.isRequired,
  isOpen: PropTypes.bool,
  clearance: PropTypes.number,
  setHeight: PropTypes.number,
};

export default Accordion;
