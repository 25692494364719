import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { ContentSection } from 'components';
import { Text, Title, GatsbyImage } from 'elements';

import { ContentWrapper, HowItIsMadeSection, HowItMadeList, HowItMadeItem } from './HowItsMade.styled';

const Item = ({ percentages }) =>
  percentages.map((item) => {
    const id = _get(item, 'sys.id');
    const text = _get(item, 'fields.text');
    return (
      <HowItMadeItem key={id}>
        <Text as='span' content={text} />
      </HowItMadeItem>
    );
  });

const HowItsMade = ({ title, percentages, breakPointImages, description }) => {
  return (
    <HowItIsMadeSection>
      <ContentSection>
        <ContentWrapper>
          <Title as='h3' modifiers='lineHeightNormal' content={title} />
          <HowItMadeList>
            <Item percentages={percentages} />
          </HowItMadeList>
          {!!breakPointImages && <GatsbyImage breakPointImages={breakPointImages} alt={description || title} />}
        </ContentWrapper>
      </ContentSection>
    </HowItIsMadeSection>
  );
};

HowItsMade.propTypes = {
  title: PropTypes.string,
  percentages: PropTypes.array,
  breakPointImages: PropTypes.array,
};

export default HowItsMade;
