import styled from 'styled-components';

import { Flex } from 'components';
import { SquaredButton } from 'elements';
import breakPoints from 'theme/Media';

export const PosAuthWrapper = styled(Flex)`
  flex: 1;
  height: 100vh;
`;

export const FormWrapper = styled(Flex)`
  margin: auto;
  width: 20rem;

  .InputStyled div,
  .MuiInputBase-root,
  .MuiFormControl-root {
    max-width: unset;
  }
  .InputStyled {
    margin-bottom: 0;
    margin-top: 1rem;
  }
  .InputStyled:first-of-type {
    margin-bottom: 0;
    margin-top: 0;
  }

  input.MuiInputBase-input {
    height: 2.5rem;
    line-height: 2rem;
  }

  @media ${breakPoints.large_break} {
    width: 25rem;
  }
`;

export const SubmitButton = styled(SquaredButton)`
  margin-top: 2rem;
  width: 100%;
`;
