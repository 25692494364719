import styled from 'styled-components/macro';

const ConfigurableSectionStyled = styled.div`
  position: relative;
  background: ${(props) => props.backgroundColor};
  .Title {
    color: ${(props) => props.titleTextColor};
  }
  .Text,
  .lg-text,
  li,
  blockquote {
    color: ${(props) => props.bodyTextColor};
  }
  hr {
    border-color: ${(props) => props.bodyTextColor};
  }
  .BackgroundImage {
    position: absolute;
    z-index: -1;
  }
  .ConfigurableSectionCopy {
    margin: 1rem;
  }
  > .Flex {
    padding: 2rem;
  }
  .Image:not(.BackgroundImage) {
    height: 300px;
    width: 100%;
    margin: 1rem;
  }

  .Button {
    margin: 1rem 2rem 1rem 0;
  }
  ${(props) => props.customCSS}
`;

export default ConfigurableSectionStyled;
