import React, { forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Text, SquaredButton } from 'elements';

import { ModalWarningStyled, ModalInner, ButtonGroup } from './WarningModal.styled';

import { trackClick, trackViewed } from 'utils/googleTagManager';

const WarningModal = forwardRef(
  (
    {
      title,
      content,
      cancelCTA = {},
      confirmCTA = {},
      onConfirm,
      shouldCloseOnConfirm = false,
      eventDataOnShow,
      eventDataOnConfirm,
      eventDataOnCancel,
    },
    ref
  ) => {
    const [isShowing, setIsShowing] = useState(false);
    const confirmCallbackRef = useRef();
    const cancelCallbackRef = useRef();

    useEffect(() => {
      if (isShowing && eventDataOnShow?.action) {
        trackViewed(eventDataOnShow);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowing]);

    useImperativeHandle(ref, () => ({
      openModal: (onSuccessCallback, onCancelCallback) => {
        setIsShowing(true);
        if (onSuccessCallback) confirmCallbackRef.current = onSuccessCallback;
        if (onCancelCallback) cancelCallbackRef.current = onCancelCallback;
      },
      closeModal: onCancel,
    }));

    const onCancel = () => {
      if (eventDataOnCancel?.action) trackClick(eventDataOnCancel);

      setIsShowing(false);
      cancelCallbackRef.current?.();
    };

    const handleOnConfirm = () => {
      if (eventDataOnConfirm?.action) trackClick(eventDataOnConfirm);

      onConfirm?.();
      confirmCallbackRef.current?.();
      if (shouldCloseOnConfirm) setIsShowing(false);
    };

    return (
      <ModalWarningStyled modifiers={isShowing && 'show'}>
        <ModalInner>
          <Text as='span' modifiers={['brandFont', 'noLineHeight', 'semibold', 'errorColor']} content={title} />
          <Text modifiers={['brandFont', 'noLineHeight', 'light']} content={content} isHTML />
          <ButtonGroup>
            <SquaredButton type='button' modifiers={['whiteActive', 'lightGrayColor']} label={cancelCTA?.linkText} onClick={onCancel} />
            <SquaredButton type='button' modifiers='tertiary' label={confirmCTA?.linkText} onClick={handleOnConfirm} />
          </ButtonGroup>
        </ModalInner>
      </ModalWarningStyled>
    );
  }
);

WarningModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  cancelCTA: PropTypes.object,
  confirmCTA: PropTypes.object,
  onConfirm: PropTypes.func,
  shouldCloseOnConfirm: PropTypes.bool,
  eventDataOnShow: PropTypes.object,
  eventDataOnConfirm: PropTypes.object,
  eventDataOnCancel: PropTypes.object,
};

WarningModal.displayName = 'WarningModal';

export default WarningModal;
