const contactFormSubjects = {
  GROWING: 'GROWING',
  FARMSTAND: 'FARMSTAND',
  BILLING: 'BILLING',
  DELIVERY: 'DELIVERY',
  GENERAL: 'GENERAL',
  DEMO: 'SCHEDULE A DEMO',
};

export const anonymousUserSubjects = {
  'PRODUCT QUESTIONS': 'Product Questions',
  'GROWING QUESTIONS': 'Growing Questions',
  'PURCHASING QUESTIONS': 'Purchasing Questions',
  'SCHOOL ORDERS': 'School Orders',
  'GENERAL QUESTIONS': 'General Questions',
};

export const existingUserSubjects = {
  GROWING: 'Growing',
  HARVESTING: 'Harvesting',
  SEEDLINGS: 'Seedlings',
  ASSEMBLY: 'Assembly',
  MAINTENANCE: 'Maintenance',
  'PUMP / TIMER': 'Pump / Timer',
  'GLOW RINGS': 'Glow Rings',
  ACCOUNT: 'Account',
  'ORDER STATUS': 'Order Status',
  ORDERING: 'Ordering',
  BILLING: 'Billing',
  RETURNS: 'Returns',
  GENERAL: 'General',
};

export default contactFormSubjects;
