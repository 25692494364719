import { useEffect, useState } from 'react';
import { axiosGet } from 'utils/api-utils';
import { authDomain, isPos } from 'utils/envConfig';
import logError from 'utils/errorHandler';

const locationsList = [{ value: 'westfield', label: 'Westfield' }];
const POS_MEDIUM = 'retail';

/**
 * * useTerminals - Custom hook to fetch POS terminals list
 *
 * @return {object} - locations and terminals lists
 * */

export default function usePOSData() {
  const [locations, setLocations] = useState([]);
  const [terminals, setTerminals] = useState([]);

  useEffect(() => {
    const fetchPOSData = async () => {
      const headers = { headers: { authDomain } };
      try {
        const terminalsList = (await axiosGet('/app/public/stripe/terminal?limit=100', headers)).data?.data || [];
        setLocations(locationsList);
        setTerminals(terminalsList.map((e) => ({ ...e, value: e.id })));
      } catch (err) {
        logError(err);
      }
    };

    if (isPos) fetchPOSData();
  }, []);

  return { locations, terminals, medium: POS_MEDIUM };
}
