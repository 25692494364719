import growingEnvironments from 'constants/growingEnvironments';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCustomBundleSeedlings, getSeedlingsList } from 'reduxState/bundlesSeedlings';

const { INDOOR, DEFAULT } = growingEnvironments;

/**
 * * useBundleSeedlings - Custom hook to fetch Bundles seedlings SKU list, store in redux
 *
 * @return {Array} seedlings SKU list
 * */
export default function useBundleSeedlings(bundleId = null, shouldFetch = true, isNookFarm) {
  // Get redux dispatch function
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const { userSetEnvironment, zip, email } = useSelector((state) => state.user);
  const { environment } = useSelector(({ catalog }) => catalog?.packRecommendation) || {};
  const userEnvironment = isNookFarm ? INDOOR : userSetEnvironment === DEFAULT && !!environment ? environment : userSetEnvironment;
  const seedlings = useSelector((state) => getSeedlingsList(state, bundleId, userEnvironment, zip, email));

  useEffect(() => {
    if (shouldFetch && bundleId && !isFetching && !seedlings.length && userEnvironment && zip) {
      setIsFetching(true);
      dispatch(fetchCustomBundleSeedlings(bundleId, userEnvironment, zip, email));
    }
    if (!!seedlings.length && isFetching) setIsFetching(false);
  }, [dispatch, shouldFetch, seedlings, bundleId, isFetching, userEnvironment, zip, email]);

  return bundleId ? { seedlings, isFetching } : { seedlings: [], isFetching };
}
