import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

import { SlimBanner } from 'components';
import { Text, Icon } from 'elements';
import SavingsIcon from 'elements/icon/assets/savings.svg';

import { useQueryParams, useContent, useBreakpoint } from 'utils/hooks';
import { resetReservation } from 'reduxState/reservation/reservation';
import { PROMO_BANNER, GIFT_CARD, PROMO_BANNER_MESSAGES } from 'constants/contentful';

const PromoBanner = ({ isAbbreviated }) => {
  let [text, setText] = useState('');

  const { promoCode } = useQueryParams('pc');
  const cart = useSelector((state) => state.cart);
  const reservation = useSelector((state) => state.reservation);
  const dispatch = useDispatch();
  const content = useContent(PROMO_BANNER);
  const messages = useContent(PROMO_BANNER_MESSAGES)?.[0]?.fields?.data;
  const viewportSize = useBreakpoint();

  useEffect(() => {
    if (!content?.[0]) return;
    if (!!promoCode && !cart.discounts[promoCode]) {
      // if promo code in url is not valid
      setText(messages?.invalidUrlPromoCode);
    } else if (reservation.hasExpired) {
      setText(messages?.expiredUrlPromoCode);
    } else if (cart.giftBalanceCents) {
      // gift only messaging
      setText(content[0]?.fields?.body2?.replace?.('GIFT_CARD_BALANCE', cart ? cart.giftBalanceCents / 100 : 0));
    } else if (!_isEmpty(cart.discounts)) {
      const giftCardGift = cart.giftsWithPurchase?.find((gift) => gift.sku === GIFT_CARD.id);
      if (giftCardGift) {
        setText(messages?.earnedGiftCard?.replace?.('__PROMOCENTS__', giftCardGift.originalPriceCents / 100));
      } else if (isAbbreviated || viewportSize === 'SM') {
        setText(messages?.promoCodeApplied?.replace?.('__PROMOCODE__', promoCode || Object.keys(cart?.discounts)?.[0]));
      } else {
        setText(messages?.promoCodeApplied?.replace?.('__PROMOCODE__', promoCode || Object.keys(cart?.discounts)?.[0]));
      }
    }
  }, [promoCode, cart, content, reservation, isAbbreviated, viewportSize]);

  useEffect(() => {
    return () => {
      if (reservation.hasExpired) {
        // reset on unmount so we don't show expired message again
        dispatch(resetReservation());
      }
    };
    /* eslint-disable-next-line */
  }, []);

  if (cart.isLoadingCartPreview || !content?.[0] || (!promoCode && _isEmpty(cart.discounts) && !reservation.hasExpired)) return null;

  return (
    <SlimBanner modifiers='lightGreen'>
      {!_isEmpty(cart.discounts) && (
        <Icon modifiers='small'>
          <SavingsIcon></SavingsIcon>
        </Icon>
      )}
      <Text content={text} isHTML modifiers={['brandFont', 'small', 'center']}></Text>
    </SlimBanner>
  );
};

PromoBanner.defaultProps = {};
PromoBanner.propTypes = {
  isAbbreviated: PropTypes.bool,
};

export default PromoBanner;
