import React from 'react';
import PropTypes from 'prop-types';

import { Swiper } from 'components';
import { Image } from 'elements';

import { SwiperImageWrapper, ImageWrapper } from './ImageSlider.styled';

const ImageSlider = ({ images = [] }) => {
  const onRenderImageSlide = (item, key) => {
    return (
      <ImageWrapper key={key}>
        <Image picture={item?.fields?.file?.url} alt={item?.fields?.description} />
      </ImageWrapper>
    );
  };

  return (
    <SwiperImageWrapper>
      <Swiper items={images} renderSlideCard={onRenderImageSlide} swiperProps={{ navigation: true, pagination: true, autoplay: true }} />
    </SwiperImageWrapper>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.array,
};

export default ImageSlider;
