import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { Text, Title, LiteVimeoEmbed } from 'elements';

import { FarmstandReviewedStyled, ContentWrapper } from './FarmstandReviewed.styled';

const FarmstandReviewed = ({ title, body, video, modifiers }) => {
  return (
    <FarmstandReviewedStyled modifiers={modifiers}>
      <ContentSection>
        <ContentWrapper>
          <Title content={title} modifiers='secondarySmall' />
          <Text content={body} />
        </ContentWrapper>
        <LiteVimeoEmbed embedCode={video} title='Lettuce Grow' />
      </ContentSection>
    </FarmstandReviewedStyled>
  );
};

FarmstandReviewed.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  video: PropTypes.string,
  modifiers: PropTypes.any,
};

export default FarmstandReviewed;
