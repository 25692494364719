import styled from 'styled-components/macro';

import breakPoints from 'theme/Media';

export const WeAreHereToHelpSection = styled.div.attrs({
  className: 'WeAreHereToHelpSection',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_3};

  .ContentSection {
    .SectionInner {
      .Text {
        text-align: center;
      }
      .Title {
        text-align: center;
      }
      .Link {
        position: relative;

        .Image {
          margin-bottom: 2rem;
        }
      }
      .ContentWrapper {
        .Title {
          margin-bottom: 1rem;
        }

        .Button {
          max-width: 24rem;
          margin: 1.5rem auto 0;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      padding: 5rem 3rem;

      .SectionInner {
        .Link {
          .Image {
            margin-bottom: 4rem;
          }
        }

        .ContentWrapper {
          .Title {
            font-size: 3rem;
            margin-bottom: 1.5rem;
          }

          .Button {
            margin-top: 3rem;
            max-width: 17rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;

        .Link {
          .Image {
            margin-bottom: 0;
          }
        }

        > a {
          width: calc(50% - 2rem);
        }

        .Image {
          margin-left: 0;
          margin-bottom: 0;
        }

        .ContentWrapper {
          width: calc(50% - 2rem);
        }
      }
    }
  }
`;

export const ContactListItem = styled.li.attrs({
  className: 'ContactListItem',
})`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .Text {
    font-size: 0.75rem;

    a {
      line-height: 2rem;
    }

    * {
      font-size: 0.75rem;
      text-align: center;
      line-height: 1rem;
    }
  }
`;
