import React from 'react';
import PropTypes from 'prop-types';

import RadioBase from '@lettucegrow/atoms.radio';

/**
 * * Radio input
 */

const Radio = ({ label, className, isSelected, onChange, dataNw, field }) => {
  return (
    <RadioBase
      label={label}
      className={className}
      isSelected={isSelected}
      onChange={onChange}
      dataNw={dataNw}
      value={field.value}
      name={field.name}
    />
  );
};

Radio.defaultProps = {
  label: '',
  field: {},
};

Radio.propTypes = {
  label: PropTypes.any,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
  dataNw: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
  }),
};

export default Radio;
