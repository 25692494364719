import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import { GatsbyImage, ButtonText, Link, Icon, Text } from 'elements';
import { Flex } from 'components';
import Account from './account/Account';
import MobileNav from './mobile-site-navigation/MobileSiteNavigation';
import Search from './search/Search';
import NavStyled, { NavPageLink, SubNavOverlay } from './SiteNavigation.styled';
import SearchIcon from 'elements/icon/assets/search.svg';
import UserIcon from 'elements/icon/assets/user.svg';
import ChevronIcon from 'elements/icon/assets/chevron-thin.svg';
import CartIcon from 'elements/icon/assets/cart.svg';
import CartFilledIcon from 'elements/icon/assets/filled-cart.svg';
import SandwichIcon from 'elements/icon/assets/menu.svg';
import CloseIcon from 'elements/icon/assets/close.svg';
import ChevronLeft from 'elements/icon/assets/left.svg';
import ChevronRight from 'elements/icon/assets/right.svg';

import paths from 'constants/paths';
import { openCartModal, getCartItems } from 'reduxState/cart';
import { getLoginState, getUserIsFromMobile } from 'reduxState/user';
import { useScrollListener, useToggleBodyScrolling } from 'utils/hooks';
import { useFeatureFlags } from 'app/context/FeatureFlag';
import { lgUrlPrefix } from 'utils/envConfig';

const MOUSE_OUT_OFFSET = 400;

// nav items where only one can be open/active at a time, updated by contentful nav links
const exclusiveNavItems = {
  ACCOUNT: 'ACCOUNT',
  SEARCH: 'SEARCH',
  CART: 'CART',
};

const SiteNavigation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isFromMobile = useSelector(getUserIsFromMobile);
  const [activeNavItem, setActiveNavItem] = useState(null);
  const [hoveredNavItem, setHoveredNavItem] = useState(null);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const isPageScrolled = useScrollListener(20);
  const isLoggedIn = useSelector(getLoginState);
  const cartItems = useSelector(getCartItems);
  const cartItemQty = cartItems.reduce((total, item) => {
    total += item.qty;
    return total;
  }, 0);

  useToggleBodyScrolling(isMobileNavOpen);
  const contentfulFeatureFlags = useFeatureFlags();
  // const isFeatureFlagEnabled = contentfulFeatureFlags?.[FeatureFlags.NAV_V2NAV_KILLSHOP];
  const isNewNavEnabled = contentfulFeatureFlags?.['nav-v2nav-killshop'];

  const data = useStaticQuery(graphql`
    query NavQuery {
      contentfulSectionBlocks(contentful_id: { eq: "2CRUIIJHe1wHPyx9j541On" }) {
        contentful_id
        percentages {
          ... on ContentfulSectionConfigurable {
            contentful_id
            callToActions {
              linkText
              linkUrl
              contentful_id
              target
              accent {
                gatsbyImageData(layout: FIXED, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 60, width: 150)
              }
            }
            title
            image {
              gatsbyImageData(layout: FIXED, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70, width: 300)
            }
            subTitle {
              subTitle
            }
            body {
              raw
            }
          }
        }
      }
    }
  `);

  const links = data.contentfulSectionBlocks.percentages;
  const isActivePath = (path) => location.pathname.includes(path);
  const isCheckout = location.pathname.includes(paths.CHECKOUT);

  // HEADS UP - this mounted flag is used in conditional below to aviod React SSR/Hydration errors (differences in HTML)
  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, []);

  useEffect(() => {
    if (isPageScrolled && !!hoveredNavItem) {
      setHoveredNavItem(null);
    }
  }, [isPageScrolled, hoveredNavItem]);

  useEffect(() => {
    const activeLink = links.find((link) => isActivePath(link.subTitle?.subTitle));
    const activeSubLink = links.find((link) => link.callToActions.map((cta) => cta.linkUrl).find(isActivePath));
    if (activeLink) {
      setActiveNavItem(activeLink.title);
    } else if (activeSubLink) {
      setActiveNavItem(activeSubLink.title);
    }
    //close the menu when we switch routes
    if (!!hoveredNavItem || isMobileNavOpen) {
      setHoveredNavItem(null);
      setIsMobileNavOpen(false);
    }
  }, [location.pathname, location.hash, location.search]);

  const handleCartOpen = () => {
    dispatch(openCartModal());
    handleNavItemClick(exclusiveNavItems.CART)();
  };

  const handleNavItemClick = (item) => () => {
    setActiveNavItem(item);
    if (activeNavItem === item) {
      setActiveNavItem(null);
    }
    // reset hovered nav item if exclusive nav item is active
    if (Object.values(exclusiveNavItems).includes(item)) {
      setHoveredNavItem(null);
    }
  };

  const handleNavItemHover = (item) => () => {
    setHoveredNavItem(item);
    // reset exclusive nav item if active
    if (Object.values(exclusiveNavItems).includes(activeNavItem)) {
      setActiveNavItem(null);
    }
  };

  const handleMouseMove = (ev) => {
    if (ev.clientY > MOUSE_OUT_OFFSET) {
      setHoveredNavItem(null);
    }
  };

  const handleSubLinkClick = (linkUrl) => () => {
    // HEADS UP - WORKAROUND for maker smart nav iframe rendering issues
    const url = new URL(linkUrl, lgUrlPrefix);
    const searchParams = url.searchParams;
    const makerId = searchParams.get('QNCategoryId');
    if (makerId) {
      const makerTab = document.querySelector(`[data-id="${makerId}"]`);
      makerTab && makerTab.click();
    }
  };

  const isLinkActive = (link) => {
    return link.title.toUpperCase() === activeNavItem?.toUpperCase();
  };

  const isLinkHovered = (link) => {
    return link.title.toUpperCase() === hoveredNavItem?.toUpperCase();
  };

  const goBack = () => navigate(-1);

  const goForward = () => navigate(1);

  const renderSubNavOverlay = () => {
    const link = links.find(isLinkHovered);
    const hasImagesInSubLinks = link?.callToActions?.find((cta) => cta.accent);
    const isOpen = !isPageScrolled && link?.title;
    return (
      <SubNavOverlay className='SubNavOverlay' modifiers={['alignCenter']} isOpen={isOpen} isPageScrolled={isPageScrolled}>
        <Text content={link?.title?.toUpperCase()} modifiers={['xxLarge', 'brandFont']} />
        {link?.image && <GatsbyImage image={link.image.gatsbyImageData} />}
        <Flex
          modifiers={['directionColumn', 'alignStart', hasImagesInSubLinks && 'row', !hasImagesInSubLinks && 'wrap']}
          className='SubLinks'
        >
          {link?.callToActions?.map((cta) => {
            if (hasImagesInSubLinks) {
              return (
                <Flex key={cta.contentful_id} className='SubLinkImage'>
                  <Link href={cta.linkUrl}>
                    <GatsbyImage image={cta.accent.gatsbyImageData} />
                  </Link>
                  <Flex modifiers={['directionColumn', 'alignStart']}>
                    <ButtonText label={cta.linkText} href={cta.linkUrl} modifiers={['secondary', 'brandHover']} />
                    {cta.target && <Text content={cta.target} isHTML modifiers='small' />}
                  </Flex>
                </Flex>
              );
            } else {
              return (
                <ButtonText
                  key={cta.contentful_id}
                  label={cta.linkText}
                  href={cta.linkUrl}
                  onClick={handleSubLinkClick(cta.linkUrl)}
                  modifiers={['secondary', 'brandHover']}
                  target={cta.target}
                />
              );
            }
          })}
        </Flex>
      </SubNavOverlay>
    );
  };

  if (!isNewNavEnabled) return null;
  return (
    <NavStyled isPageScrolled={isPageScrolled} onMouseMove={handleMouseMove}>
      {!isCheckout && !isFromMobile && (
        <Icon onClick={() => setIsMobileNavOpen(!isMobileNavOpen)} modifiers={['small', 'darkGrayColor']} className='Sandwich'>
          {isMobileNavOpen ? <CloseIcon /> : <SandwichIcon />}
        </Icon>
      )}
      {isFromMobile && (
        <Flex>
          <Icon modifiers='small' onClick={goBack}>
            <ChevronLeft />
          </Icon>
          <Icon modifiers='small' onClick={goForward}>
            <ChevronRight />
          </Icon>
        </Flex>
      )}
      <Link href={paths.HOME} className='Logo'>
        <StaticImage loading='eager' placeholder='none' src={'./assets/logo.svg'} alt='logo' />
      </Link>
      {!isCheckout && (
        <Flex className='NavPageLinks'>
          {links.map((link) => {
            const { title, subTitle, body } = link;
            return (
              <NavPageLink key={title} onMouseEnter={handleNavItemHover(title)} showUnderline={isLinkActive(link) || isLinkHovered(link)}>
                <ButtonText
                  label={title}
                  onClick={handleNavItemClick(title)}
                  href={subTitle?.subTitle}
                  modifiers={['secondary', 'brandHover']}
                  target={body?.raw}
                />
              </NavPageLink>
            );
          })}

          {renderSubNavOverlay()}
        </Flex>
      )}
      {!isCheckout && (
        <>
          <Flex className='NavActionLinks'>
            <Flex className='NavAccountLinks'>
              <Icon modifiers={['small', isLoggedIn && 'brandStroke']} onClick={handleNavItemClick(exclusiveNavItems.ACCOUNT)}>
                <UserIcon />
              </Icon>
              <Icon modifiers={['small', activeNavItem === exclusiveNavItems.ACCOUNT ? 'rotate90' : 'rotate270']}>
                <ChevronIcon />
              </Icon>
              {activeNavItem === exclusiveNavItems.ACCOUNT && <Account isPageScrolled={isPageScrolled} />}
            </Flex>
            <Icon className='SearchLink' modifiers='small' onClick={handleNavItemClick(exclusiveNavItems.SEARCH)}>
              <SearchIcon></SearchIcon>
            </Icon>
            <Flex as='button' type='button' title='Your Cart' onClick={handleCartOpen} className='CartWrapper'>
              <Icon modifiers='small'>{cartItemQty > 0 && isMounted ? <CartFilledIcon /> : <CartIcon />}</Icon>
              <Text modifiers={['brandFont', 'tiny']}>{isMounted ? (cartItemQty <= 99 ? cartItemQty : '99+') : 0}</Text>
            </Flex>
          </Flex>
          <Search
            onClose={handleNavItemClick(null)}
            isExpanded={activeNavItem === exclusiveNavItems.SEARCH}
            isPageScrolled={isPageScrolled}
          />
          {isMobileNavOpen && <MobileNav links={links} activeNavLink={activeNavItem} onLinkClick={handleNavItemClick} />}
        </>
      )}
    </NavStyled>
  );
};

export default SiteNavigation;
