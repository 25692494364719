import styled from 'styled-components/macro';
import breakPoints from 'theme/Media';

import { Flex } from 'components';

export const GrowEnvironmentToggleStyled = styled(Flex).attrs({
  className: 'GrowEnvironmentToggleStyled',
})`
  margin-top: 1rem;
  > .Text {
    white-space: nowrap;
  }
  .ButtonText {
    .Icon {
      width: 1.5rem;
      margin-right: 0.175rem;
      svg {
        margin-left: 0;
      }
    }
  }
  @media ${breakPoints.medium_break} {
    flex-direction: row;
    margin-top: 0;
  }
`;
