import styled from 'styled-components/macro';

import breakPoints from 'theme/Media';

export const PreSproutedSection = styled.div.attrs({
  className: 'PreSprouted',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.gray_2};

    .SectionInner {
      .ContentWrapper {
        .Image {
          width: 100%;
          height: auto;
          margin-bottom: 3rem;
        }

        .Title {
          font-size: 1.75rem;
          text-align: left;
          max-width: 18rem;
          margin-bottom: 1rem;
        }

        .Text {
          text-align: left;
        }

        .Button {
          width: 100%;
          max-width: 24rem;
          margin-top: 3rem;
          margin-left: 0;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 2.3125rem;
            text-align: left;
            max-width: 20rem;
            margin-bottom: 1.5rem;
          }

          .Text {
            text-align: left;
            max-width: 30rem;
          }

          .Button {
            max-width: 17rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;

        .ContentWrapper {
          width: calc(50% - 1rem);

          .Title {
            font-size: 3rem;
            max-width: 30rem;
          }

          .Image {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export const PreSproutedList = styled.ul.attrs({
  className: 'PreSproutedList',
})`
  padding: 0;
  margin: 2rem 0;
  list-style: none;

  li {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${breakPoints.medium_break} {
    max-width: 25rem;
  }
`;

export const PreSproutedItem = styled.li.attrs({
  className: 'PreSproutedItem',
})`
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;

  &:before {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border: solid 1px ${({ theme }) => theme.farmProject.green};
    border-radius: 100%;
    position: absolute;
    left: 0.5rem;
    top: 0.8rem;
    transform: translate(-50%, -50%);
  }

  .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    color: ${({ theme }) => theme.farmProject.green};
    padding-left: 2rem;
  }
`;
