import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { navigate } from 'gatsby';

import { GatsbyImage, Image } from 'elements';

/* Component to handle image, video, and gatsby/graphql image rendering */

const Media = ({ asset, href, className, nativeWidth, modifiers, altDescription }) => {
  if (_isEmpty(asset.file)) return null;
  const isGatsbyData = !!asset.gatsbyImageData;

  if (isGatsbyData) {
    return <GatsbyImage image={asset.gatsbyImageData} alt={altDescription || asset.description} />;
  }

  return (
    <React.Fragment>
      {asset.file.contentType.includes('image') && (
        <Image
          absoluteLink={href.includes('http') ? href : null}
          imageRouteLink={!href.includes('http') ? href : null}
          className={cx('lg-media', className, {
            'lg-media__pointer': !!href,
          })}
          picture={asset.file.url}
          alt={altDescription || asset.description}
          nativeWidth={nativeWidth}
          modifiers={modifiers}
        />
      )}
      {asset.file.contentType.includes('video') && (
        <video
          onClick={href.includes('http') ? window.open(href) : href ? () => navigate(href) : null}
          className={cx(`lg-media`, 'Video', className, {
            'lg-media__pointer': !!href,
          })}
          controls
          loop
        >
          <source src={asset.file.url} />
        </video>
      )}
    </React.Fragment>
  );
};

Media.defaultProps = {
  asset: { description: '', file: {}, title: '' },
  href: '',
  className: '',
  nativeWidth: null,
  modifiers: '',
};

Media.propTypes = {
  asset: PropTypes.shape({
    gatsbyImageData: PropTypes.object,
    description: PropTypes.string,
    file: PropTypes.object,
    title: PropTypes.string,
  }),
  href: PropTypes.string,
  className: PropTypes.string,
  nativeWidth: PropTypes.number,
  modifiers: PropTypes.any,
};

export default Media;
