import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;

export const SliderSectionStyled = styled.div.attrs({
  className: 'SliderSection',
})`
  .ContentSection {
    .SectionInner {
      .ContentWrapper {
        .Title {
          margin-bottom: 3rem;
          text-align: left;
          max-width: 21rem;
        }

        > .Text {
          font-size: 1rem;
          text-align: left;
          color: ${({ theme }) => theme.farmProject.green};
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 3.4rem;
            max-width: 30rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .ContentWrapper {
            width: calc(50% - 2rem);
          }
        }
      }
    }
  }
`;

export const SliderWrapper = styled.div.attrs({
  className: 'SliderWrapper',
})`
  .swiper-container {
    .swiper-button-prev {
      left: -0.5rem;
    }

    .swiper-button-next {
      right: -0.35rem;
    }
  }

  @media ${breakPoints.large_break} {
    width: calc(50% - 2rem);
  }
`;

export const SliderItem = styled.div.attrs({
  className: 'SliderItem',
})``;

export const ItemInner = styled.div.attrs({
  className: 'ItemInner',
})`
  padding: 0 1.5rem;

  > .Text {
    text-align: left;
    font-family: ${({ theme }) => theme.farmProject.font_family};
    color: ${({ theme }) => theme.farmProject.green};

    span {
      margin-left: 0.25rem;
      padding-left: 0.25rem;
      border-left: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
      color: ${({ theme }) => theme.farmProject.dark_gray};
    }

    &:last-of-type {
      font-family: ${({ theme }) => theme.farmProject.font_family_text};
      color: ${({ theme }) => theme.farmProject.dark_gray};
    }
  }

  .Image {
    margin-top: 1rem;
    margin-bottom: 1.75rem;
  }
`;
