import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { getOpenModal, setCloseModal, MODALS } from 'reduxState/modal';
import { Modal } from '../index';
import { FarmForm } from 'components/index';

import { FarmModalStyled } from './FarmModal.styled';

import { useCatalog } from 'utils/hooks';

const FarmModal = ({ farmstand }) => {
  useCatalog();
  const dispatch = useDispatch();
  const openModal = useSelector(getOpenModal);
  const isModalOpen = openModal?.id === MODALS.FARMSTAND_FORM;

  const closeModal = () => dispatch(setCloseModal());

  if (!isModalOpen) return null;

  return (
    <FarmModalStyled>
      <Modal isOpen={isModalOpen} onCloseAction={closeModal} dataNw='farm-modal'>
        <FarmForm onSubmit={closeModal} farmstand={farmstand} isOnModal />
      </Modal>
    </FarmModalStyled>
  );
};

FarmModal.propTypes = {
  farmstand: PropTypes.object,
};

export default FarmModal;
