import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const TextTooltipWrapperStyled = styled.div.attrs({
  className: 'TextTooltipWrapperStyled',
})`
  display: flex;
  align-items: center;
  margin-top: 0.3rem;

  .Text {
    font-size: 0.725rem;
    letter-spacing: 0.1px;
  }

  .TipIconWrapper 
    .TooltipStyled {
      width: 42vw;
      transform: translate(-64%, -21%);
      padding: 0.75rem 0.75rem;
      background-color: #fff;
      z-index: 3;

      @media ${breakPoints.medium_break} {
        width: 22vw;
      }

      @media ${breakPoints.large_break} {
        width: 15vw;
        transform: translate(-65%, -16%);
        left: 0;
      }
    }
  }
`;
