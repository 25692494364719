import React from 'react';
import { useSelector } from 'react-redux';

import { Icon, Text } from 'elements';
import { LivePlantsWrapper } from './LivePlantsMessage.styled';

import LivePlantsIcon from 'elements/icon/assets/live-plants.svg';

import { getContentMessages } from 'app/reduxState/contentful';

const LivePlantsMessage = () => {
  const message = useSelector(getContentMessages)?.LGE_live_plants_warning || '';

  if (!message) return null;
  return (
    <LivePlantsWrapper>
      <Icon>
        <LivePlantsIcon />
      </Icon>
      <Text content={message} modifiers={['brandFont', 'normalLetterSpace', 'lineHeightSmall', 'small', 'bold']} />
    </LivePlantsWrapper>
  );
};

export default LivePlantsMessage;
