import { axiosPost } from 'utils/api-utils';
import { environment } from 'utils/envConfig';

/**
 * * emailSignup - Newsletter Signup Submit
 *
 * @param {Object} emailObj - params object
 * @param {string} emailObj.email - user email for signup
 * @param {string} emailObj.formType - 'footerNewsletter' || 'lettuce'
 * @param {string} [emailObj.zip] - user zip code for signup
 * @param {string} emailObj.countryId - user Country code for signup
 * @param {boolean} emailObj.newsletterOptIn - flag for user to opt in or not to receive Newsletter
 * @param {Object} [emailObj.capiData] - user data to be sent to FB CAPI through GTM events
 *
 * @return {} axios response or null if error
 * */

export const emailSignup = async ({ email, formType, zip, countryId, newsletterOptIn = true, capiData = {} }) => {
  // identify user in LogRocket with email
  if (environment === 'production') {
    window.LogRocket && window.LogRocket.identify(email);
  }

  const body = {
    email,
    zip,
    countryId,
    newsletterOptIn,
    formType,
  };

  const response = await axiosPost('/app/public/newsletterSignup', body, { withCredentials: true });
  const isSuccessResponse = response.status === 200;

  let event;
  if (isSuccessResponse) {
    event = 'formSubmitted';
  } else {
    event = 'formSubmitFailure';
  }

  // Trigger GTM event based on whether or not we got a response from BE
  window.dataLayer?.push({
    event,
    formId: 'newsletter',
    ...(isSuccessResponse ? capiData : {}),
  });

  return response;
};

export default emailSignup;
