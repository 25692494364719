import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const ModalButtonGroup = styled.div.attrs({
  className: 'ModalButtonGroup',
})`
  display: flex;
  flex-direction: column;
  align-items: center;

  .Button {
    margin-bottom: 1rem;
  }

  .Button + .Button {
    margin-bottom: 0;
  }

  @media ${breakPoints.medium_break} {
    flex-direction: row;
    .Button {
      margin-bottom: 0;
      margin-right: 1rem;
    }

    .Button + .Button {
      margin-right: 0;
    }
  }
`;

export const ModalPlantsRow = styled.div.attrs({
  className: 'ModalPlantsRow',
})`
  display: flex;
  justify-content: center;
  align-items: baseline;

  .Text {
    margin-bottom: 0;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    margin: 0 0.125rem 0 0.25rem;
    &:last-of-type {
      margin-bottom: 0rem;
    }
  }

  @media ${breakPoints.medium_break} {
    .Text {
      margin-bottom: 0;
    }
  }
`;
