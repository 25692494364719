import { createSlice, createSelector } from '@reduxjs/toolkit';
import { AXIOS } from 'constants/redux';

/**
 * * Global UI - Redux Reducer - persists UI related states for each page or site-wide
 *
 * @param state
 *
 */

export const initialState = {
  seedlingsLandingPage: {
    search: '',
    filters: {
      type: [],
      availability: [],
    },
  },
  farmProductPage: {
    selectedSize: 24,
  },
  checkout: {
    optInAutofillWithPurchase: false,
  },
  countries: [],
  isFetchingCountries: false,
  isCountriesFetched: false,
  areProductReviewsMounted: false,
};

const globalUISlice = createSlice({
  name: 'globalUI',
  initialState,
  reducers: {
    updateSeedlingsLPField(state, { payload }) {
      state.seedlingsLandingPage[payload.key] = payload.value;
    },
    clearSeedlingsLPField(state, { payload }) {
      state.seedlingsLandingPage[payload] = initialState.seedlingsLandingPage[payload];
    },
    updateFarmProductPage(state, { payload }) {
      state.farmProductPage[payload.key] = payload.value;
    },
    clearFarmProductPage(state) {
      state.farmProductPage = initialState.farmProductPage;
    },
    setCountriesFetchError(state) {
      state.isFetchingCountries = false;
    },
    setCountries(state, { payload }) {
      state.isFetchingCountries = false;
      state.isCountriesFetched = true;
      state.countries = payload.countries;
    },
    setAreProductReviewsMounted(state, { payload }) {
      state.areProductReviewsMounted = payload;
    },
    optInAutofillWithPurchase(state, { payload }) {
      state.checkout.optInAutofillWithPurchase = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AXIOS, (state, { payload }) => {
      if (payload.url === '/app/geolocation/country') {
        state.isFetchingCountries = true;
      }
    });
  },
});

const { actions, reducer } = globalUISlice;

export const getGlobalUIState = (state) => state.globalUI;

export const getCountries = createSelector(getGlobalUIState, (globalUI) => globalUI.countries);

export const getIsFetchingCountries = createSelector(getGlobalUIState, (globalUI) => globalUI.isFetchingCountries);

export const getIsCountriesFetched = createSelector(getGlobalUIState, (globalUI) => globalUI.isCountriesFetched);

export const getFarmProductPage = createSelector(getGlobalUIState, (globalUI) => globalUI.farmProductPage);

export const getFarmDefaultSize = createSelector(getFarmProductPage, (farmProductPage) => farmProductPage.selectedSize);

export const getAreProductReviewsMounted = createSelector(getGlobalUIState, (globalUI) => globalUI.areProductReviewsMounted);

export const shouldOptInAutofillWithPurchase = createSelector(getGlobalUIState, (globalUI) => globalUI.checkout.optInAutofillWithPurchase);

export const getCountriesState = createSelector(
  [getCountries, getIsFetchingCountries, getIsCountriesFetched],
  (countries, isFetchingCountries, isCountriesFetched) => ({
    countries,
    isFetchingCountries,
    isCountriesFetched,
  })
);

export const {
  updateSeedlingsLPField,
  clearSeedlingsLPField,
  updateFarmProductPage,
  clearFarmProductPage,
  setCountries,
  setCountriesFetchStart,
  setCountriesFetchError,
  setAreProductReviewsMounted,
  optInAutofillWithPurchase,
} = actions;

export default reducer;

export const fetchUserCountry = () => {
  return {
    type: AXIOS,
    payload: {
      url: '/app/geolocation/country',
      method: 'GET',
      onSuccess: setCountries,
      onFailure: setCountriesFetchError,
    },
  };
};
