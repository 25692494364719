import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, CardRecipe } from 'components';

import { Title } from '../../elements';

import { CardRecipeSliderStyled } from './CardRecipeSlider.styled';

const recipeSliderConfig = {
  loop: true,
  speed: 500,
  slidesPerView: 1,
  initialSlide: 1,
  navigation: true,
  pagination: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    480: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    640: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 16,
      navigation: false,
    },
  },
};

const onRenderRecipeCard = (recipe) => {
  return (
    <CardRecipe
      title={recipe?.fields?.title}
      key={recipe?.fields?.slug}
      slug={recipe?.fields?.slug}
      image={recipe?.fields?.mainImage?.fields?.file?.url}
      imageAlt={recipe?.fields?.mainImage?.fields?.description}
      author={recipe?.fields?.author?.fields?.name}
      time={recipe?.fields?.prepTime}
      recipeContent={recipe?.fields?.introSentence}
    />
  );
};

const CardRecipeSlider = ({ items, titleSlider }) => {
  return (
    <CardRecipeSliderStyled>
      <Title modifiers='tertiarySmall' content={titleSlider} />
      <Swiper items={items} swiperProps={recipeSliderConfig} renderSlideCard={onRenderRecipeCard} />
    </CardRecipeSliderStyled>
  );
};

CardRecipeSlider.defaultProps = {
  titleSlider: 'What’s on the menu?',
};

CardRecipeSlider.propTypes = {
  items: PropTypes.array,
  children: PropTypes.node,
  titleSlider: PropTypes.string,
};

export default CardRecipeSlider;
