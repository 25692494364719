import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { useLocation } from '@reach/router';

import { SquaredButton, Text, Icon } from 'elements';
import { Paging, Loading, ReviewContent, ReviewFooter } from 'components';

import {
  ReviewList,
  ReviewItem,
  Review,
  CustomerInfo,
  CustomerAvatar,
  CustomerInfoContent,
  ReviewListHeader,
  ProductInfo,
} from '../ReviewsAndQuestions.styled';
import ModalReview from './modal-review/ModalReview';

import Check from 'elements/icon/assets/circle-check.svg';

import {
  YOTPO_GLOW_RINGS_ID,
  YOTPO_FARMSTAND_ID,
  getReviewsById,
  getTotalReviews,
  fetchReviewsByProduct,
  fetchSiteReviews,
} from 'reduxState/userGenerated/userGenerated';
import { setAreProductReviewsMounted } from 'reduxState/globalUI';
import { formatShortDate } from 'utils/date-utils';

const PAGE_SIZE = 10;

const ReviewListComponent = ({ productId, openReviewForm }) => {
  const [modalContent, setModalContent] = useState(null);
  const reviews = useSelector((state) => getReviewsById(state, productId));
  const totalReviews = useSelector((state) => getTotalReviews(state, productId));
  const location = useLocation();
  const page = parseInt(queryString.parse(location.search)?.page) || 1;
  const isLoading = useSelector((state) => state.userGenerated.loading.reviews);
  const dispatch = useDispatch();

  useEffect(() => {
    if (productId) {
      dispatch(fetchReviewsByProduct(productId, PAGE_SIZE, page));
    } else {
      dispatch(fetchSiteReviews(PAGE_SIZE, page));
    }
    /* eslint-disable-next-line */
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(setAreProductReviewsMounted(true));
    return () => {
      dispatch(setAreProductReviewsMounted(false));
    };
  }, []);

  const onClosePictureModal = () => setModalContent(null);

  const handleModalContent = (productTitle) => (content) => setModalContent({ productTitle, ...content });

  // no reviews yet to list
  if (!isLoading && _isEmpty(reviews)) return <SquaredButton label='BE THE FIRST TO WRITE A REVIEW' onClick={openReviewForm} />;

  if (isLoading && _isEmpty(reviews)) return <Loading />;
  return (
    <>
      <ReviewListHeader>
        <Text modifiers={['brandFont']} content={`${totalReviews} Reviews`} />
      </ReviewListHeader>
      <ReviewList>
        {reviews.map((review) => {
          const productTitle = review.productTitle;

          return (
            <ReviewItem key={review.id}>
              <Review>
                <CustomerInfo>
                  <CustomerAvatar>
                    <Text
                      modifiers={['noLineHeight', 'noLetterSpace', 'brandFont']}
                      content={review.user?.display_name?.substring?.(0, 1)}
                    />
                    {review.reviewer_type === 'verified_reviewer' && (
                      <Icon>
                        <Check />
                      </Icon>
                    )}
                  </CustomerAvatar>
                  <CustomerInfoContent>
                    <Text modifiers={['noLineHeight', 'brandColor']} content={review.user?.display_name} />
                    <Text modifiers={['noLineHeight', 'brandFont']} content={formatShortDate(review.created_at)} />
                    <ProductInfo>
                      <Text content='Product:' modifiers='brandFont'></Text>
                      <Text modifiers={['noLineHeight', 'brandFont', 'light']} content={productTitle} />
                    </ProductInfo>
                  </CustomerInfoContent>
                </CustomerInfo>
                <ReviewContent review={review} setModalContent={handleModalContent(productTitle)} />
                <ReviewFooter id={review.id} votesUp={review.votes_up} votesDown={review.votes_down} />
              </Review>
            </ReviewItem>
          );
        })}
      </ReviewList>
      {totalReviews > PAGE_SIZE && (
        <Paging
          pageSize={PAGE_SIZE}
          total={totalReviews}
          currentPage={page}
          baseRoute={`${location.pathname}?page=`}
          suffix='#reviews-and-questions'
        />
      )}
      {!!modalContent && <ModalReview content={modalContent} onClose={onClosePictureModal} />}
    </>
  );
};

ReviewListComponent.propTypes = {
  productId: PropTypes.oneOf([YOTPO_GLOW_RINGS_ID, YOTPO_FARMSTAND_ID]),
  openReviewForm: PropTypes.func.isRequired,
};
export default ReviewListComponent;
