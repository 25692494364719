import React, { useRef, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Title, SquaredButton, Input, Text, ButtonText } from 'elements';
import { ImageUploads, SizeSelector, Flex, WarningModal, Accordion } from 'components';
import { FormWrapper, FormGroup, WrapFields, TextWrapper } from './FarmForm.styled';
import deviceTypes, { V2_SIZE } from 'constants/deviceTypes';
import { MY_FARM } from 'constants/contentful';
import useContent from 'utils/hooks/useContent';

import EnvSelector from './components/env-selector/EnvSelector';
import HeaterSelector from './components/heater-selector/HeaterSelector';
import LightsSelector from './components/lights-selector/LightsSelector';
import TypeSelector from './components/type-selector/TypeSelector';

import { getDevicePayload, getValidationSchema, initialFormValues } from './FormHelpers';
import growingEnvironments from 'constants/growingEnvironments';
import { getContentMessages } from 'app/reduxState/contentful';
import { getV1FarmstandsSizes } from 'app/reduxState/catalog';
import { getUserAuthToken, userCreateFarmDevice, userDeleteFarmDevice, userUpdateFarmDevice } from 'app/reduxState/user';
import { hasErrorOnTouchedFields } from 'utils/form-utils';
import { replaceText } from 'utils/string-utils';

import { useBreakpoint } from 'utils/hooks';

const { OUTDOORS, WITH_HEATER } = growingEnvironments;
const DEVICE_MODAL_ID = '2pc2p5ZYLOnhEGQBzj6kfn';

const LABEL_IDS = {
  name: '32DHVNrndTiaj0Ed4WtZU8',
  zipCode: '4Bauqu265ku2WXGPKXRMHm',
  size: '3WvJVTyTsTkzhTbYvKtLfN',
  growingEnvironment: '2uEytniLxPdDpjH9P6dIjD',
  farmstandHeater: '62eFY1FJJFRsf1upARBNf2',
  glowRings: '2s5dKxxERGyMWxxq2BdaNZ',
};

const FarmForm = ({ farmstand, onSubmit }) => {
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const deleteModalRef = useRef();
  const farmSizes = useSelector(getV1FarmstandsSizes);
  const authToken = useSelector(getUserAuthToken);
  const messages = useSelector(getContentMessages);
  const content = useContent(MY_FARM);
  const screenSize = useBreakpoint();

  const fields = content ? content[0].fields : {};
  const addEditDeviceModalSection = fields?.sections?.find((section) => section.sys.id === DEVICE_MODAL_ID)?.fields || {};
  const { title, lowerTitle, body2, percentages, leftBackgroundAccent, rightBackgroundAccent } = addEditDeviceModalSection;
  const isEdit = !!farmstand?.id;

  const initialValues = isEdit
    ? farmstand?.size === V2_SIZE
      ? { ...farmstand, farmType: deviceTypes.v2 }
      : { ...farmstand, farmType: deviceTypes.farmstand }
    : initialFormValues;

  const submitCTALabel = isEdit ? 'SAVE' : 'ADD TO MY FARM';
  const formTitle = replaceText(title, [{ key: '__action__', value: isEdit ? 'Edit' : 'Add' }]);
  const labelName = percentages.find((item) => item.sys.id === LABEL_IDS.name)?.fields?.text;
  const labelZipCode = percentages.find((item) => item.sys.id === LABEL_IDS.zipCode)?.fields?.text;
  const labelSize = percentages.find((item) => item.sys.id === LABEL_IDS.size)?.fields?.text;
  const labelGrowingEnvironment = percentages.find((item) => item.sys.id === LABEL_IDS.growingEnvironment)?.fields?.text;
  const labelFarmstandHeater = percentages.find((item) => item.sys.id === LABEL_IDS.farmstandHeater)?.fields?.text;
  const labelGlowRings = percentages.find((item) => item.sys.id === LABEL_IDS.glowRings)?.fields?.text;
  const deviceTypeData = [
    { ...(leftBackgroundAccent?.fields || {}), isV2: false },
    { ...(rightBackgroundAccent?.fields || {}), isV2: true },
  ];

  const handleSubmit = async (values, { resetForm }) => {
    const onEndSubmitting = () => {
      resetForm();
      onSubmit?.();
    };

    setIsFetching(true);
    const newValues =
      values.farmType === deviceTypes.v2 ? { ...values, size: '20', glowRings: 'YES', growingEnvironment: 'INDOORS' } : { ...values };
    const deviceData = getDevicePayload(newValues);
    const action = isEdit ? userUpdateFarmDevice : userCreateFarmDevice;
    dispatch(action({ id: isEdit ? farmstand.id : null, deviceData, authToken, setIsFetching, onSubmit: onEndSubmitting }));
  };

  const handleOpenDeleteModal = () => {
    deleteModalRef.current?.openModal();
  };

  const handleDelete = () => {
    setIsFetching(true);
    deleteModalRef.current?.closeModal();
    dispatch(userDeleteFarmDevice({ id: farmstand.id, authToken, setIsFetching, onSubmit }));
  };

  return (
    <FormWrapper>
      <FormGroup>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={getValidationSchema(messages)}>
          {({ setFieldValue, values, errors, touched }) => {
            const isOutdoorsEnv = [OUTDOORS, WITH_HEATER].includes(values.growingEnvironment);
            const isSubmitDisabled = hasErrorOnTouchedFields({ errors, touched }) || !values.farmType;

            const showFarmstand = values.farmType === deviceTypes.farmstand;
            const showV2 = values.farmType === deviceTypes.v2;
            return (
              <Form noValidate>
                <TextWrapper>
                  <Title as='h1' content={formTitle} modifiers={{ MD: 'xLarge', SM: 'small' }} size={screenSize} />
                </TextWrapper>

                <WrapFields>
                  <Text as='h3' content={isEdit ? body2 : lowerTitle} />
                  <Field required name='farmType' type='hidden' />
                  <TypeSelector
                    onSelect={setFieldValue}
                    selectedValue={values.farmType}
                    options={deviceTypeData}
                    currentImage={farmstand?.imageUrl}
                    isEdit={isEdit}
                  />

                  <Accordion
                    isOpen={showFarmstand || showV2}
                    setHeight={1200}
                    content={
                      <>
                        <ImageUploads
                          values={{ image: values.image }}
                          setFieldValue={setFieldValue}
                          acceptedTypes={['image/*']}
                          hideUploadIcon={true}
                        />
                        <Field
                          required
                          variant='filled'
                          name='name'
                          type='text'
                          label={labelName}
                          autoComplete={labelName}
                          component={Input}
                        />
                        <Field required variant='filled' name='zipCode' inputMode='numeric' label={labelZipCode} component={Input} />

                        <Accordion
                          isOpen={showFarmstand}
                          setHeight={1200}
                          content={
                            <>
                              <Field title={`${labelSize}*`} name='size' sizes={farmSizes} component={SizeSelector} />
                              <Field title={`${labelGrowingEnvironment}*`} name='growingEnvironment' component={EnvSelector} />
                              {isOutdoorsEnv && <Field title={labelFarmstandHeater} name='growingEnvironment' component={HeaterSelector} />}
                              <Field title={`${labelGlowRings} *`} name='glowRings' component={LightsSelector} />
                            </>
                          }
                        ></Accordion>
                      </>
                    }
                  ></Accordion>

                  <Flex className='buttonGroup' modifiers={[!isEdit && 'spaceAround', 'directionColumn']}>
                    {isEdit && !isFetching && (
                      <Flex modifiers={['row', 'justifyCenter']}>
                        <ButtonText type='button' modifiers={['redColor']} label='REMOVE FARMSTAND' onClick={handleOpenDeleteModal} />
                      </Flex>
                    )}

                    {(isEdit || values.farmType) && (
                      <Flex modifiers={['row', 'spaceAround']}>
                        {!isFetching && (
                          <SquaredButton
                            type='button'
                            className='CancelButton'
                            onClick={onSubmit}
                            label='CANCEL'
                            modifiers={['inverted']}
                          />
                        )}
                        <SquaredButton
                          type='submit'
                          className='submitButton'
                          label={submitCTALabel}
                          disabled={isSubmitDisabled || isFetching}
                          modifiers={[isFetching ? 'loading' : 'tertiary', isSubmitDisabled && 'disabled']}
                        />
                      </Flex>
                    )}
                  </Flex>
                </WrapFields>
              </Form>
            );
          }}
        </Formik>
      </FormGroup>
      <WarningModal
        ref={deleteModalRef}
        title='DELETE FARMSTAND'
        content='Are you sure you want to delete this Farmstand?<br/>This cannot be undone.'
        cancelCTA={{ linkText: 'CANCEL' }}
        confirmCTA={{ linkText: 'YES, DELETE' }}
        onConfirm={handleDelete}
      />
    </FormWrapper>
  );
};

FarmForm.propTypes = {
  onSubmit: PropTypes.func,
  farmstand: PropTypes.object,
};

export default FarmForm;
