import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Text } from 'elements';
import PromoContainerStyled from './PromoContainer.styled';
import { RemoveItemButton } from 'components';

import { removeDiscount } from 'reduxState/cart';
import { formatPrice } from 'utils/cart-utils';

const PromoContainer = ({ discount, canRemove, discountCents }) => {
  const dispatch = useDispatch();

  const handleRemovePromo = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    dispatch(removeDiscount(discount));
  };

  return (
    <PromoContainerStyled>
      <Text modifiers={['brandFont', 'brandColor']} content={discount}></Text>
      {canRemove && <RemoveItemButton onClick={handleRemovePromo}></RemoveItemButton>}
    </PromoContainerStyled>
  );
};

PromoContainer.defaultProps = {
  canRemove: true,
  discountCents: 0,
};
PromoContainer.propTypes = {
  discount: PropTypes.string,
  canRemove: PropTypes.bool,
  discountCents: PropTypes.number,
};
export default PromoContainer;
