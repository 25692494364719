import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { minBy as _minBy, maxBy as _maxBy, isEmpty as _isEmpty, find as _find } from 'lodash';
import { navigate } from 'gatsby';

import { ButtonText, Title, Text, SquaredButton, ResponsiveEmbed, Icon } from 'elements';
import { GrowEnvironmentToggle, ProductGift, ProductGiftList, StarRating, PromoToggle, PromoPrice, AffirmMessage, Modal } from 'components';

import {
  BundleItems,
  FarmSelectSize,
  FarmGlowRings,
  FarmIconFeatures,
  FarmAccordion,
  FarmCarouselMobile,
  FarmCarouselDesktop,
} from './components';

import {
  HeroWrapper,
  HeroDetailStyled,
  HeroDetailInner,
  ProductInformation,
  ProductDescription,
  PlayButtonWrapper,
} from './FarmProductDetail.styled';

import useCarouselPosition from './utils/useCarouselPosition';
import { viewedProduct } from 'utils/klaviyo-utils';
import { formatDynamicDecimalPrice } from 'utils/cart-utils';
import scrollIntoView from 'utils/scrollIntoView';
import paths from 'constants/paths';
import growingEnvironments from 'constants/growingEnvironments';
import { glowRingsMapSizeSku } from 'constants/sku';
import { productDetail } from 'utils/googleTagManager';
import { useQueryParams } from 'utils/hooks';

import { getFarmstands, getGlowRings, getSupplies, getCatalogEnvironment } from 'reduxState/catalog';
import { fetchReviewsByProduct, YOTPO_GLOW_RINGS_ID, YOTPO_FARMSTAND_ID } from 'reduxState/userGenerated/userGenerated';
import { getAreProductReviewsMounted, updateFarmProductPage, getFarmDefaultSize } from 'reduxState/globalUI';
import { getUserSetEnv } from 'reduxState/user';
import shopCategories from 'constants/shopCategories';

import IconPlay from 'elements/icon/assets/play-filled-icon.svg';

const HeroDetail = ({
  title,
  buttonSizesTitle,
  growEnvTitle,
  body,
  sizeModalContent,
  visualizerContent = {},
  glowRingsSection = {},
  promoSection = {},
  giftsSection = {},
  infoSection = {},
  accordionSection = [],
  bundlesSection = {},
  bundlesPromoSection = {},
  bundlePrices = [],
  plantBundlesPrice = 0,
  mediaBackground = [],
  video = '',
  mediaBackgroundGatsby = [],
  leftBackgroundAccent = {},
  leftBackgroundAccentGatsby = null,
  farmstandsData: farmstandsContent = [],
  isGlowRing = false,
  hasScrollBehavior = false,
  onAddToCart,
  farmSize,
}) => {
  const isBundlePage = !!bundlesSection;
  const isGlowOrBundle = isGlowRing || isBundlePage;
  const location = useLocation();
  const dispatch = useDispatch();
  const [refImageSliderMobile, refImageSliderDesktop] = [useRef(), useRef()];
  const farmstandsCatalog = useSelector(getFarmstands);
  const glowRingsCatalog = useSelector(getGlowRings);
  const suppliesCatalog = useSelector(getSupplies);
  const selectedSize = useSelector(getFarmDefaultSize);
  const reviews = useSelector((state) => state.userGenerated.reviews[isGlowRing ? YOTPO_GLOW_RINGS_ID : YOTPO_FARMSTAND_ID]);
  const userSetEnvironment = useSelector(getUserSetEnv);
  const catalogEnv = useSelector(getCatalogEnvironment);
  const [isGRAdded, setIsGRAdded] = useState(false);
  const [isPromoSelected, setIsPromoSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { navHeight } = useCarouselPosition(isGlowRing, isBundlePage);
  const { environment: paramEnvironment } = useQueryParams();

  const sizeToFind = (farmSize || selectedSize)?.toString?.() || '';
  const farmIndex = farmstandsContent?.findIndex((e) => e.size?.toString() === sizeToFind);
  const userEnv = userSetEnvironment === growingEnvironments.DEFAULT ? catalogEnv : userSetEnvironment;

  useEffect(() => {
    // unique logic here to handle this component on shop page for farmstand only
    // shop page does not have <ReviewsList> component which normally is responsible for fetching reviews and hydrating redux slice
    if (!isGlowRing && !reviews.all.length) {
      dispatch(fetchReviewsByProduct(YOTPO_FARMSTAND_ID));
    }
  }, [isGlowRing, reviews.all, dispatch]);

  const selectedFarmstand = farmstandsContent[farmIndex];
  const farmstandPrice = farmstandsCatalog.find((farmCat) => +farmCat.size === +selectedFarmstand?.size)?.priceCents;
  const giftPlaceholder = giftsSection?.percentages?.[0] || null;
  const carouselImages = mediaBackground?.map((e) => ({ id: e?.sys?.id || '', ...(e?.fields || {}) }));
  const glowRingsSlugs = (isGlowRing && glowRingsCatalog?.map((e) => e?.slug)) || [];
  const glowRingImage = leftBackgroundAccent?.fields?.file?.url;

  const areProductReviewsMounted = useSelector(getAreProductReviewsMounted);
  const reviewsUrl = areProductReviewsMounted ? `${location.pathname}#reviews-and-questions` : paths.CUSTOMER_REVIEWS;

  const minPrice = formatDynamicDecimalPrice(
    _minBy(isBundlePage ? bundlePrices : isGlowRing ? glowRingsCatalog : farmstandsCatalog, (e) => e?.priceCents)?.priceCents || 0,
    false
  );
  const maxPrice = formatDynamicDecimalPrice(
    _maxBy(isBundlePage ? bundlePrices : isGlowRing ? glowRingsCatalog : farmstandsCatalog, (e) => e?.priceCents)?.priceCents || 0,
    false
  );
  const rangePricesFormatted = `$${minPrice} - $${maxPrice}`;
  const showingGlowRing =
    (!!selectedFarmstand && glowRingsCatalog?.find((e) => e?.sku === glowRingsMapSizeSku[selectedFarmstand?.size])) || null;
  const sumPriceFormatted = formatDynamicDecimalPrice(
    showingGlowRing?.priceCents + (isBundlePage ? plantBundlesPrice + farmstandPrice - (bundlePrices?.[farmIndex]?.discount || 0) : 0)
  );
  const farmPriceFormatted = formatDynamicDecimalPrice(farmstandPrice);
  const productPriceOrRange = !selectedFarmstand ? rangePricesFormatted : isGlowOrBundle ? sumPriceFormatted : farmPriceFormatted;
  const hasFarmstand = !!selectedFarmstand && !_isEmpty(selectedFarmstand);

  const glowRingAddPrice = (isGRAdded && showingGlowRing?.priceCents) || 0;
  const buttonPrice = isGlowOrBundle ? productPriceOrRange : formatDynamicDecimalPrice(farmstandPrice + glowRingAddPrice);
  const buttonText = hasFarmstand ? `ADD TO CART ${!isGlowOrBundle ? '& SELECT PLANTS ' : ''}- ${buttonPrice}` : 'SELECT A SIZE';

  const farmSectionRef = useCallback(
    (node) => {
      if (node && farmstandsContent.length && location?.pathname?.includes?.(paths.SHOP_PRODUCTS)) scrollIntoView(location, node);
    },
    [location, farmstandsContent.length]
  );

  useEffect(() => {
    document.querySelector('body').classList.add('body--sticky-child');
    return () => document.querySelector('body').classList.remove('body--sticky-child');
  }, []);

  useEffect(() => {
    if (userSetEnvironment === growingEnvironments.INDOOR && !isGRAdded) {
      setIsGRAdded(true);
    }
    if (userSetEnvironment === growingEnvironments.OUTDOOR && isGRAdded) {
      setIsGRAdded(false);
    }
    if (userSetEnvironment === growingEnvironments.DEFAULT && catalogEnv === growingEnvironments.INDOOR) {
      setIsGRAdded(true);
    }
    // HEADS UP - we don't want this effect to run when isGRAdded changes, as the user can manually change that value as well as programmatic changes
    /* eslint-disable-next-line */
  }, [userSetEnvironment, catalogEnv]);

  if (!isGlowRing && !_isEmpty(farmstandsContent) && !!farmSize && farmIndex < 0) {
    navigate(paths.NOT_FOUND);
    return;
  }

  const onSelectFarmstand = (index, shouldTrack = true) => {
    const size = farmstandsContent[index]?.size;
    if (shouldTrack) {
      if (isGlowRing && glowRingsCatalog[index]) {
        const { priceCents, name, slug, category, sku, imageUrl } = glowRingsCatalog[index];
        viewedProduct({
          priceCents,
          name,
          slug,
          category,
          sku,
          imageUrl,
        });
        productDetail({
          pageName: 'Glow Rings',
          name: 'Glow Rings',
          id: sku,
          price: `${priceCents}`,
          category: shopCategories.SUPPLIES,
        });
      } else if (farmstandsContent[index]) {
        const { name, slug, id, mediaMain } = farmstandsContent[index];
        const priceCents = farmstandsCatalog.find((farmCat) => +farmCat.size === +size)?.priceCents;
        viewedProduct({
          priceCents,
          name,
          slug,
          category: shopCategories.FARMSTAND,
          sku: id,
          imageUrl: mediaMain?.fields?.file?.url,
        });

        productDetail({
          pageName: isBundlePage ? 'Farmstand Bundle' : 'The Farmstand',
          name: isBundlePage ? 'Farmstand Bundle' : name,
          id,
          price: priceCents,
          category: isBundlePage ? 'Bundle' : shopCategories.FARMSTAND,
        });
      }
    }

    dispatch(updateFarmProductPage({ key: 'selectedSize', value: size }));
  };

  const onSelectGlowRing = (isGlowRingAdded) => setIsGRAdded(isGlowRingAdded);

  const onAddFarmstand = () => {
    const { relatedGifts = [] } = selectedFarmstand;
    const farmstandFromCatalog = _find(farmstandsCatalog, { sku: selectedFarmstand.id }) || null;
    const glowRingFromCatalog = isGRAdded || isGlowOrBundle ? showingGlowRing : null;

    if (onAddToCart) {
      onAddToCart({
        farmstandCatalog: farmstandFromCatalog,
        glowRingCatalog: glowRingFromCatalog,
        relatedGifts,
        isPromoSelected,
      });
    }
  };

  const onChangePromoCheck = ({ target }) => setIsPromoSelected(target.value !== 'true');
  const toggleVideoModal = () => setShowModal(!showModal);

  const renderGiftLine = (giftData, index) => {
    const { shortDescription, detailImage } = giftData?.fields || {};
    const { priceCents = 0, name = '' } = suppliesCatalog[giftData?.sys?.id] || {};

    if (!name) return null;
    return (
      <ProductGift
        key={index}
        productName={name}
        productDescription={shortDescription}
        productImage={detailImage}
        newPrice='FREE GIFT'
        oldPrice={formatDynamicDecimalPrice(priceCents)}
      />
    );
  };

  const renderGiftPlaceholder = () => {
    const { name, shortDescription, detailImage } = giftPlaceholder?.fields || {};
    const { priceCents = 0 } = suppliesCatalog[giftPlaceholder?.sys?.id || ''] || {};

    if (!name) return null;
    return (
      <ProductGift
        modifiers='disabled'
        productName={name}
        productDescription={shortDescription}
        productImage={detailImage}
        newPrice='FREE GIFT'
        oldPrice={formatDynamicDecimalPrice(priceCents)}
      />
    );
  };

  const playVideoComponent = () => (
    <PlayButtonWrapper>
      <ButtonText label={`Meet the Farmstand`} onClick={toggleVideoModal} eventData={{ action: `Meet the Farmstand` }}>
        <Icon>
          <IconPlay />
        </Icon>
      </ButtonText>
    </PlayButtonWrapper>
  );

  return (
    <HeroWrapper id='shop-farmstands' ref={farmSectionRef}>
      <HeroDetailStyled navHeight={navHeight + 16} modifiers={[isGlowOrBundle && hasScrollBehavior ? 'sticky' : '']}>
        {showModal && video && (
          <Modal isOpen={showModal} onCloseAction={toggleVideoModal}>
            <ResponsiveEmbed embedCode={video} />
          </Modal>
        )}
        <HeroDetailInner>
          <FarmCarouselDesktop
            ref={refImageSliderDesktop}
            data={carouselImages}
            dataGatsby={mediaBackgroundGatsby}
            promo={bundlesPromoSection?.body}
            playVideoComponent={video && playVideoComponent}
            visualizerData={visualizerContent}
            environment={userEnv}
          />
          <FarmCarouselMobile
            ref={refImageSliderMobile}
            data={carouselImages}
            dataGatsby={mediaBackgroundGatsby}
            promo={bundlesPromoSection?.body}
            playVideoComponent={video && playVideoComponent}
            visualizerData={visualizerContent}
            environment={userEnv}
          />
          <ProductInformation isGlowRing={isGlowRing}>
            <Title as='h2' modifiers='secondarySmall' content={title} isHTML />
            <div className='RatingWrapper'>
              <StarRating readOnly rating={Math.round(reviews.average)} />
              <ButtonText
                label={`(${reviews.total} ${reviews?.total === 1 ? 'REVIEW' : 'REVIEWS'})`}
                href={reviewsUrl}
                modifiers={['underlineContent', 'compact', 'secondary']}
                eventData={{ action: 'Read the Reviews' }}
              ></ButtonText>
            </div>
            {isBundlePage && bundlePrices?.[farmIndex]?.discount ? (
              <PromoPrice
                newPrice={formatDynamicDecimalPrice(bundlePrices[farmIndex].priceCents - bundlePrices[farmIndex].discount)}
                oldPrice={formatDynamicDecimalPrice(bundlePrices[farmIndex].priceCents)}
              />
            ) : (
              <Text as='span' modifiers={['brandFont']} className='Price'>
                {!paramEnvironment && productPriceOrRange}
              </Text>
            )}
            <AffirmMessage priceCents={(hasFarmstand ? +buttonPrice?.replace(/[$,]/g, '') : minPrice) * 100} />
            <ProductDescription tabIndex={0}>
              <Text content={body} isHTML />
            </ProductDescription>
            <FarmSelectSize
              title={buttonSizesTitle}
              data={farmstandsContent}
              onSelectFarmstand={onSelectFarmstand}
              imgSliderRefs={[refImageSliderMobile, refImageSliderDesktop]}
              glowRingSlugs={glowRingsSlugs}
              glowRingsNumberLabel={`${hasFarmstand ? farmIndex + 2 : 0} GLOW RINGS️`}
              isBundlePage={isBundlePage}
              hasFarmstand={hasFarmstand}
              defaultSize={farmSize}
              farmIndex={farmIndex}
              sizeModalContent={sizeModalContent}
            />
            {!isGlowOrBundle && (
              <GrowEnvironmentToggle
                title={<Text content={growEnvTitle} as='span' modifiers={['brandFont', 'noLineHeight', 'bold', 'small']} />}
                modifiers='darkGray'
                showDetailModal
              />
            )}
            {(!isGlowOrBundle || !!glowRingsSection) &&
              (userSetEnvironment === growingEnvironments.INDOOR ||
                (userSetEnvironment === growingEnvironments.DEFAULT && catalogEnv === growingEnvironments.INDOOR)) && (
                <FarmGlowRings
                  image={glowRingImage}
                  imageGatsby={leftBackgroundAccentGatsby}
                  glowRing={showingGlowRing}
                  hasFarmstand={hasFarmstand}
                  onSelectGlowRing={onSelectGlowRing}
                  isGRAdded={isGRAdded}
                  {...glowRingsSection}
                />
              )}
            {!_isEmpty(promoSection) && (
              <PromoToggle content={promoSection?.body} onChange={onChangePromoCheck} modifiers={isPromoSelected && 'checked'} />
            )}
            <SquaredButton
              modifiers={['tertiary', !hasFarmstand && 'newDisabled']}
              label={buttonText}
              onClick={onAddFarmstand}
              dataNw='add-farmstand-to-cart'
              eventData={{ action: buttonText }}
            />
            {infoSection?.body2 && <Text modifiers={['brandFont', 'center']} content={infoSection.body2} />}
            {isBundlePage && (
              <BundleItems title={bundlesSection?.title} glowRing={showingGlowRing} bundleItems={bundlesSection?.percentages || []} />
            )}
            {!isGlowOrBundle && (!_isEmpty(selectedFarmstand?.relatedGifts) || !!giftPlaceholder) && (
              <ProductGiftList listTitle={!_isEmpty(selectedFarmstand?.relatedGifts) ? giftsSection?.title : giftsSection?.lowerTitle}>
                {!_isEmpty(selectedFarmstand?.relatedGifts)
                  ? selectedFarmstand?.relatedGifts?.map(renderGiftLine)
                  : !!giftPlaceholder && renderGiftPlaceholder()}
              </ProductGiftList>
            )}
            {infoSection?.percentages && <FarmIconFeatures data={infoSection.percentages} />}
            {!!accordionSection && <FarmAccordion data={accordionSection} />}
          </ProductInformation>
        </HeroDetailInner>
      </HeroDetailStyled>
    </HeroWrapper>
  );
};

HeroDetail.defaultProps = {
  hasScrollBehavior: false,
  buttonSizesTitle: null,
  glowRingsSection: null,
  infoSection: null,
  accordionSection: null,
  promoSection: null,
  giftsSection: null,
  bundlesSection: null,
  bundlesPromoSection: null,
  farmstandsData: null,
};

HeroDetail.propTypes = {
  isGlowRing: PropTypes.bool,
  title: PropTypes.string,
  lowerTitle: PropTypes.string,
  buttonSizesTitle: PropTypes.string,
  growEnvTitle: PropTypes.string,
  body: PropTypes.string,
  glowRingsSection: PropTypes.object,
  infoSection: PropTypes.object,
  accordionSection: PropTypes.array,
  promoSection: PropTypes.object,
  giftsSection: PropTypes.object,
  bundlesSection: PropTypes.object,
  bundlesPromoSection: PropTypes.object,
  bundlePrices: PropTypes.array,
  plantBundlesPrice: PropTypes.number,
  farmstandsData: PropTypes.array,
  farmSize: PropTypes.string,
  mediaBackground: PropTypes.array,
  mediaBackgroundGatsby: PropTypes.array,
  leftBackgroundAccent: PropTypes.object,
  leftBackgroundAccentGatsby: PropTypes.object,
  hasScrollBehavior: PropTypes.bool,
  onAddToCart: PropTypes.func,
  sizeModalContent: PropTypes.object,
  visualizerContent: PropTypes.object,
  video: PropTypes.string,
};

export default HeroDetail;
