import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { fontSize } from 'theme/Mixins';
import breakpoints from 'theme/Media';

export const modifiers = {
  fullBleed: () => ({
    styles: `
      padding: 0 0 1.6rem;
    `,
  }),
};
const CardStyled = styled.div.attrs({ className: 'Card' })`
  max-width: 100%;
  padding: 1.1rem 1.6rem 1.6rem;
  background: transparent;
  text-align: center;
  &:hover {
    background: ${({ theme }) => theme.farmProject.gray_2};
    cursor: pointer;
  }
  .Title {
    margin-bottom: 1rem;
  }
  .Title,
  .Text {
    padding: 0 1.6rem;
  }
  .Text {
    line-height: 1.3;
  }
  .Image {
    height: 200px;
    width: 100%;
  }
  @media ${breakpoints.medium_break} {
    .Text {
      line-height: 1.6;
    }
  }
  ${applyStyleModifiers(modifiers)};
`;

export const subTitleModifiers = {
  accentColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.magenta}
    `,
  }),
  secondaryAccentColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.pink}
    `,
  }),
  errorColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.error}
    `,
  }),
  brandColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.brand_primary}
    `,
  }),
};

export const SubTitle = styled.p.attrs({ className: 'SubTitle ' })`
  ${fontSize(12)};
  letter-spacing: 0.16rem;
  margin: 1rem 0;
  font-family: ${({ theme }) => theme.farmProject.font_family};

  ${applyStyleModifiers(subTitleModifiers)};
`;

export default CardStyled;
