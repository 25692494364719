import { createSlice, createSelector } from '@reduxjs/toolkit';

/**
 * * Modal - Redux Reducer - controls a single modal opened at a time, records opened history of modal types
 *
 * @param state
 *
 */

export const MODALS = {
  VERIFY_ADDRESS: 'verifyAddress',
  PASSWORD_RESET: 'passwordReset',
  LOGIN: 'login',
  ERROR: 'error',
  REFERRAL: 'referral',
  GROW_ENVIRONMENT: 'growEnvironment',
  INTERNATIONAL: 'international',
  GIFT_CHECKOUT: 'giftCheckout',
  FARMSTAND_FORM: 'farmstandForm',
  COMPLETE_ACCOUNT: 'completeAccount',
};

const initialState = {
  open: '',
  verifyAddress: { id: MODALS.VERIFY_ADDRESS, hasBeenOpen: false },
  passwordReset: { id: MODALS.PASSWORD_RESET, hasBeenOpen: false },
  login: { id: MODALS.LOGIN, hasBeenOpen: false },
  error: { id: MODALS.ERROR, label: 'Unexpected Error', hasBeenOpen: false },
  referral: { id: MODALS.REFERRAL, hasBeenOpen: false },
  growEnvironment: { id: MODALS.GROW_ENVIRONMENT, hasBeenOpen: false },
  international: { id: MODALS.INTERNATIONAL, hasBeenOpen: false },
  giftCheckout: { id: MODALS.GIFT_CHECKOUT, hasBeenOpen: false },
  farmstandForm: { id: MODALS.FARMSTAND_FORM, hasBeenOpen: false },
  completeAccount: { id: MODALS.COMPLETE_ACCOUNT, hasBeenOpen: false },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setLabel(state, { payload }) {
      state[payload.id].label = payload.label;
    },
    setOpenModal(state, { payload }) {
      if (typeof payload === 'string') {
        state[payload] = Object.assign({}, state[payload], { hasBeenOpen: true });
        state.open = payload;
      }

      if (typeof payload === 'object') {
        state[payload.id] = Object.assign({}, state[payload.id], { hasBeenOpen: true }, payload);
        state.open = payload.id;
      }
    },
    setCloseModal(state) {
      state.open = '';
    },
  },
});

export const getOpenModal = createSelector([(state) => state.modal], (modal) => modal[modal.open] || {});

const { actions, reducer } = modalSlice;

export const { setLabel, setOpenModal, setCloseModal } = actions;

export default reducer;
