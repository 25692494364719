import React from 'react';
import PropTypes from 'prop-types';

import { Title, Text, Image } from 'elements';

import { SocialPostStyled, SocialInformation, UserInfo } from './SocialPost.styled';
const SocialPost = ({ picturePost, userName, userHandle, alt }) => {
  return (
    <SocialPostStyled>
      <Image picture={picturePost} alt={alt} />
      <SocialInformation>
        <UserInfo>
          <Title as='h5' modifiers={['xxSmall', 'bold']} content={userName} />
          <Text as='span' modifiers={['left', 'noLineHeight']} content={userHandle} />
        </UserInfo>
      </SocialInformation>
    </SocialPostStyled>
  );
};

SocialPost.prototype = {
  picturePost: PropTypes.string,
  userPic: PropTypes.string,
  userName: PropTypes.string,
  userHandle: PropTypes.string,
  alt: PropTypes.string,
};

export default SocialPost;
