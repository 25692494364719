import React from 'react';
import PropTypes from 'prop-types';

import TextAreaBase from '@lettucegrow/atoms.textarea';

/**
 * * Text Area - TextArea input field
 */

const TextArea = ({ label, field, form, required, width, customBlur, helperText }) => {
  const errorMsg = form.errors[field.name];
  const hasError = !!errorMsg && form.touched[field.name];
  const blurHandler = (ev) => {
    // Call custom onBlur
    if (customBlur) customBlur(form.values);

    // Call formik onBlur
    field.onBlur(ev);
  };
  return (
    <TextAreaBase
      required={required}
      label={label}
      width={width}
      onBlur={blurHandler}
      onChange={field.onChange}
      error={hasError ? errorMsg : ''}
      helperText={helperText}
      name={field.name}
      value={field.value}
    />
  );
};

TextArea.defaultProps = {
  type: 'text',
  width: '100%',
  field: {},
};

TextArea.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }),
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
  }),
  required: PropTypes.bool,
  customBlur: PropTypes.func,
  helperText: PropTypes.string,
};

export default TextArea;
