import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  checked: ({ theme }) => ({
    styles: `
      form {
        .component__Checkbox {
          .checkbox__IconContainer {
            background-color: ${theme.farmProject.brand_primary};

            svg {
              fill: white;
              opacity: 1;
            }
          }
        }
      }
    `,
  }),
};

export const PromoToggleStyled = styled.div.attrs({
  className: 'PromoToggleStyled',
})`
  border: dashed 2px ${({ theme }) => theme.farmProject.green}25;
  width: fit-content;
  padding: 0.5rem;

  form {
    .component__Checkbox {
      color: ${({ theme }) => theme.farmProject.green};
      font-size: 0.875rem;
      text-transform: uppercase;
      font-weight: 500;

      .checkbox__IconContainer {
        flex-shrink: 0;
        width: 1.125rem;
        height: 1.125rem;
        border-radius: 100%;
        border-color: ${({ theme }) => theme.farmProject.green};
      }
    }
  }

  ${applyStyleModifiers(modifiers)};
`;
