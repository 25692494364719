import paths from './paths';

export const ShopMenu = [
  {
    label: 'The Farmstand',
    label_alt: 'Shop Farmstand',
    path: paths.shopPages.FARMSTAND,
  },
  {
    label: 'Seedlings',
    label_alt: 'Shop Seedlings',
    path: paths.shopPages.SEEDLINGS,
  },
  {
    label: 'Supplies',
    label_alt: 'Shop Supplies',
    path: paths.shopPages.SUPPLIES,
  },
  {
    label: 'Gift Cards',
    label_alt: 'Shop Gift Card',
    path: paths.shopPages.GIFT_CARDS,
  },
];
