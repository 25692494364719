import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Text } from 'elements';

import { ProductCollapseStyled, CollapseWrapper, ContentWrapper } from './FarmAccordion.styled';

import AccordionSection from 'components/deprecated/elements/AccordionSection';

const INITIAL_OPEN_INDEX = 0;

const ProductCollapse = ({ data = [] }) => {
  const [currentOpenIndex, setCurrentOpenIndex] = useState(INITIAL_OPEN_INDEX);
  const accordionRefs = data.map(() => useRef());

  const onOpenAccordion = (index) => () => {
    const ref = accordionRefs[currentOpenIndex] || null;
    if (ref?.current?.close) ref.current.close();

    setCurrentOpenIndex(index);
  };

  const renderAccordionRow = (itemData, index) => {
    const { body = '', title } = itemData?.fields || {};
    const bodyLines = body.split('- ');

    return (
      <CollapseWrapper key={index}>
        <AccordionSection
          title={title}
          onOpen={onOpenAccordion(index)}
          ref={accordionRefs[index] || null}
          isInitiallyOpen={index === INITIAL_OPEN_INDEX}
        >
          <ContentWrapper>
            {bodyLines.map(
              (line, i) => !!line && <Text key={i} modifiers={['brandFont', 'noLineHeight', 'preWrap']} content={line} isHTML />
            )}
          </ContentWrapper>
        </AccordionSection>
      </CollapseWrapper>
    );
  };

  return <ProductCollapseStyled>{data.map(renderAccordionRow)}</ProductCollapseStyled>;
};

ProductCollapse.propTypes = {
  data: PropTypes.array,
};

export default ProductCollapse;
