import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const ContactUsModalStyled = styled.div.attrs({
  className: 'ContactUsModal',
})`
  .ComponentModal {
    .ModalWrapper {
      .modal__Content {
        padding: 1rem 1rem 0;
        height: 600px;

        .ModalInner {
          max-width: 23.5rem;
          display: block;

          form {
            width: 100%;
            margin-bottom: 2rem;
            .InputStyled,
            .SelectStyled,
            .TextArea {
              margin: 0.5rem auto;
            }
            .TextArea {
              margin-top: 2rem;
              & + .Text {
                margin-bottom: 3rem;
              }
            }
            .AddPhotoWrapper {
              text-align: center;
            }
          }

          .Title {
            font-size: 1.75rem;
            line-height: 2.125rem;
            margin-bottom: 0.5rem;
          }

          .Text {
            &:last-of-type {
              margin-bottom: 0;
            }
          }

          .Button {
            margin-top: 1rem;
            margin-bottom: 3rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ComponentModal {
      .ModalWrapper {
        .modal__Content {
          padding: 1.2rem 0 0;
          .ModalInner {
            max-width: 29.5rem;

            .Title {
              font-size: 3.125rem;
              line-height: normal;
              margin-bottom: 0;
              margin-top: 0;
            }

            .Text {
              &:last-of-type {
                margin-bottom: 0;
              }
            }

            form {
              max-height: unset;
            }

            .Button {
              margin-bottom: 0;
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
`;
