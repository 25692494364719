import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { getImageUrl } from 'utils/contentful-utils';
import { encodeIdToSku } from 'utils/string-utils';

import { userLogout } from './user';

/**
 * * User Preferences - Redux Reducer - likes/dislikes that user has voted on
 *
 * @param state
 *
 */

export const initialState = {
  all: [],
  byPlantTypeId: {},
  pendingUpdatesVotes: {
    likedSection: {},
    dislikedSection: {},
  },
  hasFetched: false,
  isFetching: false,
};

const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    fetchedPreferencesSuccess(state, { payload }) {
      state.hasFetched = true;
      state.isFetching = false;
      state.all = payload.preferences.map((pref) => pref.id);
      if (payload.shouldOverwrite) state.byPlantTypeId = {};
      payload.preferences.forEach((pref) => {
        // HEADS UP - we are only setting a new object here (triggering renders) for preferences where properties have changed
        if (state.byPlantTypeId[pref.plantTypeId]?.pending || !state.byPlantTypeId[pref.plantTypeId] || payload.shouldOverwrite) {
          state.byPlantTypeId[pref.plantTypeId] = pref;
        }
      });
    },
    isFetchingSeedlingPreferences(state) {
      state.isFetching = true;
    },
    optimisticVote(state, { payload }) {
      if (payload.vote === null) {
        delete state.byPlantTypeId[payload.plantTypeId];
      } else {
        state.byPlantTypeId[payload.plantTypeId] = { ...state.byPlantTypeId[payload.plantTypeId], liked: payload.vote, pending: true };
      }
    },
    updatePendingUpdateVote(state, { payload: { isLikesSection, ...vote } }) {
      state.pendingUpdatesVotes[isLikesSection ? 'likedSection' : 'dislikedSection'][vote.plantTypeId] = {
        ...(state.pendingUpdatesVotes[isLikesSection ? 'likedSection' : 'dislikedSection'][vote.plantTypeId] || vote || {}),
        liked: vote.liked,
      };
    },
    clearPendingUpdateVote(state, { payload }) {
      if (payload?.clearLikedSectionOnly) {
        state.pendingUpdatesVotes.likedSection = initialState.pendingUpdatesVotes.likedSection;
      } else if (payload?.clearDislikedSectionOnly) {
        state.pendingUpdatesVotes.dislikedSection = initialState.pendingUpdatesVotes.dislikedSection;
      } else {
        state.pendingUpdatesVotes = initialState.pendingUpdatesVotes;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout.toString(), () => ({ ...initialState }));
  },
});

const { actions, reducer } = userPreferencesSlice;

export const fetchPreferences = createAction('userPreferences/fetchPreferences');

export const seedlingUpVote = createAction('userPreferences/seedlingUpVote');
export const seedlingDownVote = createAction('userPreferences/seedlingDownVote');
export const resetVote = createAction('userPreferences/resetVote');
export const saveUpdates = createAction('userPreferences/saveUpdates');

export const getUserPreferences = (state) => state.userPreferences;

export const getUserPreferencesByPlant = createSelector(getUserPreferences, (prefs) => prefs.byPlantTypeId);

export const getUserPlantsPendingVotes = createSelector(
  [getUserPreferences, (_, isLikesSection) => isLikesSection],
  (prefs, isLikesSection) => Object.values(prefs.pendingUpdatesVotes[isLikesSection ? 'likedSection' : 'dislikedSection'])
);

export const getUserPlantPendingVoteById = createSelector(
  [getUserPreferences, (_, { id, isLikesSection }) => ({ id, isLikesSection })],
  (prefs, { id, isLikesSection }) => prefs.pendingUpdatesVotes[isLikesSection ? 'likedSection' : 'dislikedSection'][id]
);

export const getLikedAndDislikedPlantsWithContent = createSelector(
  [getUserPreferencesByPlant, (_, content, category) => ({ content, filterCategory: category })],
  (plantsPrefs, { content, filterCategory }) => {
    const isEmpty = !Object.values(plantsPrefs).length;
    const allPlants = Object.values(plantsPrefs)
      .map((pref) => {
        const sku = encodeIdToSku({ id: pref.plantTypeId });
        const { friendlyName, thumbnailImage, category } = content?.find?.(({ sys }) => sys.id === sku)?.fields || {};
        return { ...pref, sku, category, name: friendlyName, image: getImageUrl(thumbnailImage) };
      })
      .filter(({ category }) => !filterCategory || category === filterCategory);

    return {
      liked: allPlants.filter(({ liked }) => liked),
      disliked: allPlants.filter(({ liked }) => !liked),
      isEmpty,
    };
  }
);

export const getIsFetchingPreferences = createSelector(getUserPreferences, (slice) => slice.isFetching);
export const getHasFetchedPreferences = createSelector(getUserPreferences, (slice) => slice.hasFetched);
export const getSeedlingPreference = createSelector([getUserPreferences, (_, id) => id], (slice, id) => {
  return slice.byPlantTypeId[id];
});

export const {
  fetchedPreferencesSuccess,
  isFetchingSeedlingPreferences,
  optimisticVote,
  updatePendingUpdateVote,
  clearPendingUpdateVote,
} = actions;

export default reducer;
