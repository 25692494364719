import styled from 'styled-components/macro';

import breakPoints from 'theme/Media';

export const LettuceAppSection = styled.div.attrs({
  className: 'LettuceApp',
})`
  .ContentSection {
    .SectionInner {
      max-width: 33rem;
      margin: auto;

      .ContentWrapper {
        .Image {
          margin-bottom: 3rem;
        }

        .Title {
          text-align: left;
          max-width: 20rem;
          margin-bottom: 1rem;
        }

        .Text {
          text-align: left;
          margin-bottom: 1.5rem;
        }
      }

      .AccordionWrapper {
        > div {
          &:nth-child(4) {
            border: none;
          }
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 3rem;
            max-width: 25rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row-reverse;
        max-width: 62rem;

        .ContentWrapper {
          width: calc(50% - 1rem);

          .Title {
            font-size: 3.9rem;
            max-width: 30rem;
          }
        }
      }
    }
  }
`;

export const LinkIconWrapper = styled.div.attrs({
  className: 'AccordionWrapper',
})`
  max-width: 22rem;
  display: flex;
  margin-top: 4rem;
  .Link {
    flex-grow: 2;
    max-width: 11rem;
    &:first-child {
      margin-right: 1rem;
      max-width: 10rem;
    }
    .Icon {
      display: flex;
      justify-content: flex-start;
      height: 3.25rem;
      width: auto;
      margin-top: 0;
      pointer-events: none;
      @media (max-width: 380px) {
        height: 2.6rem;
      }
      svg {
        top: 0px;
        left: 0px;
        transform: translate(0, 0);
        height: 100%;
      }
    }
  }
`;

export const AccordionWrapper = styled.div.attrs({
  className: 'AccordionWrapper',
})`
  .AccordionSection {
    padding-bottom: 1rem;
    margin-top: 1.5rem;
    border-bottom: solid 1px ${({ theme }) => theme.farmProject.dark_gray};

    .AccordionTitleSection {
      display: flex;
      align-items: center;
      position: relative;

      span {
        position: absolute;
        top: 0.7rem;
        left: 0.5rem;
        transform: translate(-50%, -50%);
      }

      .AccordionTitle {
        font-family: ${({ theme }) => theme.farmProject.font_family};
        font-weight: 300;
        margin-left: 2rem;
      }
    }

    .Accordion {
      margin-top: 0.5em;
      transition: all 0.4s;
    }

    .Text {
      text-align: left;
      padding-left: 2rem;
      padding-bottom: 0.4rem;
    }
  }

  .Image {
    max-width: 8rem;
    margin-top: 4rem;
    margin-bottom: 0;
  }

  @media ${breakPoints.medium_break} {
    .AccordionSection {
      .AccordionTitleSection {
        span {
          top: 0.85rem;
        }

        .AccordionTitle {
          font-size: 1.25rem;
        }
      }
    }
  }
`;
