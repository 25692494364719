import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const ContactListItem = styled.li.attrs({
  className: 'ContactListItem',
})`
  width: 100%;
  max-width: 17rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ButtonText {
    font-size: 1.25rem;
    padding-bottom: 0;
    margin-bottom: 1rem;

    span {
      font-size: 1.25rem;
      text-align: center;
    }
  }

  .Text {
    margin-bottom: 1rem;
    border-bottom: none;
    margin-bottom: 0;
    font-size: 0.75rem;

    * {
      font-size: 0.75rem;
    }
  }

  @media ${breakPoints.large_break} {
    padding: 0;
    margin: 0;
    width: 33%;
  }
`;

export const ContactList = styled.ul.attrs({
  className: 'ContactList',
})`
  padding: 0;
  margin: 0;
  margin-top: 2rem;
  list-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  li {
    &:last-child {
      .Text {
        a {
          margin-top: 1rem;
          display: block;
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    margin-top: 5rem;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;

    li {
      padding: 1rem;

      &:last-child {
        width: 50%;
        max-width: 18rem;
        margin-top: 1rem;
      }
    }
  }
`;

export const ContactSectionStyled = styled.div.attrs({
  className: 'ContactSection',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.gray_1};

    .SectionInner {
      .ContentWrapper {
        .Title {
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      padding: 5rem 3rem;

      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 3rem;
            max-width: 30rem;
            margin: auto;
            margin-bottom: 1.5rem;
          }

          .Text {
            font-size: 1rem;
            max-width: 32rem;
            margin: auto;
          }

          .Button {
            margin-top: 3rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding: 8rem 3rem;

      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 4rem;
            max-width: 34rem;
            margin-bottom: 1.5rem;
          }
          .Text {
            max-width: 43rem;
          }
        }
      }
    }
  }
`;

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;
