import { put, select, call, takeLatest } from 'redux-saga/effects';
import { getAuth } from 'firebase/auth';

import {
  userLogout,
  userAuthenticated,
  getUser,
  getCustomer,
  userCreateAccount,
  userAddPaymentMethod,
  userPaymentMethodAdded,
  userLogin,
  userCreateFarmDevice,
  userUpdateFarmDevice,
  userDeleteFarmDevice,
  fetchUserDevices,
  userUpdateAutofillSettings,
  updateAutofillSettingsSuccess,
  fetchMyFarm,
  getUserAuthToken,
  getAutofillPreferences,
  startFetchMyFarm,
  setMyFarm,
  getUserPassword,
} from 'reduxState/user';
import { clearDiscounts, clearBuyNow, clearAutofill } from 'reduxState/cart';
import { identify } from 'utils/klaviyo-utils';
import { axiosDelete, axiosGet, axiosPost } from 'utils/api-utils';
import logError from 'utils/errorHandler';
import { checkSnoozeStatus } from 'utils/autofill-utils';
import { trackClick } from 'utils/googleTagManager';
import DateFnsUtils from '@date-io/date-fns';
import { AutofillWeeks } from 'constants';

function* doUserAuthenticated() {
  const user = yield select(getUser);
  const customer = yield select(getCustomer);
  identify({ user, customer });
}

function* doUserLogout() {
  yield put(clearDiscounts());
  yield put(clearBuyNow());
  yield put(clearAutofill());
}

function* doUserCreateOrUpdateFarmstandDevice({ payload: { id, deviceData, authToken, setIsFetching, onSubmit } }) {
  try {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const isEdit = !!id;
    const endpoint = `/app/lgcom/deviceSetup${isEdit ? `/${id}` : ''}`;
    yield axiosPost(endpoint, deviceData, { headers }, authToken);
    yield put(fetchUserDevices());
    onSubmit?.();
  } catch (error) {
    logError(error);
  } finally {
    setIsFetching(false);
  }
}

function* doUserRemoveFarmstandDevice({ payload: { id, authToken, setIsFetching, onSubmit } }) {
  try {
    yield axiosDelete(`/app/lgcom/deviceSetup/${id}`, {}, authToken);
    yield put(fetchUserDevices());
    onSubmit?.();
  } catch (error) {
    logError(error);
  } finally {
    setIsFetching(false);
  }
}

function* doUserCreateAccount({ payload: { auth, email, password, accountData, onError } }) {
  try {
    const firebaseAccountResponse = yield auth.signUp(email, password);
    if (firebaseAccountResponse?.getIdToken) {
      const authToken = yield firebaseAccountResponse.getIdToken();

      /*
       * Synce the create account form has a custom email field that already checks for duplicated emails
       * calling to the /app/public/checkAccount endpoint will blow away the guest user’s Firebase account,
       * but the GCS guest customer account will still exists under the same email so creating/posting to /app/public/customer
       * is not needed and results in an error.That's why I think we can skip this additional call to /app/public/checkAccount
       * and assume we only have 2 options when we hit this generator doUserCreateAccount:
       * - guest account
       * - non existing account
       * But calling this endpoint /app/lgcom/unguest, will return false for both cases. But it will also throw an error for a non existing account
       * And I'm using the returned error like a condition to try to create a new account
       */
      try {
        yield axiosGet('/app/lgcom/unguest', {}, authToken);
        yield put(userLogin());
      } catch (e) {
        if (e.response.status === 403) {
          //new user try to create the account
          try {
            yield axiosPost('/app/public/customer', accountData, {}, authToken);
            yield put(userLogin());
          } catch (e) {
            logError(e);
          }
        }
      } finally {
        window.dataLayer.push({
          event: 'formSubmitted',
          formId: 'create-account',
        });
      }
    }
  } catch (error) {
    logError(error);
    onError?.();
  }
}

function* doUserAddPaymentMethod({ payload: { token, auth, password, onSuccess, onFailure } }) {
  const generatedPassword = yield select(getUserPassword);
  const fbAuth = yield call(getAuth);
  const fbUser = fbAuth.currentUser;
  const authToken = yield fbUser.getIdToken();
  if (auth && password) {
    // "create account" for guest user first
    yield auth.changePassword(generatedPassword, password);
    yield axiosGet('/app/lgcom/unguest', {}, authToken);
    yield put(userLogin());
  }
  const body = { stripeToken: token.id };
  try {
    yield axiosPost('/app/lgcom/paymentInfo', body, {}, authToken);
    yield put(userPaymentMethodAdded());
    yield call(onSuccess);
  } catch (err) {
    logError(err);
    yield call(onFailure);
  }
}

function* doUserLogin({ payload }) {
  /* 
  TECH DEBT: THERE IS A HOOK IN NAVIGATION THAT IS HANDLING CALLS TO FETCH MY FARM
  WE SHOULD THINK ABOUT ANOTHER WAY TO HANDLE THAT AND MOVE TO A SAGA TO HANDLE LOGIN UPDATES
  ALSO, CARTPREVIEW IS COUPLED TO THE SETMYFARM ACTION, WE SHOULD THINK ABOUT DECOUPLING THAT

  WE NEED THIS CALL TO MY FARM SO WHEN A USER LOGS IN FROM CHECKOUT SO WE CAN FORCE THE CART PREVIEW CALL
  TO USE THE ZIP THAT COMES FROM THE CHECKOUT FORM
   */
  if (window.location.pathname.includes('/checkout')) {
    yield call(doFetchMyFarm, { payload: { cartPreviewZip: payload.zip } });
  }
}

/**
 * doUpdateAutofillSettings - Async action creator that updates the Autofill settings for the given user
 */
function* doUpdateAutofillSettings({ payload }) {
  const { enabled, processDay, snoozeUntil, snoozeWeekLabel, setSubmitting, onSuccess, onFailure } = payload;
  const authToken = yield select(getUserAuthToken);
  const oldAutofillPreferences = yield select(getAutofillPreferences);
  const { snoozeDate } = checkSnoozeStatus(oldAutofillPreferences?.snoozeUntil);
  const dateFns = new DateFnsUtils();
  const currentSnoozeDate = snoozeDate ? dateFns.format(snoozeDate, 'yyyy-MM-dd') : AutofillWeeks.OFF.value;

  try {
    const body = Object.assign(
      {},
      oldAutofillPreferences,
      { enabled: enabled !== undefined ? enabled : oldAutofillPreferences?.enabled },
      { processDay: processDay !== undefined ? processDay : oldAutofillPreferences?.processDay },
      { snoozeUntil: snoozeUntil !== undefined ? snoozeUntil : oldAutofillPreferences?.snoozeUntil }
    );

    yield axiosPost('/app/lgcom/autofill/preferences', body, { method: 'put' }, authToken);
    yield put(updateAutofillSettingsSuccess(body));
    yield put(fetchMyFarm());
    //clean up the buyNow state
    yield put(clearBuyNow());

    if (enabled && !oldAutofillPreferences?.enabled) {
      trackClick({ action: 'autofill', label: 'autofill enabled' });
    }

    if (!enabled && oldAutofillPreferences?.enabled) {
      trackClick({ action: 'autofill', label: 'autofill disabled' });
    }

    if (processDay !== oldAutofillPreferences?.processDay) {
      trackClick({ action: 'Delivery Day', label: processDay });
    }

    if (snoozeUntil !== currentSnoozeDate) {
      trackClick({ action: 'Snooze', label: snoozeWeekLabel });
    }

    onSuccess?.('Your settings have been updated.');
  } catch (error) {
    const readableError = error?.response?.data || error?.response || error;
    onFailure?.(readableError);
  } finally {
    setSubmitting?.(false);
  }
}

function* doFetchMyFarm({ payload = {} }) {
  const { cartPreviewZip } = payload;
  const authToken = yield select(getUserAuthToken);

  try {
    const myFarm = yield axiosGet('/app/lgcom/v2/myFarm', {}, authToken);

    // WE PASS CART PREVIEW ZIP TO BE CONSUMED IN CART MIDDLEWARE FOR CART PREVIEW
    yield put(setMyFarm({ ...myFarm?.data, ...(cartPreviewZip && { zip: cartPreviewZip }) }));
  } catch (error) {
    logError(error);
  }
}

function* userAuthenticateSaga() {
  yield takeLatest(userAuthenticated.toString(), doUserAuthenticated);
}

function* userLogoutSaga() {
  yield takeLatest(userLogout.toString(), doUserLogout);
}

function* userCreateAccountSaga() {
  yield takeLatest(userCreateAccount.toString(), doUserCreateAccount);
}

function* userCreateFarmstandDeviceSaga() {
  yield takeLatest(userCreateFarmDevice.toString(), doUserCreateOrUpdateFarmstandDevice);
}

function* userUpdateFarmstandDeviceSaga() {
  yield takeLatest(userUpdateFarmDevice.toString(), doUserCreateOrUpdateFarmstandDevice);
}

function* userDeleteFarmstandDeviceSaga() {
  yield takeLatest(userDeleteFarmDevice.toString(), doUserRemoveFarmstandDevice);
}

function* userAddPaymentMethodSaga() {
  yield takeLatest(userAddPaymentMethod.toString(), doUserAddPaymentMethod);
}

function* userLoginSaga() {
  yield takeLatest(userLogin.toString(), doUserLogin);
}

function* fetchMyFarmSaga() {
  yield takeLatest(startFetchMyFarm.toString(), doFetchMyFarm);
}

function* updateAutofillSettingsSaga() {
  yield takeLatest(userUpdateAutofillSettings.toString(), doUpdateAutofillSettings);
}

export default [
  userLogoutSaga,
  userAuthenticateSaga,
  userCreateAccountSaga,
  userCreateFarmstandDeviceSaga,
  userUpdateFarmstandDeviceSaga,
  userDeleteFarmstandDeviceSaga,
  userAddPaymentMethodSaga,
  userLoginSaga,
  fetchMyFarmSaga,
  updateAutofillSettingsSaga,
];
