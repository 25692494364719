import React from 'react';
import PropTypes from 'prop-types';

import { Title } from 'elements';

import { ProductGiftListStyled, ListInner } from './ProductGiftList.styled';

const ProductGiftList = ({ modifiers, children, listTitle }) => (
  <ProductGiftListStyled>
    <ListInner modifiers={modifiers}>
      <Title modifiers='tiny' content={listTitle} />
      {children}
    </ListInner>
  </ProductGiftListStyled>
);

ProductGiftList.propTypes = {
  children: PropTypes.any,
  listTitle: PropTypes.string,
  modifiers: PropTypes.any,
};

export default ProductGiftList;
