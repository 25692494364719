import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

import { DisclaimerWrapper } from './CreateAccountDisclaimer.styled';
import { ButtonText, Link, Text } from 'elements';

import { LOGIN_CREATE_ACCOUNT_MESSAGE } from 'constants/contentful';
import { useContent } from 'utils/hooks';
import paths from 'constants/paths';

const CreateAccountDisclaimer = ({ onCTAClick }) => {
  const redirectUrl = useLocation().pathname === paths.CHECKOUT ? `?redirect=${paths.CHECKOUT}` : '';
  const { body, primaryButton } = useContent(LOGIN_CREATE_ACCOUNT_MESSAGE)?.[0]?.fields || {};
  const { linkText, linkUrl } = primaryButton?.fields || {};

  return (
    <DisclaimerWrapper>
      <Text content={body} modifiers='brandFont' />
      <ButtonText onClick={onCTAClick} type='button'>
        <Link content={linkText} modifiers={['medium', 'brandColor', 'underline']} href={linkUrl + redirectUrl} />
      </ButtonText>
    </DisclaimerWrapper>
  );
};

CreateAccountDisclaimer.propTypes = {
  isOnModal: PropTypes.bool,
  onCTAClick: PropTypes.func,
};

export default CreateAccountDisclaimer;
