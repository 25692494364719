const icons = {
  arrowRight: '\\e905',
  arrowLeft: '\\e90b',
  arrowDown: '\\e720',
  cart: '\\e906',
  chat: '\\e924',
  check: '\\e911',
  comment: '\\e926',
  contactUs: '\\e912',
  dashes: '\\e718',
  demo: '\\e923',
  difficulty: '\\e91c',
  drawCurveArrowLeft: '\\e910',
  drawCurveArrowRight: '\\e919',
  drawArrowRight: '\\e90f',
  loopArrowRight: '\\e719',
  drawArrowChevronRight: '\\e918',
  chevronRight: '\\e90c',
  chevronLeft: '\\e90b',
  chevronUp: '\\e915',
  chevronDown: '\\e916',
  close: '\\e907',
  email: '\\e922',
  farmstand: '\\e925',
  instagram: '\\e901',
  youtube: '\\e903',
  facebook: '\\e900',
  twitter: '\\e902',
  instagramThin: '\\e711',
  youtubeThin: '\\e700e',
  facebookThin: '\\e710',
  twitterThin: '\\e700f',
  plusThin: '\\e791b',
  minusThin: '\\e791a',
  measurement: '\\e917',
  info: '\\e913',
  promo: '\\e914',
  print: '\\e90a',
  resize: '\\e91d',
  servings: '\\e91b',
  timer: '\\e91a',
  quotationMark: '\\e791e',
  pausePackage: '\\e91e',
  warning: '\\e91f',
  location: '\\e947',
  seed: '\\e921',
  snowflake: '\\e920',
  calendar: '\\e953',
};

icons.ALL = Object.values(icons);

export default icons;
