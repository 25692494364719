import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'gatsby';

import { SubMenu } from './Navigation.styled';
import { ButtonText, Text, Icon } from 'elements';
import UserIcon from 'elements/icon/assets/user.svg';

import paths from 'constants/paths';
import { getLoginState, getCustomer, getUserHasOrders } from 'reduxState/user';
import { setOpenModal } from 'reduxState/modal';
import { useAuth, useBreakpoint } from 'utils/hooks';

const AccountMenu = ({ isOpen }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getLoginState);
  const customer = useSelector(getCustomer);
  const hasUserOrders = useSelector(getUserHasOrders);
  const size = useBreakpoint();
  const auth = useAuth();
  const isMobile = size === 'SM' || size === 'MD';
  const isLoggedInOnMobile = isLoggedIn && isMobile;

  const handleLogout = () => {
    auth.signOut();
  };

  const handleLoginModal = () => {
    dispatch(setOpenModal('login'));
  };

  return (
    <SubMenu isOpen={isOpen}>
      {isOpen && (
        <ul>
          <li>
            {isLoggedIn && <Text modifiers={['bold', 'brandFont']} content={`HI, ${user.firstName.toUpperCase()}`}></Text>}
            {!isLoggedInOnMobile && (
              <ButtonText
                dataNw='login-button'
                modifiers={['secondary', 'underline']}
                label={isLoggedIn ? 'Log out' : 'Login'}
                onClick={isLoggedIn ? handleLogout : handleLoginModal}
              />
            )}
            <Icon modifiers={['small', isLoggedIn && 'brandStroke']}>
              <UserIcon />
            </Icon>
          </li>
          {!isLoggedIn && (
            <li>
              <ButtonText
                as='a'
                dataNw='create-account-button'
                modifiers={['secondary', 'underline', 'textLeft']}
                label={'Create Account'}
                href={paths.CREATE_ACCOUNT}
              />
            </li>
          )}
          {isLoggedIn && (
            <>
              <li>
                <Link to={paths.MY_FARM}>My Farm</Link>
              </li>
              {hasUserOrders && (
                <li>
                  <Link to={`${paths.MY_FARM}#my-deliveries`}>My Deliveries</Link>
                </li>
              )}
              <li>
                <Link to={`${paths.MY_FARM}#my-account`}>My Account</Link>
              </li>
              {customer?.referralCode && (
                <li>
                  <Link to={`${paths.MY_FARM}#my-referral`}>Refer & Earn</Link>
                </li>
              )}
            </>
          )}
          {isLoggedIn && isMobile && (
            <ButtonText
              dataNw='login-button'
              modifiers={['secondary', 'underline']}
              label={isLoggedIn ? 'Log out' : 'Login'}
              onClick={isLoggedIn ? handleLogout : handleLoginModal}
            />
          )}
        </ul>
      )}
    </SubMenu>
  );
};

AccountMenu.defaultProps = { isOpen: false, isExpandable: true };
AccountMenu.propTypes = { isOpen: PropTypes.bool, isExpandable: PropTypes.bool };

export default AccountMenu;
