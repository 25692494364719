import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const UserGeneratedContentWrapper = styled.div.attrs({
  className: 'UserGeneratedContentWrapper',
})`
  background-color: ${({ theme }) => theme.farmProject.green};

  .ContentSection {
    padding: 5rem 0;
    background-color: ${({ theme }) => theme.farmProject.green};
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        max-width: 100%;
        margin: auto;
      }
    }
  }
`;

export const UserContentComponentStyled = styled.div.attrs({
  className: 'UserContentComponentStyled',
})`
  > .Title {
    text-align: left;
  }
  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        max-width: 33.5rem;
        margin: auto;
      }
    }
  }
`;

export const SwiperWrapper = styled.div.attrs({
  className: 'SwiperWrapper',
})`
  padding: 2rem 0;

  @media ${breakPoints.large_break} {
    padding: 2rem 1rem;
    max-width: 75rem;
    margin: auto;
    pointer-events: none;

    .swiper-container {
      max-height: 43rem;

      .swiper-wrapper {
        flex-wrap: wrap;

        .swiper-slide {
          &:nth-child(1) {
            order: 1;
          }
          &:nth-child(2) {
            order: 2;
          }
          &:nth-child(3) {
            order: 3;
          }
          &:nth-child(4) {
            order: 4;
          }
          &:nth-child(5) {
            order: 6;
            top: -11.05rem;
          }
          &:nth-child(6) {
            order: 5;
          }
          &:nth-child(7) {
            order: 8;
            top: -11.05rem;
          }
          &:nth-child(8) {
            order: 7;
          }
        }
      }
    }
  }
`;

export const TitleWrapper = styled.div.attrs({
  className: 'TitleWrapper',
})`
  padding: 0 2rem;

  .Title {
    text-align: center;
    letter-spacing: normal;
    margin-bottom: 0.5rem;
  }

  @media ${breakPoints.medium_break} {
    max-width: 33rem;
    margin: auto;
  }

  @media ${breakPoints.large_break} {
    max-width: 62rem;

    .Title {
      font-size: 3rem;

      &:first-of-type {
        font-size: 1.25rem;
      }
    }
  }
`;

export const SocialMediaCall = styled.div.attrs({
  className: 'SocialMediaCall',
})`
  margin-top: 1.5rem;
  .Title {
    letter-spacing: 1px;
  }
`;

export const SocialMediaLinks = styled.div.attrs({
  className: 'SocialMediaLinks',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;

  .Link {
    width: 1.75rem;
    height: 1.75rem;
    margin: 0 1rem;
    flex-shrink: 0;

    .Icon {
      width: 1.75rem;
      height: 1.75rem;
      pointer-events: none;

      svg {
        * {
          fill: white;
          stroke: white;
        }
      }
    }

    &:nth-child(2) {
      width: 2.4rem;
      height: 2.4rem;

      .Icon {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
`;
