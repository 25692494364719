import { useEffect } from 'react';

export default (shouldPreventScrolling) => {
  useEffect(() => {
    if (shouldPreventScrolling) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = null;
      document.body.style.overflow = null;
    }
    return () => {
      document.documentElement.style.overflow = null;
      document.body.style.overflow = null;
    };
  }, [shouldPreventScrolling]);
};
