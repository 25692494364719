import styled from 'styled-components';

import breakPoints from 'theme/Media';

import { Image } from '../../elements';

export const ProductOverviewStyled = styled.div.attrs({
  className: 'ProductOverviewStyled',
})`
  max-width: 30rem;
  margin: auto;

  @media ${breakPoints.large_break} {
    max-width: 74rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
  }
`;

export const ProductDescription = styled.div.attrs({
  className: 'ProductDescription',
})`
  .Title {
    letter-spacing: normal;
    margin-bottom: 1rem;
    text-align: center;
    padding: 0 2rem;
  }

  .Text {
    text-align: center;
  }

  @media ${breakPoints.large_break} {
    width: 40%;
    max-width: 25rem;
    margin-left: 2rem;

    .Title {
      font-size: 3rem;
      text-align: left;
      padding: 0;
      line-height: normal;
    }
    .Text {
      text-align: left;
    }
  }

  @media ${breakPoints.xLarge_break} {
    width: 50%;

    .Title {
      font-size: 5rem;
    }
  }
`;

export const ProductDetails = styled.div.attrs({
  className: 'ProductDetails',
})`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;

  .MobileImage {
    width: 50%;
    box-sizing: border-box;
  }

  .DesktopImage {
    width: 50%;
    box-sizing: border-box;
  }
  .ListDetailWrapper {
    width: 50%;
    box-sizing: border-box;
  }

  @media ${breakPoints.large_break} {
    width: 60%;
    margin-top: 0;
    align-items: center;

    .ListDetailWrapper {
      max-width: 210px;
      width: 30%;
      box-sizing: border-box;
    }

    .DesktopImage {
      width: 70%;
      box-sizing: border-box;
      margin-right: 1rem;
    }
  }

  @media ${breakPoints.xLarge_break} {
    .ListDetailWrapper {
      .Title {
        font-size: 18px;
      }
    }
  }
`;

export const MobileImage = styled(Image).attrs({
  className: 'MobileImage',
})`
  height: auto;
  background-color: #f2f3f5;

  picture {
    img {
      width: auto;
      height: 100%;
    }
  }

  @media ${breakPoints.large_break} {
    display: none;
  }
`;

export const DesktopImage = styled(Image).attrs({
  className: 'DesktopImage',
})`
  display: none;

  picture {
    img {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }
  }

  @media ${breakPoints.large_break} {
    display: block;
    height: auto;
  }
`;

export const ListDetailWrapper = styled.div.attrs({
  className: 'ListDetailWrapper',
})`
  padding: 2rem 1rem;
  background-color: ${({ theme }) => theme.farmProject.green};

  .Title {
    text-align: center;
    font-size: 18px;
  }

  @media ${breakPoints.large_break} {
    height: fit-content;

    .Title {
      font-size: 16px;
    }
  }
`;

export const ListDetailList = styled.ul.attrs({
  className: 'ListDetailList',
})`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const Detail = styled.li.attrs({
  className: 'Detail',
})`
  margin: 1rem 0;
  padding-left: 1.25rem;
  position: relative;

  &::before {
    content: '';
    display: block;
    background-image: url('/icons/leaf.svg');
    width: 0.875rem;
    height: 0.875rem;
    position: absolute;
    top: 0.5rem;
    left: 0.35rem;
    transform: translate(-50%, -50%);
    background-size: cover;
  }
`;
