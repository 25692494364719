import React from 'react';
import { SlimBanner } from 'components';
import { Text } from 'elements';

import { useContent } from 'utils/hooks';
import { LETTUCE_GIVE } from 'constants/contentful';

const LettuceGiveBanner = () => {
  const content = useContent(LETTUCE_GIVE);
  if (!content) return null;

  const title = content[0].fields.title;
  const body = content[0].fields.body;

  return (
    <SlimBanner modifiers='lightGreen2'>
      <Text modifiers={['small', 'brandFont', 'brandColor', 'semibold']} content={body} isHTML as='span'></Text>
      <span>&nbsp;|&nbsp;</span>
      <Text modifiers={['small', 'brandFont', 'brandColor', 'semibold']} content={title} as='span'></Text>
    </SlimBanner>
  );
};

LettuceGiveBanner.defaultProps = {};
LettuceGiveBanner.propTypes = {};

export default LettuceGiveBanner;
