import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import useIsInViewport from 'utils/hooks/useIsInViewport';
import { ContentSectionStyled, SectionInner } from './ContentSection.styled';

const ContentSection = ({ children, modifiers, animation, id }) => {
  const [isInViewport, targetRef] = useIsInViewport(0.135);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    if (isInViewport && !startAnimation) {
      setStartAnimation(true);
    }
  }, [isInViewport, startAnimation]);

  return (
    <ContentSectionStyled modifiers={modifiers} id={id}>
      <SectionInner modifiers={animation} animationState={startAnimation ? 'running' : 'paused'} ref={targetRef}>
        {children}
      </SectionInner>
    </ContentSectionStyled>
  );
};

ContentSection.propTypes = {
  children: Proptypes.oneOfType([Proptypes.array, Proptypes.element, Proptypes.string]),
  modifiers: Proptypes.oneOfType([Proptypes.array, Proptypes.string]),
  animation: Proptypes.string,
};

export default ContentSection;
