import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SizeButtonGroup, SelectSizeButton, FarmstandSizeStyled } from './SizeSelector.styled';
import { Text } from 'elements';

const SizeSelector = ({ title, sizes, onSizeUpdate, field, form }) => {
  const [selectedSize, setSelectedSize] = useState(field.value);

  const onSelectSize = (size, index) => {
    setSelectedSize(size);
    onSizeUpdate?.(size, index);
    const setField = form?.setFieldValue;
    if (field?.name && setField) setField(field.name, size);
  };

  const renderSizeButton = (size, index) => {
    const isSelected = +size === +selectedSize;
    const modifiers = [isSelected ? 'primaryNoHover' : 'invertedNoHover'];

    return (
      <SelectSizeButton
        type='button'
        key={index}
        modifiers={modifiers}
        label={size}
        onClick={() => onSelectSize(size, index)}
        ariaLabel={`${size}-plant farmstand`}
        ariaSelected={isSelected ? 'true' : 'false'}
      />
    );
  };

  return (
    <FarmstandSizeStyled>
      {!!title && <Text modifiers={['brandFont', 'xLarge', 'left']} content={title} as='span' />}
      <SizeButtonGroup>{sizes.map(renderSizeButton)}</SizeButtonGroup>
    </FarmstandSizeStyled>
  );
};

SizeSelector.propTypes = {
  sizes: PropTypes.array.isRequired,
  title: PropTypes.string,
  onSizeUpdate: PropTypes.func,
  field: PropTypes.object,
  form: PropTypes.object,
};

export default SizeSelector;
