import styled from 'styled-components';

const MessageBannerStyled = styled.div.attrs({
  className: 'MessageBanner',
})`
  height: 2.85rem;
  overflow: hidden;
  background: ${(props) => props.theme.farmProject.dark_gray};
  
  .SlimBanner {
    height: 100%;
    
    .Text {
      font-weight: 600;
      text-transform: uppercase;

        a {
          font-size: 0.7rem;
          color: ${(props) => props.theme.farmProject.gray_3};
        }
      }
    }
  }

  .SwiperStyled {
    .swiper-container {
      height: 2.85rem;

      .swiper-wrapper {
        align-items: center;

        .swiper-slide {
          .SlimBanner {
            .Icon {
              width: 0.72rem;
              height: 0.72rem;
              margin-left: 0.2rem;
              margin-bottom: 0.1rem;
              overflow: visible;

              svg {
                width: 95%;
              }
            }
            svg,
            g {
              fill: transparent;
              stroke: ${(props) => props.theme.farmProject.gray_3};
              width: 100%;
              height: auto;
              vertical-align: middle;
              cursor: pointer;
            }

            .Link {
              color: ${(props) => props.theme.farmProject.pink}
            }
          }
        }
      }
    }
  }
`;

export default MessageBannerStyled;
