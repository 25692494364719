const colors = {
  MAIN_GREEN: '#3D714E',
  BLACK: '#383a36',
  WHITE: '#FFF',
  ERROR_RED: '#d0021b',
  MAIN_YELLOW: '#ffca3c',
  SECTION_BLUE: '#56cbdc',
  GREY: '#b4b4b4',
  LIGHT_GREY: '#FAFAFA',
  BLACK_INPUT: '#383a36',
};
export default colors;
