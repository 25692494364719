import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme/Media';
import { centeredMixin } from 'theme/Mixins';

const modifiersModal = {
  hide: () => ({
    styles: `
      display: none;
    `,
  }),
  show: () => ({
    styles: `
      display: block;
    `,
  }),
};

const modifiersContent = {
  small: () => ({
    styles: `
      max-width: 20rem;
    `,
  }),
  large: () => ({
    styles: `
      max-width: 50rem;
    `,
  }),
  normal: () => ({}),
  scroll: () => ({
    styles: `
      height: 100%;
      .modal__body {
        height: 100%;
        overflow: auto;
      }
    `,
  }),
  fitContent: () => ({
    styles: `
      width: fit-content;
      max-width: 47rem;

      @media ${breakPoints.medium_break} {
        padding: 1rem 0;
      }
    `,
  }),
  bundleModal: () => ({
    styles: `
      border-radius: 0.25rem;
      background-color: white;

      .ModalInner {
        .Title {
          font-size: 2rem;
          margin-bottom: 1rem;
        }

        .ModalPlantsRow {
          .Text {
            margin-bottom: 0 !important;

          }
        }

        .Text {
          margin-bottom: 0;


          &:last-of-type {
            margin-bottom: 2rem;
          }
        }
      }
    `,
  }),
};

export const ModalStyled = styled.div.attrs({
  className: 'ComponentModal',
})`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  ${applyStyleModifiers(modifiersModal)};
`;

export const ModalWrapper = styled.div.attrs({
  className: 'ModalWrapper',
})`
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${centeredMixin};
`;

export const ModalBackdrop = styled.div.attrs({
  className: 'modal__backdrop',
})`
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1040;
`;

export const ModalCloseButton = styled.button.attrs({
  type: 'button',
  className: 'ModalCloseBtn',
})`
  background: none;
  border: none;
  width: 1.75rem;
  height: 1.75rem;
  padding: 0;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2rem;
  right: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.farmProject.dark_gray};
    width: 1.25rem;
    height: 1.25rem;
  }
  * {
    cursor: pointer;
  }

  @media ${breakPoints.medium_break} {
    top: 2.5rem;
    right: 0.5rem;
  }
`;

export const ModalContent = styled.div.attrs({
  className: 'modal__Content',
})`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.farmProject.gray_3};
  width: calc(100% - 2rem);
  max-width: 38.31rem;
  max-height: 100%;
  overflow-y: auto;
  border-radius: 0;
  padding: 1rem;
  margin: 1.75rem 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1050;

  @media ${breakPoints.medium_break} {
    padding: 3rem 0;
  }

  ${applyStyleModifiers(modifiersContent)};
`;

export const ModalInner = styled.div.attrs({
  className: 'ModalInner',
})`
  width: 100%;
  max-width: 28.5rem;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .Title {
    font-size: 2rem;
    text-align: center;
    line-height: 2.5rem;
    letter-spacing: 0.2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .Text {
    text-align: center;
  }

  .Button {
    width: 10rem;
    margin: auto;
    margin-bottom: 2rem;
    margin-top: 3rem;
  }

  @media ${breakPoints.medium_break} {
    .Title {
      font-size: 3.125rem;
      line-height: normal;
      letter-spacing: 0;
      margin-top: 0;
      margin-bottom: 1.75rem;
    }

    .Button {
      margin-top: 5.875rem;
      margin-bottom: 0;
    }
  }
`;
