import React from 'react';
import PropTypes from 'prop-types';

import BackButtonBase from '@lettucegrow/atoms.back-button';
import { trackClick } from 'utils/googleTagManager';

const BackButton = ({ label, onClick = null, modifiers, eventData }) => {
  const handleClick = (event) => {
    eventData?.action && trackClick(eventData);
    onClick && onClick(event);
  };
  return <BackButtonBase label={label} onClick={handleClick} modifiers={modifiers} />;
};

BackButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  modifiers: PropTypes.any,
  eventData: PropTypes.object,
};

export default BackButton;
