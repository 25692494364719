import { initializeApp } from 'firebase/app';
import { authDomain } from './envConfig';

/**
 * * Firebase config variables
 */

let config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain,
  databaseURL: process.env.GATSBY_FIREBASE_DB_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT,
  storageBucket: process.env.GATSBY_FIREBASE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_SENDER_ID,
};

const firebaseApp = initializeApp(config);

export default firebaseApp;
