import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { PaginationWrapper } from './Paging.styled';

const Paging = ({ pageSize, total, currentPage, baseRoute, suffix }) => {
  return (
    <PaginationWrapper>
      <Pagination
        count={Math.ceil(total / pageSize)}
        defaultPage={currentPage}
        page={currentPage}
        siblingCount={0}
        boundaryCount={1}
        renderItem={(item) => <PaginationItem component={Link} to={`${baseRoute}${item.page}${suffix}`} {...item} />}
      />
    </PaginationWrapper>
  );
};

Paging.defaultProps = {
  suffix: '',
};

Paging.propTypes = {
  pageSize: PropTypes.number,
  total: PropTypes.number,
  currentPage: PropTypes.number,
  baseRoute: PropTypes.string,
  suffix: PropTypes.string,
};

export default Paging;
