import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { ButtonText, Text } from 'elements';
import AccountStyled from './Account.styled';

import paths from 'constants/paths';
import { getLoginState, getCustomer, getUserHasOrders } from 'reduxState/user';
import { setOpenModal } from 'reduxState/modal';
import { useAuth } from 'utils/hooks';

const AccountMenu = ({ isPageScrolled }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getLoginState);
  const customer = useSelector(getCustomer);
  const hasUserOrders = useSelector(getUserHasOrders);
  const auth = useAuth();

  const handleLogout = () => {
    auth.signOut();
  };

  const handleLoginModal = () => {
    dispatch(setOpenModal('login'));
  };

  return (
    <AccountStyled className='Account' isPageScrolled={isPageScrolled}>
      <Text modifiers={['bold', 'brandFont']} content={isLoggedIn ? `HI, ${user.firstName.toUpperCase()}` : 'ACCOUNT'} />
      <ul>
        {isLoggedIn && (
          <>
            <li>
              <ButtonText modifiers={['secondary', 'brandHover', 'small']} label={'MY FARM'} href={paths.MY_FARM} />
            </li>
            {hasUserOrders && (
              <li>
                <ButtonText
                  modifiers={['secondary', 'brandHover', 'small']}
                  label={'MY DELIVERIES'}
                  href={`${paths.MY_FARM}#my-deliveries`}
                />
              </li>
            )}
            <li>
              <ButtonText modifiers={['secondary', 'brandHover', 'small']} label={'MY ACCOUNT'} href={`${paths.MY_FARM}#my-account`} />
            </li>
            {customer?.referralCode && (
              <li>
                <ButtonText modifiers={['secondary', 'brandHover', 'small']} label={'REFER & EARN'} href={`${paths.MY_FARM}#my-referral`} />
              </li>
            )}
          </>
        )}
        <li>
          <ButtonText
            modifiers={['secondary', 'brandHover', 'small']}
            label={isLoggedIn ? 'LOGOUT' : 'LOGIN'}
            onClick={isLoggedIn ? handleLogout : handleLoginModal}
          />
        </li>
        {!isLoggedIn && (
          <li>
            <ButtonText modifiers={['secondary', 'brandHover', 'small']} label={'CREATE ACCOUNT'} href={paths.CREATE_ACCOUNT} />
          </li>
        )}
      </ul>
    </AccountStyled>
  );
};

AccountMenu.defaultProps = { isPageScrolled: false };
AccountMenu.propTypes = { isPageScrolled: PropTypes.bool };

export default AccountMenu;
