import { setReservationCode, fetchReservation } from './reservation';

const reservationMiddleware = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === setReservationCode.toString()) {
    dispatch(fetchReservation(action.payload));
  }
};

export default reservationMiddleware;
