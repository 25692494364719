import styled from 'styled-components';
import breakPoints from 'theme/Media';
import { CardElement } from '@stripe/react-stripe-js';

export const CompleteAccountModalStyled = styled.div.attrs({
  className: 'CompleteAccountModal',
})`
  .ComponentModal {
    .ModalWrapper {
      .modal__Content {
        margin: 0;
        height: 100%;
        width: 100%;
        .ModalInner {
          form {
            width: 100%;
          }
          max-width: 23.5rem;

          .Text.Description {
            margin-bottom: 3rem;
          }
        }
        .InputStyled {
          align-items: start;
        }
        .MuiFormControl-root,
        .MuiInputBase-root {
          max-width: unset;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ComponentModal {
      .ModalWrapper {
        .modal__Content {
          width: calc(100% - 2rem);
          height: unset;
          .ModalInner {
            max-width: 29.5rem;
          }
          .StatusMessage {
            margin-top: 1rem;
          }
        }
      }
    }
  }
`;

export const StyledCardElement = styled(CardElement)`
  margin: 0.8em 0;
  border-bottom: 1px solid ${({ theme }) => theme.farmProject.dark_gray};
  background-color: transparent;
  padding: 0.85em 1.1em;
  color: ${({ theme }) => theme.farmProject.dark_gray};
  font-size: ${({ theme }) => theme.sizes.x6};
  transition: border-color 0.2s, background-color 0.2s;
  width: 100%;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
`;
