import React from 'react';
import PropTypes from 'prop-types';

import { Loading } from 'components';

const FedExRedirect = ({ to, trackingNum }) => {
  window.location.href = `${to}${trackingNum}`;
  return <Loading></Loading>;
};

FedExRedirect.propTypes = {
  to: PropTypes.string,
  trackingNum: PropTypes.string,
};

export default FedExRedirect;
