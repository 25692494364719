import styled from 'styled-components';
import { TransitionGroup } from 'react-transition-group';

import breakPoints from 'theme/Media';
import { Title } from 'elements';

export const Section = styled.section`
  padding: 1rem;
  max-width: 1440px;
  margin: 0 auto;
  text-align: left;

  .Promotion {
    margin-top: -2rem;
    margin-bottom: 2rem;
  }

  @media ${breakPoints.large_break} {
    .CategoriesWrapper {
      flex-direction: row;
    }

    .EnvironmentConfig {
      gap: 5rem;
      justify-content: space-between;
      flex-direction: row;
    }
  }

  .ToggleInOutStyled {
    margin-top: 2rem;
  }
  .ToggleStyled {
    margin: unset;
  }

  > .Text {
    margin-top: 1rem;
  }

  > .Button {
    margin: 1rem auto 5rem;
  }
`;

export const CategoryBtn = styled.div`
  font-size: 1rem;
  font-family: Rubik, sans-serif;
  font-weight: ${({ isSelected }) => (isSelected ? 500 : 400)};
  padding: 0.8rem 0;
  margin: 0 0.5rem;
  border-bottom: 3px solid ${({ isSelected, theme }) => (isSelected ? theme.farmProject.green : 'transparent')};
  color: ${({ isSelected, theme }) => (isSelected ? theme.farmProject.green : theme.farmProject.dark_gray)};
  transition: border 0.2s;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: ${({ theme }) => theme.farmProject.green};
    border-bottom: 3px solid ${({ theme }) => theme.farmProject.green};
  }
`;

export const Categories = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
`;

export const ZipWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 75%;
  margin: 0 auto;

  .Text {
    margin: 0 1rem;
    letter-spacing: 0;
  }

  .ZipCode {
    width: auto;
    margin: unset;

    .ButtonText {
      right: -2.5rem;
    }
  }

  @media ${breakPoints.large_break} {
    flex-direction: row;
    justify-content: start;
    margin: 1rem 0;
    width: 100%;

    .Text {
      margin: unset;
      margin-right: 0.5rem;
    }
  }
`;

export const SingleSeedlingList = styled(TransitionGroup).attrs({
  className: 'SingleSeedlingList',
})`
  margin-bottom: 0rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .SeedlingCardStyled {
    .PillLabelStyled {
      padding: 0.125rem 0.5rem;

      .Text {
        font-size: 0.6rem;
      }
    }

    .ImageWrapper {
      width: 85%;
      margin: auto;
    }

    .BundleInfo {
      padding: 0.75rem 0.25rem 0.125rem;
    }

    .TextTooltipWrapperStyled {
      padding: 0;
    }
  }

  @media ${breakPoints.medium_break} {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  @media ${breakPoints.large_break} {
    grid-template-columns: repeat(5, 1fr);
    .SeedlingCardStyled {
      .PillLabelStyled {
        padding: 0.125rem 0.5rem;

        .Text {
          font-size: 0.5rem;
        }
      }
    }
  }
`;

export const SectionTitle = styled(Title)`
  margin: 3rem 0 2rem;
  font-weight: 400;
`;
