import styled from 'styled-components/macro';

export const DisclaimerWrapper = styled.div.attrs({
  className: 'CreateAccountDisclaimerWrapper',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  border: 1px solid ${({ theme }) => theme.farmProject.gray_1};
  padding: 0.25rem 1rem 0.625rem;
  background-color: ${({ theme }) => theme.farmProject.gray_2};
`;
