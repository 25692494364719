import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage as Image, withArtDirection } from 'gatsby-plugin-image';

import ImageStyled from './ImageGatsby.styled';

const GatsbyImage = ({ modifiers, alt, image, breakPointImages }) => {
  let breakPointImagesArtDirection;
  if (breakPointImages?.length) {
    const defaultImage = breakPointImages.find(({ isDefault }) => isDefault)?.image;
    const restImages = breakPointImages.filter(({ isDefault }) => !isDefault).map(({ image, media }) => ({ image, media }));
    breakPointImagesArtDirection = withArtDirection(defaultImage, restImages);
  }
  return (
    <ImageStyled modifiers={modifiers}>
      <Image image={breakPointImagesArtDirection ? breakPointImagesArtDirection : image} alt={alt || 'alt'} />
    </ImageStyled>
  );
};

export const GatsbyImageProps = {
  image: PropTypes.object,
  breakPointImages: PropTypes.arrayOf(
    PropTypes.shape({
      isDefault: PropTypes.bool,
      image: PropTypes.shape({
        layout: PropTypes.oneOf(['fixed', 'fullWidth', 'constrained']),
        width: PropTypes.number,
        height: PropTypes.number,
        backgroundColor: PropTypes.string,
        images: PropTypes.any,
        placeholder: PropTypes.any,
      }),
      media: PropTypes.string,
    })
  ),
  alt: PropTypes.string,
  modifiers: PropTypes.any,
};

export const GatsbyImageDefaultProps = {
  breakPointImages: [],
};

GatsbyImage.propTypes = GatsbyImageProps;

GatsbyImage.defaultProps = GatsbyImageDefaultProps;

export default GatsbyImage;
