import React from 'react';
import { Image, Text } from 'elements';
import { deviceTypes } from 'constants';
import { WrapFarmTypes, TypeCard } from './TypeSelector.styled';
import PropTypes from 'prop-types';

const TypeSelector = ({ onSelect, selectedValue, currentImage, options, isEdit }) => {
  const handleClick = (value) => {
    onSelect('farmType', value);
  };

  const renderTypeCardOption = (option, index) => {
    const deviceTypeOption = option.isV2 ? deviceTypes.v2 : deviceTypes.farmstand;
    const isSelected = selectedValue === deviceTypeOption;

    if (isEdit && !isSelected) return null;

    return (
      <TypeCard
        key={index}
        onClick={() => handleClick(deviceTypeOption)}
        isSelected={isSelected}
        isDisabled={!!selectedValue && !isSelected}
      >
        <Image picture={currentImage || option?.file?.url} alt={option?.title} />
        <Text content={option?.title} />
      </TypeCard>
    );
  };

  return (
    <WrapFarmTypes modifiers={['spaceBetween', ...(isEdit ? ['justifyCenter', 'editMode'] : [])]}>
      {options.map(renderTypeCardOption)}
    </WrapFarmTypes>
  );
};

TypeSelector.propTypes = {
  onSelect: PropTypes.func,
  selectedValue: PropTypes.string,
  currentImage: PropTypes.string,
  options: PropTypes.array,
  isEdit: PropTypes.bool,
};

export default TypeSelector;
