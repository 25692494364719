/**
 * A Line Item for the Cart on Lettuce Grow
 */

import React from 'react';

import { Link } from 'gatsby';
import styled from 'styled-components/macro';

import paths from 'constants/paths';
import { SquaredButton } from 'elements';

const EmptyCartContainer = styled.section`
  padding-top: 30px;
  text-align: center;
  position: relative;
  opacity: ${(props) => (props.state === 'entered' ? '1' : '0')};
  transition: opacity 200ms ease-in;

  p {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 1.2rem;
    margin-bottom: 40px;
  }

  .Button {
    margin: auto;
  }
`;

export default ({ closeModal, state }) => {
  return (
    <EmptyCartContainer state={state}>
      <p>Looks like your cart is currently empty.</p>
      <Link to={paths.SHOP_PRODUCTS} onClick={closeModal}>
        <SquaredButton label='START SHOPPING' />
      </Link>
    </EmptyCartContainer>
  );
};
