import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme/Media';

export const zipcodeModifiers = {
  small: () => ({
    styles: `
      width: 15rem;

      > .Text {
        font-size: 0.5rem;
      }

      .ButtonText {
        &:first-of-type {
          max-width: 4rem;
          padding-left: 0;
          span {
            font-size: 1rem;
          }
        }
        &:nth-of-type(2) {
          right: 0;
        }
      }

      .InputStyled {
        max-width: 3.5rem;
        .MuiTextField-root {
          padding-left: 0;
          .MuiInputBase-input {
            font-size: 1rem;
          }
        }
      }
    `,
  }),
  medium: () => ({
    styles: `
      .ButtonText {
        &:first-of-type {
          span {
            font-size: 1.7rem;
          }
        }
      }

      .InputStyled {
        max-width: 5.65rem;
        .MuiTextField-root {
          .MuiInputBase-input {
            font-size: 1.7rem;
          }
        }
      }
    `,
  }),
  openInput: () => ({
    styles: `
      .ButtonText {
        &:first-of-type {
          display: none;
        }
      }
    `,
  }),
};

export const modifiersInputGroup = {
  show: () => ({
    styles: `
      display: block;
    `,
  }),
  hide: () => ({
    styles: `
      display: none;
    `,
  }),
};

export const ZipCode = styled.div.attrs({
  className: 'ZipCode',
})`
  width: 100%;
  display: flex;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  > .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 0.875rem;
    font-weight: 700;
    text-align: flex-start;
    color: ${({ theme }) => theme.farmProject.dark_gray};
    line-height: normal;
    margin: 0.75rem auto;
    display: block;
  }

  .ButtonText {
    &:first-of-type {
      span {
        font-size: 2.375rem;
        font-weight: 200;
        text-align: center;
        color: ${({ theme }) => theme.farmProject.brand_primary};
      }
      width: 100%;
      max-width: 9rem;
    }
    &:nth-of-type(2) {
      position: absolute;
      padding: 0;
      right: 2.5rem;
      bottom: 0.28rem;
      @media ${breakPoints.medium_break} {
        right: 5rem;
      }
    }
  }

  .InputStyled {
    width: 100%;
    max-width: 9rem;
    align-items: center;
    padding: 0.25rem;
    padding-right: 0;
    padding-left: 0;
    margin: 0;

    .MuiTextField-root {
      max-width: 7.5rem;
      padding-left: 0.4rem;

      .MuiInputBase-input {
        color: ${({ theme }) => theme.farmProject.brand_primary};
        font-family: ${({ theme }) => theme.farmProject.font_family};
        font-size: 2.375rem;
        font-weight: 200;
        letter-spacing: 0.05375rem;
        line-height: normal;
        box-sizing: border-box;
        height: auto;
        padding: 0px;
      }
    }
  }

  @media ${breakPoints.medium_break} {
    margin: 1rem auto;
    flex-direction: row;

    > .Text {
      text-align: left;
      width: 7rem;
      margin: 0;
    }
  }

  ${applyStyleModifiers(zipcodeModifiers)};
`;

export const InputGroup = styled.div.attrs({
  className: 'InputGroup',
})`
  display: none;
  margin: auto;
  width: 100%;
  max-width: 15rem;

  .ButtonText {
    span {
      font-size: 0.85rem;
      text-align: center;
      letter-spacing: normal;
      color: ${({ theme }) => theme.farmProject.brand_primary};
    }
    width: auto;
    max-width: 2.5rem;
    padding-bottom: 0.125rem;
    margin: auto;
    display: block;
  }

  ${applyStyleModifiers(modifiersInputGroup)};
`;
