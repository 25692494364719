import styled from 'styled-components/macro';
import { SquaredButton } from 'elements';
import breakpoints from 'theme/Media';

export const WrapShopButton = styled.div.attrs({ className: 'WrapShopButton' })`
  width: 100%;

  &.active {
    .NavShopWrapper {
      margin-bottom: 0;
      height: 1.4rem;
      padding-top: 1rem;
      border: none;
      border-radius: 2px 2px 0 0;
      background-color: ${({ theme }) => theme.farmProject.gray_3};

      span {
        margin-top: -0.7rem;
        color: ${({ theme }) => theme.farmProject.dark_green};
      }

      & > div {
        margin-top: -0.7rem;
      }
    }
  }

  @media ${breakpoints.large_break} {
    order: 1;
    position: relative;
    width: auto;

    padding: 0.2rem;
    padding-right: 0.1rem;
    height: 1.25rem;
    margin: 0 0 0 1em;

    span {
      margin: 0 auto;
    }

    & > div {
      margin-top: 0;
    }
  }

  @media (hover: hover) {
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 100%;
    }

    &:hover .ShopDropdown {
      display: grid;

      @media ${breakpoints.large_break} {
        grid-template-columns: 1fr;
      }
    }
  }
`;

export const ShopButton = styled(SquaredButton).attrs({ className: 'NavShopWrapper' })`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin: 1.6rem 0 1rem 0;
  padding: 0.2rem;
  padding-right: 0.1rem;
  height: 1.75rem;
  order: -1;
  position: relative;
  transition: none;

  &:hover {
    background-color: ${({ theme }) => theme.farmProject.dark_green};

    span {
      color: ${({ theme }) => theme.farmProject.gray_3};
    }
  }

  > a {
    margin-right: 0.5em;
    white-space: nowrap;
  }

  span {
    font-size: 0.75rem;
    margin: 0 auto;
    font-weight: 500;
  }

  > div {
    position: absolute;
    width: 8px;
    right: 10%;
  }

  @media ${breakpoints.large_break} {
    span {
      font-size: 0.65rem;
    }
    height: 1.25rem;
    margin: 0 0 0 1em;
    order: unset;
  }
  @media ${breakpoints.xLarge_break} {
    margin: 0 0 0 2em;
  }
`;

export const ShopDropdown = styled.ul.attrs({ className: 'ShopDropdown' })`
  display: ${(props) => (props.isOpen ? 'grid' : 'none')};
  grid-template-columns: 1fr 1fr;
  background-color: ${({ theme }) => theme.farmProject.gray_3};
  min-width: 12.5rem;
  padding: 0;
  position: relative;
  box-shadow: 0px 4px 6px rgba(130, 130, 127, 0.2);

  @media ${breakpoints.large_break} {
    grid-template-columns: 1fr;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 25%;
    box-sizing: border-box;

    border: 0.6em solid black;
    border-color: transparent transparent ${({ theme }) => theme.farmProject.gray_3} ${({ theme }) => theme.farmProject.gray_3};

    transform-origin: 0 0;
    transform: rotate(135deg);
    display: none;

    @media ${breakpoints.large_break} {
      display: block;
    }
  }

  li {
    a {
      display: block;
      padding: 1rem 1.5rem;
      font-size: 0.875rem;
      text-align: center;

      span {
        text-align: center;
        display: block;
      }

      @media ${breakpoints.large_break} {
        text-align: left;
        span {
          text-align: left;
        }
      }

      &:hover {
        img {
          opacity: 0;

          &.hover {
            opacity: 1;
          }
        }

        @media ${breakpoints.large_break} {
          background-color: ${({ theme }) => theme.farmProject.dark_green};
          color: ${({ theme }) => theme.farmProject.gray_3};

          img {
            display: none;
          }
        }
      }
    }

    &:first-child a:hover:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 25%;
      box-sizing: border-box;

      border: 0.6em solid black;
      border-color: transparent transparent ${({ theme }) => theme.farmProject.dark_green} ${({ theme }) => theme.farmProject.dark_green};

      transform-origin: 0 0;
      transform: rotate(135deg);
      z-index: 40;
      display: none;

      @media ${breakpoints.large_break} {
        display: block;
      }
    }
  }

  @media ${breakpoints.large_break} {
    position: absolute;
    z-index: 20;
    top: 2.5rem;
    margin-left: 1.25rem;
  }
`;

export const WrapImage = styled.picture.attrs({ className: 'WrapImage' })`
  position: relative;

  @media ${breakpoints.large_break} {
    display: none;
  }

  img {
    position: relative;
    z-index: 5;
    transition: all 0.5s ease;
    width: 100%;

    @media ${breakpoints.large_break} {
      width: auto;
    }

    &.hover {
      position: absolute;
      z-index: 4;
      left: 0;
      opacity: 0;
    }
  }
`;
