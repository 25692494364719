import React from 'react';
import PropTypes from 'prop-types';

import FlexStyled from './Flex.styled';

const Flex = ({ id, modifiers, children, className, onClick, as }) => {
  return (
    <FlexStyled id={id} className={className ? 'Flex ' + className : 'Flex'} as={as} modifiers={modifiers} onClick={onClick}>
      {children}
    </FlexStyled>
  );
};

Flex.propTypes = {
  as: PropTypes.string,
  modifiers: PropTypes.any,
  children: PropTypes.any,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Flex;
