import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';

import createStore from './src/app/reduxState/store';

// Firebase auth inside React context.
import { ProvideAuth } from 'utils/hooks/useAuth';
import { farmTheme } from 'theme/Theme';
import theme from 'utils/theme';
import { getStorage } from 'utils/storageManager';
import { isPos } from 'utils/envConfig';

import POSAuth from 'src/staticPages/pos-auth/PosAuth';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  const store = createStore();
  const isAuthorized = getStorage('POS_ACCESS');

  if (isPos && !isAuthorized) return <POSAuth />;

  if (typeof window === 'undefined') {
    return (
      <StaticRouter>
        <ThemeProvider theme={{ ...theme, ...farmTheme }}>
          <Provider store={store}>
            <ProvideAuth>{element}</ProvideAuth>
          </Provider>
        </ThemeProvider>
      </StaticRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <ThemeProvider theme={{ ...theme, ...farmTheme }}>
          <Provider store={store}>
            <ProvideAuth>{element}</ProvideAuth>
          </Provider>
        </ThemeProvider>
      </BrowserRouter>
    );
  }
};
