import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import cx from 'classnames';
import WidthProvider from './WidthProvider';
import { icons } from 'constants/';

ReactModal.setAppElement('#___gatsby');

class Modal extends Component {
  close = () => {
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        className={cx(`lg-modal ${this.props.className}`)}
        overlayClassName={cx('lg-modal-overlay', this.props.overlayClassName, {
          'lg-modal-overlay--fullscreen': this.props.isFullscreen || (this.props.isMobileFullscreen && this.props.device.isMobile),
          'lg-modal-overlay--transparent': this.props.isTransparent,
          'lg-modal-overlay--white': this.props.hasWhiteOverlay,
        })}
        bodyOpenClassName={this.props.bodyOpenClassName}
        htmlOpenClassName={this.props.htmlOpenClassName}
        portalClassName='lg-modal-portal'
        onRequestClose={this.close}
        contentRef={(node) => (this.contentContainer = node)}
        // closeTimeoutMS={300} // timeout for modal closing
        shouldCloseOnOverlayClick={this.props.canClose}
        shouldCloseOnEsc={this.props.canClose}
        style={
          !this.props.hasOverlay && !!this.contentContainer
            ? {
                overlay: Object.assign({}, this.props.style.overlay, {
                  width: this.contentContainer.offsetWidth,
                  height: this.contentContainer.offsetHeight,
                }),
                content: this.props.style.content,
              }
            : this.props.style
        }
      >
        {this.props.hasCloseButton && <div className={`icon icon-${icons.CLOSE}`} onClick={this.close} />}
        {this.props.children}
      </ReactModal>
    );
  }
}

Modal.defaultProps = {
  isOpen: false,
  onClose: null,
  isFullscreen: false,
  isMobileFullscreen: false,
  isTransparent: false,
  hasWhiteOverlay: false,
  hasOverlay: true,
  hasCloseButton: true,
  canClose: true,
  className: '',
  overlayClassName: '',
  style: {},
  bodyOpenClassName: 'ReactModal__Body--open', // Default class to apply to body when modal is open
  htmlOpenClassName: 'ReactModal__Html--open', // Default class to apply to html when modal is open
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isFullscreen: PropTypes.bool,
  isMobileFullscreen: PropTypes.bool,
  isTransparent: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  hasWhiteOverlay: PropTypes.bool,
  hasOverlay: PropTypes.bool,
  canClose: PropTypes.bool,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  style: PropTypes.object,
  bodyOpenClassName: PropTypes.string,
  htmlOpenClassName: PropTypes.string,
};

export default WidthProvider(Modal);
