import React from 'react';
import PropTypes from 'prop-types';

import { ToastStyled } from './Toast.styled';
import { Icon, Text, ButtonText } from 'elements';
import Tick from 'elements/icon/assets/tick.svg';

const Toast = ({ label, linkText, onClick, modifiers }) => {
  return (
    <ToastStyled modifiers={modifiers}>
      <Icon>
        <Tick />
      </Icon>
      <Text as='span' content={label} />
      <ButtonText label={linkText} onClick={onClick} />
    </ToastStyled>
  );
};

Toast.propTypes = {
  label: PropTypes.string,
  linkText: PropTypes.string,
  onClick: PropTypes.func,
  modifiers: PropTypes.any,
};

export default Toast;
