import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import breakPoints from 'theme/Media';

import Flex from 'components/flex/Flex';

const modifiers = {
  singlePhotoWithText: () => ({
    styles: `
      display: flex;
      flex-direction: row;
    `,
  }),
};

export const PhotoContainer = styled.div.attrs({ className: 'PhotoContainer' })`
  ${applyStyleModifiers(modifiers)};
`;

export const AddPhotoWrapper = styled.div.attrs({
  className: 'AddPhotoWrapper',
})`
  text-align: center;
  .Text {
    margin-bottom: 0.5rem;
  }

  .Text + .Text {
    font-size: 0.75rem;
  }
  @media ${breakPoints.large_break} {
    text-align: left;
  }
`;

export const AddPhotoBtnGroup = styled.div.attrs({
  className: 'AddPhotoBtnGroup',
})`
  max-width: 18rem;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;

  .Image {
    height: 5rem;
    width: 5rem;
  }

  @media ${breakPoints.large_break} {
    max-width: unset;
  }
`;

export const AddPhotoBtn = styled.button.attrs({
  className: 'AddPhotoBtn',
})`
  border: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover {
    cursor: pointer;
  }

  // hide file upload field
  .InputStyled {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: 10;
    &:hover,
    &:hover input {
      cursor: pointer;
    }
    .MuiInputBase-root:hover,
    .MuiFormControl-root {
      cursor: pointer;
    }
  }

  .Icon {
    height: 3.55rem;
    width: 2.25rem;
    &:hover {
      cursor: pointer;
    }

    svg {
      width: 98%;
      opacity: 0.5;
      g {
        path {
          stroke: ${({ theme }) => theme.farmProject.dark_gray};
        }
      }
    }
  }
`;

export const PhotoWrapper = styled.div.attrs({ className: 'PhotoWrapper' })`
  position: relative;
  border: ${({ theme, hasError }) => (hasError ? `1px solid ${theme.farmProject.error}` : 'none')};
  height: 5rem;
  .Icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 1rem;
    height: 1rem;
    border: 3px solid ${({ theme }) => theme.farmProject.gray_3};
    background: ${({ theme }) => theme.farmProject.gray_3};
  }

  img,
  source {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PhotosTextContent = styled(Flex).attrs({ className: 'PhotosTextContent' })`
  .Text {
    text-align: left;
  }
`;
