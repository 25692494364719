/**
 * A Line Item for the Cart on Lettuce Grow
 */

import React from 'react';

import styled from 'styled-components/macro';

import paths from 'constants/paths';
import { ButtonText } from 'elements';

const EmptyOrderContainer = styled.section`
  padding: 30px 20px 20px;
  text-align: center;

  p {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }
`;

const EmptyOrderMessage = () => {
  return (
    <EmptyOrderContainer>
      <p>Looks like your cart is currently empty.</p>
      <ButtonText label='START SHOPPING' href={paths.FARMSTAND} />
    </EmptyOrderContainer>
  );
};

export default EmptyOrderMessage;
