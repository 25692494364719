import layout from './layout';
import colors from './colors';
import sizes from './sizes';
import font from './font';
import icons from './icons';

/**
 * * The general styles used throughout LG passed via context as the theme prop
 */
export { sizes, layout, colors, font, icons };

export default { sizes, layout, colors, font, icons };
