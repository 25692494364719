import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { isIOS, isAndroid } from 'react-device-detect';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

import { SquaredButton, Text } from 'elements';
import { Flex } from 'components';
import paths from 'constants/paths';
import { lgUrlPrefix } from 'utils/envConfig';

import { BannerWrapper } from './FarmVisualizerBanner.styled';

const IOS_CTA_ID = '4yTs1NWtcIfZvO7lnH5Q3v';
const ANDROID_CTA_ID = '4PQE0M2xniAj38e63VWHz0';

const FarmVisualizerBanner = ({ title, body, cta = {}, mobileCtas = [] }) => {
  const location = useLocation();
  const iosCta = mobileCtas?.find?.(({ sys }) => sys.id === IOS_CTA_ID)?.fields;
  const androidCta = mobileCtas?.find?.(({ sys }) => sys.id === ANDROID_CTA_ID)?.fields;
  const mobileCta = isIOS ? iosCta : isAndroid ? androidCta : null;
  const desktopCtaUrl = cta?.linkUrl;

  if (!desktopCtaUrl && !mobileCtas?.length) return null;

  const QRCodeAnalyticsAction = 'QRVisualizer';
  const queryParamsConfig = {
    action: QRCodeAnalyticsAction,
    ios: iosCta?.linkUrl,
    android: androidCta?.linkUrl,
    currentUrl: location.pathname,
  };
  const queryParams = queryString.stringify(queryParamsConfig);
  const QRCodeValue = `${lgUrlPrefix}${paths.ANALYTICS}?${queryParams}`;

  return (
    <BannerWrapper modifiers={['row']}>
      <Flex modifiers={['directionColumn', 'flex1']}>
        <Text content={title} className='title' modifiers={['xLarge', 'brandFont', 'noLineHeight']} />
        <Text content={body} modifiers={['brandFont', 'light', 'noLineHeight']} />
        {!!mobileCta && (
          <SquaredButton
            label={mobileCta.linkText}
            href={mobileCta.linkUrl}
            eventData={{ action: 'mobileClickVisualizer' }}
            target={mobileCta.target}
            className='visualizerCTA'
            modifiers='tertiary'
            as='a'
          />
        )}
      </Flex>
      <Flex className='qrCodeContainer' modifiers={['flex0', 'alignCenter', 'justifyCenter']}>
        {desktopCtaUrl && <QRCode value={QRCodeValue} size={85} />}
      </Flex>
    </BannerWrapper>
  );
};

FarmVisualizerBanner.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  cta: PropTypes.object,
  mobileCtas: PropTypes.array,
};

export default FarmVisualizerBanner;
