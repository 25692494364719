import React from 'react';
import PropTypes from 'prop-types';

import { RemoveItemButtonStyled } from './RemoveItemButton.styled';

const RemoveItemButton = ({ onClick, value }) => {
  return (
    <RemoveItemButtonStyled value={value} onClick={onClick}>
      &#x2715;
    </RemoveItemButtonStyled>
  );
};

RemoveItemButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default RemoveItemButton;
