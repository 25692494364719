import { css, keyframes } from 'styled-components';

export const fontSize = (pxValue) => `
  font-size: ${pxValue / 16}rem;
`;

export const containerMixin = css`
  // TODO: is there a max width?
  //   max-width: ???;
  width: 80%;
  margin: 0 auto;
`;

export const centeredMixin = css`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const bounceAnimation = css`
  @-webkit-keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }

  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }

  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const loadingIconAnimation = () => css`
  width: 36px !important; // force width
  height: 36px;
  margin: 0 auto;
  padding: 0 !important; // force full circular shape
  pointer-events: none;
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  animation: ${spin} 3s linear;
  animation-iteration-count: infinite;
  transition: all 0.7s;
  transition: border-color 0s;
  transform-origin: center;
  .Icon {
    width: 34px;
    height: 34px; // must be specified for Safari
  }
  span {
    color: transparent;
  }
`;

export const MuiFormHelperText = css`
  .MuiFormHelperText-root {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 0.75rem;
    color: ${({ theme }) => theme.farmProject.dark_gray};
  }

  .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.farmProject.red};
  }
`;
