import { useState } from 'react';

/**
 * * usePaging - Custom hook to track page count and load more state
 *
 * @param {number} pageSize - how many items per page
 * @param {number} total - how many items total
 *
 * @return {object} { pageCount, showMore, handleShowMore }
 *
 * */
export default function usePaging(pageSize = 12, total = 0) {
  const [{ pageCount, showMore }, setPaging] = useState({ pageCount: 1, showMore: total > pageSize });

  const handleShowMore = () => {
    setPaging({ pageCount: showMore ? pageCount + 1 : pageCount, showMore: total > (pageCount + 1) * pageSize });
  };

  return { pageCount, showMore, handleShowMore };
}
