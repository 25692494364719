export const whiteListedDomains = [
  'gmail.com',
  'yahoo.com',
  'lettucegrow.com',
  'hotmail.com',
  'aol.com',
  'me.com',
  'icloud.com',
  'mac.com',
  'comcast.net',
  'outlook.com',
  'msn.com',
  'sbcglobal.net',
  'live.com',
  'thefarmproject.com',
  'verizon.net',
  'att.net',
  'cox.net',
  'ymail.com',
  'protonmail.com',
  'bellsouth.net',
];
