import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

import { useBreakpoint } from 'utils/hooks';
import { Title, Text, Image, GatsbyImageLink, GatsbyImage } from 'elements';

import { HeroSectionStyled, HeroInner } from './HeroSection.styled';

import scrollIntoView from 'utils/scrollIntoView';

const getBackgroundWidth = (size) => {
  switch (size) {
    case 'SM':
      return 1000;
    case 'MD':
      return 1300;
    case 'LG':
      return 1400;
    default:
      return 1920;
  }
};

const HeroSection = ({
  heroTitle,
  heroText,
  heroText2,
  heroImage,
  heroImages,
  imagebackground,
  breakPointImages,
  altImage,
  modifiers,
  children,
  imageRouteLink,
  id,
  shouldResizeBackground,
  backgroundWidth,
  className,
  isTextInverted,
  eventData,
}) => {
  const location = useLocation();
  const size = useBreakpoint();
  const containerRef = useCallback(
    (node) => {
      if (!!node && location) {
        scrollIntoView(location, node, -100);
      }
    },
    [location]
  );
  return (
    <HeroSectionStyled modifiers={modifiers} id={id} ref={containerRef} className={className}>
      <HeroInner>
        {heroTitle && <Title as='h2' content={heroTitle} isHTML modifiers={isTextInverted && 'inverted'} />}
        {heroText && <Text content={heroText} isHTML modifiers={isTextInverted && 'inverted'} />}
        {heroText2 && <Text content={heroText2} isHTML modifiers={isTextInverted && 'inverted'} />}
        {children}
        {/*
         * TODO: Remove old <Image/> from here and all its dependencies variables
         * once we replace old image data with Gatsby image data in all current HeroSection usages
         */}
        {imagebackground && !breakPointImages?.length && (
          <Image
            picture={imagebackground}
            alt={altImage}
            imageRouteLink={imageRouteLink}
            nativeWidth={shouldResizeBackground && (backgroundWidth || getBackgroundWidth(size))}
          />
        )}
        {breakPointImages?.map(({ id, image, size: imageSize }) => (
          <Image
            className={`ImageDevice${imageSize}`}
            key={id}
            picture={image}
            alt={altImage}
            imageRouteLink={imageRouteLink}
            nativeWidth={shouldResizeBackground && (backgroundWidth || getBackgroundWidth(imageSize))}
          />
        ))}
        {(!!heroImage || !!heroImages?.length) &&
          (imageRouteLink ? (
            <GatsbyImageLink
              image={heroImage}
              imageRouteLink={imageRouteLink}
              breakPointImages={heroImages}
              modifiers='cover'
              eventData={eventData}
            />
          ) : (
            <GatsbyImage image={heroImage} breakPointImages={heroImages} modifiers='cover' />
          ))}
      </HeroInner>
    </HeroSectionStyled>
  );
};

HeroSection.propTypes = {
  heroTitle: PropTypes.string,
  heroText: PropTypes.string,
  heroText2: PropTypes.string,
  imagebackground: PropTypes.string,
  altImage: PropTypes.string,
  modifiers: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string, PropTypes.object]),
  imageRouteLink: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  shouldResizeBackground: PropTypes.bool,
  backgroundWidth: PropTypes.number,
  breakPointImages: PropTypes.array,
  heroImage: PropTypes.object,
  eventData: PropTypes.object,
  heroImages: PropTypes.array,
  isTextInverted: PropTypes.bool,
};

HeroSection.defaultProps = {
  id: null,
  shouldResizeBackground: true,
};

export default HeroSection;
