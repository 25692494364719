/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';

import {
  FarmstandReviewed,
  PressReviewSlider,
  HowItWorksSection,
  ImpactStats,
  GrowIn,
  PreSprouted,
  GrowCommunity,
  UserGeneratedContent,
  Featured,
  CustomerReviewSlider,
  ConfigurableSection,
  MarketingContainer,
  MarketingContainerNav,
  GrowAppStatic,
  WeAreHereToHelp,
  ContactSection,
  FarmstandPDPSection,
  FarmstandBlock,
} from 'components';

import { COMMON_SECTIONS } from 'constants/contentful';
import { videoIdVimeo } from 'utils/pattern-utils';
import { getContentfulEntryLgType, getContentfulEntryType } from 'utils/contentful-utils';
import { LgType } from 'constants/LgType';

const CONFIGURABLE_SECTION_TYPE = 'modalUpsell'; // reusing an outdated contentful entry type here

const isDocumentReady = typeof document !== 'undefined';

export const sectionComponentMap = {
  [COMMON_SECTIONS.BRANDS]: (fields) => (
    <PressReviewSlider key={COMMON_SECTIONS.BRANDS} title={fields.title} body={fields.body} brands={fields.percentages} />
  ),
  [COMMON_SECTIONS.UGC]: ({ title, lowerTitle, body, percentages }) => (
    <UserGeneratedContent key={COMMON_SECTIONS.UGC} title={title} subtitle={lowerTitle} socialMediaCall={body} post={percentages} />
  ),
  [COMMON_SECTIONS.GROW_IN]: (fields) => <GrowIn key={COMMON_SECTIONS.GROW_IN} title={fields.title} blocks={fields.percentages} />,
  [COMMON_SECTIONS.HOW_IT_WORKS]: (fields) => (
    <HowItWorksSection key={COMMON_SECTIONS.HOW_IT_WORKS} title={fields.title} steps={fields.percentages} />
  ),
  [COMMON_SECTIONS.PRE_SPROUTED]: (fields) => (
    <PreSprouted
      key={COMMON_SECTIONS.PRE_SPROUTED}
      callToAction={fields.callToAction}
      title={fields.title}
      body={fields.body}
      body2={fields.body2}
      bullets={fields.percentages}
    />
  ),
  [COMMON_SECTIONS.IMPACT_STATS]: (fields) => (
    <ImpactStats
      key={COMMON_SECTIONS.IMPACT_STATS}
      title={fields.title}
      body={fields.body}
      headline={fields.headline}
      callToAction={fields.callToAction}
      isDocumentReady={isDocumentReady}
      fruitsAndVeggiesText={fields.fruitsAndVeggiesText}
      gallonsOfWaterText={fields.gallonsOfWaterText}
      numFruitsVeggies={fields.numFruitsVeggies}
      numGallonsOfWater={fields.numGallonsOfWater}
      milkJugsBody={fields.milkJugsBody}
      numMilkJugs={fields.numMilkJugs}
    ></ImpactStats>
  ),
  [COMMON_SECTIONS.GROW_COMMUNITY]: (fields) => (
    <GrowCommunity
      key={COMMON_SECTIONS.GROW_COMMUNITY}
      title={fields.title}
      body={fields.body}
      testimonials={fields.percentages}
      callToAction={fields.callToAction}
    />
  ),
  [COMMON_SECTIONS.FARMSTAND_REVIEWED]: ({ title, body, video }) => (
    <FarmstandReviewed key={COMMON_SECTIONS.FARMSTAND_REVIEWED} title={title} body={body} video={videoIdVimeo(video)} modifiers='white' />
  ),
  [COMMON_SECTIONS.FAQ]: ({ title, percentages }) => (
    <Featured key={COMMON_SECTIONS.FAQ} title={title} percentages={percentages} modifiers='gray_4' shouldShowAll />
  ),
  [COMMON_SECTIONS.REVIEWS_SLIDER]: ({ percentages, leftBackgroundAccent }) => (
    <CustomerReviewSlider
      key={COMMON_SECTIONS.REVIEWS_SLIDER}
      customerReviews={percentages}
      backgroundA={leftBackgroundAccent?.fields?.file?.url}
      backgroundB={leftBackgroundAccent?.fields?.file?.url}
    />
  ),
  [COMMON_SECTIONS.HERE_TO_HELP]: ({ title, body, callToAction, contentBlock }) => (
    <WeAreHereToHelp key={COMMON_SECTIONS.HERE_TO_HELP} title={title} body={body} callToAction={callToAction} contentBlock={contentBlock} />
  ),
  [COMMON_SECTIONS.MAKER]: (fields, index) => (
    <MarketingContainer key={COMMON_SECTIONS.MAKER} makerSectionId={index} modifiers='collapse' />
  ),
  [COMMON_SECTIONS.MAKER_NAV]: ({ title }, index) => (
    <MarketingContainerNav key={COMMON_SECTIONS.MAKER_NAV} makerSectionId={index} configId={title} />
  ),
  [COMMON_SECTIONS.MAKER_SHOP_NAV]: ({ title }, index) => (
    <MarketingContainerNav key={COMMON_SECTIONS.MAKER_SHOP_NAV} makerSectionId={index} configId={title} />
  ),
  [COMMON_SECTIONS.GROW_APP]: ({ title, percentages, additionalButtons }) => (
    <GrowAppStatic key={COMMON_SECTIONS.GROW_APP} title={title} sections={percentages} additionalButtons={additionalButtons} />
  ),
  [COMMON_SECTIONS.CONTACT_SECTION]: ({ contentBlock, title, body }) => (
    <ContactSection key={COMMON_SECTIONS.CONTACT_SECTION} contentBlock={contentBlock} title={title} body={body} />
  ),
  [COMMON_SECTIONS.FARMSTAND_PDP_SECTION]: ({ title, lowerTitle, body, percentages, farmSize }) => (
    <FarmstandPDPSection
      key={COMMON_SECTIONS.FARMSTAND_PDP_SECTION}
      title={title}
      lowerTitle={lowerTitle}
      body={body}
      percentages={percentages}
      farmSize={farmSize}
    />
  ),
  [CONFIGURABLE_SECTION_TYPE]: ({
    title,
    subTitle,
    body,
    callToActions,
    layout,
    titleTextColor,
    bodyTextColor,
    image,
    backgroundImage,
    backgroundColor,
    customCSS,
  }) => (
    <ConfigurableSection
      key={CONFIGURABLE_SECTION_TYPE}
      title={title}
      subTitle={subTitle}
      body={body}
      callToActions={callToActions}
      layout={layout}
      titleTextColor={titleTextColor}
      bodyTextColor={bodyTextColor}
      image={image}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      customCSS={customCSS}
    />
  ),
  [LgType.FARM_BLOCK_TYPE]: ({
    title,
    displayPriceRange,
    displayReviewsSection,
    displaySizeOptions: displaySizes,
    sizeOptionHeadline: sizesTitle,
    sizeOptions: sizes = [],
    defaultSizeOption,
    environmentOptionHeadline: envsTitle,
    displayEnvironmentOptions: displayEnvs,
    environmentOptions: envs = [],
    defaultEnvironmentOption,
    displayGlowRingsSection: displayGRs,
    glowRingsHeadline: grHeadline,
    glowRingsTitle: grTitle,
    glowRingsBody: grBody,
    glowRingsImage: grImage,
    callToAction,
    addToCartHeadline,
    displayIconsSection,
    iconsListSection = [],
    displayAccordionSection,
    accordionSection,
    displayQrcodeSection,
    qrCodeSection,
    displayRentalSection,
    rentalBannerSection,
    farmstandPictures = [],
    indoorPictures = [],
    outdoorPictures = [],
    video,
  }) => {
    const defaultSize = !displaySizes || sizes.includes(defaultSizeOption) ? defaultSizeOption : null;
    const sizesSection = { title: sizesTitle, options: sizes.map((size) => ({ size })), default: defaultSize, display: displaySizes };
    const defaultEnv =
      !displayEnvs || !!envs.find((env) => env.toUpperCase().includes(defaultEnvironmentOption.toUpperCase()))
        ? defaultEnvironmentOption
        : null;
    const envsSection = { title: envsTitle, options: envs, default: defaultEnv, display: displayEnvs };
    const glowRingsSection = { title: grTitle, headline: grHeadline, body: grBody, image: grImage, display: displayGRs };
    const ctaSection = { text: callToAction?.fields?.linkText, path: callToAction?.fields?.linkUrl, headline: addToCartHeadline };
    const accordionSectionData = displayAccordionSection ? accordionSection?.fields?.percentages || [] : null;
    const iconsSectionData = displayIconsSection ? iconsListSection : null;
    const visualizerSection = displayQrcodeSection ? qrCodeSection?.fields || {} : null;
    const rentalSection = displayRentalSection ? rentalBannerSection?.fields || {} : null;
    const videoData = video?.content?.[0]?.content?.[0]?.value;
    const pictures = [...farmstandPictures, ...indoorPictures, ...outdoorPictures];

    return (
      <FarmstandBlock
        key={LgType.FARM_BLOCK_TYPE}
        title={title}
        sizesSection={sizesSection}
        environmentsSection={envsSection}
        glowRingsSection={glowRingsSection}
        ctaSection={ctaSection}
        iconsSection={iconsSectionData}
        accordionSection={accordionSectionData}
        visualizerSection={visualizerSection}
        rentalSection={rentalSection}
        pictures={pictures}
        displayPriceRange={!!displayPriceRange}
        displayReviewsSection={!!displayReviewsSection}
        video={videoData}
      />
    );
  },
};

const SectionProvider = ({ sectionContent, customSectionMap, farmSizeFromUrl, isGraphqlData }) => {
  return (
    <>
      {sectionContent?.map((section, index) => {
        const sysId = isGraphqlData ? section.contentful_id : section.sys.id;
        const fields = (isGraphqlData ? section : section.fields) || {};
        const LgTypeId = getContentfulEntryLgType(section);
        const contentType = getContentfulEntryType(section);

        const customSectionComponentRender = customSectionMap[sysId] || customSectionMap[LgTypeId];
        const globalSectionComponentRender = sectionComponentMap[sysId] || sectionComponentMap[contentType];

        return (
          customSectionComponentRender?.(fields, farmSizeFromUrl, index) || // pass farm size here for statically built farm size PDPs
          globalSectionComponentRender?.({ ...fields, farmSize: farmSizeFromUrl }, index) ||
          null
        );
      })}
    </>
  );
};

SectionProvider.defaultProps = {
  sectionContent: [],
  customSectionMap: {},
  farmSize: '',
};

SectionProvider.propTypes = {
  sectionContent: PropTypes.array,
  customSectionMap: PropTypes.object,
  farmSize: PropTypes.string,
};
export default SectionProvider;
