import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

import breakPoints from 'theme/Media';
import { Flex } from 'components';

const modifiers = {
  editMode: () => ({
    styles: `
      .TypeCard {
        cursor: default;
        width: 75%;
        .Image {
          width: 6.375rem;
          img {
            width: auto;
            margin: auto;
          }
        }
      }
    `,
  }),
};

export const WrapFarmTypes = styled(Flex).attrs({
  className: 'WrapFarmTypes',
})`
  padding: 0.375rem;
  ${applyStyleModifiers(modifiers)};
`;

export const TypeCard = styled.div.attrs({
  className: 'TypeCard',
})`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem;
  gap: 0.75rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid ${(props) => (props.isSelected ? ({ theme }) => theme.farmProject.dark_green : 'transparent')};
  opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
  border-radius: 0.625rem;
  width: 48%;
  height: 8rem;

  .Image {
    width: 3rem;
    overflow: initial;

    picture {
      img {
        width: 3rem;
        height: 100%;
        position: initial;
        transform: unset;
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .Text {
      font-size: 1.375rem;
    }
  }
`;
