import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { PillLabel } from 'components';
import { SelectBundleModal } from 'components/modal';
import { Title, Text, Image, SquaredButton, Icon } from 'elements';

import {
  BundleCardStyled,
  BundleCardWrapper,
  BundleImage,
  BundleHeader,
  BundleInfoWrapper,
  BundleName,
  BundleLabel,
  SeedlingsList,
  List,
  Seedling,
  ButtonGroup,
} from './BundleCard.styled';

import paths from 'constants/paths';
import { FYFBundleContext } from 'app/pages/fill-your-farmstand-bundles/utils/fyfBundlesContext';
import { useBreakpoint, useBundleSeedlings } from 'utils/hooks';
import { getSeedlingsListFromCustomBundle } from 'utils/bundles-utils';
import { getNumSeedlingsInCart, removeItemsOfCategories, addItem, addBulkItems, setCartValue } from 'reduxState/cart';
import { shopCategories } from 'constants/index';

import LoadingIcon from 'assets/images/loading.svg';

const BundleCard = (props) => {
  const {
    id: bundleId,
    labelBundle,
    bundleTitle,
    bundleIncludesSubtitle,
    bundlePrice,
    // eslint-disable-next-line
    bundlePriceCents, // Passing down to be set in context state
    bundleDescription,
    bundleImage,
    bundleImageDescription,
    qtyBundle,
    isNookFarm = false,
    pillStyle,
    pillContent,
    isReview,
    isCreditsBundle,
    modifiers = [],
    seedlingsCatalog = {},
    chooseModalData = {},
    onCustomSelect,
  } = props;
  const isDesktop = ['XL', 'LG'].includes(useBreakpoint());
  const dispatch = useDispatch();
  const dispatchContext = useContext(FYFBundleContext)?.dispatch;
  const seedlingsInCartQty = useSelector(getNumSeedlingsInCart) || 0;

  const [showChooseModal, setShowChooseModal] = useState(false);
  const [showEditBundleModal, setShowEditBundleModal] = useState(false);
  const [seedlingsList, setSeedlingsList] = useState([]);
  const { seedlings } = useBundleSeedlings(bundleId, true, isNookFarm);
  const bundlesSeedlingsList = seedlings;
  const ctaSelectLabel = 'SELECT';
  const ctaLabel = 'PERSONALIZE';

  useEffect(() => {
    if (bundlesSeedlingsList.length) {
      setSeedlingsList(getSeedlingsListFromCustomBundle(bundlesSeedlingsList, null, seedlingsCatalog));
    }
  }, [bundlesSeedlingsList, seedlingsCatalog]);

  const toggleChooseModal = () => setShowChooseModal(!showChooseModal);

  const addBundleAndNavigate = () => {
    const bundleToAdd = {
      sku: bundleId,
      category: shopCategories.CUSTOM_BUNDLES,
      qty: 1,
      plantCount: parseInt(qtyBundle, 10),
      priceCents: bundlePriceCents,
      name: bundleTitle,
    };
    dispatch(addItem({ item: bundleToAdd }));
    dispatch(setCartValue({ label: 'forceCartPreviewFetchOnCheckout', value: true }));
    navigate(paths.CHECKOUT);
  };

  const onSelectBundle = () => {
    if (seedlingsInCartQty > 0) toggleChooseModal();
    else if (!isReview) {
      addBundleAndNavigate();
    }
  };

  const onConfirmSelection = (shouldRemoveSeeds = false) => {
    toggleChooseModal();
    const categoriesToRemove = [shopCategories.SEEDLINGS, shopCategories.BUNDLES, shopCategories.CUSTOM_BUNDLES];
    if (!isReview && dispatch && dispatchContext) {
      if (shouldRemoveSeeds) dispatch(removeItemsOfCategories(categoriesToRemove));
      addBundleAndNavigate();
    }
  };

  const editBundle = () => {
    const bulkItemsToAdd = bundlesSeedlingsList.map((seedlingSku) => ({
      ...seedlingsCatalog[seedlingSku],
      qty: 1,
    }));
    dispatch(addBulkItems({ items: bulkItemsToAdd }));
    navigate(paths.FILL_YOUR_FARMSTAND, { state: { bundleTitle } });
  };

  const onEditBundle = () => {
    if (seedlingsInCartQty > 0) setShowEditBundleModal(true);
    else editBundle();
  };

  const onConfirmEditSelection = (shouldRemoveSeeds = false) => {
    setShowEditBundleModal(false);
    const categoriesToRemove = [shopCategories.SEEDLINGS, shopCategories.BUNDLES, shopCategories.CUSTOM_BUNDLES];
    if (shouldRemoveSeeds) dispatch(removeItemsOfCategories(categoriesToRemove));
    editBundle();
  };

  const renderSeedling = (item, index) => {
    const { qty, name } = item;
    return (
      <Seedling key={index}>
        <Text modifiers={['brandFont', 'noLineHeight', 'lightGrayColor']} content={`${qty} ${name ? name : 'Seedling'}`} />
      </Seedling>
    );
  };

  return (
    <BundleCardStyled modifiers={[...modifiers, isReview && 'reviewSeedlings']}>
      <BundleCardWrapper>
        <PillLabel modifiers={pillStyle} content={pillContent} />
        <BundleImage>
          <Image picture={bundleImage} nativeWidth={isDesktop ? 800 : 400} alt={bundleImageDescription} />
        </BundleImage>
        <BundleInfoWrapper>
          <BundleHeader>
            <BundleName>
              {!isCreditsBundle && (
                <BundleLabel>
                  <Text as='span' modifiers={['brandFont', 'noLineHeight', 'lightGrayColor']} content={labelBundle} />
                  <Text as='span' modifiers={['brandFont', 'noLineHeight', 'lightGrayColor']} content={`${qtyBundle} PLANTS`} />
                </BundleLabel>
              )}
              <Title as='h3' modifiers={['tertiarySmall', 'brandColor']} content={bundleTitle} />
            </BundleName>
            <Text as='span' modifiers={['brandFont', 'noLineHeight', 'brandColor']} content={bundlePrice} />
          </BundleHeader>
          <SeedlingsList>
            <Title
              as='h3'
              modifiers={['tertiaryXSmall', 'brandColor']}
              content={isCreditsBundle ? bundleIncludesSubtitle?.replace?.('__SIZE__', qtyBundle) : `Includes ${qtyBundle} Seedlings`}
            />
            {seedlingsList?.length > 0 || (isCreditsBundle && bundleDescription) ? (
              <List>
                {isCreditsBundle ? (
                  <Text
                    className='ContentBody'
                    content={bundleDescription}
                    modifiers={['brandFont', 'noLineHeight', 'lightGrayColor', 'left']}
                  />
                ) : (
                  seedlingsList.map(renderSeedling)
                )}
              </List>
            ) : (
              <div className='LoadingWrapper'>
                <Icon>
                  <LoadingIcon />
                </Icon>
              </div>
            )}
          </SeedlingsList>
          <ButtonGroup>
            {!!seedlings?.length && (
              <SquaredButton
                modifiers='lined'
                className='PersonalizeBtn'
                label={ctaLabel}
                onClick={onEditBundle}
                dataNw='select-bundle'
                eventData={{ action: `${ctaLabel}:${bundleTitle}` }}
              />
            )}
            <SquaredButton
              modifiers='tertiary'
              label={ctaSelectLabel}
              onClick={isCreditsBundle ? onCustomSelect : onSelectBundle}
              dataNw='select-bundle'
              eventData={{ action: `${ctaSelectLabel} ${bundleTitle}` }}
            />
          </ButtonGroup>
        </BundleInfoWrapper>
      </BundleCardWrapper>
      {!!chooseModalData?.body && (
        <>
          <SelectBundleModal
            show={showChooseModal}
            title={chooseModalData?.title || ''}
            body={chooseModalData?.body || ''}
            onReplace={() => onConfirmSelection(true)}
            onAdd={() => onConfirmSelection()}
            onCloseAction={toggleChooseModal}
          />
          <SelectBundleModal
            show={showEditBundleModal}
            title={chooseModalData?.title || ''}
            body={chooseModalData?.body || ''}
            onReplace={() => onConfirmEditSelection(true)}
            onAdd={() => onConfirmEditSelection()}
            onCloseAction={() => setShowEditBundleModal(false)}
          />
        </>
      )}
    </BundleCardStyled>
  );
};

BundleCard.propTypes = {
  id: PropTypes.string,
  isNookFarm: PropTypes.bool,
  labelBundle: PropTypes.string,
  qtyBundle: PropTypes.number,
  bundleTitle: PropTypes.string,
  bundleIncludesSubtitle: PropTypes.string,
  bundlePrice: PropTypes.string,
  bundlePriceCents: PropTypes.number,
  bundleDescription: PropTypes.string,
  bundleImageDescription: PropTypes.string,
  bundleImage: PropTypes.string,
  pillStyle: PropTypes.string,
  pillContent: PropTypes.string,
  fillsFarmstand: PropTypes.bool,
  growsOutdoor: PropTypes.bool,
  growsIndoor: PropTypes.bool,
  isFeatured: PropTypes.bool,
  isReview: PropTypes.bool,
  isCreditsBundle: PropTypes.bool,
  modifiers: PropTypes.array,
  seedlingsCatalog: PropTypes.object,
  chooseModalData: PropTypes.object,
  onCustomSelect: PropTypes.func,
};

export default BundleCard;
