import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';

import { IncreaseInputStyled, Input, BtnInput, modifiersIncrementInput } from './IncrementInput.styled';

const IncreaseInput = ({
  sku,
  quantity,
  onIncrease,
  onDecrease,
  disabledDecrease,
  disabledIncrease,
  inputDisabled,
  isActive,
  className,
  modifiers,
}) => (
  <IncreaseInputStyled className={className} modifiers={modifiers}>
    <Input value={quantity.toString()} disabled={inputDisabled} readOnly />
    <BtnInput modifiers={(disabledDecrease || !isActive) && 'disabled'}>
      <span id={sku} onClick={onDecrease}>
        -
      </span>
    </BtnInput>
    <BtnInput modifiers={(disabledIncrease || !isActive) && 'disabled'}>
      <span id={sku} onClick={onIncrease}>
        +
      </span>
    </BtnInput>
  </IncreaseInputStyled>
);

IncreaseInput.propTypes = {
  sku: PropTypes.string,
  quantity: PropTypes.number,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
  disabledIncrease: PropTypes.bool,
  disabledDecrease: PropTypes.bool,
  inputDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  modifiers: styleModifierPropTypes(modifiersIncrementInput),
};

IncreaseInput.defaultProps = {
  quantity: 0,
  disabledIncrease: false,
  disabledDecrease: false,
  inputDisabled: false,
  isActive: true,
  className: '',
  modifiers: null,
};

export default IncreaseInput;
