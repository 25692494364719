import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Select, Title, SquaredButton, Input, Text, TextArea } from 'elements';
import { Accordion, StarRating } from 'components';
import { ReviewFormStyled, NameEmail } from '../ReviewsAndQuestions.styled';

import { FARMSTAND_12, FARMSTAND_18, FARMSTAND_24, FARMSTAND_30, FARMSTAND_36 } from 'constants/sku';

import { productMap, postReview, YOTPO_APP_KEY, YOTPO_GLOW_RINGS_ID } from 'reduxState/userGenerated/userGenerated';
import { setOpenModal, setLabel } from 'reduxState/modal';
import { lgUrlPrefix } from 'utils/envConfig';

const createOptions = (keysToFilter, map) => {
  return Object.keys(map)
    .filter((key) => keysToFilter.includes(key))
    .map((key) => {
      return {
        label: map[key].title,
        value: key,
      };
    });
};

const ReviewForm = ({ isVisible, onSubmit }) => {
  const messages = useSelector((state) => state.contentful.messages);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [didSubmit, setDidSubmit] = useState(false);
  const dispatch = useDispatch();
  let timeout;

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
    /* eslint-disable-next-line */
  }, []);

  if (!messages) return null;

  const handleReviewPost = async (values) => {
    try {
      const params = {
        appkey: YOTPO_APP_KEY,
        domain: lgUrlPrefix,
        sku: productMap[values.product].id,
        product_title: productMap[values.product].title,
        product_url: productMap[values.product].url,
        product_image_url: '',
        display_name: values.display_name,
        email: values.email,
        review_content: values.content,
        review_title: values.title,
        review_score: values.rating,
        prevent_duplicate_review: true,
      };
      await dispatch(postReview(params));
      setIsSubmitted(true);
      window.dataLayer.push({
        event: 'formSubmitted',
        formId: 'review-to-yotpo',
      });
      timeout = setTimeout(() => {
        setDidSubmit(true);
        onSubmit && onSubmit();
      }, 2000);
    } catch (error) {
      dispatch(setOpenModal('error'));
      dispatch(setLabel(error?.message || error?.error));
    }
  };

  return (
    <div>
      <Accordion
        isOpen={isVisible && !didSubmit}
        content={
          isSubmitted ? (
            <Text modifiers='brandColor' content='Thank you for leaving a review!'></Text>
          ) : (
            <ReviewFormStyled>
              <Title content='LEAVE A REVIEW' />
              <Formik
                initialValues={{ title: '', content: '', display_name: '', email: '', rating: null }}
                validationSchema={Yup.object().shape({
                  content: Yup.string().required(messages.LGE_required),
                  title: Yup.string().required(messages.LGE_required),
                  display_name: Yup.string().required(messages.LGE_required),
                  email: Yup.string().email(messages.LGE_email_invalid).required(messages.LGE_required),
                  rating: Yup.number().required(messages.LGE_required),
                  ...{ product: Yup.string().required(messages.LGE_required) },
                })}
                onSubmit={handleReviewPost}
              >
                {({ isSubmitting, errors, touched, values, setFieldValue }) => {
                  // hasError does not account for rating value
                  const hasError = !!Object.keys(errors).find((fieldName) => Object.keys(touched)?.includes?.(fieldName));

                  const handleRating = (rating) => {
                    setFieldValue('rating', rating);
                  };
                  return (
                    <Form>
                      <Text content='Overall Rating*' modifiers='brandFont' />
                      <StarRating
                        onClick={handleRating}
                        rating={values.rating}
                        hasError={values.title && values.content && values.display_name && values.email && !values.rating}
                      />
                      <NameEmail>
                        <Field
                          component={Input}
                          name='display_name'
                          label='Full Name*'
                          placeholder='Type your name here'
                          helperText='*Required'
                        />
                        <Field component={Input} name='email' label='Email*' placeholder='Type your e-mail here' helperText='*Required' />
                      </NameEmail>
                      <Field
                        component={Select}
                        options={createOptions(
                          [FARMSTAND_12, FARMSTAND_18, FARMSTAND_24, FARMSTAND_30, FARMSTAND_36, YOTPO_GLOW_RINGS_ID],
                          productMap
                        )}
                        name='product'
                        label='Product*'
                        placeholder='Select the product for your review'
                        helperText='*Required'
                      />
                      <Field
                        component={Input}
                        name='title'
                        label='Review Title*'
                        placeholder='Type your review title here'
                        helperText='*Required'
                      />
                      <Field
                        component={TextArea}
                        name='content'
                        label='Review*'
                        placeholder={`Write your review here. Please share with us and other customers what you love about your purchase, and what you would like to see in the future.`}
                        helperText='*Required'
                      />
                      <SquaredButton
                        label='POST'
                        type='submit'
                        disabled={hasError}
                        modifiers={[(hasError || !values.rating) && 'disabled', isSubmitting && 'loading']}
                        eventData={{ action: `Write A Review: POST` }}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </ReviewFormStyled>
          )
        }
      ></Accordion>
    </div>
  );
};

ReviewForm.defaultProps = {
  isVisible: false,
  onSubmit: null,
};
ReviewForm.propTypes = {
  isVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
};
export default ReviewForm;
