import PropTypes from 'prop-types';

const ExternalRedirect = ({ to }) => {
  window.location.href = to;
};

ExternalRedirect.propTypes = {
  to: PropTypes.string,
};

export default ExternalRedirect;
