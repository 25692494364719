/**
 * Used for Autofill Order processing days by preferred delivery days
 * since the autofill order can't be processed on a very specific day for 7 days of the week
 * we are offering the user a range of days while BE will only need to internally read from: Monday, Tuesday and Wednesday
 */

export const AutofillDays = {
  TUESDAY_WEDNESDAY: {
    label: 'Tuesday - Wednesday',
    value: 'MONDAY',
  },
  WEDNESDAY_THURSDAY: {
    label: 'Wednesday - Thursday',
    value: 'TUESDAY',
  },
  THURSDAY_FRIDAY: {
    label: 'Thursday - Friday',
    value: 'WEDNESDAY',
  },
};
