const animations = {
    BOUNCE: 'bounce',
    EASE: 'ease',
    FADE: 'fade',
    FADE_SCROLL: 'fade-scroll',
    GENIE: 'genie',
    LOADING: 'loading',
    RISE_SHRINK: 'rise-shrink',
    ROTATE: 'rotate',
    SLIDE_RIGHT: 'slide-right',
};

animations.ALL = Object.values(animations);

export default animations;
