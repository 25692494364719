/**
 * * scrollIntoView - scrolls node into view
 * @param {object} location
 * @param {element} node - node to scroll into view, must have id that matches hash in url
 *
 */

const NAV_OFFSET = 100;

const scrollIntoView = (location, node, offsetSubtract = NAV_OFFSET) => {
  if (location.hash && node?.id && location.hash.includes(node.id)) {
    setTimeout(
      () =>
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: window.pageYOffset + node.getBoundingClientRect()?.top - offsetSubtract,
        }),
      100 // timeout here to handle loading content height - TODO: better approach to detect page content loaded
    );
  }
};

export default scrollIntoView;
