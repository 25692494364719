import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import useContent from 'utils/hooks/useContent';
import { VERIFY_ADDRESS_MODAL, VERIFY_ADDRESS_MODAL_PO_BOX } from 'constants/contentful';
import messages from 'constants/messages';
import { SquaredButton, Title, Text, ButtonText } from 'elements';
import { Modal } from 'components/modal';

import { FedExPopUpStyled, AddressWrapper, Address } from './FexExPopUp.styled';

const FedExPopUp = ({ isOpen, onClose, values, submitClick, verifiedAddressCode }) => {
  let localShouldOverrideAddress = true;
  let content;
  const verifyAddressContent = useContent(VERIFY_ADDRESS_MODAL);
  const verifyPOBoxContent = useContent(VERIFY_ADDRESS_MODAL_PO_BOX);

  switch (verifiedAddressCode) {
    case messages?.PO_BOX:
      localShouldOverrideAddress = false;
      content = verifyPOBoxContent;
      break;
    default:
      localShouldOverrideAddress = true;
      content = verifyAddressContent;
  }

  if (!content) {
    isOpen && onClose();
    return null;
  }

  const title = _get(content[0].fields, 'callout.fields.title');
  const body = _get(content[0].fields, 'callout.fields.body');
  const btnText = _get(content[0].fields, 'buttonText');

  const handleClick = () => {
    if (localShouldOverrideAddress) {
      submitClick();
    } else {
      onClose();
    }
  };

  return (
    <FedExPopUpStyled>
      <Modal isOpen={isOpen} onCloseAction={onClose} dataNw='fedex-modal'>
        <Title as='h2' content={title} />
        <Text modifiers='xLarge' content={body} />
        <AddressWrapper>
          <Address>
            <Text as='span' content={`${values.firstName} ${values.lastName}`} />
            <Text as='span' content={`${values.address}${values.postStop ? ', ' + values.postStop : ''}`} />
            <Text as='span' content={`${values.city}, ${values.state} ${values.zip}`} />
          </Address>
          {localShouldOverrideAddress && (
            <ButtonText label='Change address' modifiers={['secondary']} onClick={onClose} dataNw='change-address' />
          )}
        </AddressWrapper>
        <SquaredButton label={btnText} onClick={handleClick} dataNw='use-this-address' />
        {localShouldOverrideAddress && <Text as='span' content='Your order will be submitted' />}
      </Modal>
    </FedExPopUpStyled>
  );
};

FedExPopUp.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    address: PropTypes.string,
    postStop: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  submitClick: PropTypes.func.isRequired,
  verifiedAddressCode: PropTypes.string,
};

export default FedExPopUp;
