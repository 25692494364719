import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import formatDate from 'utils/date-utils';
import { lgUrlPrefix } from 'utils/envConfig';
import paths from 'constants/paths';

const Head = ({ ogImage, twitterImage, title, description, canonicalRoute, author, date, children }) => {
  //images from contentful get urls that begin with //, which fb and twitter link sharing don't like
  let _ogImage = ogImage;
  if (ogImage.startsWith('//')) {
    _ogImage = 'https:' + ogImage;
  }
  let _twitterImage = twitterImage;
  if (twitterImage.startsWith('//')) {
    _twitterImage = 'https:' + twitterImage;
  }
  const slashToAdd = !lgUrlPrefix.endsWith('/') && !canonicalRoute.startsWith('/') ? '/' : '';
  const fixedUrl = `${lgUrlPrefix}${slashToAdd}${canonicalRoute}`;

  return (
    <Helmet
      title={title}
      meta={[
        { name: 'og:title', property: 'og:title', content: title },
        { name: 'content', property: 'content', content: title },
        { name: 'twitter:title', property: 'twitter:title', content: title },
        { name: 'description', property: 'description', content: description },
        { name: 'og:description', property: 'og:description', content: description },
        { name: 'twitter:description', property: 'twitter:description', content: description },
        { name: 'og:image', property: 'og:image', content: _ogImage },
        { name: 'og:url', property: 'og:url', content: fixedUrl },
        { name: 'twitter:image', property: 'twitter:image', content: _twitterImage },
        { name: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' }, //format of link preview to display
        { name: 'author', property: 'author', content: author },
        {
          name: 'date',
          property: 'date',
          content: date ? formatDate(date) : '',
        },
      ]}
      link={[{ rel: 'canonical', href: fixedUrl }]}
    >
      {children}
    </Helmet>
  );
};

Head.defaultProps = {
  title: 'The future of food is grow-it-yourself.',
  description: "We're on a mission to help everyone grow 20% of their own food.",
  ogImage: lgUrlPrefix + '/social_meta_share.jpg',
  twitterImage: lgUrlPrefix + '/social_meta_share.jpg',
  author: '', //only for blog posts and recipes
  date: '', //only for blog posts
  canonicalRoute: paths.HOME,
};

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ogImage: PropTypes.string,
  twitterImage: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  canonicalRoute: PropTypes.string,
  children: PropTypes.any,
};

export default Head;

/*
Without prerendering, Helmet on front end not expect to work with fb/twitter fields. See:
https://github.com/nfl/react-helmet/issues/26
https://stackoverflow.com/questions/50193960/react-helmet-facebook-open-graph
https://stackoverflow.com/questions/50968603/sharing-on-social-media-the-url-does-not-render-any-meta-data
Can test it for fb with the sharing debugger: https://developers.facebook.com/tools/debug/sharing

Example of how add other fields (can see helmet-builder.js in the-farm-project repo for reference as well):
<Helmet
title={this.props.title} description={this.props.description}
meta={[
    { name: 'description', content: this.state.dataForMeta.metaDescription },
    {
        property: 'og:image',
        content: this.state.dataForMeta.metaFogImage
            ? this.state.dataForMeta.metaFogImage.fields.file.url
            : '',
    },
    {
        property: 'og:title',
        content: 'test og title',
    },
]}
 />
*/
