/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { navigate } from 'gatsby';

import { QuickViewModal } from 'components/modal';
import { Loading, Flex, GrowEnvironmentToggle, ZipCodeEditor, ZipMessageError } from 'components';
import { Text } from 'elements';
import { Section, ZipWrap, Categories, CategoryBtn, SingleSeedlingList, SectionTitle } from './ShopSeedlings.styled';
import SeedlingCard from './components/seedling-card/SeedlingCard';
import BundleShopCard from './components/bundle-shop-card/BundleShopCard';
import ShopListItem from 'components/deprecated/ShopListItem';

import { getCatalogEnvironment, getShopSeedlingsBySubcategory, getCatalogShopBundlesArray, getGrowPacks } from 'reduxState/catalog';
import { getBuyablesByCategory } from 'reduxState/contentful';
import { useCatalog, useContent, useStopLoading, useIsInViewport } from 'utils/hooks';
import { nameSort, featuredSort, availabilitySort, availableDateSort, environmentSort, limitedReleaseSort } from 'utils/seedlings-utils';
import { bundleEnvSort, bundleFeaturedSort } from 'utils/bundles-utils';
import { PLANT_TYPE, PLANT_BUNDLE, BUYABLES } from 'constants/contentful';
import growingEnvironments from 'constants/growingEnvironments';
import shopCategories from 'constants/shopCategories';
import paths from 'constants/paths';
import { trackClick } from 'utils/googleTagManager';

const { BUNDLES, CUSTOM_BUNDLES, VOUCHERS } = shopCategories;

const ShopSeedlings = ({ location, promotionText }) => {
  const [isInViewport, targetRef] = useIsInViewport();
  const [selectedCat, setSelectedCat] = useState('');
  const [isQuickViewVisible, setIsQuickViewVisible] = useState(false);
  const [quickView, setQuickView] = useState({ data: null, showCTA: false });
  const seedlings = useSelector((state) => getShopSeedlingsBySubcategory(state, selectedCat));
  const seedlingContent = useContent(PLANT_TYPE, isInViewport);
  const bundlesContent = useContent(PLANT_BUNDLE, isInViewport);
  useContent(BUYABLES, isInViewport);
  const bundles = useSelector(getCatalogShopBundlesArray);
  const userEnvironment = useSelector((state) => state.user.userSetEnvironment);
  const recommendedEnv = useSelector(getCatalogEnvironment);
  const catalogGrowPacks = useSelector(getGrowPacks);
  const growPackContent = useSelector((state) => getBuyablesByCategory(state, VOUCHERS));
  useCatalog();
  const isLoading = !recommendedEnv || !seedlingContent || !bundlesContent || !growPackContent;
  useStopLoading(!isLoading);

  useEffect(() => {
    const { seedlingsCategory } = queryString.parse(location?.hash || '') || {};

    if (!!seedlingsCategory && selectedCat !== seedlingsCategory) {
      setSelectedCat(seedlingsCategory === 'all' ? '' : seedlingsCategory);
    }
  }, [location?.hash, selectedCat]);

  let sortedBundles = bundles.sort(nameSort).sort(bundleFeaturedSort(bundlesContent));
  let sortedSeedlings = seedlings
    .sort(nameSort)
    .sort(featuredSort(seedlingContent))
    .sort(limitedReleaseSort(seedlingContent))
    .sort(availabilitySort)
    .sort(availableDateSort);
  if (userEnvironment === growingEnvironments.INDOOR || recommendedEnv === growingEnvironments.INDOOR) {
    sortedBundles = [...sortedBundles].sort(bundleEnvSort(bundlesContent));
    sortedSeedlings = [...sortedSeedlings].sort(environmentSort(seedlingContent));
  }

  const changeCat = (ev) => {
    trackClick({ action: ev.target.dataset.value.toUpperCase() });
    navigate(`${location?.pathname}#shop-seedlings&seedlingsCategory=${encodeURIComponent(ev.target.dataset.value)}`);
  };

  const toggleQuickViewModal = () => {
    setIsQuickViewVisible(!isQuickViewVisible);
  };

  const renderBundleCard = (item, content, index) => {
    return (
      <Transition key={index} timeout={200} exit={false}>
        {(state) => (
          <BundleShopCard
            state={state}
            catalog={item}
            content={content}
            toggleQuickViewModal={toggleQuickViewModal}
            setQuickView={setQuickView}
          />
        )}
      </Transition>
    );
  };

  const renderSeedlingCard = (item, content, index) => {
    return (
      <Transition key={index} timeout={200} exit={false}>
        {(state) => (
          <SeedlingCard
            state={state}
            catalog={item}
            content={content}
            toggleQuickViewModal={toggleQuickViewModal}
            setQuickView={setQuickView}
          />
        )}
      </Transition>
    );
  };

  const renderPackCard = (content) => {
    const item = catalogGrowPacks.find((pack) => pack.sku === content.sys.id);
    return (
      <Transition key={item.sku} timeout={200} exit={false}>
        {(state) => (
          <ShopListItem
            state={state}
            itemId={item.sku}
            key={item.sku}
            path={`${paths.SHOP_PRODUCTS}/${item.slug}`}
            flagLabel={content?.fields?.flagLabel || null}
          />
        )}
      </Transition>
    );
  };

  const renderItemCard = (item, index) => {
    const isBundle = [BUNDLES, CUSTOM_BUNDLES].includes(item?.category);
    const isCustomBundle = item?.category === CUSTOM_BUNDLES;
    const categoryContents = isBundle ? bundlesContent : seedlingContent;
    const categoryRender = isBundle ? renderBundleCard : renderSeedlingCard;
    const itemContent = categoryContents?.find?.((contentItem) => {
      const sku = isCustomBundle ? item.contentSourceSku : item.sku;
      return contentItem.sys.id === sku;
    });

    return categoryRender(item, itemContent, index);
  };

  return (
    <Section id='shop-seedlings' ref={targetRef}>
      {isLoading ? (
        <Loading isFullHeight />
      ) : (
        <>
          <Flex modifiers='directionColumn' className='EnvironmentConfig'>
            <ZipWrap>
              <Text modifiers={['brandFont']}>See what&apos;s available near you. Enter your zip code:</Text>
              <ZipCodeEditor modifiers='medium' shouldHideLabel />
            </ZipWrap>
            <GrowEnvironmentToggle modifiers={['justifyCenter', 'alignCenter']} title={'Growing Selection:'} />
          </Flex>
          <ZipMessageError />
          <Flex modifiers={['spaceBetween', 'directionColumn']} className='CategoriesWrapper'>
            <Flex modifiers='directionColumn'>
              <SectionTitle modifiers={['tertiarySmall', 'semiBold']}>
                {selectedCat === VOUCHERS ? 'GROW PACKS' : selectedCat === BUNDLES ? 'BUNDLES' : 'SEEDLINGS'}
              </SectionTitle>
              {!!promotionText && <Text modifiers={['brandFont', 'xLarge']} content={promotionText} isHTML className='Promotion' />}
            </Flex>
            <Categories>
              <CategoryBtn data-value='all' onClick={changeCat} isSelected={selectedCat === ''}>
                ALL
              </CategoryBtn>
              <CategoryBtn data-value='Fruits & Vegetables' onClick={changeCat} isSelected={selectedCat === 'Fruits & Vegetables'}>
                VEGGIES
              </CategoryBtn>
              <CategoryBtn data-value='Herbs' onClick={changeCat} isSelected={selectedCat === 'Herbs'}>
                HERBS
              </CategoryBtn>
              <CategoryBtn data-value='Leafy Greens' onClick={changeCat} isSelected={selectedCat === 'Leafy Greens'}>
                GREENS
              </CategoryBtn>
              <CategoryBtn data-value='Special Varieties' onClick={changeCat} isSelected={selectedCat === 'Special Varieties'}>
                SPECIAL
              </CategoryBtn>
              <CategoryBtn data-value={BUNDLES} onClick={changeCat} isSelected={selectedCat === BUNDLES}>
                BUNDLES
              </CategoryBtn>
              <CategoryBtn data-value={VOUCHERS} onClick={changeCat} isSelected={selectedCat === VOUCHERS}>
                GROW PACKS
              </CategoryBtn>
            </Categories>
          </Flex>

          {selectedCat !== VOUCHERS && selectedCat !== BUNDLES && (
            <SingleSeedlingList>{sortedSeedlings.map(renderItemCard)}</SingleSeedlingList>
          )}

          {selectedCat === '' && <SectionTitle modifiers={['tertiarySmall', 'semiBold']}>BUNDLES</SectionTitle>}
          {(selectedCat === '' || selectedCat === BUNDLES) && <Flex modifiers='wrap'>{sortedBundles.map(renderItemCard)}</Flex>}

          {selectedCat === '' && <SectionTitle modifiers={['tertiarySmall', 'semiBold']}>GROW PACKS</SectionTitle>}
          {(selectedCat === VOUCHERS || selectedCat === '') && (
            <SingleSeedlingList>{growPackContent.map(renderPackCard)}</SingleSeedlingList>
          )}

          {isQuickViewVisible && (
            <QuickViewModal
              show={isQuickViewVisible}
              catalog={quickView.data}
              showCTA={quickView.showCTA}
              onCloseAction={toggleQuickViewModal}
            />
          )}
        </>
      )}
    </Section>
  );
};

ShopSeedlings.propTypes = {
  location: PropTypes.object,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaTitle: PropTypes.string,
  promotionText: PropTypes.string,
  seedlingCategory: PropTypes.string,
  onCategorySelect: PropTypes.func,
};

export default ShopSeedlings;
