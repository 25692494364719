import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  inverted: () => ({
    styles: `  
      flex-direction: row-reverse;

      .Text {
        margin-left: 0.35rem;
      }

      .Text + .Text {
        margin-left: 0;
      }
    `,
  }),
  column: () => ({
    styles: `  
      flex-direction: column;
      .Text {
        margin: 0;
      }
    `,
  }),
  invertedColumn: () => ({
    styles: `  
      flex-direction: column-reverse;
      .Text {
        margin: 0;
      }
    `,
  }),
  alignLeft: () => ({
    styles: `  
      align-items: flex-start;
    `,
  }),
  alignRight: () => ({
    styles: `  
      align-items: flex-end;
    `,
  }),
  disabled: () => ({
    styles: `  
      opacity: 0.5
    `,
  }),
};

export const PromoPriceStyled = styled.div.attrs({
  className: 'PromoPriceStyled',
})`
  width: fit-content;
  border-radius: 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .Text {
    margin-right: 0.35rem;
  }

  .Text + .Text {
    margin-right: 0;
  }

  ${applyStyleModifiers(modifiers)};
`;
