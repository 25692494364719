import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';

import InputAdornment from '@material-ui/core/InputAdornment';
import EyeShowIcon from 'elements/icon/assets/eye-show.svg';
import EyeHideIcon from 'elements/icon/assets/eye-hide.svg';
import { Icon } from 'elements';

import InputBase, { modifiers } from '@lettucegrow/atoms.input';

const Input = ({
  id,
  name,
  value,
  placeholder,
  required,
  onChange,
  onKeyPress,
  onKeyDown,
  onBlur,
  modifiers,
  label,
  type,
  variant,
  error,
  helperText,
  form,
  field,
  autoComplete,
  autoFocus,
  inputMode,
  pattern,
  dataNw,
  accept,
  disabled,
}) => {
  const inputName = name || field.name;
  const inputValue = value || field.value;
  const inputChange = onChange || field.onChange;
  const inputTouched = form.touched[inputName];
  const formError = form.errors[inputName];
  const errorMessage = ((inputTouched || autoFocus) && (error || formError)) || null;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleBlur = (ev) => {
    !!onBlur && onBlur(ev, form.values);
    field.onBlur && field.onBlur(ev);
  };

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <InputBase
      modifiers={modifiers}
      id={id}
      name={inputName}
      value={inputValue}
      placeholder={placeholder}
      required={required}
      variant={variant}
      type={type === 'password' && isPasswordVisible ? 'text' : type}
      label={label}
      onChange={inputChange}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      onBlur={handleBlur}
      error={errorMessage}
      helperText={helperText}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      inputMode={inputMode}
      pattern={pattern}
      dataNw={dataNw}
      accept={accept}
      endAdornment={
        type === 'password' ? (
          <InputAdornment>
            <Icon modifiers='xSmall' onClick={handleTogglePasswordVisibility}>
              {isPasswordVisible ? <EyeHideIcon /> : <EyeShowIcon />}
            </Icon>
          </InputAdornment>
        ) : null
      }
      disabled={disabled}
    />
  );
};

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.any,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
  }),
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  modifiers: styleModifierPropTypes(modifiers),
  dataNw: PropTypes.string,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
};

Input.defaultProps = {
  error: null,
  required: false,
  autoFocus: false,
  onKeyPress: null,
  onKeyDown: null,
  form: {
    touched: {},
    errors: {},
    values: {},
  },
  field: {
    name: '',
    value: '',
    onChange: null,
    onBlur: null,
  },
  disabled: false,
};

export default Input;
