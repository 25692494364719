import * as Yup from 'yup';

import growingEnvironments from 'constants/growingEnvironments';
import { deviceTypes } from 'constants';

export const initialFormValues = {
  farmType: '',
  name: '',
  zipCode: '',
  image: null,
  size: '',
  growingEnvironment: '',
  glowRings: '',
};

const REQ_MSG = 'Please complete all required fields.';

export const getValidationSchema = (messages) => {
  const requiredMsg = messages?.LGE_required || REQ_MSG;
  const validationObject = Yup.object().shape({
    farmType: Yup.string().required(requiredMsg),
    name: Yup.string().required(requiredMsg),
    zipCode: Yup.string()
      .required(requiredMsg)
      .matches(/^[0-9]{5}$/, 'Please enter a valid 5 digit zip code.'),
    size: Yup.string().when('farmType', {
      is: deviceTypes.farmstand,
      then: Yup.string().required(requiredMsg),
    }),
    growingEnvironment: Yup.string().when('farmType', {
      is: deviceTypes.farmstand,
      then: Yup.string().required(requiredMsg).notOneOf([growingEnvironments.UNSET], requiredMsg),
    }),
    glowRings: Yup.string().when('farmType', {
      is: deviceTypes.farmstand,
      then: Yup.string().required(requiredMsg),
    }),
  });

  return validationObject;
};

export const getDevicePayload = (formValues) => {
  const KEYS_TO_FILTER = ['id', 'farmType', 'imageUrl', 'weeklyNutrients', 'initialNutrients'];
  const { image, size, ...payload } = formValues;
  const fixedPayload = { ...payload, size: parseInt(size, 10) };

  const data = new FormData();
  Object.keys(fixedPayload)
    .filter((key) => !KEYS_TO_FILTER.includes(key))
    .forEach((key) => data.append(key, fixedPayload[key]));
  if (image?.size) data.append('image', image);

  return data;
};
