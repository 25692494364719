import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';

import { Checkbox } from '../../elements';

import { PromoToggleStyled } from './PromoToggle.styled';

const PromoToggle = ({ modifiers, content, onChange }) => (
  <PromoToggleStyled modifiers={modifiers}>
    <Formik initialValues={{ value: false }}>
      {() => (
        <Form>
          <Field name='value' component={Checkbox} label={content} onChange={onChange} />
        </Form>
      )}
    </Formik>
  </PromoToggleStyled>
);

PromoToggle.propTypes = {
  modifiers: PropTypes.any,
  content: PropTypes.string,
  onChange: PropTypes.func,
};

export default PromoToggle;
