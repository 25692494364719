const sizes = {
  MINI: 'mini',
  TINY: 'tiny',
  SMALL: 'small',
  SMALLISH: 'smallish',
  BASE: 'base',
  NORMAL: 'normal',
  MEDIUM: 'medium',
  MEDIUMISH: 'mediumish',
  LARGE: 'large',
  XLARGE: 'xlarge',
  XXLARGE: 'xxlarge',
};

sizes.ALL = Object.values(sizes);

export default sizes;
