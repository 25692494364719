const sizes = {
  x4: '0.4rem',
  x5: '0.5rem',
  x6: '0.6rem',
  x7: '0.7rem',
  x8: '0.8rem',
  x9: '0.9rem',
  x10: '1rem',
  x11: '1.1rem',
  x12: '1.2rem',
  x13: '1.3rem',
  x14: '1.4rem',
  x15: '1.5rem',
  x16: '1.6rem',
  x17: '1.7rem',
};

sizes.ALL = Object.values(sizes);

export default sizes;
