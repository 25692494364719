import styled from 'styled-components/macro';

import breakPoints from 'theme/Media';

export const LettuceCommunitySection = styled.div.attrs({
  className: 'LettuceCommunity',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.gray_2};

    .SectionInner {
      .ContentWrapper {
        .Title {
          text-align: center;
          max-width: 20rem;
          margin: auto;
          margin-bottom: 1rem;
        }

        > .Text {
          max-width: 24rem;
          margin: auto;
        }

        .Button {
          width: 100%;
          max-width: 100%;
          margin: auto;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 2.3125rem;
            max-width: 25rem;
          }

          > .Text {
            max-width: 30rem;
          }

          .Button {
            max-width: 17rem;
            margin: 3rem auto 0;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 3rem;
            max-width: 30rem;
          }

          > .Text {
            max-width: 33rem;
          }

          .Button {
            margin: 3rem auto 0;
          }
        }
      }
    }
  }
`;

export const CommunityCards = styled.div.attrs({
  className: 'CommunityCards',
})`
  width: 100%;
  height: auto;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media ${breakPoints.medium_break} {
    margin-top: 3rem;
  }
`;

export const Card = styled.div.attrs({
  className: 'Card',
})`
  width: calc(50% - 0.5rem);
  height: auto;
  margin: 0 0 2rem;
  position: relative;
  overflow: hidden;

  .Image {
    height: 8rem;
    width: 100%;
  }

  .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 0.75rem;
    color: ${({ theme }) => theme.farmProject.green};
    text-align: left;
    margin-top: 0.5rem;
    display: block;
  }

  @media ${breakPoints.medium_break} {
    width: calc(25% - 0.5rem);

    .Image {
      height: 9.375rem;
      width: 120%;
    }
  }

  @media ${breakPoints.large_break} {
    .Image {
      height: 15rem;
    }
    .Text {
      font-size: 1rem;
    }
  }
`;
