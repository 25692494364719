import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';

import colors from 'constants/styledColors';

// animation to hide loading image for .5 s
const loadSpin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Styled "Section"
const CircleSpinnerContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 0.1rem solid transparent;
  border-right: 0.1rem solid transparent;
  border-left: 0.1rem solid ${props => props.primaryColor};
  animation: ${loadSpin} 1.1s infinite linear;
  border-radius: 50%;
  width: ${props => props.size};
  height: ${props => props.size};
  margin-left: ${props => (props.isInline ? '10px' : 0)};
  position: ${props => (props.isInline ? 'static' : 'absolute')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(0);
`;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: opacity 200ms ease, right 300ms ease-in;
  opacity: ${props => (props.isOn ? 1 : 0)};
  pointer-events: ${props => (props.isOn ? 'initial' : 'none')};
`;

/**
 * * A small loading indicator for Lettuce Grow
 * @param {string} size - the height and width of the spinner
 * @param {string} primaryColor - the color that looks like it's spinning
 * @param {string} secondaryColor - the color of the track that the primary color spins around
 *
 */

const CircleSpinner = ({ size, primaryColor, isInline, hasOverlay, isOn }) => {
  if (hasOverlay) {
    return (
      <Overlay isOn={isOn}>
        <CircleSpinnerContainer size={size} isInline={isInline} primaryColor={primaryColor}>
          Loading...
        </CircleSpinnerContainer>
      </Overlay>
    );
  }

  return (
    <CircleSpinnerContainer size={size} isInline={isInline} primaryColor={primaryColor}>
      Loading...
    </CircleSpinnerContainer>
  );
};

CircleSpinner.defaultProps = {
  isInline: false,
  size: '2rem',
  primaryColor: colors.MAIN_GREEN,
  secondaryColor: 'rgba(61, 175, 44, 0.48)',
};

CircleSpinner.propTypes = {
  isInline: PropTypes.bool,
  size: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
};

export default CircleSpinner;
