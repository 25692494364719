import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Accordion from 'components/accordion/Accordion';
import { ButtonText, Text } from 'elements';

/* Truncate - renders text, 'READ MORE' button and more text on button click */
const Truncate = ({ children, charLimit }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const visibleText = children.substring(0, charLimit);
  const restOfText = children.substring(charLimit, children.length - 1);

  const toggleTruncatedText = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <Text isHTML content={visibleText} modifiers={['noLineHeight']} as='span'></Text>
      <Accordion isOpen={isExpanded} content={<Text isHTML content={restOfText} modifiers={['noLineHeight']} as='span'></Text>} />
      <ButtonText
        as='span'
        label={isExpanded ? 'READ LESS' : 'READ MORE'}
        modifiers={['underline', 'compact', 'secondary']}
        onClick={toggleTruncatedText}
      />
    </>
  );
};

Truncate.propTypes = {
  charLimit: PropTypes.number,
};

Truncate.defaultProps = {
  charLimit: 400,
};
export default Truncate;
