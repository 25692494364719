const NUTRIENTS_DOSAGE_MAPPING = {
  0.1: '1/2 tsp',
  0.3: '1 tsp',
  0.5: '1/2 tbsp',
  0.6: '2 tsp',
  1: '1 tbsp',
  1.5: '1 1/2 tbsp',
  2: '2 tbsp',
  2.5: '2 1/2 tbsp',
  3: '3 tbsp',
  3.5: '3 1/2 tbsp',
  4: '4 tbsp',
};

const NutrientDosage = {
  INDOORS: NUTRIENTS_DOSAGE_MAPPING,
  OUTDOORS: NUTRIENTS_DOSAGE_MAPPING,
  WITH_HEATER: NUTRIENTS_DOSAGE_MAPPING,
};

export default NutrientDosage;
