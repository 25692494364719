import React from 'react';
import { breakpoints } from 'constants/';

// HOC that provides viewport width as prop to component
const WidthProvider = (Component) => {
  return class extends React.Component {
    constructor() {
      super();
      this.container = null;

      if (typeof window === 'undefined' || typeof navigator === 'undefined') {
        this.state = { width: 0, device: {} };
      } else {
        //using document.documentElement.clientWidth instead of window.innerWidth to resolve FB IAB issue
        //don't want to use document.documentElement.clientWidth on desktop browser
        //because if desktop users have OS system setting configured to have scrollbar always show if page is scrollable,
        //clientWidth doesn't include the width of the scrollbar. breakpoints will  not match up with css media query breakpoints
        //hence, code below detects if FB IAB and only uses clientWidth if so
        const browserIdentifier = navigator.userAgent || navigator.vendor || window.opera;
        this.isFacebookBrowser = browserIdentifier.indexOf('FBAN') > -1 || browserIdentifier.indexOf('FBAV') > -1;
        //on macOS Safari, css media queries don't include scrollbar width, so want to use clientWidth to match it
        this.isMacSafari = !!window.safari;

        const width = this.isFacebookBrowser || this.isMacSafari ? document.documentElement.clientWidth : window.innerWidth;

        // create device object from width
        const device = {
          isMobile: width < breakpoints.MOBILE,
          isTablet: width >= breakpoints.MOBILE && width < breakpoints.TABLET,
          isDesktop: width >= breakpoints.TABLET,
        };
        this.state = { width, device };
      }
    }

    componentDidMount() {
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
      }
    }

    componentWillUnmount() {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.handleResize);
      }
    }

    handleResize = () => {
      const curr = this.state.device;

      const width = this.isFacebookBrowser || this.isMacSafari ? document.documentElement.clientWidth : window.innerWidth;
      const device = {
        isMobile: width < breakpoints.MOBILE,
        isTablet: width >= breakpoints.MOBILE && width < breakpoints.TABLET,
        isDesktop: width >= breakpoints.TABLET,
      };
      if (device.isMobile !== curr.isMobile || device.isTablet !== curr.isTablet || device.isDesktop !== curr.isDesktop)
        this.setState({ width, device });
    };

    render() {
      return <Component {...this.props} {...this.state} />;
    }
  };
};

export default WidthProvider;
