const dbFormatPhone = (input, addCountryCode = true) => {
  if (!input) return null;
  return (addCountryCode ? '+1' : '') + input.replace(/\D/g, '');
};
const prettyFormatPhone = (input) => {
  if (!input) return null;
  let cleanInput = input.replace(/-/g, '');
  return '(' + cleanInput.slice(2, 5) + ') ' + cleanInput.slice(5, 8) + '-' + cleanInput.slice(8);
};
const rawFormatPhone = (input) => {
  if (!input) return null;
  if (input.startsWith('+1')) {
    return input.slice(2);
  } else {
    return input;
  }
};

const hasErrorOnTouchedFields = ({ errors = {}, touched = {} }) => {
  const fieldsKeys = Object.keys(errors) || [];
  return !!fieldsKeys.find((key) => !!errors[key] && !!touched[key]);
};

export { dbFormatPhone, prettyFormatPhone, rawFormatPhone, hasErrorOnTouchedFields };
