import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Input, Select } from 'elements';

import { PosAuthWrapper, FormWrapper, SubmitButton } from './PosAuth.styled';
import { Loading } from 'components';

import usePOSData from 'utils/hooks/usePOSData';
import { setStorage } from 'utils/storageManager';
import { posPassword } from 'utils/envConfig';

const PosAuth = () => {
  const { locations, terminals } = usePOSData();
  const isLoading = !locations?.length || !terminals?.length;

  const onHandleSubmit = ({ terminal, location, email, password }) => {
    const isCorrectPass = posPassword === password;
    if (isCorrectPass) {
      setStorage('POS_DATA', { terminal, location, email });
      setStorage('POS_ACCESS', true);
      const utmParams = `?utm_medium=retail&utm_source=${location}&utm_campaign=${email}`;
      window.location?.replace?.(`${window.location?.href}${utmParams}`);
    } else {
      alert('Wrong site password');
    }
  };

  return (
    <PosAuthWrapper modifiers={['alignCenter', 'justifyCenter']}>
      {isLoading && <Loading />}
      {!isLoading && (
        <FormWrapper modifiers={['directionColumn']}>
          <Formik
            onSubmit={onHandleSubmit}
            initialValues={{ terminal: terminals[0].value, location: locations[0].value, email: '', password: '' }}
            validationSchema={Yup.object().shape({
              email: Yup.string().required('Agent Email is required'),
              location: Yup.string().required('Location is required'),
              terminal: Yup.string().required('Terminal is required'),
              password: Yup.string().required('Site Password is required'),
            })}
          >
            <Form noValidate>
              <Field name='email' type='email' label='Sales Agent Email' component={Input} required />
              <Field name='terminal' label='Terminal' modifiers={['compact']} options={terminals} component={Select} required />
              <Field name='location' label='Location' modifiers={['compact']} options={locations} component={Select} required />
              <Field name='password' type='password' label='Site Password' component={Input} required />
              <SubmitButton type='submit' label='SUBMIT' modifiers='tertiary' />
            </Form>
          </Formik>
        </FormWrapper>
      )}
    </PosAuthWrapper>
  );
};

export default PosAuth;
