import contentful from './contentfulMiddleware';
import appSettings from './appSettingsMiddleware';
import api from './apiMiddleware';
import cart from './cartMiddleware';
import storage from './storageMiddleware';
import reservation from '../reservation/reservationMiddleware';
import sagaMiddleware from './sagas';

// export array of middleware
export default [contentful, appSettings, api, cart, storage, reservation, sagaMiddleware];
