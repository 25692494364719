import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const HowItWorksSectionStyled = styled.div.attrs({
  className: 'HowItWorksSection',
})`
  .ContentSection {
    .SectionInner {
      position: relative;
      max-width: 20rem;
      margin: auto;

      .ContentWrapper {
        .Title {
          font-size: 1.75rem;
          padding: 0 1.6rem;
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        max-width: 33.5rem;

        .ContentWrapper {
          .Title {
            font-size: 2.3125rem;
            padding: 0;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding: 10rem 2rem;

      .SectionInner {
        max-width: 62rem;

        .ContentWrapper {
          .Title {
            font-size: 3rem;
            padding: 0;
          }
        }
      }
    }
  }
`;

export const RefilProcess = styled.div.attrs({
  className: 'RefilProcess',
})`
  width: 2.5rem;
  height: 40rem;
  border: dashed 1px red;
  border: solid 1px ${({ theme }) => theme.farmProject.green};
  border-left: none;

  position: absolute;
  top: auto;
  right: -1.5rem;
  bottom: -5rem;
  left: auto;
  transform: translate(-50%, -50%);

  > .Icon {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: -0.5px;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      path {
        fill: ${({ theme }) => theme.farmProject.green};
      }
    }
  }

  @media ${breakPoints.medium_break} {
    height: 23rem;
    right: -2rem;
    bottom: 3rem;
  }

  @media ${breakPoints.large_break} {
    height: 2rem;
    width: 51%;
    right: auto;
    bottom: auto;
    top: 100%;
    left: 62.5%;
    border-top: none;
    border-left: solid 1px ${({ theme }) => theme.farmProject.green};

    > .Icon {
      height: 44px;
      width: 44px;
      top: 20px;
      right: auto;
      bottom: 3.25rem;
      left: -1px;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
`;

export const RefilLabel = styled.div.attrs({
  className: 'RefilLabel',
})`
  position: absolute;
  top: 3rem;
  left: calc(100% + 0.7rem);
  transform: translate(-50%, -50%) rotate(90deg);

  .Text {
    font-size: 0.75rem;
  }

  @media ${breakPoints.large_break} {
    top: 3rem;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
  }
`;

export const RefilLabelIcon = styled.div.attrs({
  className: 'RefilLabelIcon',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: auto;
  bottom: 3rem;
  left: calc(100% + 0.7rem);
  transform: translate(-50%, -50%) rotate(90deg);

  .Icon {
    width: 1.75rem;
    height: 1.25rem;
    margin-right: 0.25rem;

    svg {
      path {
        fill: ${({ theme }) => theme.farmProject.green};
      }
    }
  }

  .Text {
    font-size: 0.75rem;
    line-height: 1.4;
  }

  @media ${breakPoints.large_break} {
    display: none;
  }
`;

export const ListIcon = styled.ul.attrs({
  className: 'ListIcon',
})`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  li {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${breakPoints.medium_break} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    li {
      &:nth-child(3) {
        margin-bottom: 0;
      }
    }
  }

  @media ${breakPoints.large_break} {
    li {
      margin-bottom: 0;
    }
  }
`;

export const ListItem = styled.li.attrs({
  className: 'ListItem',
})`
  width: 100%;
  max-width: 16rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Title {
    font-size: 1.125rem;
    color: ${({ theme }) => theme.farmProject.green};
    width: 100%;
    max-width: 10rem;
    margin-bottom: 1.5rem;
    padding: 0 1.8rem 0 1.8rem;
  }

  .Text {
    width: 100%;
  }

  @media ${breakPoints.medium_break} {
    max-width: 15rem;
  }

  @media ${breakPoints.large_break} {
    width: calc(25% - 2rem);
  }
`;

export const IconWrapper = styled.div.attrs({
  className: 'IconWrapper',
})`
  width: 9rem;
  height: 9rem;
  margin-bottom: 1.5rem;
  display: block;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .Icon {
    width: 83%;
    height: 83%;
  }

  @media ${breakPoints.large_break} {
    .Icon {
      width: 63%;
      height: 63%;
    }
  }
`;
