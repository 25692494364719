export const FeatureFlagStatus = {
  ON: {
    label: 'On',
    value: true,
  },
  OFF: {
    label: 'Off',
    value: false,
  },
  ROLLOUT: {
    label: 'Rollout',
    value: false,
  },
};
