import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme/Media';

import { loadingIconAnimation } from 'theme/Mixins';

export const modifiers = {
  withPill: () => ({
    styles: `  
      .BundleCardWrapper {
        .PillLabelStyled {
          display: block;
        }
      }
    `,
  }),
};

export const BundleCardStyled = styled.div.attrs({
  className: 'BundleCardStyled',
})`
  width: fit-content;
  border-radius: 0.125rem;
  width: 100%;
  max-width: 24rem;
  position: relative;
  border-radius: 0.125rem;
  overflow: hidden;
  border: solid 1px ${({ theme }) => theme.farmProject.light_green};
  background-color: ${({ theme }) => theme.farmProject.white};

  @media ${breakPoints.large_break} {
    max-width: 57.75rem;
  }

  .SeedlingsList .LoadingWrapper {
    ${loadingIconAnimation()};
    margin: 0.75rem auto;
  }

  ${applyStyleModifiers(modifiers)};
`;

export const BundleCardWrapper = styled.div.attrs({
  className: 'BundleCardWrapper',
})`
  .PillLabelStyled {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    transform: translate(0%, 0%);
    z-index: 5;
    display: none;
  }

  @media ${breakPoints.large_break} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const BundleImage = styled.div.attrs({
  className: 'BundleImage',
})`
  height: 8.5rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);

  @media ${breakPoints.large_break} {
    height: 100%;
    top: auto;
    left: auto;
    transform: none;
    flex-shrink: 0;
    width: 40%;

    .Image {
      picture {
        img {
          width: auto;
          height: 110%;
          min-width: 25rem;
          min-height: 25rem;
        }
      }
    }
  }
`;

export const BundleInfoWrapper = styled.div.attrs({
  className: 'BundleInfoWrapper',
})`
  padding: 1rem;
  padding-top: 9.5rem;

  @media ${breakPoints.large_break} {
    padding: 1.5rem;
    flex-shrink: 0;
    width: calc(60% - 3rem);
    padding-left: calc(40% + 1.5rem);
  }
`;

export const BundleHeader = styled.div.attrs({
  className: 'BundleHeader',
})`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  > .Text {
    font-weight: 500;
    font-size: 1rem;
  }

  @media ${breakPoints.large_break} {
    flex-direction: column;
    margin-bottom: 1.125rem;
  }
`;

export const BundleName = styled.div.attrs({
  className: 'BundleName',
})`
  .Title {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0.25rem 0 0.5rem;
    color: ${({ theme }) => theme.farmProject.dark_green};
    text-align: left;
  }

  @media ${breakPoints.large_break} {
    .Title {
      font-size: 2rem;
      margin: 0.25rem 0;
    }
  }
`;

export const BundleLabel = styled.div.attrs({
  className: 'BundleLabel',
})`
  display: flex;
  justify-content: flex-start;

  .Text {
    font-size: 0.75rem;
    line-height: normal;
    letter-spacing: normal;
  }

  .Text + .Text {
    position: relative;
    padding-left: 0.5rem;
    margin-left: 0.5rem;

    &::before {
      content: '';
      display: block;
      width: 0.25rem;
      height: 0.25rem;
      background-color: ${({ theme }) => theme.farmProject.mid_gray};
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      border-radius: 1rem;
    }
  }

  @media ${breakPoints.large_break} {
    .Text {
      font-size: 0.875rem;
    }
  }
`;

export const BundleDescription = styled.div.attrs({
  className: 'BundleDescription',
})`
  .Text {
    font-weight: 300;
    color: ${({ theme }) => theme.farmProject.gray};
    text-align: left;
  }

  @media ${breakPoints.large_break} {
    padding-right: 1rem;

    .Text {
      font-size: 1rem;
    }
  }
`;

export const IconFeaturesList = styled.div.attrs({
  className: 'IconFeaturesList',
})`
  display: flex;
  margin: 1.5rem 0 1rem;
  flex-wrap: wrap;
`;

export const IconFeature = styled.div.attrs({
  className: 'IconFeature',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 5.7rem;
  margin: 0.5rem 0;
  margin-right: 0.3rem;

  .Icon {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    margin-right: 0.25rem;
  }

  .Text {
    font-size: 0.55rem;
    text-align: left;
  }

  @media ${breakPoints.large_break} {
    max-width: 8.5rem;

    .Icon {
      width: 1.75rem;
      height: 1.75rem;

      svg {
        path {
          fill: ${({ theme }) => theme.farmProject.light_gray};
        }
      }
    }

    .Text {
      font-size: 0.75rem;
    }
  }
`;

export const SeedlingsList = styled.div.attrs({
  className: 'SeedlingsList',
})`
  margin-bottom: 2rem;

  .Title {
    font-weight: 400;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.farmProject.dark_green};
    text-align: left;
  }
`;

export const List = styled.div.attrs({
  className: 'List',
})`
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  width: 18rem;
  box-sizing: border-box;
  width: 100%;

  .ContentBody {
    font-weight: 300;
    text-align: left;
  }
`;

export const Seedling = styled.div.attrs({
  className: 'Seedling',
})`
  width: 100%;
  margin: 0.125rem 0;

  .Text {
    font-weight: 300;
    text-align: left;
    padding-right: 0.5rem;
  }

  @media ${breakPoints.small_break} {
    width: 50%;
  }
`;

const buttonGroupModifiers = {
  directionColumn: () => ({
    styles: `  
      flex-direction: column;
      align-items: start;
      .ButtonText {
        margin-bottom: 1rem;
      }
    `,
  }),
};

export const ButtonGroup = styled.div.attrs({
  className: 'ButtonGroup',
})`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  @media ${breakPoints.medium_break} {
    justify-content: space-evenly;
  }

  @media ${breakPoints.large_break} {
    justify-content: flex-start;
  }

  .Button {
    width: 8rem;
    border-radius: 0.125rem;

    @media ${breakPoints.large_break} {
      margin-right: 1rem;
    }
  }

  .PersonalizeBtn span {
    color: ${({ theme }) => theme.farmProject.dark_green};
  }

  ${applyStyleModifiers(buttonGroupModifiers)};
`;
