import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  accentColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.magenta}
    `,
  }),
};

export const SeedlingCardStyled = styled.div.attrs({
  className: 'SeedlingCardStyled ',
})`
  width: 100%;
  border-radius: 0.25rem;
  position: relative;

  .PillLabelStyled {
    padding: 0.125rem 0.75rem;
    position: absolute;
    z-index: 5;
    top: 0.75rem;
    left: 0.75rem;
  }

  .TextTooltipWrapperStyled {
    width: 100%;
    padding: 0 0.5rem;
    justify-content: center;
    text-align: center;
  }
  ${applyStyleModifiers(modifiers)};
`;

export const SeedlingCardInner = styled.div.attrs({
  className: 'SeedlingCardInner',
})`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const ImageWrapper = styled.div.attrs({
  className: 'ImageWrapper',
})`
  height: 8.75rem;
  width: 100%;
  flex-shrink: 0;
  cursor: pointer;

  .Image {
    picture {
      img {
        width: auto;
        height: 100%;
      }
    }
  }
`;

export const BundleInfo = styled.div.attrs({
  className: 'BundleInfo',
})`
  padding: 0.75rem 0.5rem 0.125rem;

  .Text {
    height: 2.35rem;
    text-align: left;
  }
`;

export const SeedlingsDetails = styled.div.attrs({
  className: 'SeedlingsDetails',
})`
  padding: 0;
  margin: 0;
  list-style: none;

  .Title {
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  .Text {
    letter-spacing: normal;
    color: ${({ theme }) => theme.farmProject.gray};
  }
`;

export const CardFooter = styled.div.attrs({
  className: 'CardFooter',
})`
  .Button {
    width: 100%;
    margin-top: 0;
  }

  .IncreaseInput {
    margin-top: 0;
    width: 100%;
  }
`;
