import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { PlayerWrapperStyled } from './LiteVimeoEmbed.styled';

const shadowRootStyle = `
  #frame, #fallbackPlaceholder, iframe {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const LiteVimeoEmbed = ({ embedCode, title }) => {
  const containerRef = React.useRef();

  React.useEffect(() => {
    const shadowRoot = containerRef.current.querySelector('lite-vimeo').shadowRoot;
    const shadowStyle = document.createElement('style');
    shadowStyle.innerHTML = shadowRootStyle;
    shadowRoot?.appendChild(shadowStyle);

    return () => {
      try {
        shadowRoot.removeChild(shadowStyle);
      } catch (error) {
        // No error handling needed here, we just want to
        // catch the exception
      }
    };
  }, []);

  return (
    <PlayerWrapperStyled ref={containerRef}>
      <lite-vimeo videoid={embedCode} videotitle={title} class='LiteVimeoEmbed'></lite-vimeo>
    </PlayerWrapperStyled>
  );
};

LiteVimeoEmbed.propTypes = {
  embedCode: PropTypes.string.isRequired,
  title: PropTypes.string,
};

LiteVimeoEmbed.defaultProps = {
  title: 'Lettuce Grow Video',
};

export default LiteVimeoEmbed;
