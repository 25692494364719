import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const ProductHeroSliderStyled = styled.div.attrs({
  className: 'ProductHeroSliderMobileStyled',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_3};

  .PillLabelStyled {
    top: 0.65rem;
    left: 0.65rem;
    position: absolute;
    z-index: 10;
  }

  .ResponsiveEmbed {
    padding: 50%;
    margin: 3rem auto;
  }

  @media ${breakPoints.small_break} {
    .ResponsiveEmbed {
      padding: 30%;
      margin: 0;
    }
  }

  @media ${breakPoints.xxLarge_break} {
    width: 100%;
    max-width: 37rem;
    height: 35rem;
  }
`;

export const HeroSliderInner = styled.div.attrs({
  className: 'HeroSliderInner',
})`
  .swiper-container {
    .swiper-button-prev {
      top: 47%;
      left: -3px;
      width: 1.8rem;
      height: 1.6rem;

      &::after {
        width: 2rem;
        height: 2rem;
        content: url('/icons/left.svg');
      }
    }

    .swiper-button-next {
      top: 47%;
      right: 6px;
      width: 1.8rem;
      height: 1.6rem;

      &::after {
        width: 2rem;
        height: 2rem;
        content: url('/icons/right.svg');
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: transparent;
        border: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
        opacity: 1;
        transform: none;
      }
      .swiper-pagination-bullet-active {
        background-color: ${({ theme }) => theme.farmProject.dark_gray};
      }
    }

    @media ${breakPoints.xxLarge_break} {
      .swiper-button-prev,
      .swiper-button-prev {
        display: none;
      }
    }
  }
`;

export const ProductSlideStyled = styled.div.attrs({
  className: 'ProductSlideStyled',
  tabIndex: '0',
})`
  height: 28rem;

  .ResponsiveEmbed {
    background: black;
    height: 100%;
  }
  .Image {
    height: 100%;

    picture {
      img {
        height: 100%;
        width: auto;
      }
    }
  }

  @media ${breakPoints.xxLarge_break} {
    height: 35rem;
  }
`;
