import React, { useImperativeHandle, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import icons from 'constants/icons';
import Text from './Text';
import Accordion from 'components/accordion/Accordion';

const AccordionSection = forwardRef(({ title, children, renderCustomTitle, isInitiallyOpen, onOpen }, ref) => {
  const hasTitle = !!title;
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  useImperativeHandle(ref, () => ({
    close: () => setIsOpen(false),
  }));

  const handleOpen = () => {
    if (!isOpen && onOpen) onOpen();
    setIsOpen(!isOpen);
  };

  return (
    <div className='AccordionSection'>
      {hasTitle && (
        <div className='AccordionTitleSection' onClick={handleOpen}>
          <span className={`icon icon-${isOpen ? icons.MINUS_THIN : icons.PLUS_THIN}`} />
          <Text className='AccordionTitle'>{title}</Text>
        </div>
      )}
      {renderCustomTitle && renderCustomTitle(handleOpen, isOpen)}
      {children && <Accordion isOpen={isOpen} content={children} />}
    </div>
  );
});

AccordionSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  renderCustomTitle: PropTypes.func,
  onOpen: PropTypes.func,
  isInitiallyOpen: PropTypes.bool,
};

AccordionSection.defaultProps = {
  title: null,
  children: null,
  renderCustomTitle: null,
  isInitiallyOpen: false,
};

AccordionSection.displayName = 'AccordionSection';

export default AccordionSection;
