import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

import { Text, Icon, SquaredButton } from 'elements';
import { Loading, Accordion, ReviewFooter } from 'components';

import {
  CustomerInfo,
  CustomerAvatar,
  CustomerInfoContent,
  QuestionList,
  QuestionItem,
  Question,
  AnswerNumberCta,
  QuestionInner,
  ReviewListHeader,
  Answer,
  Logo,
  ProductInfo,
} from '../ReviewsAndQuestions.styled';

import Check from 'elements/icon/assets/circle-check.svg';
import ReviewIcon from 'elements/icon/assets/review-icon.svg';
import LogoIcon from 'elements/icon/assets/logo-avatar.svg';

import {
  productMap,
  getQuestionsById,
  fetchQuestions,
  YOTPO_FARMSTAND_ID,
  YOTPO_GLOW_RINGS_ID,
} from 'reduxState/userGenerated/userGenerated';
import { formatShortDate } from 'utils/date-utils';

const QuestionListComponent = ({ openQuestionForm, productId }) => {
  const questions = useSelector((state) => getQuestionsById(state, productId));
  const isLoading = useSelector((state) => state.userGenerated.loading.questions);
  const [answersVisible, setAnswersVisible] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (_isEmpty(questions)) {
      // if we don't have questions in redux yet, fetch on mount
      if (productId) {
        dispatch(fetchQuestions(productId));
      } else {
        dispatch(fetchQuestions(YOTPO_FARMSTAND_ID));
        dispatch(fetchQuestions(YOTPO_GLOW_RINGS_ID));
      }
    }
    /* eslint-disable-next-line */
  }, [dispatch]);

  const toggleAnswersVisible = (questionId) => () => {
    let updatedVisibleAnswersByQuestionId;
    if (answersVisible[questionId]) {
      updatedVisibleAnswersByQuestionId = { ...answersVisible, [questionId]: false };
    } else {
      updatedVisibleAnswersByQuestionId = { ...answersVisible, [questionId]: true };
    }
    setAnswersVisible(updatedVisibleAnswersByQuestionId);
  };

  if (isLoading && _isEmpty(questions)) return <Loading />;

  return (
    <>
      <ReviewListHeader>
        <Text modifiers={['brandFont']} content={`${questions.length} Questions`} />
      </ReviewListHeader>
      <QuestionList>
        {_isEmpty(questions) && !isLoading ? (
          <SquaredButton label='BE THE FIRST TO ASK A QUESTION' onClick={openQuestionForm} />
        ) : (
          questions.map((question) => {
            return (
              <QuestionItem key={question.id}>
                <Question>
                  <CustomerInfo>
                    <CustomerAvatar>
                      <Text
                        modifiers={['noLineHeight', 'noLetterSpace', 'brandFont']}
                        content={question.asker?.display_name?.substring(0, 1)}
                      />
                    </CustomerAvatar>
                    <CustomerInfoContent>
                      <Text modifiers={['noLineHeight', 'brandColor']} content={question.asker?.display_name} />
                      <Text modifiers={['noLineHeight', 'brandFont']} content={formatShortDate(question.created_at)} />
                      <ProductInfo>
                        <Text content='Product:' modifiers='brandFont'></Text>
                        <Text modifiers={['noLineHeight', 'brandFont', 'light']} content={productMap[question.product_id].title} />
                      </ProductInfo>
                    </CustomerInfoContent>
                  </CustomerInfo>
                  <QuestionInner>
                    <Text isHTML modifiers={['brandFont', 'bold', 'lineHeightSmall']} content={`Q: ${question.content.toUpperCase()}`} />
                    {!_isEmpty(question.sorted_public_answers) && (
                      <AnswerNumberCta onClick={toggleAnswersVisible(question.id)}>
                        <Icon>
                          <ReviewIcon />
                        </Icon>
                        <Text modifiers={['brandFont', 'noLineHeight']} content='Answer' />
                        <Text modifiers={['brandFont', 'noLineHeight']} content={`(${question.sorted_public_answers.length})`} />
                      </AnswerNumberCta>
                    )}
                    <Accordion
                      isOpen={!_isEmpty(question.sorted_public_answers) && answersVisible[question.id]}
                      content={question.sorted_public_answers.map((answer) => {
                        return (
                          <React.Fragment key={answer.id}>
                            <Answer>
                              <CustomerInfo>
                                <CustomerAvatar>
                                  {answer['is_store_owner_comment?'] ? (
                                    <Logo modifiers='brandColor'>
                                      <LogoIcon />
                                    </Logo>
                                  ) : (
                                    <Text
                                      modifiers={['noLineHeight', 'noLetterSpace', 'brandFont']}
                                      content={answer.answerer?.display_name?.substring(0, 1)}
                                    />
                                  )}
                                  <Icon>
                                    <Check />
                                  </Icon>
                                </CustomerAvatar>
                                <CustomerInfoContent>
                                  <Text
                                    modifiers={['noLineHeight', 'brandColor']}
                                    content={answer['is_store_owner_comment?'] ? 'Lettuce Grow' : answer.answerer?.display_name}
                                  />
                                  <Text modifiers={['noLineHeight', 'brandFont']} content={formatShortDate(answer.created_at)} />
                                </CustomerInfoContent>
                              </CustomerInfo>
                              <Text isHTML modifiers={['lineHeightSmall']} content={`A: ${answer.content}`} />
                            </Answer>
                            <ReviewFooter id={answer.id} votesUp={answer.votes_up} votesDown={answer.votes_down} />
                          </React.Fragment>
                        );
                      })}
                    ></Accordion>
                  </QuestionInner>
                </Question>
              </QuestionItem>
            );
          })
        )}
      </QuestionList>
    </>
  );
};

QuestionListComponent.propTypes = {
  openQuestionForm: PropTypes.func.isRequired,
  productId: PropTypes.oneOf(Object.keys(productMap)),
};
export default QuestionListComponent;
