import styled from 'styled-components';

import { Flex } from 'components';

export const BannerWrapper = styled(Flex).attrs({
  className: 'BannerWrapperStyled',
})`
  display: ${({ shouldShow }) => (shouldShow ? 'flex' : 'none')};
  margin: 1rem auto auto;
  padding: 0.75rem;
  width: 100%;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.farmProject.light_green_7};
  border: 2px solid ${({ theme }) => theme.farmProject.lg_green_400};

  .title {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.farmProject.lg_green_800};
  }

  .body {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
    color: ${({ theme }) => theme.farmProject.lg_green_800};
  }

  .contentWrapper {
    max-width: 21.5rem;
  }

  .iconWrapper {
    margin-left: auto;
    .Icon {
      width: 2.5rem;
      height: 2.813rem;

      path {
        fill: ${({ theme }) => theme.farmProject.lg_green_800};
      }
    }
  }

  .ButtonText {
    padding: 0;
    justify-content: flex-start;
    span {
      font-size: 0.875rem;
      font-weight: 400;
      color: ${({ theme }) => theme.farmProject.dark_green};
    }
  }
`;
