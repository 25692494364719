import { put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { axiosGet } from 'utils/api-utils';

import { getUser, setUserValue } from 'reduxState/user';
import {
  setCatalog,
  setCatalogFetchError,
  createCatalogUrl,
  fetchCatalog,
  fetchRedemptionCode,
  fetchRedemptionCodeSuccess,
  fetchRedemptionCodeFailure,
} from 'reduxState/catalog';
import { AXIOS } from 'constants/redux';

function* doSetCatalog({ payload }) {
  // get zip from catalog and user
  const { zip: catalogZip, zipSupported } = payload;
  const { zip: userZip } = yield select(getUser);
  const hasZipChanged = userZip !== catalogZip;

  if (hasZipChanged) yield put(setUserValue({ label: 'zip', value: catalogZip }));
  yield put(setUserValue({ label: 'isZipSupported', value: zipSupported }));
}

const CancelToken = axios.CancelToken;
let source;

function* doFetchCatalog({ payload }) {
  const url = createCatalogUrl(payload);
  source && source('multiple requests');
  yield put({
    type: AXIOS,
    payload: {
      url,
      method: 'GET',
      onSuccess: setCatalog,
      onFailure: setCatalogFetchError,
      cancelToken: new CancelToken(function executor(cancel) {
        source = cancel;
      }),
    },
  });
}

function* doFetchRedemptionCode({ payload }) {
  const url = `/app/public/redemptionCode/${payload.redemptionCode}`;
  try {
    const response = yield axiosGet(url, {});
    if (response) {
      yield put(fetchRedemptionCodeSuccess(response.data));
    }
  } catch (error) {
    payload.setFieldError?.('redemptionCode', error?.response?.data?.message);
    yield put(fetchRedemptionCodeFailure({ error }));
  }
}

function* fetchRedemptionCodeSaga() {
  yield takeLatest(fetchRedemptionCode.toString(), doFetchRedemptionCode);
}

function* setCatalogSaga() {
  yield takeLatest(setCatalog.toString(), doSetCatalog);
}

function* fetchCatalogSaga() {
  yield takeLatest(fetchCatalog.toString(), doFetchCatalog);
}

export default [setCatalogSaga, fetchCatalogSaga, fetchRedemptionCodeSaga];
