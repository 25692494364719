/**
 * The form for Promo Code in the Cart on Lettuce Grow
 */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { setDiscount } from 'reduxState/cart';
import colors from 'constants/styledColors';
import icons from 'constants/icons';
import Accordion from 'components/accordion/Accordion';
import { Input, ButtonText, Text } from 'elements';

const PromoCodeContainer = styled.section.attrs({ className: 'PromoCodeContainer ' })`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  transition: max-height 400ms ease;
  border-bottom: 1px solid #b1b1b1;
  padding: 6px 0;
  & > div {
    width: 100%;
    margin: 5px 0;
  }
  > span {
    text-decoration: underline;
    font-size: ${({ theme }) => theme.sizes.x7};
    cursor: pointer;
    button {
      margin-right: 0.8rem;
    }
  }
`;

const PromoCodeButton = styled.button`
  background: transparent;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.farmProject.dark_gray};
  text-align: center;
  cursor: pointer;
  padding: 0;
  font-size: ${({ theme }) => theme.sizes.x7};
  height: 20px;
  width: 20px;
  transform-origin: center center;
  transform: ${(props) => (props.isPromoCodeOpen ? 'rotate(45deg)' : 'rotate(0deg)')};
  transition: transform 200ms ease;

  .icon {
    margin-left: 0;
    text-decoration: none;
    font-size: ${({ theme }) => theme.sizes.x6};
  }
`;

const ApplyButtonWrapper = styled.div`
  display: inline-block;
  margin: 1rem 0 !important;
  button {
    padding-left: 0;
  }
`;

const Status = styled.section`
  display: block;
  font-size: 0.7rem;
  margin-left: 10px;
  color: ${(props) => (props.isError ? colors.ERROR_RED : colors.MAIN_GREEN)};
`;

const PromoForm = styled.div`
  padding-top: 5px;
  padding-bottom: 0.5rem;
  > div {
    margin: 0;
  }
  label {
    z-index: 1;
  }
  .MuiTextField-root,
  .MuiInputBase-root {
    max-width: unset;
  }
  input.MuiInputBase-input {
    height: 2.5rem;
  }
`;

export default () => {
  const dispatch = useDispatch();
  const [codeAttempted, setCodeAttempted] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [isPromoCodeOpen, setIsPromoCodeOpen] = useState(false);

  const toggleIsPromoCodeOpen = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsPromoCodeOpen(!isPromoCodeOpen);
    setCodeAttempted('');
    setPromoCode('');
  };

  const { isLoadingCartPreview, discounts } = useSelector((state) => state.cart);

  if (!discounts) return null;

  const handlePromoCode = (ev) => {
    ev.preventDefault();
    dispatch(setDiscount(promoCode));
    setCodeAttempted(promoCode);
  };

  const handlePromoChange = (ev) => {
    setPromoCode(ev.target.value);
  };

  return (
    <PromoCodeContainer>
      <span onClick={toggleIsPromoCodeOpen} tabIndex={0}>
        <PromoCodeButton isPromoCodeOpen={isPromoCodeOpen}>
          <span className={`icon icon-${icons.PLUS_THIN}`} />
        </PromoCodeButton>
        <Text as='span' modifiers={['small', 'brandFont']}>
          Add Code or Gift Card
        </Text>
      </span>
      <Accordion
        isOpen={isPromoCodeOpen}
        content={
          <PromoForm>
            <Input name='promoCode' label='Enter Code' type='text' onChange={handlePromoChange} value={promoCode} required />
            <ApplyButtonWrapper>
              <ButtonText
                label={codeAttempted && isLoadingCartPreview ? 'APPLYING...' : 'APPLY'}
                onClick={handlePromoCode}
                modifiers='underline'
              />
            </ApplyButtonWrapper>
            {codeAttempted &&
              !isLoadingCartPreview &&
              (discounts[codeAttempted] ? (
                <Status>Coupon applied! Discount applied at checkout.</Status>
              ) : (
                codeAttempted && !isLoadingCartPreview && <Status isError>Oops! This coupon cannot be applied.</Status>
              ))}
          </PromoForm>
        }
      ></Accordion>
    </PromoCodeContainer>
  );
};
