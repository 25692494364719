import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getDayMonthFormat } from 'utils/date-utils';
import { Title, Text } from 'elements';
import { useBreakpoint } from 'utils/hooks';
import { AddToCart } from 'components';
import { TextTooltipWrapper } from 'components';
import growingEnvironments from 'constants/growingEnvironments';

import {
  HeroProductDetailStyled,
  BackgroundMobile,
  BackgroundDesktop,
  ImageWrapper,
  HeroInfoWrapper,
  ProductInfo,
  HeroInfoWrapperInner,
} from './HeroProductDetail.styled';
import itemAvailabilities from 'constants/itemAvailabilities';

const HeroProductDetail = ({
  name,
  item,
  headline,
  introImage,
  introImageMobile,
  introSentence,
  children,
  indoorApproved,
  shouldHideAddToCartCTA = false,
}) => {
  const screenSize = useBreakpoint();
  const messages = useSelector((state) => state.contentful.messages);
  const userEnvironment = useSelector((state) => state.user.userSetEnvironment);
  const isMobile = screenSize === 'SM' || screenSize === 'MD';
  const isNotInSeason = item?.availability === itemAvailabilities.OUT_OF_SEASON;
  const isBuyableSeedNotSeason = isNotInSeason && !item?.inStockDate && !!item?.inSeasonDate;
  const isCompleteOutSeason = isNotInSeason && !item?.inStockDate && !item?.inSeasonDate;
  const isSeedOutOfStock = isNotInSeason && !!item?.inStockDate;

  if (!item) return null;

  let seasonWrapper = '';
  if (isBuyableSeedNotSeason) {
    const helpText = (messages || {}).LG_buyableSeedlingHelpText || '';
    const bestAfterDate = getDayMonthFormat(item.inSeasonDate);
    const growsBestText = helpText.replace('DATE_TAG', bestAfterDate);
    seasonWrapper = <TextTooltipWrapper label={`${messages?.LG_seasonalSeedling} ${bestAfterDate}`} tooltipText={growsBestText} />;
  } else if (isSeedOutOfStock) {
    const availableDate = getDayMonthFormat(item.inStockDate);
    seasonWrapper = <TextTooltipWrapper label={`Available ${availableDate}`} />;
  } else if (item.shipsOnDate) {
    const shipsOnDate = getDayMonthFormat(item.shipsOnDate, true);
    seasonWrapper = <TextTooltipWrapper label={`Ships ${shipsOnDate}`} />;
  }
  if (userEnvironment === growingEnvironments.INDOOR && !indoorApproved) {
    seasonWrapper = <TextTooltipWrapper label='Not recommended for indoor growing' labelModifiers={['small', 'brandColor']} />;
  }

  return (
    <HeroProductDetailStyled>
      <ImageWrapper>
        {isMobile ? (
          <BackgroundMobile nativeWidth={970} picture={introImageMobile?.fields?.file?.url} alt={introImageMobile?.fields?.description} />
        ) : (
          <BackgroundDesktop nativeWidth={2000} picture={introImage?.fields?.file?.url} alt={introImage?.fields?.description} />
        )}
      </ImageWrapper>
      <HeroInfoWrapper>
        <HeroInfoWrapperInner>
          <ProductInfo>
            <Title as='h2' modifiers={['secondary', 'left']} content={name?.toUpperCase()} />
            <Text as='span' modifiers={['brandFont', 'xLarge', 'left']} content={headline} />
            <Text modifiers={['xLarge', 'lineHeightSmall', 'left']} content={introSentence} />
          </ProductInfo>
          {children}
          {!shouldHideAddToCartCTA && (
            <>
              <AddToCart item={item} showPrice={true} isSoldOut={isSeedOutOfStock} isOutOfSeason={isCompleteOutSeason} />
              {seasonWrapper}
            </>
          )}
        </HeroInfoWrapperInner>
      </HeroInfoWrapper>
    </HeroProductDetailStyled>
  );
};

HeroProductDetail.propTypes = {
  name: PropTypes.string,
  item: PropTypes.object,
  headline: PropTypes.string,
  introImage: PropTypes.object,
  introImageMobile: PropTypes.object,
  introSentence: PropTypes.string,
  indoorApproved: PropTypes.bool,
  shouldHideAddToCartCTA: PropTypes.bool,
  children: PropTypes.object,
};

export default HeroProductDetail;
