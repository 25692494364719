import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme/Media';

import { SquaredButton } from 'elements';

export const modifiers = {
  selected: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.dark_gray};

      span {
        color: white;
      }

      &:hover {
        background-color: ${theme.farmProject.dark_gray};

        span {
          color: white;
        }
      }
    `,
  }),

  topPick: ({ theme, topPickLabel }) => ({
    styles: `
    position: relative;

     &:after {
        content: '${topPickLabel || ''}';
        display: block;
        width: 4rem;
        height: 1rem;
        position: absolute;
        top: -0.7rem;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: ${theme.farmProject.font_family};
        font-weight: 600;
        color: ${theme.farmProject.dark_green};
        font-size: 0.75rem;
     }
    `,
  }),

  secondaryProduct: ({ theme }) => ({
    styles: `
      border-color: ${theme.farmProject.light_green_4};
    `,
  }),
};

export const cardModifiers = {
  flexDivisionLargeBreak: () => ({
    styles: `
      @media ${breakPoints.large_break} {
        .HarvestProducWrapper {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          justify-content: flex-start;
          min-height: 28.5rem;

          .HarvestProducInformation {
            flex: 0.5;
            width: auto;
            height: auto;
            padding: 1.5rem 1rem;

            > .Button {
              position: absolute;
              bottom: 1.5rem;
              margin: unset;
            }
          }

          .ProductMedia {
            flex: 0.5;
            width: 50%;
            height: auto;
            position: relative;
          }
        }
      }
    `,
  }),
};

export const HarvestProductCardStyled = styled.div.attrs({
  className: 'HarvestProductCardStyled',
})`
  border: solid 1px ${({ theme }) => theme.farmProject.light_green_5};
  border-radius: 0.25rem;
  max-width: 30rem;
  margin: 1rem auto;
  margin-top: 2.5rem;
  overflow: hidden;

  @media ${breakPoints.large_break} {
    max-width: 62rem;
    &:last-occu .HarvestProducWrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .HarvestProducInformation {
        width: 50%;
      }

      .ProductMedia {
        width: 50%;
      }
    }
  }

  ${applyStyleModifiers(cardModifiers)};
`;

export const HarvestProducWrapper = styled.div.attrs({
  className: 'HarvestProducWrapper',
})`
  position: relative;

  .PillLabelStyled {
    position: absolute;
    z-index: 10;
    top: 1rem;
    left: 1rem;

    .Text {
      font-size: 0.65rem;
      line-height: normal;
      font-weight: bold;
    }
  }
`;

export const HarvestProducInformation = styled.div.attrs({
  className: 'HarvestProducInformation',
})`
  padding: 1.5rem 1rem;

  .ToggleStyled {
    .ButtonGroup {
      padding-left: 0;
      justify-content: start;
      gap: 0rem;
      .ButtonText {
        flex: 1;
      }
    }
  }

  .Title {
    text-align: left;
    margin-bottom: 0.75rem;
    font-size: 1.75rem;
    line-height: 1.2;
    letter-spacing: normal;
    font-weight: 500;
    color: ${({ theme }) => theme.farmProject.dark_green2};
  }

  .Text {
    text-align: left;
    line-height: 1.3;

    p {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0.5rem;
      }
    }

    ul {
      margin: 0;
      margin-bottom: 0.5rem;

      li {
        margin-bottom: 0.25rem;
        padding-left: 0.8rem;
        position: relative;

        &:before {
          content: '';
          display: block;
          width: 0.25rem;
          height: 0.25rem;
          position: absolute;
          top: 0.3rem;
          left: 0;
          background-color: ${({ theme }) => theme.farmProject.dark_gray};
          border-radius: 50%;
        }
      }
    }
  }

  > .Button {
    margin-top: 1.5rem;
    height: 2.5rem;
    min-width: 15rem;
  }

  @media ${breakPoints.medium_break} {

  .ToggleStyled {
    .ButtonGroup {
      gap: 1rem;
      .ButtonText {
        flex: unset;
      }
    }
  }

  @media ${breakPoints.large_break} {
    padding-left: calc(50% + 1.5rem);

    .Title {
      font-size: 2rem;
    }
  }
`;

export const SeedlingListWrapper = styled.div.attrs({
  className: 'SeedlingListWrapper',
})`
  .Text {
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    margin: 1rem 0;
    color: ${({ theme }) => theme.farmProject.dark_gray_2};
  }

  @media ${breakPoints.large_break} {
    min-height: 6.5rem;
  }
`;

export const GlowRingCheckWrapper = styled.div.attrs({
  className: 'GlowRingCheckWrapper',
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .Image {
    width: 6rem;
    height: 6rem;
  }
`;

export const GlowInfoWrapper = styled.div.attrs({
  className: 'GlowInfoWrapper',
})`
  margin-left: 0.5rem;

  .Text {
    line-height: normal;
    letter-spacing: normal;

    &:first-of-type {
      font-weight: 500;
      margin-bottom: 0.125rem;
    }
  }

  .component__Checkbox {
    margin-top: 0.5rem;
    justify-content: flex-start;
    font-size: 0.8rem;
    letter-spacing: normal;
    font-weight: 500;
    color: ${({ theme }) => theme.farmProject.dark_gray};

    .checkbox__IconContainer {
      border-radius: 0.125rem;
    }
  }
`;

export const ProductMedia = styled.div.attrs({
  className: 'ProductMedia',
})`
  @media ${breakPoints.large_break} {
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
  }
`;

export const FarmstandSizeStyled = styled.div.attrs({
  className: 'FarmstandSizeStyled',
})``;

export const SizeButtonGroup = styled.div.attrs({
  className: 'SizeButtonGroup',
})`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.25rem 0 0.5rem;
`;

export const SelectSizeButton = styled(SquaredButton).attrs({
  className: 'SelectSizeButton',
})`
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.25rem;
  margin-right: 0.75rem;
  margin-top: 1.5rem;

  span {
    font-size: 0.875rem;
  }

  ${applyStyleModifiers(modifiers)};
`;
