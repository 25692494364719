import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import { prodLinkPathRegex } from 'utils/pattern-utils';
import { LinkStyled } from './Link.styled';

import { trackClick } from 'utils/googleTagManager';

const Link = ({ content, modifiers, as, href, target, children, dataAttributes = {}, ariaAttributes = {}, className = '', eventData }) => {
  const isHardCodedProdLink = href?.includes('lettucegrow.com') && !href?.includes('@');
  const isNewTab = target?.includes('blank');
  const isExternalOrActionLink = href && (href?.includes('http') || !href?.startsWith('/'));

  const handleEnter = (ev) => {
    if (ev?.keyCode === 13) {
      handleClick();
    }
  };

  const handleClick = () => {
    eventData?.action && trackClick(eventData);

    if (isNewTab) {
      window.open(href);
    } else if (isHardCodedProdLink) {
      const pathPattern = prodLinkPathRegex;
      const path = href.match(pathPattern);
      path && path[1] && navigate(path[1]);
    } else if (isExternalOrActionLink) {
      window.location.href = href;
    } else {
      href && navigate(href);
    }
  };

  return (
    <LinkStyled
      className={className}
      modifiers={modifiers}
      as={as}
      target={target}
      onClick={handleClick}
      tabIndex={0}
      onKeyDown={handleEnter}
      {...dataAttributes}
      {...ariaAttributes}
    >
      {content || children}
    </LinkStyled>
  );
};

Link.propTypes = {
  content: PropTypes.any,
  as: PropTypes.string,
  modifiers: PropTypes.any,
  href: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  dataAttributes: PropTypes.shape({
    'data-amount': PropTypes.number,
    'data-page-type': PropTypes.string,
  }),
  ariaAttributes: PropTypes.shape({
    'aria-label': PropTypes.string,
  }),
  eventData: PropTypes.object,
};

export default Link;
