import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const CardRecipeSliderStyled = styled.div.attrs({
  className: 'CardRecipeSliderStyled',
})`
  width: 100%;
  max-width: 64rem;
  margin: 1rem auto;

  > .Title {
    text-align: center;
    letter-spacing: normal;
    margin-bottom: 1.5rem;

    @media ${breakPoints.large_break} {
      font-size: 3rem;
    }
  }

  .swiper-container {
    padding-bottom: 2rem;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-wrapper {
      .CardInner {
        .Title {
          font-size: 1rem;
        }

        .RecipeInfo {
          align-items: center;
        }
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: transparent;
        border: solid 1px ${({ theme }) => theme.farmProject.green};
        opacity: 1;
        transform: none;
      }
      .swiper-pagination-bullet-active {
        background-color: ${({ theme }) => theme.farmProject.green};
      }
    }
  }

  @media ${breakPoints.large_break} {
    .swiper-container {
      padding-bottom: 2rem;

      .swiper-pagination {
        display: none;
      }
    }
  }
`;