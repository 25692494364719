import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveEmbed } from 'elements';

import Modal from '../Modal';

import { ModalVideoWrapper } from './VideoModal.styled';

const VideoModal = ({ show, embedCode, onCloseAction }) => {
  if (!show) return null;

  return (
    <ModalVideoWrapper>
      <Modal isOpen={show} onCloseAction={onCloseAction}>
        {show && embedCode && <ResponsiveEmbed embedCode={embedCode} />}
      </Modal>
    </ModalVideoWrapper>
  );
};

VideoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  embedCode: PropTypes.string.isRequired,
  onCloseAction: PropTypes.func,
};

export default VideoModal;
