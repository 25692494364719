import { useEffect, useState } from 'react';

/**
 * useSwipe - Custom hook to detect a swipe motion over the provided ref
 * It will return true or false for the swipe direction: up or down
 * and you can use that boolean to react to the event in this case by showing or hidding content
 * TODO left and right motion are not implemented yet
 */

export default function useSwipe(swipeRef, setState) {
  const [pointerSupport, setPointerSupport] = useState(false);

  //swipe detect
  useEffect(() => {
    const pointerFineSupported = window.matchMedia('(pointer: fine)')?.matches;
    setPointerSupport(pointerFineSupported);
  }, []);

  useEffect(() => {
    let xDown = null;
    let yDown = null;

    const getTouches = (evt) => {
      return (
        evt.touches || evt.originalEvent.touches // browser API
      );
    };

    const handleTouchStart = (evt) => {
      const firstTouch = evt.type === 'mousedown' ? evt : getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;

      //prevent parent scroll
      if (!pointerSupport) {
        document.querySelector('html').classList.add('disable-parent-scroll');
      }
    };

    const handleTouchMove = (evt) => {
      if (!xDown || !yDown) {
        return;
      }

      var xUp = evt.type === 'mousemove' ? evt.clientX : evt.touches[0].clientX;
      var yUp = evt.type === 'mousemove' ? evt.clientY : evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          /* left swipe TODO */
        } else {
          /* right swipe TODO */
        }
      } else {
        if (yDiff > 0) {
          /* up swipe */
          setState(true);
        } else {
          /* down swipe */
          setState(false);
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    };

    const handleTouchEnd = () => {
      //clean up overlfow class once the touch event ends
      if (!pointerSupport) {
        document.querySelector('html').classList.remove('disable-parent-scroll');
      }
    };

    if (swipeRef && swipeRef.current) {
      if (!pointerSupport) {
        swipeRef.current.addEventListener('touchstart', handleTouchStart, false);
        swipeRef.current.addEventListener('touchmove', handleTouchMove, false);
        swipeRef.current.addEventListener('touchend', handleTouchEnd, false);
      } else {
        swipeRef.current.addEventListener('mousedown', handleTouchStart, false);
        swipeRef.current.addEventListener('mousemove', handleTouchMove, false);
        swipeRef.current.addEventListener('mouseup', handleTouchEnd, false);
      }

      return () => {
        if (!pointerSupport) {
          swipeRef.current.removeEventListener('touchstart', handleTouchStart);
          swipeRef.current.removeEventListener('touchmove', handleTouchMove);
          swipeRef.current.removeEventListener('touchend', handleTouchEnd);
        } else {
          swipeRef.current.removeEventListener('mousedown', handleTouchStart);
          swipeRef.current.removeEventListener('mousemove', handleTouchMove);
          swipeRef.current.removeEventListener('mouseup', handleTouchEnd);
        }
      };
    }
  }, [pointerSupport]);
}
