import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';

/**
 * * appSettings - Redux Reducer
 *
 * appSettings Content type from Contentful
 *
 * @param state
 *
 */

// reducer, action types, action creators all in 1 createSlice

const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState: { isLoading: true, outSeedsBuyableIn: 8, seedsShippingInFutureBuyableIn: 13 },
  reducers: {
    // set fetched data into store
    setAppSettings(state, { payload }) {
      // Clean up the structure of the Contentful response to create a more
      // developer friendly redux store
      payload.data.items.forEach((current) => {
        state[current.fields.key] = current.fields.value;
      });
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    // set loading error
    fetchAppSettingsError(state, { payload }) {
      state.error = payload.error;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = appSettingsSlice;

// Extract and export action creators from slice by name
export const { setAppSettings, fetchAppSettingsError, setIsLoading } = actions;

// Export the reducer as the default
export default reducer;

// Async Action Creator
export const fetchAppSettings = createAction('appSettings/FETCH_APP_SETTINGS');

// Selector for getting Affirm Min
export const getAffirmMin = createSelector([(state) => state.appSettings], (appSettings) => appSettings.affirmMin);

export const getIsLoading = createSelector(
  (state) => state.appSettings.isLoading,
  (isLoading) => isLoading
);

// Selector for getting Default Recommended Farmstand
export const getDefaultRecFarmstand = createSelector(
  [(state) => state.appSettings],
  (appSettings) => appSettings.defaultRecommendedFarmstand
);
