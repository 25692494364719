import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';

import TitleBase, { modifiers } from '@lettucegrow/atoms.title';

const Title = ({ as, children, content, modifiers, size, isHTML, dataNw, className }) => {
  return (
    <TitleBase content={content} className={className} modifiers={modifiers} as={as} size={size} dataNw={dataNw} isHTML={isHTML}>
      {children}
    </TitleBase>
  );
};

Title.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.node,
  modifiers: styleModifierPropTypes(modifiers),
  size: PropTypes.string,
  isHTML: PropTypes.bool,
  dataNw: PropTypes.string,
  className: PropTypes.string,
};

Title.defaultProps = {
  as: '',
  children: null,
  content: '',
  isHTML: false,
  dataNw: null,
};

export default Title;
