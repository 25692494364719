export const channelSales = {
  INTERNAL: {
    WEB: 'WEB',
    MOBILE: 'MOBILE',
    MOBILE_WEB: 'MOBILE_WEB',
    GCS: 'GCS',
    SUBSCRIPTION: 'SUBSCRIPTION',
    TERMINAL: 'TERMINAL',
  },
  EXTERNAL: {
    AMAZON: 'AMAZON',
    COSTCO: 'COSTCO',
    BESTBUY: 'BESTBUY',
    WESTELM: 'WESTELM',
    BTTR: 'BTTR',
    GOOP: 'GOOP',
    THE_KNOT: 'THE_KNOT',
    UNKNOWN: 'UNKNOWN',
    OUTER: 'OUTER',
    INVOICE: 'INVOICE',
    KARDASHIAN: 'KARDASHIAN',
  },
};
