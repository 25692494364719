import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const CenteredSliderStyled = styled.div.attrs({
  className: 'CenteredSliderStyled',
})`
  width: 100%;
  max-width: 70rem;
  margin: 1rem auto;

  > .Title {
    text-align: center;
    letter-spacing: normal;
    margin-bottom: 0.5rem;
  }

  > .Text {
    text-align: center;
    margin-bottom: 1.5rem;
    padding: 0 1rem;
  }

  .Button {
    margin: 1rem auto;
  }

  .swiper-container {
    .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: transparent;
        border: solid 1px ${({ theme }) => theme.farmProject.green};
        opacity: 1;
        transform: none;
      }
      .swiper-pagination-bullet-active {
        background-color: ${({ theme }) => theme.farmProject.green};
      }
    }
  }

  

  @media ${breakPoints.large_break} {
    > .Title {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }
`;
