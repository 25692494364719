export default {
  INDOOR: 'INDOOR',
  OUTDOOR: 'OUTDOOR',
  INDOORS: 'INDOORS',
  OUTDOORS: 'OUTDOORS',
  BOTH: 'INDOORS & OUTDOORS',
  DEFAULT: 'DEFAULT',
  WITH_HEATER: 'WITH_HEATER',
  UNSET: 'UNSET',
};
