import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { QtyControl, AddToAutofillStyled } from './AddToAutofill.styled';
import { Text } from 'elements';

import {
  getItemInAutofillPendingOrder,
  addItemToAutofillPendingOrder,
  updateItemToAutofillPendingOrder,
  deleteTitemFromAutofillPendingOrder,
  getAutofillPendingOrder,
  getIsBuyNowFetching,
  getIsAutofillFetching,
} from 'reduxState/cart';
import { getItemInResevation } from 'app/reduxState/reservation/reservation';
import { useBreakpoint } from 'utils/hooks';
import { formatDynamicDecimalPrice } from 'utils/cart-utils';

const AddToAutofill = ({
  item,
  label,
  isDisabled,
  isSoldOut,
  isOutOfSeason,
  outOfSeasonLabel,
  showPrice,
  modifiers,
  disabledModifier,
  isTrackingEvent,
}) => {
  const autofillPendingOrder = useSelector(getAutofillPendingOrder);
  const autofillItem = useSelector((state) => getItemInAutofillPendingOrder(state, item.buyableId));
  const reservedItem = useSelector((state) => getItemInResevation(state, item.sku));
  const dispatch = useDispatch();
  const screenSize = useBreakpoint();
  const isBuyNowFetching = useSelector(getIsBuyNowFetching);
  const isAutofillFetching = useSelector(getIsAutofillFetching);

  const itemQty = (autofillItem && autofillItem?.qty) || 0;
  const hasAnyItems = itemQty > 0;
  const soldOutLabel = isOutOfSeason ? outOfSeasonLabel : 'SOLD OUT';
  const isSoldOutOrOutOfSeason = isSoldOut || isOutOfSeason;
  const pendingOrderId = autofillPendingOrder.id;
  const buyableId = item.buyableId;

  const addToAutofill = () => {
    const itemToAdd = {
      pendingOrderId,
      buyableId,
      count: 1,
    };
    dispatch(addItemToAutofillPendingOrder(itemToAdd));
  };

  const incrementQty = () => {
    const itemToUpdate = {
      pendingOrderId,
      itemId: autofillItem.id,
      buyableId,
      count: autofillItem.qty + 1,
    };
    dispatch(updateItemToAutofillPendingOrder(itemToUpdate));
  };

  const decrementQty = () => {
    if (autofillItem.qty === 1) {
      const itemToDelete = {
        pendingOrderId,
        itemId: autofillItem.id,
      };
      dispatch(deleteTitemFromAutofillPendingOrder(itemToDelete));
    } else {
      const itemToUpdate = {
        pendingOrderId,
        itemId: autofillItem.id,
        buyableId,
        count: autofillItem.qty - 1,
      };
      dispatch(updateItemToAutofillPendingOrder(itemToUpdate));
    }
  };
  return (
    <>
      {hasAnyItems && !isBuyNowFetching && !isAutofillFetching ? (
        <QtyControl
          onDecrease={decrementQty}
          onIncrease={incrementQty}
          disabledIncrease={reservedItem && reservedItem.max && itemQty >= reservedItem.max}
          quantity={itemQty}
          isActive={!isDisabled && (!isSoldOutOrOutOfSeason || !!reservedItem)}
          modifiers={screenSize === 'SM' && 'fluid'}
        />
      ) : (
        <AddToAutofillStyled
          label={
            isSoldOutOrOutOfSeason && !reservedItem
              ? soldOutLabel
              : isBuyNowFetching || isAutofillFetching
              ? '...PROCESSING'
              : `${label} ${showPrice && item.priceCents ? formatDynamicDecimalPrice(item.priceCents) : ''}`
          }
          onClick={addToAutofill}
          eventData={isTrackingEvent ? { action: `${label}: ${item.name}` } : {}}
          disabled={isDisabled}
          modifiers={[
            ...modifiers,
            screenSize === 'SM' && 'fluid',
            (isDisabled || (isSoldOutOrOutOfSeason && !reservedItem) || isBuyNowFetching || isAutofillFetching) &&
              (disabledModifier || 'disabled'),
          ]}
        />
      )}
      {reservedItem && reservedItem.max && <Text modifiers={['small', 'errorColor']}>{`Maximum of ${reservedItem.max}`}</Text>}
    </>
  );
};

AddToAutofill.defaultProps = {
  isDisabled: false,
  showPrice: true,
  item: {},
  isSoldOut: false,
  isOutOfSeason: false,
  outOfSeasonLabel: 'OUT OF SEASON',
  label: 'ADD TO CART',
  modifiers: [],
  disabledModifier: 'disabled',
  isTrackingEvent: true,
};

AddToAutofill.propTypes = {
  isDisabled: PropTypes.bool,
  showPrice: PropTypes.bool,
  item: PropTypes.shape({
    sku: PropTypes.string,
    qty: PropTypes.number,
    name: PropTypes.string,
    priceCents: PropTypes.number,
    buyableId: PropTypes.number,
  }),
  isSoldOut: PropTypes.bool,
  isOutOfSeason: PropTypes.bool,
  outOfSeasonLabel: PropTypes.string,
  label: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabledModifier: PropTypes.string,
  isTrackingEvent: PropTypes.bool,
};

export default AddToAutofill;
