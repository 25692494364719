import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const CustomerReviewSliderStyled = styled.div.attrs({
  className: 'CustomerReviewSliderStyled',
})`
  background-color: ${({ theme }) => theme.farmProject.green};

  .swiper-container {
    .swiper-button-prev {
      &::after {
        content: url('/icons/left-white.svg');
      }
    }

    .swiper-button-next {
      &::after {
        content: url('/icons/right-white.svg');
      }
    }

    @media ${breakPoints.xxLarge_break} {
      .swiper-button-prev,
      .swiper-button-prev {
        display: none;
      }
    }
  }
`;
