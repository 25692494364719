import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';

import { FarmProductDetailBlock } from 'components';

import { addBulkItems, setLastAddedFarmstandId } from 'reduxState/cart';
import { useCatalog, useContent } from 'utils/hooks';
import { FARMSTANDS_2020 } from 'constants/contentful';
import { fetchReviewsByProduct, getFarmstandReviews, YOTPO_FARMSTAND_ID } from 'app/reduxState/userGenerated/userGenerated';
import { getAreProductReviewsMounted } from 'app/reduxState/globalUI';
import paths from 'constants/paths';
import { getFarmstands, getGlowRings } from 'app/reduxState/catalog';

const FarmstandBlock = ({
  title,
  body,
  sizesSection,
  glowRingsSection,
  environmentsSection,
  ctaSection,
  iconsSection,
  accordionSection,
  visualizerSection,
  rentalSection,
  pictures,
  displayPriceRange,
  displayReviewsSection,
  video,
}) => {
  useCatalog();
  const dispatch = useDispatch();
  const location = useLocation();
  const farmstandsCatalog = useSelector(getFarmstands);
  const glowRingsCatalog = useSelector(getGlowRings);
  const reviews = useSelector(getFarmstandReviews);
  const areProductReviewsMounted = useSelector(getAreProductReviewsMounted);
  const reviewsUrl = areProductReviewsMounted ? `${location.pathname}#reviews-and-questions` : paths.CUSTOMER_REVIEWS;
  const reviewsData = displayReviewsSection ? { ...reviews, url: reviewsUrl } : null;
  const farmsContent = useContent(FARMSTANDS_2020)?.[0]?.fields?.farmstandModels || [];
  const farmstandsData = farmsContent.map((e) => ({ ...(e?.fields || {}), id: e?.sys?.id || '' }));

  useEffect(() => {
    // unique logic here to handle this component on shop page for farmstand only
    // shop page does not have <ReviewsList> component which normally is responsible for fetching reviews and hydrating redux slice
    if (displayReviewsSection && !reviews.all.length) {
      dispatch(fetchReviewsByProduct(YOTPO_FARMSTAND_ID));
    }
  }, [reviews.all, displayReviewsSection, dispatch]);

  const onAddFarmstand = ({ farmstandCatalog, glowRingCatalog }) => {
    if (farmstandCatalog) {
      const items = [{ ...farmstandCatalog, qty: 1 }];
      if (glowRingCatalog) items.push({ ...glowRingCatalog, qty: 1 });

      dispatch(addBulkItems({ items }));
      dispatch(setLastAddedFarmstandId(farmstandCatalog.sku));
      if (ctaSection?.path) navigate(ctaSection.path);
    }
  };

  return (
    <FarmProductDetailBlock
      title={title}
      body={body}
      farmstandsData={farmstandsData}
      farmstandsCatalog={farmstandsCatalog}
      glowRingsCatalog={glowRingsCatalog}
      sizesSection={sizesSection}
      glowRingsSection={glowRingsSection}
      environmentsSection={environmentsSection}
      ctaSection={ctaSection}
      iconsSection={iconsSection}
      accordionSection={accordionSection}
      visualizerContent={visualizerSection}
      rentalContent={rentalSection}
      reviewsData={reviewsData}
      video={video}
      mediaBackground={pictures}
      displayPriceRange={displayPriceRange}
      onAddToCart={onAddFarmstand}
    />
  );
};

FarmstandBlock.propTypes = {
  title: PropTypes.any,
  body: PropTypes.any,
  sizesSection: PropTypes.any,
  glowRingsSection: PropTypes.any,
  environmentsSection: PropTypes.any,
  ctaSection: PropTypes.any,
  iconsSection: PropTypes.any,
  accordionSection: PropTypes.any,
  visualizerSection: PropTypes.any,
  rentalSection: PropTypes.object,
  pictures: PropTypes.array,
  displayPriceRange: PropTypes.bool,
  displayReviewsSection: PropTypes.bool,
  video: PropTypes.string,
};

export default FarmstandBlock;
