import styled from 'styled-components/macro';
import { breakPoints } from '@lettucegrow/atoms.utils.theme';

import { Flex } from 'components';

export default styled(Flex)`
  width: 90%;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.farmProject.light_green_5};
  padding-top: 1.5rem;
  @media ${breakPoints.large_break} {
    border-top: none;
    padding: 1rem;
    padding-top: 1rem;
    margin: 0px;
    top: ${({ theme, isPageScrolled }) => (isPageScrolled ? theme.layout.navHeight : theme.layout.navAccessibilityHeight)};
    right: 1rem;
    width: 10rem;
    text-align: left;
    position: absolute;
    background: ${({ theme }) => theme.farmProject.white};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3), 0px 3px 3px 2px rgba(0, 0, 0, 0.15);
    z-index: 11;
  }
`;
