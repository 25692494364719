import styled from 'styled-components/macro';

export default styled.div`
  position: relative;
  overflow: hidden;
  padding: 30%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;
