import { axiosPost } from 'utils/api-utils';
import { GIFT_CARD } from 'constants/contentful';
import logError from 'utils/errorHandler';

export const DUPLICATE_PATH = '/app/lgcom/isDuplicateOrder';

export const createPayload = ({ cart }) => {
  const order = { items: [], discounts: {} };
  cart.items.forEach(({ sku, qty, imageUrl, priceCents }) => {
    order.items.push({
      sku,
      qty,
      ...(sku === GIFT_CARD.id ? { meta: { imageUrl: imageUrl, amountCents: priceCents } } : {}),
    });
  });
  Object.keys(cart.discounts)?.forEach((code) => (order.discounts[code] = { discount_amount: 0 }));

  return order;
};

export const checkIsDuplicatedOrder = async ({ cart, authToken }) => {
  const payload = createPayload({ cart });

  let isDuplicate = false;
  try {
    isDuplicate = (await axiosPost(DUPLICATE_PATH, payload, { withCredentials: true, supressErrorCodeExpectation: true }, authToken))?.data;
    return isDuplicate;
  } catch (error) {
    logError(error);
    return false;
  }
};
